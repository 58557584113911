import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import { IMagicBellTheme } from "@magicbell/magicbell-react"
import { DeepPartial } from "independent/typescript"
import { TypeOfEventBackgroundType } from "constants/general"
import { TypeOfTemplateType } from "constants/general"
import { EventSetupType } from "../eventSetup"

type ExternalLinksType = {
  [key: string]: {
    url: string
    text: string
    iconUrl: string
    id: string
    position: number
  }
}

type EventTypeFromResponse = EventSetupType & {
  name: string
  backgroundType: TypeOfEventBackgroundType
  templateType: TypeOfTemplateType
  startTime: string
  endTime: string
  notificationsKey: string
  giphyApiKey: string
  googleAnalyticsCode?: string
  pubnubPublishKey: string
  pubnubSubscribeKey: string
  pubnubCustomDomain: string
  logVerbosity: boolean
  defaultOpeningStageId?: string
  frontendAirbrakeProjectId?: number
  frontendAirbrakeProjectKey?: string
  algoliaSearchKey?: string
  algoliaApplicationId?: string
  attendeeIndexName?: string
  awsUrl: string
  uploadsKey: string
  uploadsBucket: string
  awsSignerUrl: string
  preStartAccessMinutes: number
  awsRegion: string
  awsAssetsUrl: string
  clickyCode?: string
  newrelicApplicationId?: string
  newrelicLicenseKey?: string
  stageWelcomeMessage?: string
  gscKey?: string
  linkedinId?: string
  linkedinIdEvent?: string
  facebookTrackingId?: string
  hidePhotobooth: boolean
  hideNotifications: boolean
  posthogKey?: string
  displaySearch: boolean
  maxNumberInVideoRoom?: number
  dailyMaxVideoLengthMinutes?: number
  iframeLobbyUrl: string
  notificationsTheming?: DeepPartial<IMagicBellTheme>
  boothIndexName?: string
  navMenuClosed: boolean
  hideNavMenu: boolean
  anonymiseChats: boolean
  hideProfile: boolean
  iframeHoldingPageUrl: string
  photoBoothIndexName?: string
  maxNumberOfGroupPhotoSelections: number
  chatWelcomeMessage: string
  disableGroupPhotos: boolean
  leaderboardPresent: boolean
  leaderboardIndexName: string
  hideGifs: boolean
  breakoutIndexName: string
  connectEventId?: string
  connectMapId?: string
  mingleIframeLinkText?: string
  mingleIframeTitle?: string
  mingleIframeSrc?: string
  dancefloorIframeLinkText?: string
  dancefloorIframeTitle?: string
  dancefloorIframeSrc?: string
  webinarIframeLinkText?: string
  webinarIframeSrc?: string
  webinarIframeTitle?: string
  lastInvalidationAt: string
  footerText?: string
  externalLinks?: ExternalLinksType
}

type EventsResponseType = {
  event: EventTypeFromResponse
}

export type EventType = Omit<EventTypeFromResponse, "lastInvalidationAt"> & {
  lastCacheInvalidationAtAsInteger: number
}

export const fetchEvent =
  () =>
  (eventUuid: string): Promise<EventType> =>
    baseApi
      .get<EventsResponseType>({
        url: apiUrls.event,
        headers: {
          EventToken: eventUuid,
        },
      })
      .then(({ data }) => data)
      .then((data) => {
        const event = data.event

        return {
          ...event,
          lastCacheInvalidationAtAsInteger: new Date(event.lastInvalidationAt).getTime(),
        }
      })
