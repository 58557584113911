import { useCallback, useEffect, useState } from "react"
import { GalleryPhoto } from "api/apiFunctions/photobooth/photoGallery"
import { GroupPhoto } from "api/apiFunctions/photobooth/groupPhoto"
import { useApi } from "api/useApi"

export const usePhotoboothPhotosFetcher = () => {
  const [usersPhotos, setUsersPhotos] = useState<GalleryPhoto[]>([])
  const [otherUsersPhotos, setOtherUsersPhotos] = useState<GalleryPhoto[]>([])
  const [groupPhotos, setGroupPhotos] = useState<GroupPhoto[]>([])
  const { api } = useApi()

  const fetchAndSaveCurrentUsersPhotos = useCallback(async () => {
    try {
      const galleryPhotosResult = await api.photobooth.photoGallery.fetchGalleryPhotos({ isUsersPhotos: true })

      setUsersPhotos(galleryPhotosResult)
    } catch (error) {
      console.error(error)
    }
  }, [api])

  const fetchAndSaveOtherUsersPhotos = useCallback(async () => {
    try {
      const galleryPhotosResult = await api.photobooth.photoGallery.fetchGalleryPhotos({})

      setOtherUsersPhotos(galleryPhotosResult)
    } catch (error) {
      console.error(error)
    }
  }, [api])

  useEffect(() => {
    fetchAndSaveCurrentUsersPhotos()
    fetchAndSaveOtherUsersPhotos()
  }, [fetchAndSaveCurrentUsersPhotos, fetchAndSaveOtherUsersPhotos])

  const fetchAndSaveGroupPhotos = useCallback(async () => {
    try {
      const groupPhotos = await api.photobooth.groupPhoto.fetchGroupPhotos()

      setGroupPhotos(groupPhotos)
    } catch (error) {
      console.error(error)
    }
  }, [api])

  useEffect(() => {
    fetchAndSaveGroupPhotos()
  }, [fetchAndSaveGroupPhotos])

  return { usersPhotos, otherUsersPhotos, groupPhotos, fetchAndSaveGroupPhotos, fetchAndSaveCurrentUsersPhotos }
}
