import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { SliderArrow } from "./SliderArrow"
import { SliderArrowLeft } from "independent/icons/SliderArrowLeft"
import { SliderArrowRight } from "independent/icons/SliderArrowRight"
import { Typography } from "@material-ui/core"
import { reactSlickActiveDotColor } from "components/styles/reactSlickActiveDotColor"

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
`

const CategoryName = styled(Typography)`
  color: ${({ theme }) => theme.custom.colors.currentStage.videoLibrary.category.title.color};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

const transitionCss = ({ base, time, ease }: { base: string; time: string; ease: string }) => `
  -webkit-transition: -webkit-${base} ${time} ${ease};
  -moz-transition: -moz-${base} ${time} ${ease};
  -o-transition: -o-${base} ${time} ${ease};
  transition: ${base} ${time} ${ease};
`

const SliderStyled = styled(Slider)<{ sideMarginsOn: boolean }>`
  ${({ theme, sideMarginsOn }) => (sideMarginsOn ? `margin: ${theme.spacing(0, 4)}` : "")};

  ${({ theme }) => reactSlickActiveDotColor(theme.palette.primary.main)}
`

const SliderItem = styled.div``

const SliderItemContent = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: ${({ theme }) => theme.spacing(1)}px;
  ${transitionCss({ base: "transform", time: "0.2s", ease: "ease-out" })}
  &:hover {
    transform: scale(1.1);
  }
`

const SliderItemOverlay = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${transitionCss({ base: "all", time: "0.2s", ease: "ease" })}

  :hover {
    opacity: 1;
  }
`

const ItemImage = styled.img``

const ItemPlayButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background: #be91ff;
  color: white;
  outline: 0;
  cursor: pointer;
`

const ItemTitle = styled(Typography)`
  color: white;
  margin-top: 10px;
`

const ItemTitleWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.custom.colors.currentStage.videoLibrary.category.videoTitle.background};
`

const ItemBottomTitle = styled(Typography)`
  color: ${({ theme }) => theme.custom.colors.currentStage.videoLibrary.category.videoTitle.color};
  padding: 4px 10px;
  max-width: fit-content;
  width: 100%;
`

type Props = {
  categoryTitle: string
  videos: { id: string; title: string; sourceUrl: string; thumbnailUrl: string }[]
  handleOpenPlayerModal: (url: string) => void
}

export const CategorySlider: React.FC<Props> = ({ categoryTitle, videos, handleOpenPlayerModal }) => {
  return (
    <Wrapper>
      <CategoryName variant="h5">
        <b>{categoryTitle}</b>
      </CategoryName>

      <SliderStyled
        {...{
          infinite: false,
          dots: true,
          variableWidth: true,
          prevArrow: (
            <SliderArrow>
              <SliderArrowLeft />
            </SliderArrow>
          ),
          nextArrow: (
            <SliderArrow right>
              <SliderArrowRight />
            </SliderArrow>
          ),
          responsive: [
            {
              breakpoint: 400,
              settings: {
                variableWidth: false,
              },
            },
          ],
        }}
        sideMarginsOn={videos.length > 1}
      >
        {videos.map(({ id, title, sourceUrl, thumbnailUrl }) => (
          <SliderItem key={id}>
            <SliderItemContent>
              <SliderItemOverlay>
                <ItemPlayButton onClick={(): void => handleOpenPlayerModal(sourceUrl)}>Play</ItemPlayButton>

                <ItemTitle variant="subtitle2">{title}</ItemTitle>
              </SliderItemOverlay>

              <ItemImage src={thumbnailUrl} alt={title} width={250} />

              <ItemTitleWrapper>
                <ItemBottomTitle variant="subtitle2">{title}</ItemBottomTitle>
              </ItemTitleWrapper>
            </SliderItemContent>
          </SliderItem>
        ))}
      </SliderStyled>
    </Wrapper>
  )
}
