import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { XYCoord } from "react-dnd"
import { DraggableData, Rnd, RndDragEvent } from "react-rnd"
import { AddedSticker } from ".."
import { nonDraggable } from "../../PrepareAndViewPhotos.style"
import { useRotateHandle } from "../useRotateHandle"

const resizeHandleClassName = "resize-handle"
const nonDraggableStickerElement = "non-draggable-sticker-element"

const rectIconCss = css`
  position: absolute;
  display: none;

  border-radius: 50%;
  background: #7f347f;
  padding: 4px 6px;
  font-size: 12px;
  color: white;
  cursor: pointer;
  z-index: 6;

  ${({ theme }) => theme.custom.utils.hoverNoneMedia} {
    display: initial;
    font-size: 17px;
  }
`

const RemoveIcon = styled.div`
  ${rectIconCss}
  left: -10px;
  top: -10px;
`

const RotateIcon = styled.div`
  ${rectIconCss}
  right: -10px;
  top: -10px;
`

const ResizeIconCss = css`
  ${rectIconCss}
  padding-left: 5px;
  padding-top: 2px;

  ${({ theme }) => theme.custom.utils.hoverNoneMedia} {
    padding-top: 0px;
    width: 26px !important;
    height: 26px !important;
  }
`

const RndStyled = styled(Rnd)`
  z-index: 5;
  border: 2px solid transparent;

  & .${resizeHandleClassName} {
    ${ResizeIconCss}
  }

  & .${`${resizeHandleClassName}::before`} {
    content: "\\2921";
  }

  :hover
    ${RemoveIcon},
    :active
    ${RemoveIcon},
    :hover
    ${RotateIcon},
    :active
    ${RotateIcon},
    :hover
    .${resizeHandleClassName},
    :active
    .${resizeHandleClassName} {
    display: initial;
  }

  :hover,
  :active {
    border: 2px dashed #ffe6e6;
    z-index: 6;
  }

  ${({ theme }) => theme.custom.utils.hoverNoneMedia} {
    border: 2px dashed #ffe6e6b2;
  }
`

const InnerContainer = styled.div`
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
`

const ImageStyled = styled.img`
  width: 100%;
  height: 100%;

  ${nonDraggable}
`

export const SingleSticker: React.FC<{
  sticker: AddedSticker
  removeSticker: () => void
  onDragSticker: (clientOffset: XYCoord) => void
  onResizeSticker: (width: number, height: number) => void
  onRotateSticker: (angle: number) => void
}> = ({ sticker, removeSticker, onDragSticker, onResizeSticker, onRotateSticker }) => {
  const rotateIconRef = useRef<HTMLDivElement>(null)
  const rotateElementRef = useRef<HTMLDivElement>(null)

  useRotateHandle({
    rotateHandle: rotateIconRef,
    rotateElement: rotateElementRef,
    onRotateStop: onRotateSticker,
  })

  return (
    <>
      <RndStyled
        resizeHandleClasses={{
          bottomRight: `${resizeHandleClassName} ${nonDraggableStickerElement}`,
        }}
        size={{ width: sticker.width, height: sticker.height }}
        position={{ x: sticker.clientOffset.x, y: sticker.clientOffset.y }}
        lockAspectRatio={1}
        cancel={`.${nonDraggableStickerElement}`}
        onDragStop={(e: RndDragEvent, data: DraggableData): void => {
          onDragSticker({ x: data.x, y: data.y })
        }}
        onResizeStop={(e: MouseEvent | TouchEvent, direction: unknown, ref: HTMLElement): void => {
          onResizeSticker(ref.offsetWidth, ref.offsetHeight)
        }}
      >
        <RemoveIcon className={nonDraggableStickerElement} onGotPointerCapture={removeSticker} onClick={removeSticker}>
          &#10005;
        </RemoveIcon>

        <RotateIcon className={nonDraggableStickerElement} ref={rotateIconRef}>
          &#10226;
        </RotateIcon>

        <InnerContainer ref={rotateElementRef}>
          <ImageStyled src={sticker.src} alt="sticker" />
        </InnerContainer>
      </RndStyled>
    </>
  )
}
