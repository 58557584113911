import React from "react"
import styled from "styled-components"
import { PrepareAndViewPhotos } from "./PrepareAndViewPhotos"
import { Dialog, useTheme } from "@material-ui/core"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2.5)}px;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    padding: ${({ theme }) => theme.spacing(0.5)}px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Photobooth: React.FC<{
  open: boolean
  onRequestClose: () => void
}> = ({ open, onRequestClose }) => {
  const theme = useTheme()

  return (
    <>
      <Dialog
        open={open}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: theme.custom.colors.photobooth.background,
          },
        }}
      >
        <Wrapper>
          <ContentWrapper>
            <PrepareAndViewPhotos onRequestClose={onRequestClose} />
          </ContentWrapper>
        </Wrapper>
      </Dialog>
    </>
  )
}
