import { DailyThemeConfig } from "@daily-co/daily-js"
import { Theme } from "@material-ui/core"
import { transparentize } from "polished"

const utils = {
  matchMedia: (mediaString: string): MediaQueryList =>
    window.matchMedia(`(${mediaString.split("(").splice(1).join("(")}`),
  hoverNoneMedia: "@media (pointer: none), (pointer: coarse)",
}

export type ThemeCustom = {
  utils: {
    matchMedia: (mediaString: string) => MediaQueryList
    hoverNoneMedia: string
  }
  colors: {
    mobile: {
      main: {
        background: string
        color: string
      }
      header: {
        background: string
        color: string
      }
      logoImage: {
        background: string
      }
      poll: {
        trigger: {
          background: string
          color: string
        }
      }
      messagesPanel: {
        messageList: {
          background: string
          color: string
        }
        messageWriter: {
          background: string
          color: string
        }
      }
      bottomNavigation: {
        background: string
        color: string
      }
    }
    standard: {
      mainMenu: {
        background: string
        color: string
      }
      appBarActions: {
        tooltipPopper: {
          background: string
          color: string
        }
      }
    }
    headsUpDisplay: {
      generic: {
        background: string
        color: string
      }
      logoImage: {
        background: string
      }
      mainMenu: {
        background: string
        color: string
      }
    }
    background: string
    color: string
    appBar: {
      background: string
      color: string
    }
    currentStage: {
      background: string
      color: string
      videoLibrary: {
        featured: {
          infoPanel: {
            background: string
            color: string
          }
        }
        category: {
          title: {
            color: string
          }
          videoTitle: {
            background: string
            color: string
          }
        }
      }
      mingle: {
        brandColor: string
        iconMainColor: string
      }
    }
    reactions: {
      background: string
      border: string
    }
    messagesPanel: {
      message: {
        background: string
        color: string
        ownersBackground: string
      }
      gifs: {
        picker: {
          background: string
        }
      }
    }
    photobooth: {
      background: string
      countdown: {
        color: string
      }
    }
    dailyThemeConfig: DailyThemeConfig
    openChatRooms: {
      chatRoomPanel: {
        background: string
        color: string
      }
    }
    breakout: {
      background: string
    }
    leaderboard: {
      background: string
      color: string
      predominant: {
        background: string
        color: string
      }
    }
    poll: {
      background: string
      color: string
    }
    snackbar: {
      error: {
        background: string
        color: string
      }
      warning: {
        background: string
        color: string
      }
      info: {
        background: string
        color: string
      }
      success: {
        background: string
        color: string
      }
    }
    errorsBanner: {
      background: string
      color: string
    }
  }
}

export const constructThemeCustom = (theme: Omit<Theme, "custom">): ThemeCustom => ({
  utils,
  colors: {
    mobile: {
      main: {
        background: "white",
        color: "black",
      },
      header: {
        background: "black",
        color: "white",
      },
      logoImage: { background: "black" },
      poll: {
        trigger: {
          background: "#a7a8ab",
          color: "white",
        },
      },
      messagesPanel: {
        messageList: {
          background: "white",
          color: "black",
        },
        messageWriter: {
          background: "#e8e8e8",
          color: "#292929",
        },
      },
      bottomNavigation: {
        background: "black",
        color: "white",
      },
    },
    standard: {
      mainMenu: {
        background: "transparent",
        color: "white",
      },
      appBarActions: {
        tooltipPopper: {
          background: "rgba(75, 75, 75, 0.4)",
          color: "white",
        },
      },
    },
    headsUpDisplay: {
      generic: {
        background: "rgba(75, 75, 75, 0.4)",
        color: "white",
      },
      logoImage: { background: "#24c32459" },
      mainMenu: {
        background: "#000000a6",
        color: "white",
      },
    },
    background: "white",
    color: "#000000DE",
    appBar: {
      background: "black",
      color: "white",
    },
    currentStage: {
      background: "#ffffff30",
      color: "#000000bd",
      videoLibrary: {
        featured: {
          infoPanel: {
            background: "#041038",
            color: "white",
          },
        },
        category: {
          title: {
            color: "white",
          },
          videoTitle: {
            background: "#041038",
            color: "white",
          },
        },
      },
      mingle: {
        brandColor: "#247531",
        iconMainColor: "#00CF92",
      },
    },
    reactions: { background: theme.palette.secondary.main, border: "transparent" },
    messagesPanel: {
      message: {
        background: "rgba(75, 75, 75, 0.4)",
        color: "white",
        ownersBackground: transparentize(0.6, theme.palette.secondary.main),
      },
      gifs: {
        picker: {
          background: "#20398E",
        },
      },
    },
    photobooth: {
      background: "#8145c1e6",
      countdown: { color: "#FFD645" },
    },
    dailyThemeConfig: {
      colors: {
        accent: "#fdbb33",
        background: "#27346a",
        baseText: "#fff",
        mainAreaBg: "#2790c3",
      },
    },
    openChatRooms: {
      chatRoomPanel: { background: "#f7e0f5", color: "#404040" },
    },
    breakout: { background: "#8145c1e6" },
    leaderboard: {
      background: "#000000",
      color: "white",
      predominant: {
        background: "#2D2D2D",
        color: "white",
      },
    },
    poll: { background: "rgba(75, 75, 75, 0.4)", color: "white" },
    snackbar: {
      error: { background: theme.palette.error.main, color: "white" },
      warning: { background: theme.palette.warning.main, color: "white" },
      info: { background: theme.palette.info.main, color: "white" },
      success: { background: theme.palette.success.main, color: "white" },
    },
    errorsBanner: {
      background: "#F5D5D5",
      color: "#F44336",
    },
  },
})
