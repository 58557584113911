import React from "react"
import { getEvent } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"
import { Helmet } from "react-helmet"

export const GetSiteControlScript: React.FC = () => {
  const { gscKey } = useSelector(getEvent)

  return (
    <>
      {gscKey && (
        <Helmet>
          <script async type="text/javascript" src={`//l.getsitecontrol.com/${gscKey}.js`} />
        </Helmet>
      )}
    </>
  )
}
