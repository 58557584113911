import * as t from "runtypes"

const iImageRaw = {
  width: t.Number,
  height: t.Number,
  url: t.String,
}
const iImageOptionalRaw = {
  size: t.String,
}

const iImageOptional = t.Partial(iImageOptionalRaw)

const iWebPRaw = {
  webp: t.String,
  webp_size: t.String,
}

const iMP4Raw = {
  mp4: t.String,
  mp4_size: t.String,
}

const imageAllTypes = t
  .Record({
    ...iImageRaw,
    ...iWebPRaw,
    ...iMP4Raw,
  })
  .And(iImageOptional)

const iImages = t.Record({
  fixed_width: imageAllTypes,
  original: imageAllTypes,
})

export const iGif = t.Record({
  images: iImages,
  title: t.String,
})

export type ImageAllTypes = t.Static<typeof imageAllTypes>
export type IGif = t.Static<typeof iGif>
