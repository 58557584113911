import { GalleryPhoto } from "api/apiFunctions/photobooth/photoGallery"
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined"
import { UserInitialsButton } from "components/UserInitialsButton"
import React from "react"
import styled from "styled-components"
import { Box, Button, Typography } from "@material-ui/core"
import { PhotoShare } from "./PhotoShare/PhotoShare"
import { GroupPhoto } from "api/apiFunctions/photobooth/groupPhoto"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import GetAppIcon from "@material-ui/icons/GetApp"
import { getStageChannelsWithMessages } from "store/domain/pubnubStore/channels/stageChannelsMeta"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(196, 196, 196, 0.6);
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  padding-right: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`

const FirstAndLastName = styled(Typography)`
  display: flex;
  align-items: center;
  color: white;
` as typeof Typography

const DownloadIconWrapper = styled.div`
  svg {
    color: white;
    width: 25px;
    height: 25px;
  }
`

const Photo = styled.img`
  width: 100%;
  border-radius: 5px;
`

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`

export const PhotoPreview: React.FC<{
  previewPhoto: GalleryPhoto | GroupPhoto
  onClickShareToMessages: () => void
}> = ({ previewPhoto, onClickShareToMessages }) => {
  const { attendanceUuid } = useSelector(getCurrentPlatformUser)
  const stageChannelsWithMessages = useSelector(getStageChannelsWithMessages)

  const isOwnerInPhoto =
    previewPhoto.type === "gallery"
      ? previewPhoto.attendanceUuid === attendanceUuid
      : previewPhoto.attendanceUuids.includes(attendanceUuid)

  return (
    <Wrapper>
      <TopBar>
        <FirstAndLastName component="span">
          <UserInitialsButton userName={previewPhoto.username} />

          <Box ml={1}>{previewPhoto.username}</Box>
        </FirstAndLastName>

        {isOwnerInPhoto && (
          <DownloadIconWrapper>
            <a href={previewPhoto.downloadUrl} download="photobooth-image">
              <GetAppIcon />
            </a>
          </DownloadIconWrapper>
        )}
      </TopBar>

      <Photo src={previewPhoto.imageUrl} alt="Gallery Photo" />

      {isOwnerInPhoto && (
        <BottomBar>
          {!!stageChannelsWithMessages.length && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<MessageOutlinedIcon />}
              onClick={onClickShareToMessages}
            >
              Share to comments
            </Button>
          )}

          <PhotoShare previewPhoto={previewPhoto} />
        </BottomBar>
      )}
    </Wrapper>
  )
}
