import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import { LastLeaderboardMovement } from "typescript/leaderboard"

export type LeaderboardBadge = {
  category: string
  description: string
  id: string
  uuid: string
  imageUrl?: string
  name: string
  points: number
  title: string
}

type LeaderboardMetaFromResponse = {
  id: string
  goldPositionUrl?: string
  silverPositionUrl?: string
  bronzePositionUrl?: string
  lastUpdatedAt?: string
  badges: { [id: string]: LeaderboardBadge }
}

export type LeaderboardMeta = Omit<LeaderboardMetaFromResponse, "badges"> & {
  badges: LeaderboardBadge[]
}

export const fetchLeaderboardMeta =
  ({ eventUuid }: { eventUuid: string }) =>
  (): Promise<LeaderboardMeta> =>
    baseApi
      .get<{ leaderboard: LeaderboardMetaFromResponse }>({
        url: apiUrls.leaderboardMeta,
        headers: {
          EventToken: eventUuid,
        },
      })
      .then(({ data }) => data)
      .then((data) => {
        const leaderboard = data.leaderboard

        return {
          ...leaderboard,
          badges: Object.values(leaderboard.badges).sort((badgeA, badgeB) => badgeB.points - badgeA.points),
        }
      })

export type LeaderboardPersonal = {
  lastLeaderboardMovement?: LastLeaderboardMovement
  leaderboardPosition?: number
  score: number
  receivedBadgeUuids: string[]
}

export const fetchLeaderboardPersonal =
  ({ eventUuid, attendanceUuid }: { eventUuid: string; attendanceUuid: string }) =>
  (): Promise<LeaderboardPersonal> =>
    baseApi
      .get<{ attendance: LeaderboardPersonal }>({
        url: apiUrls.leaderboardPersonal(attendanceUuid),
        headers: {
          EventToken: eventUuid,
        },
      })
      .then(({ data }) => data)
      .then((data) => data.attendance)
