import React from "react"
import styled from "styled-components"
import { CONTACT_EMAIL, CONTACT_EMAIL_LINK } from "constants/general"
import { Typography } from "@material-ui/core"
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined"

const TypographyStyled = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-decoration: none;
  color: #ffffff;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    margin-top: ${({ theme }) => theme.spacing(3)}px;
  }
` as typeof Typography

const EmailIconStyled = styled(EmailOutlinedIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

export const ContactEmail: React.FC = () => {
  return (
    <TypographyStyled component="a" variant="h5" href={`mailto:${CONTACT_EMAIL}`}>
      <EmailIconStyled fontSize="large" />
      {CONTACT_EMAIL_LINK}
    </TypographyStyled>
  )
}
