import React from "react"
import { useSelector } from "store/setup/useSelector"
import { useDispatch } from "store/setup/useDispatch"
import { getLayout, toggleStageMessages } from "store/domain/layout"
import { Button, useMediaQuery, useTheme } from "@material-ui/core"
import styled from "styled-components"
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined"
import CloseIcon from "@material-ui/icons/Close"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"

const ButtonStyled = styled(Button)`
  min-width: unset;
`

export const StageMessagesButton: React.FC = () => {
  const dispatch = useDispatch()
  const layout = useSelector(getLayout)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const currentStageChannel = useSelector(getCurrentStageChannel)

  return (
    <>
      {!currentStageChannel.custom.hide_messages && (
        <ButtonStyled
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            dispatch(toggleStageMessages())
          }}
        >
          {layout.stageMessages ? <CloseIcon /> : <CommentOutlinedIcon />}

          {!isXs && <>&nbsp;COMMENTS</>}
        </ButtonStyled>
      )}
    </>
  )
}
