import React from "react"
import { StagesList } from "../../baseBlocks/StagesList"
import {
  currentStageDisplayTypeSelector,
  getCurrentStageChannel,
} from "store/domain/currentStageContent/currentStageChannel"
import { useSelector } from "store/setup/useSelector"
import { Box, Hidden, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { MenuButton } from "../../baseBlocks/MenuButton"
import { getLayout } from "store/domain/layout"
import styled, { css } from "styled-components"
import { LogoImage } from "components/LogoImage"
import { AppBarActions } from "./AppBarActions"
import { OpenChatRooms } from "../../assembledBlocks/OpenChatRooms"
import { ReactionsList } from "../../baseBlocks/ReactionsList"
import { ContentToShow } from "../AppTemplate"
import { CurrentStageContent } from "features/mainContentBlocks/CurrentStageContent"
import { Expobooth } from "features/baseBlocks/Expobooth"
import { mainContentWrapperCss } from "components/styles/mainContentWrapperCss"
import { ToolbarMixin } from "components/ToolbarMixin"
import { getEvent } from "store/domain/event"
import { MobileTemplate } from "../MobileTemplate"
import { BackgroundWrapper } from "components/BackgroundWrapper"
import { transparentize } from "polished"

const stagesDrawerWidth = 240

const AppBarStyled = styled(AppBar)`
  ${({ theme }) => `
    position: absolute;
    z-index: ${theme.zIndex.drawer + 1};
    background: ${theme.custom.colors.appBar.background};
    color: ${theme.custom.colors.appBar.color};
  `}
` as typeof AppBar

const ToolbarStyled = styled(Toolbar)`
  ${({ theme }) => `
    padding-left: ${theme.spacing(2)}px;
  `}
`

const LogoImageStyled = styled(LogoImage)`
  ${({ theme }) => `
    padding: ${theme.spacing(1)}px;
    width: ${theme.spacing(40)}px;
  `}
`

const stagesDrawerOpenCss = css<{ $permanentStagesDrawerOpen: boolean }>`
  ${({ theme, $permanentStagesDrawerOpen }) =>
    $permanentStagesDrawerOpen
      ? `
        width: ${stagesDrawerWidth}px;
        transition: ${theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })};
      `
      : ""}
`

const stagesDrawerClosedCss = css<{ $permanentStagesDrawerOpen: boolean }>`
  ${({ theme, $permanentStagesDrawerOpen }) =>
    !$permanentStagesDrawerOpen
      ? `
        transition: ${theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
        overflow-x: hidden;
        width: ${theme.spacing(7) + 1}px;
      `
      : ""}
`

const StagesDrawer = styled(Drawer)<{ $permanentStagesDrawerOpen: boolean }>`
  width: ${stagesDrawerWidth}px;
  flex-shrink: 0;
  white-space: nowrap;

  ${stagesDrawerOpenCss}
  ${stagesDrawerClosedCss}

  .MuiDrawer-paper {
    border-right: none;
    background: ${({ theme }) => theme.custom.colors.standard.mainMenu.background};
    position: relative;

    ${stagesDrawerOpenCss}
    ${stagesDrawerClosedCss}
  }
`

const StagesListStyled = styled(StagesList)`
  color: ${({ theme }) => theme.custom.colors.standard.mainMenu.color};

  #stages-list-divider {
    background: ${({ theme }) => theme.custom.colors.standard.mainMenu.color};
  }
`

const AppBarContent = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ${theme.breakpoints.down("sm")} {
      justify-content: flex-end;
    }
  `}
`

const ContentWrapper = styled(Box)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding: ${theme.spacing(1)}px;
  `}
`

const ReactionsWrapper = styled(Box)`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: center;
    margin: ${theme.spacing(1, 0)};

    .MuiIconButton-root {
      padding: 9px;
    }
  `}
`

const ExpoboothStyled = styled(Expobooth)`
  ${mainContentWrapperCss}
`

const FooterText = styled(Typography)`
  color: ${({ theme }) => transparentize(0.3, theme.custom.colors.color)};
  white-space: nowrap;
  margin: 4px 15px 0 auto;
`

export const StandardTemplate: React.FC<{ contentToShow: ContentToShow }> = ({ contentToShow }) => {
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)
  const layout = useSelector(getLayout)
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const event = useSelector(getEvent)
  const { footerText } = useSelector(getEvent)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <BackgroundWrapper>
      {isXs ? (
        <MobileTemplate contentToShow={contentToShow} />
      ) : (
        <Box display="flex" height="100%">
          {!currentStageDisplayType.lobby && (
            <AppBarStyled>
              <ToolbarStyled>
                {!event.hideNavMenu && <MenuButton />}

                <AppBarContent>
                  <Hidden smDown>
                    <LogoImageStyled />
                  </Hidden>

                  <AppBarActions />
                </AppBarContent>
              </ToolbarStyled>
            </AppBarStyled>
          )}

          {!currentStageDisplayType.lobby && !event.hideNavMenu && (
            <StagesDrawer variant="permanent" $permanentStagesDrawerOpen={layout.menu}>
              <ToolbarMixin />

              <StagesListStyled showIcon horizontallySmall={!layout.menu} />
            </StagesDrawer>
          )}

          <ContentWrapper>
            {!currentStageDisplayType.lobby && <ToolbarMixin />}

            {contentToShow === ContentToShow.CurrentStage && <CurrentStageContent />}

            {contentToShow === ContentToShow.Expobooth && <ExpoboothStyled />}

            <Box display="flex" justifyContent="space-between" alignItems="center">
              {!currentStageDisplayType.lobby && !currentStageChannel.custom.hide_reactions && (
                <ReactionsWrapper>
                  <ReactionsList />
                </ReactionsWrapper>
              )}

              {footerText && <FooterText variant="subtitle1">{footerText}</FooterText>}
            </Box>
          </ContentWrapper>

          {!currentStageDisplayType.lobby && <OpenChatRooms />}
        </Box>
      )}
    </BackgroundWrapper>
  )
}
