import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { SliderArrowLeft } from "independent/icons/SliderArrowLeft"
import { SliderArrowRight } from "independent/icons/SliderArrowRight"
import { PlayButton } from "./PlayButton"
import { Typography } from "@material-ui/core"
import { SliderArrow } from "./SliderArrow"
import { SLIDER_ARROW_WIDTH } from "../constants"
import { reactSlickActiveDotColor } from "components/styles/reactSlickActiveDotColor"

const FeaturedSliderStyled = styled(Slider)`
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  display: flex;
  justify-content: center;

  .slick-list {
    width: calc(100% - ${2 * SLIDER_ARROW_WIDTH}px);
  }

  ${({ theme }) => reactSlickActiveDotColor(theme.palette.primary.main)}
`

const FeaturedSliderItem = styled.div``

const FeaturedItemContent = styled.div`
  position: relative;
  display: flex;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    flex-direction: column-reverse;
  }
`
const infoPanelWidthPercentage = 42

const InfoPanel = styled.div`
  width: ${infoPanelWidthPercentage}%;
  background: ${({ theme }) => theme.custom.colors.currentStage.videoLibrary.featured.infoPanel.background};
  position: relative;
  z-index: 10;
  padding: ${({ theme }) => theme.spacing(2.5, 2.5, 5)};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    width: 100%;
  }
`

const FeaturedTitle = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.custom.colors.currentStage.videoLibrary.featured.infoPanel.color};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  overflow: hidden;
  max-height: 150px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  flex: 0 1 auto;
`

const FeaturedDescriptionWrapper = styled.div`
  color: ${({ theme }) => theme.custom.colors.currentStage.videoLibrary.featured.infoPanel.color};
  overflow: hidden;
  flex: 1 1 auto;
  position: relative;
`

const FeaturedDescription = styled(Typography)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: auto;
  max-height: 600px;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    position: initial;
    max-height: 300px;
  }
`

const ImageWrapper = styled.div`
  width: ${100 - infoPanelWidthPercentage}%;
  position: relative;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    width: 100%;
  }
`

const ImageStyled = styled.img`
  width: 100%;
`

const PlayButtonWrapper = styled.div`
  width: ${100 - infoPanelWidthPercentage}%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    width: 100%;
  }
`

type Props = {
  videos: {
    id: string
    title: string
    description: string
    sourceUrl: string
    featuredThumbnailUrl?: string
  }[]
  handleOpenPlayerModal: (url: string) => void
}

export const FeaturedSlider: React.FC<Props> = ({ videos, handleOpenPlayerModal }) => {
  return (
    <FeaturedSliderStyled
      {...{
        infinite: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: (
          <SliderArrow place="left">
            <SliderArrowLeft />
          </SliderArrow>
        ),
        nextArrow: (
          <SliderArrow place="right">
            <SliderArrowRight />
          </SliderArrow>
        ),
      }}
    >
      {videos.map(({ id, title, description, sourceUrl, featuredThumbnailUrl }) => (
        <FeaturedSliderItem key={id}>
          <FeaturedItemContent>
            <InfoPanel>
              <FeaturedTitle variant="h5" dangerouslySetInnerHTML={{ __html: title }} />

              <FeaturedDescriptionWrapper>
                <FeaturedDescription dangerouslySetInnerHTML={{ __html: description }} />
              </FeaturedDescriptionWrapper>
            </InfoPanel>

            <ImageWrapper>
              <PlayButtonWrapper>
                <PlayButton handleOpenPlayerMedia={(): void => handleOpenPlayerModal(sourceUrl)} />
              </PlayButtonWrapper>

              <ImageStyled src={featuredThumbnailUrl} alt={title} />
            </ImageWrapper>
          </FeaturedItemContent>
        </FeaturedSliderItem>
      ))}
    </FeaturedSliderStyled>
  )
}
