import React from "react"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import { ContactEmail } from "components/ContactEmail"
import moment from "moment"
import { getEvent, useAssets } from "store/domain/event"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: white;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing(10.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(10.5)}px;
`

const MainText = styled.div`
  color: white;
  font-size: 5vw;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 3vw;
  }
`

const SecondaryText = styled.div`
  font-size: 5vw;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  text-align: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 3vw;
  }
`

const StartDateText = styled.div`
  font-size: 5vw;
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    font-size: 3vw;
  }
`

const StartDateValue = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 0;
  }
`

const MainTitleImageNarrow = styled.img`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  max-width: 200px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
    min-width: 350px;
    max-width: 450px;
  }
`

const MailWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  }
`

export const PrePostEventPage: React.FC<{ postEvent?: boolean }> = ({ postEvent = false }) => {
  const { startTime } = useSelector(getEvent)
  const assets = useAssets()

  return (
    <Wrapper>
      <InnerWrapper>
        <MainText>{postEvent ? "THE EVENT HAS NOW FINISHED" : "YOU HAVE BEEN REGISTERED"}</MainText>
        <SecondaryText>
          {postEvent ? (
            "THANK YOU FOR ATTENDING"
          ) : (
            <StartDateText>
              The Event will open at&nbsp;
              <StartDateValue>{moment(startTime).local().format("HH:mm on Do MMMM YYYY")}</StartDateValue>
            </StartDateText>
          )}
        </SecondaryText>
        <MainTitleImageNarrow src={assets.mainTitleCircle} />
        <MailWrapper>
          <ContactEmail />
        </MailWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}
