import React from "react"
import styled from "styled-components"
import { CONTACT_EMAIL } from "constants/general"
import { Typography } from "@material-ui/core"

const TypographyStyled = styled(Typography)`
  color: inherit;
` as typeof Typography

export const ContactEmailLink: React.FC = () => {
  return (
    <TypographyStyled component="a" href={`mailto:${CONTACT_EMAIL}`}>
      {CONTACT_EMAIL}
    </TypographyStyled>
  )
}
