import React from "react"
import { UserInitialsButton } from "components/UserInitialsButton"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { getEvent } from "store/domain/event"
import { LeaderboardOpenButton } from "features/baseBlocks/leaderboard/LeaderboardOpenButton"
import { PhotoboothTrigger } from "./PhotoboothTrigger"
import { RoomsSetupDialogAndMenu } from "./RoomsSetupDialogAndMenu"
import styled from "styled-components"
import { Box } from "@material-ui/core"
import { AppBarActionTooltip } from "./AppBarActionTooltip"
import { BellNotifications } from "features/baseBlocks/BellNotifications"
import { PhotoboothOpener } from "features/baseBlocks/PhotoboothOpener"

const LeaderboardOpenButtonStyled = styled(LeaderboardOpenButton)`
  #leaderboard-icon {
    background: ${({ theme }) => theme.custom.colors.appBar.color};
  }
`

export const AppBarActions: React.FC = () => {
  const { userName } = useSelector(getCurrentPlatformUser)
  const { hidePhotobooth, hideNotifications, displaySearch, hideProfile, leaderboardPresent } = useSelector(getEvent) //

  return (
    <Box display="flex" alignItems="center">
      {!hidePhotobooth && <PhotoboothOpener OpenerComponent={PhotoboothTrigger} />}

      {leaderboardPresent && <LeaderboardOpenButtonStyled TooltipForIcon={AppBarActionTooltip} />}

      {displaySearch && <RoomsSetupDialogAndMenu />}

      {!hideNotifications && <BellNotifications TooltipForIcon={AppBarActionTooltip} />}

      {!hideProfile && <UserInitialsButton userName={userName} TooltipForIcon={AppBarActionTooltip} />}
    </Box>
  )
}
