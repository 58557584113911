import { lighten } from "polished"
import React from "react"
import styled from "styled-components"
import { SLIDER_ARROW_WIDTH } from "../../constants"

type Props = { place: "left" | "right" }

const Wrapper = styled.div<{ place: Props["place"] }>`
  z-index: 99;
  display: block;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${SLIDER_ARROW_WIDTH}px;
  background: ${({ theme }) => theme.palette.primary.main};
  ${({ place }) => `${place === "left" ? "left: " : "right: "}0;`}

  :before {
    content: unset;
  }

  :hover {
    background: ${({ theme }) => lighten(0.1, theme.palette.primary.main)};
  }
`

export const SliderArrow: React.FC<Props> = ({ children, ...restProps }) => {
  const { place, ...rest } = restProps

  const { className, style, onClick } = rest as any

  return (
    <Wrapper className={className} style={{ ...style }} onClick={onClick} place={place}>
      {children}
    </Wrapper>
  )
}
