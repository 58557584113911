import React from "react"

import { TEMPLATE_TYPES, TypeOfTemplateType } from "constants/general"
import { LeaderboardDrawer } from "features/baseBlocks/leaderboard/LeaderboardDrawer"
import { SetupRoomsDialog } from "features/baseBlocks/rooms/SetupRoomsDialog"
import { useSelector } from "store/setup/useSelector"

import { ConnectTemplate } from "./ConnectTemplate"
import { HeadsUpDisplayTemplate } from "./HeadsUpDisplayTemplate"
import { SingleStageTemplate } from "./SingleStageTemplate"
import { StandardTemplate } from "./StandardTemplate"
import { getEvent } from "store/domain/event"

export enum ContentToShow {
  CurrentStage,
  Expobooth,
}

type Props = {
  contentToShow: ContentToShow
}

// const aaa = "standard" as TypeOfTemplateType
// const aaa = "heads_up_display" as TypeOfTemplateType
// const aaa = "connect" as TypeOfTemplateType
const aaa = "single_stage" as TypeOfTemplateType

export const AppTemplate: React.FC<Props> = ({ contentToShow }) => {
  const { templateType } = useSelector(getEvent)

  console.log("Template type: ", templateType, aaa)

  const renderTemplate = (): JSX.Element => {
    switch (aaa) {
      case TEMPLATE_TYPES.standard:
        return <StandardTemplate contentToShow={contentToShow} />
      case TEMPLATE_TYPES.headsUpDisplay:
        return <HeadsUpDisplayTemplate contentToShow={contentToShow} />
      case TEMPLATE_TYPES.connect:
        return <ConnectTemplate />
      case TEMPLATE_TYPES.singleStage:
        return <SingleStageTemplate />

      default: {
        return <>Template not found.</>
      }
    }
  }

  return (
    <>
      <SetupRoomsDialog />

      <LeaderboardDrawer />

      {renderTemplate()}
    </>
  )
}
