import React from "react"
import Menu from "@material-ui/core/Menu"
import { IconButton, Box, Button, Tooltip, TooltipProps } from "@material-ui/core"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import { ChatRoomList } from "../../baseBlocks/rooms/ChatRoomList"
import { useSelector } from "store/setup/useSelector"
import { UnreadMessagesBadge } from "components/UnreadMessagesBadge"
import { isUnreadMessageInAnyRoomChannelsSelector } from "store/domain/pubnubStore/channels/roomChannels"
import styled from "styled-components"
import { SetupRoomsDialogOpener } from "../../baseBlocks/rooms/SetupRoomsDialogOpener"
import { addOpenChatRoom } from "store/domain/openChatRooms"
import { useDispatch } from "store/setup/useDispatch"

const MenuInnerWrapper = styled.div`
  width: ${({ theme }) => theme.spacing(40)}px;
  max-width: 100%;
`

export const ChatRoomsMenu: React.FC<{ TooltipForIcon?: React.FC<TooltipProps> }> = ({ TooltipForIcon }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchorEl)
  const isUnreadMessageInAnyRoomChannels = useSelector(isUnreadMessageInAnyRoomChannelsSelector)
  const dispatch = useDispatch()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const onClickChatRoom = (channelId: string) => {
    dispatch(addOpenChatRoom(channelId))
    handleMenuClose()
  }

  const TooltipConditional = TooltipForIcon || Tooltip

  return (
    <>
      <TooltipConditional title="Messages">
        <IconButton onClick={handleMenuOpen} color="inherit">
          <UnreadMessagesBadge invisible={!isUnreadMessageInAnyRoomChannels}>
            <QuestionAnswerIcon />
          </UnreadMessagesBadge>
        </IconButton>
      </TooltipConditional>

      <Menu
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuInnerWrapper>
          <Box mx={2} mb={2}>
            <SetupRoomsDialogOpener
              OpenerComponent={({ onClick: openDialog }) => (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    openDialog()

                    handleMenuClose()
                  }}
                >
                  Start a new chat here
                </Button>
              )}
            />
          </Box>

          <ChatRoomList onClickRoom={onClickChatRoom} />
        </MenuInnerWrapper>
      </Menu>
    </>
  )
}
