import { Box, Typography } from "@material-ui/core"
import { LeaderboardBadge } from "api/apiFunctions/leaderboard"
import React from "react"
import styled from "styled-components"
import { BadgeDisplayed } from "../../../common/BadgeDisplayed"

const Wrapper = styled(Box)<{ $highlightedBackground: boolean }>`
  margin-top: 8px;
  padding-top: 15px;
  padding-bottom: 15px;

  ${({ $highlightedBackground, theme }) =>
    $highlightedBackground
      ? `
    background: ${theme.custom.colors.leaderboard.predominant.background};
    color: ${theme.custom.colors.leaderboard.predominant.color};
  `
      : ""}
`

const SectionTitle = styled(Typography)`
  padding: 8px 29px;
  display: flex;
` as typeof Typography

const BadgeItem = styled(Box)`
  display: flex;
  padding: 8px 29px;
`

type Props = {
  badgesList: LeaderboardBadge[]
  badgesTotalCount: number
  sectionTitle: string
  highlightedBackground?: boolean
}

export const BadgesSection: React.FC<Props> = ({
  sectionTitle,
  badgesList,
  badgesTotalCount,
  highlightedBackground = false,
}) => {
  return (
    <Wrapper $highlightedBackground={highlightedBackground}>
      <SectionTitle component="div">
        <b>{sectionTitle}</b>

        <Box ml={1}>
          {badgesList.length}/{badgesTotalCount}
        </Box>
      </SectionTitle>

      {badgesList.map((badge) => (
        <BadgeItem key={badge.id}>
          <Box mr={1.2}>
            <BadgeDisplayed imageUrl={badge.imageUrl} />
          </Box>

          <Box display="flex" flexDirection="column">
            <Box mb={2}>
              <b>{badge.title}</b>
            </Box>

            <Box>{badge.description}</Box>
          </Box>
        </BadgeItem>
      ))}
    </Wrapper>
  )
}
