import React from "react"
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import { ActionButton } from "../shared.style"
import PhoneIcon from "@material-ui/icons/Phone"
import { SetupVideoRoomConfirmationDialog } from "./SetupVideoRoomConfirmationDialog"
import { RoomUser } from "../.."

const obtainUserNames = (addedUsers: RoomUser[]) =>
  addedUsers.reduce(
    (userNames, { firstName, lastName }, currentIndex) =>
      `${currentIndex ? `${userNames}, ` : ""} ${firstName} ${lastName}`,
    "",
  )

type Props = { onClickSetupVideo: () => void; addedUsers: RoomUser[]; loading: boolean }

export const SetupVideoButton: React.FC<Props> = ({ onClickSetupVideo, addedUsers, loading }) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <SetupVideoRoomConfirmationDialog
      OpenerComponent={({ onClick }) => (
        <>
          {isXs ? (
            <ActionButton color="primary" disabled={loading} variant="contained" onClick={onClick}>
              <b>CALL</b>
            </ActionButton>
          ) : (
            <IconButton size="small" disabled={loading} color="primary" onClick={onClick}>
              <PhoneIcon />
            </IconButton>
          )}
        </>
      )}
      onConfirm={onClickSetupVideo}
      userNames={obtainUserNames(addedUsers)}
      userCount={addedUsers.length}
    />
  )
}
