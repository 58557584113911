import React, { useRef } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { SnackbarProvider } from "notistack"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { SnackbarUtilsConfigurator } from "components/snackbar/snackbarUtils"

const CloseButton = styled(IconButton)`
  color: inherit;
`

const SnackbarGlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    .SnackbarItem-variantError {
      background-color: ${theme.custom.colors.snackbar.error.background} !important;
      color: ${theme.custom.colors.snackbar.error.color} !important;
    }

    .SnackbarItem-variantWarning {
      background-color: ${theme.custom.colors.snackbar.warning.background} !important;
      color: ${theme.custom.colors.snackbar.warning.color} !important;
    }

    .SnackbarItem-variantInfo {
      background-color: ${theme.custom.colors.snackbar.info.background} !important;
      color: ${theme.custom.colors.snackbar.info.color} !important;
    }

    .SnackbarItem-variantSuccess {
      background-color: ${theme.custom.colors.snackbar.success.background} !important;
      color: ${theme.custom.colors.snackbar.success.color} !important;
    }
  `}
`
export const SnackbarSetup: React.FC = ({ children }) => {
  const notistackRef = useRef<SnackbarProvider | null>(null)

  const onClickDismiss = (key: any) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key)
    }
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={10}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      action={(key) => (
        <CloseButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </CloseButton>
      )}
    >
      <SnackbarUtilsConfigurator />
      <SnackbarGlobalStyles />
      {children}
    </SnackbarProvider>
  )
}
