import React, { useContext, useEffect } from "react"
import { useSelector } from "store/setup/useSelector"
import PubNub from "pubnub"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { usePubNub } from "pubnub-react"
import { Poll } from "./Poll"
import { pollResults } from "runtypes/pollResults"
import { useDispatch } from "store/setup/useDispatch"
import { getCurrentStageChannelPoll, setPollResults, voteOnPoll } from "store/domain/currentStageContent/polls"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"

type PollAnswersArray = string[]

type Props = {
  pollUuid: string
  pollAnswersArray: PollAnswersArray
}

export const PollActive: React.FC<Props> = ({ pollUuid, pollAnswersArray }) => {
  const dispatch = useDispatch()
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const currentPoll = useSelector(getCurrentStageChannelPoll)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)
  const pubnub = usePubNub()
  const pollSendingChannelId = `poll.${currentStageChannel.id}`
  const pollReceivingChannelId = `resultspoll.${currentStageChannel.id}`

  useEffect(() => {
    pubnub.addListener({
      message: (messageEvent: PubNub.MessageEvent): void => {
        if (pollResults.guard(messageEvent.message)) {
          const results = messageEvent.message

          dispatch(setPollResults({ pollUuid, results }))
        }
      },
    })

    return () => {
      pubnub.unsubscribe({ channels: [pollReceivingChannelId] })
    }
  }, [pubnub, pollReceivingChannelId, dispatch, pollUuid])

  const onClickVote = async (vote: string) => {
    const indexOfAnswer = pollAnswersArray.indexOf(vote)

    if (indexOfAnswer !== -1) {
      dispatch(
        voteOnPoll({
          pollUuid,
          vote,
        }),
      )

      pubnub.subscribe({ channels: [pollReceivingChannelId] })

      try {
        await pubnub.publish({
          channel: pollSendingChannelId,
          message: {
            senderId: currentPlatformUser.uuid,
            vote_number: indexOfAnswer,
            poll_uuid: pollUuid,
          },
        })
      } catch (e) {
        airbrakeNotifier.notify({
          error: e,
          params: { customMessage: "On poll vote, pubnub.publish failed." },
        })
      }
    }
  }

  return (
    <Poll
      answers={pollAnswersArray}
      votePercentages={currentPoll?.results}
      vote={currentPoll?.vote}
      onVote={onClickVote}
    />
  )
}
