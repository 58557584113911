import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
import map from "lodash/map"
import sortBy from "lodash/sortBy"

type VideoLibraryContentResponseType = {
  videoCategories: {
    [key: string]: {
      id: string
      position: number
      title: string
    }
  }
  videos: {
    [key: string]: {
      id: string
      sourceUrl: string
      title: string
      description: string
      featured: boolean
      featuredThumbnailUrl?: string
      thumbnailUrl: string
      releasedAt: string
      videoCategoryId: string
    }
  }
}

type VideoLibraryCategory = {
  id: string
  position: number
  title: string
}

type LibraryVideo = {
  id: string
  sourceUrl: string
  title: string
  description: string
  featured: boolean
  featuredThumbnailUrl?: string
  thumbnailUrl: string
  releasedAt: string
  videoCategoryId: string
}

export type VideoLibraryContent = {
  featuredVideos: LibraryVideo[]
  videosByCategory: (VideoLibraryCategory & { videos: LibraryVideo[] })[]
}

export const getVideLibraryContent =
  ({ eventUuid }: { eventUuid: string }) =>
  (): Promise<VideoLibraryContent> =>
    baseApi
      .get<VideoLibraryContentResponseType>({
        url: apiUrls.videoLibrary.videos,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          include: "video_category",
          per_page: 100,
        },
      })
      .then(({ data }) => data)
      .then((data) => (isEmpty(data) ? { videos: {}, videoCategories: {} } : data))
      .then((data) => ({
        featuredVideos: filter(data.videos, ({ featured }) => featured),

        videosByCategory: sortBy(
          map(data.videoCategories, (category) => ({
            ...category,
            videos: filter(
              data.videos,
              ({ videoCategoryId, featured }) => videoCategoryId === category.id && !featured,
            ),
          })),
          ["position"],
        ).filter(({ videos }) => videos.length),
      }))
