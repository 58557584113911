import React from "react"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { PrePostEventIframe } from "./PrePostEventIframe"
import { PrePostEventPage } from "./PrePostEventPage"

export const PrePostEvent: React.FC<{ postEvent?: boolean }> = ({ postEvent = false }) => {
  const { iframeHoldingPageUrl } = useSelector(getEvent)

  return (
    <>
      {iframeHoldingPageUrl ? (
        <PrePostEventIframe iframeHoldingPageUrl={iframeHoldingPageUrl} />
      ) : (
        <PrePostEventPage postEvent={postEvent} />
      )}
    </>
  )
}
