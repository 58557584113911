import React from "react"
import { MessageListItem } from "./MessageListItem"
import { MessageFragment } from "store/domain/pubnubStore/messages"
import styled from "styled-components"
import { useMessagesAndScrolling } from "../useMessagesAndScrolling"
import { Button, useMediaQuery } from "@material-ui/core"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import { Typography, useTheme } from "@material-ui/core"
import { Virtuoso } from "react-virtuoso"

const Wrapper = styled.div<{ forMobile: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  color: ${({ theme }) => theme.custom.colors.messagesPanel.message.color};

  ${({ theme, forMobile }) =>
    forMobile
      ? `
        background: ${theme.custom.colors.mobile.messagesPanel.messageList.background};
        color: ${theme.custom.colors.mobile.messagesPanel.messageList.color};
      `
      : ""}
`

const MessageListItemWrapper = styled.div`
  overflow: hidden;
`

const ScrollDownButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  border-radius: 15px;
  width: fit-content;
  align-self: center;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    min-width: initial;
    padding: 5px 5px;
    border-radius: 50%;
  }
`

type Props = {
  channelId: string
  isWelcomeMessagePresent: boolean
  scrollDownButtonText: string
  forMobile: boolean
  messagesAndScrolling: ReturnType<typeof useMessagesAndScrolling>
}

export const MessageList: React.FC<Props> = ({
  channelId,
  scrollDownButtonText,
  forMobile,
  messagesAndScrolling: {
    virtuosoMessagesRef,
    messages,
    atBottomStateChange,
    scrollToBottomMessage,
    isWelcomeMessagePresent,
    showScrollDownButton,
  },
}) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <Wrapper forMobile={forMobile}>
      <Virtuoso
        ref={virtuosoMessagesRef}
        data={messages}
        itemContent={(index: number, currentMessage: MessageFragment) => (
          <MessageListItemWrapper>
            <MessageListItem
              channelId={channelId}
              messageFragment={currentMessage}
              key={currentMessage.timetoken}
              forMobile={forMobile}
              disableReactions={isWelcomeMessagePresent && index === 0}
            />
          </MessageListItemWrapper>
        )}
        atBottomStateChange={atBottomStateChange}
        followOutput="auto"
      />

      {showScrollDownButton && (
        <ScrollDownButton size="small" color="primary" variant="contained" onClick={scrollToBottomMessage}>
          <ArrowDownwardIcon />

          {!isXs && <Typography variant="body2">{scrollDownButtonText}</Typography>}
        </ScrollDownButton>
      )}
    </Wrapper>
  )
}
