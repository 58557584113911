import { configureStore } from "@reduxjs/toolkit"
import Pubnub from "pubnub"
import { rootReducer } from "./rootReducer"

export const createAppStore = (pubnubInstance: Pubnub) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            pubnub: {
              api: pubnubInstance,
            },
          },
        },
      }),
  })
