import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import { Theme, ThemeOptions } from "@material-ui/core"
import { DeepPartial } from "independent/typescript"

const REGISTRATION_TYPES = {
  emailLogin: "email_login",
  guestList: "guest_list",
  domainList: "domain_list",
  skipAuthentication: "skip_authentication",
} as const

type RegistrationType = typeof REGISTRATION_TYPES[keyof typeof REGISTRATION_TYPES]

export type BrandingJsonType = { palette?: ThemeOptions["palette"]; custom?: DeepPartial<Theme["custom"]> }

type Assets = {
  background: string
  platformLogo: string
  getDancing: string
  mainTitleCircle: string
  favicon: string
  callRequestSound: string
  photobooth: {
    frameBackgroundImage: string
  }
  reactions: string[]
  stageImages: {
    iframe: string
    lobby: string
    breakout: string
    expo: string
    mingle: string
    videoGallery: string
    dancefloor: string
    connect: string
    webinar: string
  }
  lobbyStage: {
    mainCardFirstLogo: string
    mainCardSecondLogo: string
    cardMainStageBackgroundImageMobile: string
    cardMainStageBackgroundImage: string
    cardEntertainmentBackgroundImage: string
    cardEntertainmentBackgroundImageMobile: string
    vipAreaShopBackgroundImage: string
    vipAreaShopBackgroundImageMobile: string
    agendaImage: string
  }
  tiktokIcon: string
  leaderboard: {
    triggerIcon: string
  }
}

type EventSetupTypeFromResponse = {
  uuid: string
  brandingJson: BrandingJsonType
  authenticationType: RegistrationType
  intercomCode?: string
  assets: Assets
}

export type EventSetupType = Omit<EventSetupTypeFromResponse, "authenticationType"> & {
  skipAuthentication: boolean
}

export const fetchEventSetup = (eventSlug: string): Promise<EventSetupType> =>
  baseApi
    .get<{ event: EventSetupTypeFromResponse }>({
      url: apiUrls.eventSetup(eventSlug),
    })
    .then(({ data }) => {
      const eventSetup = data.event

      return {
        ...eventSetup,
        /*
        todo skip authentication logic needs to be written in LoginWidget, ask BE how should FE acquire user information
        if login is not needed?
        */
        skipAuthentication: eventSetup.authenticationType === REGISTRATION_TYPES.skipAuthentication,
      }
    })
