import { Box, Grid, Tooltip } from "@material-ui/core"
import React from "react"
import styled, { css } from "styled-components"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import YouTubeIcon from "@material-ui/icons/YouTube"
import PinterestIcon from "@material-ui/icons/Pinterest"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import { useAssets } from "store/domain/event"

const socialsCss = css`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(1.2)}px;
`

const SocialsBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

const FacebookIconWrapper = styled(FacebookIcon)`
  ${socialsCss}
`

const InstagramIconWrapper = styled(InstagramIcon)`
  ${socialsCss}
`

const TwitterIconWrapper = styled(TwitterIcon)`
  ${socialsCss}
`

const YouTubeIconWrapper = styled(YouTubeIcon)`
  ${socialsCss}
`

const PinterestIconWrapper = styled(PinterestIcon)`
  ${socialsCss}
`

const LinkedInIconWrapper = styled(LinkedInIcon)`
  ${socialsCss}
`

const TiktokIconWrapper = styled.img`
  ${socialsCss}
  height: ${({ theme }) => theme.spacing(2.7)}px;
  width: ${({ theme }) => theme.spacing(2.7)}px;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

type Props = {
  facebook?: string
  instagram?: string
  twitter?: string
  youtube?: string
  pinterest?: string
  linkedin?: string
  tiktok?: string
}

export const ExpoboothSocials: React.FC<Props> = ({
  facebook,
  instagram,
  twitter,
  youtube,
  pinterest,
  linkedin,
  tiktok,
}) => {
  const assets = useAssets()

  return (
    <Grid>
      <SocialsBox>
        <Grid container direction="row">
          <Box pt={0.5}>
            {facebook && (
              <Tooltip title="Facebook">
                <FacebookIconWrapper onClick={() => window.open(facebook, "_blank", "noopener,noreferrer")} />
              </Tooltip>
            )}
            {instagram && (
              <Tooltip title="Instagram">
                <InstagramIconWrapper onClick={() => window.open(instagram, "_blank", "noopener,noreferrer")} />
              </Tooltip>
            )}
            {twitter && (
              <Tooltip title="Twitter">
                <TwitterIconWrapper onClick={() => window.open(twitter, "_blank", "noopener,noreferrer")} />
              </Tooltip>
            )}
            {youtube && (
              <Tooltip title="Youtube">
                <YouTubeIconWrapper onClick={() => window.open(youtube, "_blank", "noopener,noreferrer")} />
              </Tooltip>
            )}
            {pinterest && (
              <Tooltip title="Pinterest">
                <PinterestIconWrapper onClick={() => window.open(pinterest, "_blank", "noopener,noreferrer")} />
              </Tooltip>
            )}
            {linkedin && (
              <Tooltip title="Linkedin">
                <LinkedInIconWrapper onClick={() => window.open(linkedin, "_blank", "noopener,noreferrer")} />
              </Tooltip>
            )}
            {tiktok && (
              <Tooltip title="Tiktok">
                <TiktokIconWrapper
                  src={assets.tiktokIcon}
                  onClick={() => window.open(tiktok, "_blank", "noopener,noreferrer")}
                />
              </Tooltip>
            )}
          </Box>
        </Grid>
      </SocialsBox>
    </Grid>
  )
}
