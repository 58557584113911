import React, { useCallback } from "react"
import { MenuItem, Tooltip, Typography } from "@material-ui/core"
import { getRoomChannels } from "store/domain/pubnubStore/channels/roomChannels"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { UnreadMessagesBadge } from "components/UnreadMessagesBadge"
import { useDispatch } from "store/setup/useDispatch"
import { setLastMessageTimetokenAsUnread } from "store/domain/lastReadTimetokens"
import { RoomChannel } from "store/domain/pubnubStore/channels/channelTypes"

const UnreadMessagesBadgeStyled = styled(UnreadMessagesBadge)`
  width: 100%;
`

const EmptyListMenuItem = styled(MenuItem)`
  white-space: break-spaces;
`

const RoomChannelDescription = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

type Props = {
  onClickRoom: (channelId: string) => void
}

export const ChatRoomList: React.FC<Props> = ({ onClickRoom }) => {
  const roomChannels = useSelector(getRoomChannels)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const dispatch = useDispatch()

  const handleRoomClick = useCallback(
    (roomChannel: RoomChannel) => () => {
      onClickRoom(roomChannel.id)

      dispatch(setLastMessageTimetokenAsUnread(roomChannel.id))
    },
    [dispatch, onClickRoom],
  )

  return (
    <>
      {roomChannels.length ? (
        roomChannels.map((roomChannel) => (
          <Tooltip key={roomChannel.id} title={roomChannel.description}>
            <MenuItem onClick={handleRoomClick(roomChannel)}>
              <UnreadMessagesBadgeStyled invisible={!roomChannel.additions.isUnreadMessage}>
                <RoomChannelDescription>{roomChannel.description}</RoomChannelDescription>
              </UnreadMessagesBadgeStyled>
            </MenuItem>
          </Tooltip>
        ))
      ) : (
        <EmptyListMenuItem>
          <Typography>
            Hi {currentPlatformUser.firstName}!
            <br />
            Welcome to Private Chats, where you can enjoy the shows and chat away to your friends. You can also use the
            “START A NEW CHAT HERE” button to choose whom to chat with. If you have any issues please contact us, using
            the support chat in the bottom left hand corner of your screen. You can also search for your team mates
            through the search icon at the top right hand corner of the page. Both message and video calls are
            available. Enjoy!
          </Typography>
        </EmptyListMenuItem>
      )}
    </>
  )
}
