import React, { useRef, useState } from "react"
import { useDrop, XYCoord, DropTargetMonitor } from "react-dnd"
import styled from "styled-components"
import { SingleSticker } from "./SingleSticker"
import { dndItemTypes, stickerDimensions, StickerItemType } from "../dndItemInfo"

const Container = styled.div`
  position: relative;
`

export type AddedSticker = {
  id: string
  src: string
  clientOffset: XYCoord
  width: number
  height: number
  angle: number
}

export type StickerAdder = {
  imageWrapperRef: (elem: HTMLDivElement) => void
  addedStickers: AddedSticker[]
  setAddedStickers: (addedSticker: AddedSticker[]) => void
  onDragSticker: (id: string, clientOffset: XYCoord) => void
  onResizeSticker: (id: string, width: number, height: number) => void
  onRotateSticker: (id: string, angle: number) => void
  removeSticker: (id: string) => void
  imageWrapper?: HTMLElement
}

export const useStickerAdder = (): StickerAdder => {
  const [addedStickers, setAddedStickers] = useState<AddedSticker[]>([])

  const addSticker = (sticker: StickerItemType, clientOffset: XYCoord | null): void => {
    if (clientOffset) {
      const newId = `${sticker.src}-${
        addedStickers.filter((currentSticker) => currentSticker.src === sticker.src).length
      }`

      setAddedStickers([
        ...addedStickers,
        {
          id: newId,
          src: sticker.src,
          clientOffset: {
            x: clientOffset.x - stickerDimensions.width / 2,
            y: clientOffset.y - stickerDimensions.height / 2,
          },
          width: stickerDimensions.width,
          height: stickerDimensions.height,
          angle: 0,
        },
      ])
    }
  }

  const onDragSticker = (id: string, clientOffset: XYCoord): void => {
    setAddedStickers((addedStickers) =>
      addedStickers.map((sticker) => (sticker.id === id ? { ...sticker, clientOffset } : sticker)),
    )
  }

  const onResizeSticker = (id: string, width: number, height: number): void =>
    setAddedStickers((addedStickers) =>
      addedStickers.map((sticker) => (sticker.id === id ? { ...sticker, width, height } : sticker)),
    )

  const onRotateSticker = (id: string, angle: number): void =>
    setAddedStickers((addedStickers) =>
      addedStickers.map((sticker) => (sticker.id === id ? { ...sticker, angle } : sticker)),
    )

  const removeSticker = (id: string): void =>
    setAddedStickers(addedStickers.filter((currentSticker) => currentSticker.id !== id))

  const dropRef = useRef<HTMLDivElement>()

  const [, drop] = useDrop<StickerItemType, unknown, unknown>({
    accept: dndItemTypes.STICKER,
    drop: (item: StickerItemType, monitor: DropTargetMonitor) => {
      let clientOffset = monitor.getClientOffset()

      if (clientOffset && dropRef.current) {
        const dropTargetXy = dropRef.current.getBoundingClientRect()

        clientOffset = {
          x: clientOffset.x - dropTargetXy.left,
          y: clientOffset.y - dropTargetXy.top,
        }
      }

      addSticker(item, clientOffset)
    },
  })

  const combinedDropRef = (elem: HTMLDivElement): void => {
    dropRef.current = elem
    drop(dropRef)
  }

  return {
    imageWrapperRef: combinedDropRef,
    setAddedStickers,
    addedStickers,
    onDragSticker,
    onResizeSticker,
    onRotateSticker,
    removeSticker,
    imageWrapper: dropRef.current,
  }
}

type Props = {
  stickerAdder: StickerAdder
}

export const AddedStickers: React.FC<Props> = ({
  stickerAdder: { addedStickers, removeSticker, onDragSticker, onResizeSticker, onRotateSticker },
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Container ref={containerRef}>
      {addedStickers.map((sticker) => (
        <SingleSticker
          key={sticker.id}
          sticker={sticker}
          removeSticker={(): void => removeSticker(sticker.id)}
          onDragSticker={(clientOffset: XYCoord): void => onDragSticker(sticker.id, clientOffset)}
          onResizeSticker={(width: number, height: number): void => onResizeSticker(sticker.id, width, height)}
          onRotateSticker={(angle: number): void => onRotateSticker(sticker.id, angle)}
        />
      ))}
    </Container>
  )
}
