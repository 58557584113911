import React, { useRef } from "react"
import { connectHits } from "react-instantsearch-dom"
import { HitsProvided } from "react-instantsearch-core"
import styled from "styled-components"
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
  IconButton,
} from "@material-ui/core"
import { PhotoboothPhotoHits } from "components/algolia/types"
import { GroupPhotoUtils } from "../../useGroupPhotoUtils"
import { GROUP_PHOTO_CREATION_WRAPPER_WIDTH } from "../constants"
import { obtainInitialsFromUserName } from "utils/obtainInitialsFromUserName"
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded"
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded"
import { TakePhotoButton } from "../../../TakePhotoButton"

const WrapperBox = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

const ImageListItemStyled = styled(ImageListItem)<{ selected: boolean }>`
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? `
        img {
          opacity: 0.4;
        }
      `
      : ""}
`

const InitialsAvatar = styled(Avatar)`
  ${({ theme }) => `
    color: ${theme.palette.getContrastText("#ffffff")};
    background-color: #ffffff;
    margin-right: 6px;
    width: ${theme.spacing(4)}px;
    height: ${theme.spacing(4)}px;
  `}
`

const PicturesNotFoundTypography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.custom.colors.photobooth.background};
  `}
`

const PicturesNotFoundTip = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(2, 1, 1)};
    padding: ${theme.spacing(2)}px;
    border: 2px dashed ${theme.custom.colors.photobooth.background};
  `}
`

const TakePhotoButtonWrapper = styled.span`
  ${({ theme }) => `
    display: inline-block;
    margin-top: ${theme.spacing(1.5)}px;
  `}
`

type OwnProps = {
  addPhotoOfGroup: GroupPhotoUtils["addPhotoOfGroup"]
  removePhotoOfGroup: GroupPhotoUtils["removePhotoOfGroup"]
  addedPhotosOfGroup: PhotoboothPhotoHits[]
  goToPreparePhoto: () => void
  isSearchInProgress: boolean
}

type Props = HitsProvided<PhotoboothPhotoHits> & OwnProps

const SearchedPhotosRaw: React.FC<Props> = ({
  hits: photoHits,
  addPhotoOfGroup,
  removePhotoOfGroup,
  addedPhotosOfGroup,
  goToPreparePhoto,
  isSearchInProgress,
}) => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up("sm"))
  const imageRef = useRef<HTMLImageElement | null>(null)

  const imageListCols = smUp ? 4 : 2

  const imageRowHeight =
    (window.innerWidth > GROUP_PHOTO_CREATION_WRAPPER_WIDTH ? GROUP_PHOTO_CREATION_WRAPPER_WIDTH : window.innerWidth) /
    imageListCols

  const getIsPhotoAdded = (photo: PhotoboothPhotoHits) =>
    !!addedPhotosOfGroup.filter(({ uuid }) => uuid === photo.uuid).length

  return (
    <WrapperBox mb={1}>
      {photoHits.length === 0 && isSearchInProgress && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <PicturesNotFoundTypography variant="h6">
            <Box ml={1}>No pictures found.</Box>

            <PicturesNotFoundTip>
              Tip: To create a Group Photo you need to include at least 1 of your own Selfies. Don{"'"}t have one yet?
              Head to the Photo Booth and select the&nbsp;&nbsp;
              <TakePhotoButtonWrapper>
                <TakePhotoButton handleClick={goToPreparePhoto} smallSize />
              </TakePhotoButtonWrapper>
              &nbsp;&nbsp;button to start making memories!
            </PicturesNotFoundTip>
          </PicturesNotFoundTypography>
        </Grid>
      )}

      <ImageList rowHeight={imageRowHeight} cols={imageListCols}>
        {photoHits.map((currentPhotoHit) => {
          const isPhotoAdded = getIsPhotoAdded(currentPhotoHit)

          return (
            <ImageListItemStyled
              key={currentPhotoHit.uuid}
              onClick={() => {
                if (isPhotoAdded) {
                  removePhotoOfGroup(currentPhotoHit.uuid)
                } else {
                  addPhotoOfGroup(currentPhotoHit)
                }
              }}
              selected={isPhotoAdded}
            >
              <img ref={imageRef} src={currentPhotoHit.thumbnail_url} alt="User's Photo" />

              <ImageListItemBar
                title={
                  <Box display="flex" alignItems="center">
                    <InitialsAvatar>{obtainInitialsFromUserName(currentPhotoHit.username)}</InitialsAvatar>{" "}
                    {currentPhotoHit.username}
                  </Box>
                }
                actionIcon={
                  <IconButton color={isPhotoAdded ? "secondary" : "primary"}>
                    {isPhotoAdded ? <RemoveCircleRoundedIcon /> : <AddCircleRoundedIcon />}
                  </IconButton>
                }
              />
            </ImageListItemStyled>
          )
        })}
      </ImageList>
    </WrapperBox>
  )
}

// @ts-ignore
export const SearchedPhotos: React.FC<OwnProps> = connectHits(SearchedPhotosRaw)
