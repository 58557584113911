// import { useNotifications } from "@magicbell/magicbell-react"
import React, { useCallback, useEffect, useState } from "react"
import { isUnreadMessageInAnyMessageChannelsSelector } from "store/domain/pubnubStore/channels/messageChannels"
import { useSelector } from "store/setup/useSelector"
import { Helmet } from "react-helmet"
import { useNotifications } from "@magicbell/magicbell-react"
import { useAssets } from "store/domain/event"

export const AppFavicon: React.FC = () => {
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  const [dataUrlWithAlert, setDataUrlWithAlert] = useState<string | null>(null)
  const isUnreadMessageInAnyMessageChannels = useSelector(isUnreadMessageInAnyMessageChannelsSelector)
  const notifications = useNotifications()
  const assets = useAssets()

  const isAlertOn = notifications.unseenCount > 0 || isUnreadMessageInAnyMessageChannels

  const createDataUrl = useCallback(
    (showAlert: boolean) => {
      const canvas = document.createElement("canvas")
      canvas.width = 16
      canvas.height = 16
      const context = canvas.getContext("2d")

      const drawOverlay = () => {
        const top = canvas.height / 1.6
        const left = canvas.width / 1.6
        const bottom = canvas.height
        const right = canvas.width
        const radius = 20 / 8

        const overlayColor = "red"

        if (context) {
          context.fillStyle = overlayColor
          context.strokeStyle = overlayColor
          context.lineWidth = 1

          context.beginPath()
          context.moveTo(left + radius, top)
          context.quadraticCurveTo(left, top, left, top + radius)
          context.lineTo(left, bottom - radius)
          context.quadraticCurveTo(left, bottom, left + radius, bottom)
          context.lineTo(right - radius, bottom)
          context.quadraticCurveTo(right, bottom, right, bottom - radius)
          context.lineTo(right, top + radius)
          context.quadraticCurveTo(right, top, right - radius, top)
          context.closePath()
          context.fill()
        }
      }

      const img = document.createElement("img")
      img.crossOrigin = "Anonymous"
      img.src = assets.favicon

      img.onload = function () {
        if (context) {
          context.clearRect(0, 0, img.width, img.height)
          context.drawImage(img, 0, 0, canvas.width, canvas.height)

          const getDataUrl = () => context.canvas.toDataURL()

          if (showAlert) {
            drawOverlay()

            setDataUrlWithAlert(getDataUrl())
          } else {
            setDataUrl(getDataUrl())
          }
        }
      }
    },
    [assets.favicon],
  )

  useEffect(() => {
    createDataUrl(false)

    createDataUrl(true)
  }, [createDataUrl])

  return (
    <>
      {dataUrl && dataUrlWithAlert && (
        <Helmet>
          <link rel="icon" type="image/x-icon" href={isAlertOn ? dataUrlWithAlert : dataUrl} />
        </Helmet>
      )}
    </>
  )
}
