import React from "react"
import styled from "styled-components"
import BookmarkIcon from "@material-ui/icons/Bookmark"

const ImageStyled = styled.img`
  width: 22px;
`

const BookmarkIconStyled = styled(BookmarkIcon)`
  width: 22px;
`

type Props = {
  imageUrl?: string
  className?: string
}

export const BadgeDisplayed: React.FC<Props> = ({ imageUrl, className }) => {
  return (
    <>
      {imageUrl ? <ImageStyled className={className} src={imageUrl} /> : <BookmarkIconStyled className={className} />}
    </>
  )
}
