import { Box } from "@material-ui/core"
import { backgroundImageOrColor } from "components/styles/backgroundImageOrColor"
import { EVENT_BACKGROUND_TYPE } from "constants/general"
import React from "react"

import { getEvent, useAssets } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"

const Wrapper = styled(Box)<{ $backgroundSrc: string; $setBackgroundImage: boolean }>`
  height: 100%;

  ${backgroundImageOrColor};
`

export const BackgroundWrapper: React.FC = ({ children }) => {
  const { backgroundType } = useSelector(getEvent)
  const assets = useAssets()

  return (
    <Wrapper $backgroundSrc={assets.background} $setBackgroundImage={backgroundType === EVENT_BACKGROUND_TYPE.image}>
      {children}
    </Wrapper>
  )
}
