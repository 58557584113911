import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import isArray from "lodash/isArray"
import isEmpty from "lodash/isEmpty"
import { sortByCreatedAt } from "utils/sortByCreatedAt"

export const removeImageBackground =
  (eventUuid: string) =>
  ({ imageKey }: { imageKey: string }): Promise<string> =>
    baseApi
      .post<string>({
        url: apiUrls.photobooth.removeImageBackground,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          photo_key: imageKey,
        },
      })
      .then(({ data }) => data)

export const publishImageToGallery =
  (eventUuid: string) =>
  ({ imageKey }: { imageKey: string }): Promise<{ status: number }> =>
    baseApi
      .post<unknown>({
        url: apiUrls.photobooth.publishImageToGallery,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          photo_key: imageKey,
        },
      })
      .then(({ status }) => ({
        status,
      }))
      .catch((error) => ({
        status: error?.response?.status || 0,
      }))

export type GalleryPhoto = {
  type: "gallery"
  id: string
  imageUrl: string
  thumbnailUrl: string
  downloadUrl: string
  attendanceUuid: string
  createdAt: string
  username: string
}

type FetchedGalleryPhotos = {
  galleryPhotos: {
    [key: string]: GalleryPhoto
  }
}

export const fetchGalleryPhotos =
  ({ eventUuid, attendanceUuid }: { eventUuid: string; attendanceUuid: string }) =>
  ({ isUsersPhotos }: { isUsersPhotos?: boolean }): Promise<GalleryPhoto[]> =>
    baseApi
      .get<FetchedGalleryPhotos>({
        url: apiUrls.photobooth.photoGallery.list(attendanceUuid),
        headers: {
          EventToken: eventUuid,
        },
        params: {
          include: "gallery",
          per_page: 200,
          ...(isUsersPhotos && { uuid: attendanceUuid }),
        },
      })
      .then(({ data }) => data)
      .then((data) => {
        const guaranteedData = isArray(data) && isEmpty(data) ? { galleryPhotos: {} } : data

        const sortedPhotos = sortByCreatedAt(Object.values(guaranteedData.galleryPhotos))

        const photosWithType = sortedPhotos.map((photo) => ({ ...photo, type: "gallery" as const }))

        return photosWithType
      })
