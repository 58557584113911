import React from "react"
import { Switch, Route } from "react-router-dom"
import { useEventTime } from "hooks/useEventTime"
import { PrePostEvent } from "features/baseBlocks/PrePostEvent"
import { FullPageCircularProgress } from "components/FullPageCircularProgress"

export const RoutesDuringEventProtection: React.FC = ({ children }) => {
  const { duringEventTime, hasEventEnded } = useEventTime()

  return (
    <>
      {duringEventTime !== null && hasEventEnded !== null ? (
        <>
          {duringEventTime ? (
            <Route render={() => <Switch>{children}</Switch>} />
          ) : (
            <PrePostEvent postEvent={hasEventEnded} />
          )}
        </>
      ) : (
        <FullPageCircularProgress isSpinning />
      )}
    </>
  )
}
