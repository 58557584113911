import { useState, useEffect, useCallback } from "react"
import moment from "moment"
import { useSelector } from "store/setup/useSelector"
import { useDispatch } from "store/setup/useDispatch"
import { getEvent, updateEvent } from "store/domain/event"
import { useInitialApi } from "api/useInitialApi"
import useEventSetup from "./useEventSetup"

type HasEventEndedType = null | boolean

type TimeLeft = {
  hasEventEnded: HasEventEndedType
}

export const useTimeLeftGeneral = (): TimeLeft => {
  const event = useSelector(getEvent)
  const [hasEventEnded, setHasEventEnded] = useState<HasEventEndedType>(null)
  const dispatch = useDispatch()
  const { initialApi } = useInitialApi()
  const eventSetup = useEventSetup()

  const calculateTimeLeft = useCallback(() => {
    const calculate = async (): Promise<void> => {
      if (hasEventEnded) {
        return
      }

      const { endTime } = event

      const calculateEnded = (endArg: string): boolean => {
        const endDate = moment(endArg)
        const nowDate = moment()

        return nowDate.isAfter(endDate)
      }

      const eventMayHaveEnded = calculateEnded(endTime)

      if (eventMayHaveEnded) {
        try {
          const eventFetched = await initialApi.event.fetchEvent(eventSetup.uuid)

          const newEndDate = eventFetched.endTime

          dispatch(updateEvent({ endTime: newEndDate }))

          setHasEventEnded(calculateEnded(newEndDate))
        } catch (error) {
          console.error(error)
        }
      } else {
        setHasEventEnded(false)
      }
    }

    calculate()
  }, [initialApi, event, hasEventEnded, dispatch, eventSetup])

  useEffect(() => {
    calculateTimeLeft()

    const timer = setInterval(calculateTimeLeft, 60 * 1000)

    return (): void => {
      clearInterval(timer)
    }
  }, [hasEventEnded, calculateTimeLeft])

  return { hasEventEnded }
}
