import { useRef, useEffect, useCallback, useState } from "react"
import { getMessagesByChannelId, MessageFragment } from "store/domain/pubnubStore/messages"
import { useDispatch } from "store/setup/useDispatch"
import { setLastMessageTimetokenAsUnread } from "store/domain/lastReadTimetokens"
import { VirtuosoHandle } from "react-virtuoso"
import { getMessageChannelsById } from "store/domain/pubnubStore/channels/messageChannels"
import { useSelector } from "store/setup/useSelector"
import { getDeletedMessages } from "../../../store/domain/messageDeletions"

export const useMessagesAndScrolling = ({
  channelId,
  welcomeMessage,
}: {
  channelId: string
  welcomeMessage?: MessageFragment
}) => {
  const messageChannel = useSelector(getMessageChannelsById)[channelId]
  const messagesByChannelId = useSelector(getMessagesByChannelId)
  const deletedMessages = useSelector(getDeletedMessages)
  const dispatch = useDispatch()
  const [atBottom, setAtBottom] = useState(true)
  const [messages, setMessages] = useState<MessageFragment[]>([])
  const virtuosoMessagesRef = useRef<VirtuosoHandle>(null)
  const [showScrollDownButton, setShowScrollDownButton] = useState(false)
  const showButtonTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setMessages([
      ...(welcomeMessage ? [welcomeMessage] : []),
      ...(messagesByChannelId[channelId]?.filter((m) => !deletedMessages.includes(`${m.timetoken}`)) || []),
    ])
  }, [messagesByChannelId, welcomeMessage, channelId])

  useEffect(() => {
    if (atBottom) {
      dispatch(setLastMessageTimetokenAsUnread(channelId))
    }
  }, [atBottom, channelId, dispatch, messages.length])

  useEffect(() => {
    if (showButtonTimeoutRef.current) {
      clearTimeout(showButtonTimeoutRef.current)
    }

    if (messageChannel.additions.isUnreadMessage) {
      showButtonTimeoutRef.current = setTimeout(() => setShowScrollDownButton(true), 1000)
    } else {
      setShowScrollDownButton(false)
    }
  }, [messageChannel.additions.isUnreadMessage])

  const atBottomStateChange = useCallback((bottom: boolean) => {
    setAtBottom(bottom)
  }, [])

  const scrollToBottomMessage = useCallback(() => {
    if (virtuosoMessagesRef.current) {
      virtuosoMessagesRef.current.scrollToIndex({ index: messages.length - 1, behavior: "smooth" })
    }
  }, [virtuosoMessagesRef, messages.length])

  return {
    virtuosoMessagesRef,
    atBottomStateChange,
    scrollToBottomMessage,
    messages,
    showScrollDownButton,
    isWelcomeMessagePresent: !!welcomeMessage,
  }
}
