import React from "react"
import { useSelector } from "store/setup/useSelector"
import { Redirect } from "react-router-dom"
import { defaultStageIdSelector } from "store/domain/defaultStageIdSelector"
import useEventSlug from "hooks/useEventSlug"
import { routerPaths } from "main/App/Router/routerPaths"

export const DefaultStageRedirector: React.FC = () => {
  const eventSlug = useEventSlug()
  const defaultStageId = useSelector(defaultStageIdSelector)

  return <Redirect to={routerPaths.stage.assemblePath(eventSlug, defaultStageId)} />
}
