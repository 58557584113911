import { useEffect, useState } from "react"

export const useCameraPermissionState = (): { cameraPermissionGranted: boolean } => {
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(true)

  useEffect(() => {
    try {
      navigator.permissions
        // @ts-ignore
        .query({ name: "camera" })

        .then(function (permissionStatus) {
          setCameraPermissionGranted(permissionStatus.state === "granted")

          permissionStatus.onchange = function () {
            setCameraPermissionGranted(this.state === "granted")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }, [])

  return { cameraPermissionGranted }
}
