import { useSelector } from "store/setup/useSelector"
import React, { useState } from "react"
import { PollByStage } from "../../../../baseBlocks/PollByStage"
import { Box, IconButton, Typography } from "@material-ui/core"
import styled from "styled-components"
import { getCurrentStagePoll } from "store/domain/currentStageContent/currentStageChannel"
import CloseIcon from "@material-ui/icons/Close"

const Wrapper = styled(Box)``

const InnerWrapper = styled(Box)`
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 6px;
`

const Header = styled(Box)`
  ${({ theme }) =>
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.secondary.contrastText};
    padding: 6px 12px;
    font-weight: bold;
    margin-bottom: 3px;
  `}
`

const CloseButtonStyled = styled(IconButton)`
  padding: 0;
`

export const PollByStageWrapped: React.FC = () => {
  const currentStagePoll = useSelector(getCurrentStagePoll)
  const [isPollOpen, setIsPollOpen] = useState(true)

  return (
    <>
      {currentStagePoll && isPollOpen && (
        <Wrapper>
          <InnerWrapper>
            <Header>
              <Typography variant="subtitle1">{currentStagePoll.pollQuestion}</Typography>

              <CloseButtonStyled color="inherit" onClick={() => setIsPollOpen(false)}>
                <CloseIcon color="inherit" />
              </CloseButtonStyled>
            </Header>

            <PollByStage />
          </InnerWrapper>
        </Wrapper>
      )}
    </>
  )
}
