import React, { useState } from "react"
import { Photobooth } from "features/baseBlocks/Photobooth"
import { useDispatch } from "store/setup/useDispatch"
import { turnOffIntercom, turnOnIntercom } from "store/domain/intercom"
import { useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"

export const PhotoboothOpener: React.FC<{ OpenerComponent: React.FC<{ onClick: () => void }> }> = ({
  OpenerComponent,
}) => {
  const [photoboothModalOpen, setPhotoboothModalOpen] = useState(false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const handleOpenPhotoboothModal = (): void => {
    setPhotoboothModalOpen(true)

    if (isXs) {
      dispatch(turnOffIntercom())
    }
  }

  const handleClosePhotoboothModal = (): void => {
    setPhotoboothModalOpen(false)

    dispatch(turnOnIntercom())
  }

  return (
    <>
      <Photobooth open={photoboothModalOpen} onRequestClose={handleClosePhotoboothModal} />

      <OpenerComponent onClick={handleOpenPhotoboothModal} />
    </>
  )
}
