import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import PubNub from "pubnub"
import { usePubNub } from "pubnub-react"
import { reactionMessage } from "runtypes/reactions"
import { AnimatedReaction, useAnimatedReactions } from "./AnimatedReaction"
import { useAnimateKeyframes } from "react-simple-animate"
import { ReactionImage } from "components/ReactionImage"
import { Box, IconButton } from "@material-ui/core"
import { getChannelFromEmoji } from "../utils"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(0, 1)};

  ${({ theme }) => theme.breakpoints.only("xs")} {
    margin: ${({ theme }) => theme.spacing(0, 0.8)};
  }
`

const ReactionTriggerWrapper = styled.div`
  &:hover {
    transform: scale(1.1);
  }
`

const ReactionTriggerIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.custom.colors.reactions.background};
  border: 1px solid ${({ theme }) => theme.custom.colors.reactions.border};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  :hover {
    background: ${({ theme }) => theme.custom.colors.reactions.background};
  }
`

const useReactionsHistory = ({
  index,
  channelId,
  userId,
  onReceive,
}: {
  index: number
  channelId: string
  userId: string
  onReceive: () => void
}) => {
  const pubnub = usePubNub()
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)

  useEffect(() => {
    const reactionsListener = {
      message: (messageEvent: PubNub.MessageEvent): void => {
        if (reactionMessage.guard(messageEvent.message)) {
          if (
            messageEvent.message.senderId !== userId &&
            messageEvent.message.channelName === channelId &&
            messageEvent.message.emoji === `${index}`
          ) {
            onReceive()
          }
        }
      },
    }

    pubnub.addListener(reactionsListener)

    return (): void => {
      pubnub.removeListener(reactionsListener)
    }
  }, [pubnub, onReceive, channelId, userId, index])

  const sendReactionMessage = (): void => {
    const emojiChannel = getChannelFromEmoji(index)

    pubnub.publish(
      {
        channel: emojiChannel,
        message: {
          channelName: channelId,
          emoji: `${index}`,
          senderId: userId,
        },
      },
      (status) => {
        if (status.error) {
          airbrakeNotifier.notify({ error: "Pubnub's publish reaction failed.", params: { error: status.error } })
        }
      },
    )
  }

  return { sendReactionMessage }
}

type Props = { userId: string; index: number; channelId: string }

export const Reaction: React.FC<Props> = ({ userId, channelId, index }) => {
  const { animatedReactions, removeAnimatedReaction, addAnimatedReaction } = useAnimatedReactions({ index })

  const { sendReactionMessage } = useReactionsHistory({
    index,
    channelId,
    userId,
    onReceive: addAnimatedReaction,
  })

  const pulseAnimation = useAnimateKeyframes({
    duration: 0.2,
    keyframes: ["transform: scale(1)", "transform: scale(1.3)", "transform: scale(1)"],
  })

  return (
    <Wrapper>
      {animatedReactions.map((currentReaction) => (
        <AnimatedReaction
          key={currentReaction.id}
          index={currentReaction.index}
          remove={() => removeAnimatedReaction(currentReaction.id)}
        />
      ))}

      <ReactionTriggerWrapper style={pulseAnimation.style}>
        <ReactionTriggerIconButton
          size="medium"
          onClick={() => {
            addAnimatedReaction()

            pulseAnimation.play(!pulseAnimation.isPlaying)

            sendReactionMessage()
          }}
        >
          <ReactionImage index={index} />
        </ReactionTriggerIconButton>
      </ReactionTriggerWrapper>
    </Wrapper>
  )
}
