import { useCallback, useState } from "react"

type TakePhotoCountdown = {
  countDownNumber: number
  triggerCountdown: () => Promise<void>
  countdownGoing: boolean
}

export const useTakePhotoCountdown = (totalCount = 3): TakePhotoCountdown => {
  const [countDownNumber, setCountDownNumber] = useState<number>(0)

  const triggerCountdown = useCallback((): Promise<void> => {
    setCountDownNumber(totalCount)

    return new Promise((resolve) => {
      const interval = window.setInterval(() => {
        setCountDownNumber((countDownNumberArg) => {
          if (countDownNumberArg === 1) {
            clearInterval(interval)

            resolve()
          }

          return countDownNumberArg - 1
        })
      }, 1000)
    })
  }, [totalCount])

  return {
    countDownNumber,
    triggerCountdown,
    countdownGoing: !!countDownNumber,
  }
}
