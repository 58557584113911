import { createTheme, responsiveFontSizes, ThemeOptions } from "@material-ui/core"
import { BrandingJsonType } from "api/apiFunctions/eventSetup"
import merge from "lodash/merge"
import { constructThemeCustom } from "./constructThemeCustom"

const constructMuiTheme = (options: ThemeOptions) =>
  responsiveFontSizes(createTheme(options), {
    factor: 5,
  })

export const constructTheme = (brandingJson: BrandingJsonType) => {
  const muiTheme = constructMuiTheme(brandingJson)

  return {
    ...muiTheme,
    custom: merge(constructThemeCustom(muiTheme), brandingJson.custom),
  }
}
