import React from "react"
import { Box, IconButton, Tooltip, TooltipProps } from "@material-ui/core"
import { setLeaderboard } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"
import styled from "styled-components"
import { useAssets } from "store/domain/event"

const IconSvg = styled.div<{ iconUrl: string }>`
  width: 32px;
  height: 32px;
  mask: ${({ iconUrl }) => `url(${iconUrl}) no-repeat center`};
  mask-size: 32px;
`

export const LeaderboardOpenButton: React.FC<{ className?: string; TooltipForIcon?: React.FC<TooltipProps> }> = ({
  className,
  TooltipForIcon,
}) => {
  const assets = useAssets()
  const dispatch = useDispatch()

  const TooltipConditional = TooltipForIcon || Tooltip

  return (
    <Box display="flex" className={className}>
      <TooltipConditional title="Leaderboard">
        <IconButton
          id="leaderboard-trigger-icon-button"
          onClick={() => {
            dispatch(setLeaderboard(true))
          }}
        >
          <IconSvg id="leaderboard-icon" iconUrl={assets.leaderboard.triggerIcon} />
        </IconButton>
      </TooltipConditional>
    </Box>
  )
}
