import React, { useState, useRef, useCallback } from "react"
import Webcam from "react-webcam"
import Jimp from "jimp"
import {
  Wrapper,
  PreparePhotoWrapper,
  TakePhotoCountdown,
  TitlesWrapper,
  MainTitle,
  TakeOrUploadPhotoWrapper,
  SelfieAndGroupPhotoWrapper,
  GroupPhotoNavigationButton,
  SelfiePhotoNavigationButton,
  TakePhotoButtonStyled,
} from "./PrepareAndViewPhotos.style"
import { PreparePhotoSteps, usePreparePhotoSteps } from "./hooks/usePreparePhotoSteps"
import { Spinner } from "independent/components/Spinner"
import { BottomBarOfTakePhoto } from "./BottomBarOfTakePhoto"
import { BottomBar } from "./BottomBar"
import { WebcamPhoto } from "./WebcamPhoto"
import { useStickerAdder } from "./AddedStickers"
import { SelectBackground } from "./editPhoto/SelectBackground"
import { AddFrame } from "./editPhoto/AddFrame"
import { DragSticker } from "./editPhoto/DragSticker"
import { assembleImage } from "./utils/assembleImage"
import { dataURLtoFile } from "./utils/dataURLtoFile"
import { useImageUploader } from "./hooks/useImageUploader"
import { useTakePhotoCountdown } from "./hooks/useTakePhotoCountdown"
import { PhotoboothHeader } from "./PhotoboothHeader"
import { NavigationPlaces, useNavigation } from "./hooks/useNavigation"
import { GroupPhotoCreation } from "./groupPhoto/GroupPhotoCreation"
import { GroupPhotoConfirmation } from "./groupPhoto/GroupPhotoConfirmation"
import { PhotoboothPhotos } from "./PhotoboothPhotos"
import { PhotoPreview } from "./PhotoPreview"
import { PhotoMessageWriter } from "./PhotoMessageWriter"
import { usePhotoboothPhotosFetcher } from "./hooks/usePhotoboothPhotosFetcher"
import { GalleryPhoto } from "api/apiFunctions/photobooth/photoGallery"
import { useCameraPermissionState } from "./hooks/useCameraPermissionState"
import { snackbarUtils } from "components/snackbar/snackbarUtils"
import { triggerWhiteFlash } from "independent/styles/whiteFlash"
import { UploadPhotoButton } from "./UploadPhotoButton"
import GroupIcon from "@material-ui/icons/Group"
import PersonIcon from "@material-ui/icons/Person"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { useGroupPhotoUtils } from "./groupPhoto/useGroupPhotoUtils"
import { GroupPhoto } from "api/apiFunctions/photobooth/groupPhoto"
import isObject from "lodash/isObject"
// handy for development to imitate different stages of photobooth immediately without waiting for API.
// import { dummyImageFile } from "./dummyImageFile"

type Props = { onRequestClose: () => void }

export const PrepareAndViewPhotos: React.FC<Props> = ({ onRequestClose }) => {
  const { disableGroupPhotos, photoBoothIndexName } = useSelector(getEvent)

  const {
    navigationPlace,
    goBack,
    canGoBack,
    goToPreparePhoto,
    goToPhotoGallery,
    goToSinglePhoto,
    goToWritePhotoMessage,
    goToGroupPhotoCreation,
    goToGroupPhotoConfirmation,
  } = useNavigation()

  const photoboothPhotosFetcher = usePhotoboothPhotosFetcher()

  const [previewPhoto, setPreviewPhoto] = useState<GalleryPhoto | GroupPhoto | null>(null)

  const { preparationStep, nextStep, previousStep, goToFirstStep } = usePreparePhotoSteps()

  const webcamRef = useRef<Webcam>(null)
  const { cameraPermissionGranted } = useCameraPermissionState()
  const { countDownNumber, triggerCountdown, countdownGoing } = useTakePhotoCountdown(3)

  const [selectedBackground, setSelectedBackground] = useState<string | null>(
    null,
    // Uncomment to have default value for development.
    // "/photobooth/backgrounds/virtualbackground_02.png",
  )
  const [selectedFrame, setSelectedFrame] = useState<string | null>(null)
  const [imageSrc, setImageSrc] = useState<File | null>(
    null,
    // Uncomment to have default value for development.
    // dummyImageFile,
  )
  const [imageId, setImageId] = useState<string | null>(
    null,
    // Uncomment to have default value for development.
    // "dummyImageId",
  )

  const stickerAdder = useStickerAdder()
  const { addedStickers, setAddedStickers } = stickerAdder

  const [finalizingImage, setFinalizingImage] = useState<boolean>(false)

  const { getBackgroundRemovedImage, backgroundRemovalPending, uploadImageToGallery } = useImageUploader()

  const groupPhotoUtils = useGroupPhotoUtils({
    onSetGroupPhotoToConfirm: goToGroupPhotoConfirmation,
    onConfirmGroupPhoto: async () => {
      await photoboothPhotosFetcher.fetchAndSaveGroupPhotos()

      goToPhotoGallery()
    },
  })

  const handlePhotoClick = (photo: GalleryPhoto | GroupPhoto) => {
    setPreviewPhoto(photo)

    goToSinglePhoto()
  }

  const resetPreparationState = (): void => {
    goToFirstStep()
    setSelectedBackground(null)
    setSelectedFrame(null)
    setImageSrc(null)
    setImageId(null)
    setAddedStickers([])
  }

  const handleGoToPhotoGallery = () => {
    resetPreparationState()

    goToPhotoGallery()
  }

  const onPreviousStep = (): void => {
    const onConfirmTakenPhoto = () => {
      setImageSrc(null)
    }

    if (preparationStep === PreparePhotoSteps.confirmTakenPhoto) {
      onConfirmTakenPhoto()
    } else if (preparationStep === PreparePhotoSteps.selectBackground) {
      setSelectedBackground(null)
      setSelectedFrame(null)
      setAddedStickers([])
      onConfirmTakenPhoto()
      previousStep()
    }

    previousStep()
  }

  const handleTakePhoto = useCallback(async () => {
    goToPreparePhoto()

    const takePhoto = (): void => {
      if (webcamRef) {
        const currentWebcam = webcamRef.current

        if (currentWebcam) {
          const webcamImageBase64 = currentWebcam.getScreenshot()

          if (webcamImageBase64) {
            const webcamImageFile = dataURLtoFile(webcamImageBase64, "webcamImage")
            setImageSrc(webcamImageFile)
            nextStep()
          }
        }
      }
    }

    await triggerCountdown()

    triggerWhiteFlash()
    takePhoto()
  }, [triggerCountdown, goToPreparePhoto, nextStep])

  const handleUploadImage = (src: File) => {
    setImageSrc(src)

    nextStep()
  }

  const handleConfirmTakenPhoto = async (): Promise<void> => {
    if (imageSrc) {
      try {
        const backgroundRemovedImage = await getBackgroundRemovedImage(imageSrc)

        if (backgroundRemovedImage) {
          setImageSrc(backgroundRemovedImage.imageSrc)
          setImageId(backgroundRemovedImage.imageId)

          nextStep()
        }
      } catch (e) {
        alert("Background could not be removed, try a different image.")
      }
    }
  }

  const handleDragSticker = async (): Promise<void> => {
    const genericErrorText = "Something went wrong. Try again, or try different image."

    try {
      setFinalizingImage(true)

      if (imageSrc && imageId && selectedBackground && stickerAdder.imageWrapper) {
        const assembledImage = await assembleImage({
          imageSrc,
          selectedBackground,
          selectedFrame,
          addedStickers,
          imageWrapper: stickerAdder.imageWrapper,
        })

        if (assembledImage) {
          const finalImageB64 = await assembledImage.getBase64Async(String(Jimp.MIME_PNG))

          const finalImageSrc = dataURLtoFile(finalImageB64)

          const uploadResult = await uploadImageToGallery({ imageSrc: finalImageSrc, imageId })

          if (isObject(uploadResult)) {
            if (uploadResult.status === 200) {
              snackbarUtils.success("Your photo is being moderated. You can view it here shortly.")

              goToPhotoGallery()
              resetPreparationState()
            } else if (uploadResult.status === 201) {
              snackbarUtils.success("Great! Your photo is ready!")

              await photoboothPhotosFetcher.fetchAndSaveCurrentUsersPhotos()

              goToPhotoGallery()
              resetPreparationState()
            } else if (uploadResult.status === 422) {
              snackbarUtils.error("This exact same image has already been submitted.")
            }
          } else if (uploadResult === "error") {
            snackbarUtils.error(genericErrorText)
          }
        }
      }
    } catch (e) {
      console.log(e)
      alert(genericErrorText)
    } finally {
      setFinalizingImage(false)
    }
  }

  return (
    <Wrapper>
      <PhotoboothHeader goBack={goBack} canGoBack={canGoBack} onRequestClose={onRequestClose}>
        <TitlesWrapper>
          <MainTitle variant="h5">
            {navigationPlace === NavigationPlaces.preparePhoto && (
              <>
                {preparationStep === PreparePhotoSteps.takePhoto && "PHOTO BOOTH"}

                {preparationStep === PreparePhotoSteps.confirmTakenPhoto && "LOOKING GOOD!"}

                {preparationStep === PreparePhotoSteps.selectBackground && "SELECT A BACKGROUND"}

                {preparationStep === PreparePhotoSteps.addFrame && "ADD A FRAME"}

                {preparationStep === PreparePhotoSteps.dragSticker && "DRAG A STICKER"}
              </>
            )}

            {(navigationPlace === NavigationPlaces.singlePhoto ||
              navigationPlace === NavigationPlaces.photoboothPhotos) &&
              "PHOTO BOOTH"}
          </MainTitle>

          {navigationPlace === NavigationPlaces.groupPhotoCreation && (
            <MainTitle variant="h5">ADD PICTURES TO THE GROUP PHOTO</MainTitle>
          )}

          {navigationPlace === NavigationPlaces.groupPhotoConfirmation && (
            <MainTitle variant="h5">PREVIEW OF THE GROUP PHOTO</MainTitle>
          )}
        </TitlesWrapper>

        <SelfieAndGroupPhotoWrapper>
          {(navigationPlace === NavigationPlaces.photoboothPhotos ||
            navigationPlace === NavigationPlaces.singlePhoto) && (
            <SelfiePhotoNavigationButton
              variant="contained"
              color="primary"
              startIcon={<PersonIcon />}
              onClick={goToPreparePhoto}
            >
              SELFIE
            </SelfiePhotoNavigationButton>
          )}

          {(navigationPlace === NavigationPlaces.preparePhoto ||
            navigationPlace === NavigationPlaces.photoboothPhotos ||
            navigationPlace === NavigationPlaces.singlePhoto) &&
            !disableGroupPhotos &&
            photoBoothIndexName && (
              <GroupPhotoNavigationButton
                variant="contained"
                color="secondary"
                startIcon={<GroupIcon />}
                onClick={goToGroupPhotoCreation}
              >
                GROUP PHOTO
              </GroupPhotoNavigationButton>
            )}
        </SelfieAndGroupPhotoWrapper>
      </PhotoboothHeader>

      {navigationPlace === NavigationPlaces.preparePhoto && (
        <PreparePhotoWrapper>
          <WebcamPhoto
            webcamRef={webcamRef}
            selectedBackground={selectedBackground}
            selectedFrame={selectedFrame}
            imageSrc={imageSrc}
            stickerAdder={stickerAdder}
            cameraPermissionGranted={cameraPermissionGranted}
          >
            {countdownGoing && <TakePhotoCountdown>{countDownNumber}</TakePhotoCountdown>}

            <Spinner spinning={backgroundRemovalPending || finalizingImage} />

            {!imageSrc && !countdownGoing && (
              <TakeOrUploadPhotoWrapper>
                {cameraPermissionGranted && <TakePhotoButtonStyled handleClick={handleTakePhoto} />}

                <UploadPhotoButton handleUploadImage={handleUploadImage} />
              </TakeOrUploadPhotoWrapper>
            )}
          </WebcamPhoto>

          {preparationStep === PreparePhotoSteps.takePhoto && !countdownGoing && (
            <BottomBarOfTakePhoto handleGoToPhotoGallery={handleGoToPhotoGallery} />
          )}

          {preparationStep === PreparePhotoSteps.confirmTakenPhoto && (
            <BottomBar
              onPreviousStep={onPreviousStep}
              onNextStep={handleConfirmTakenPhoto}
              previousButtonText="TRY AGAIN"
              nextButtonText="NEXT"
              stepBackButtonDisabled={backgroundRemovalPending}
              stepForwardButtonDisabled={backgroundRemovalPending}
            />
          )}

          {preparationStep === PreparePhotoSteps.selectBackground && (
            <>
              <SelectBackground onSelectBackground={setSelectedBackground} selectedBackground={selectedBackground} />

              <BottomBar
                onPreviousStep={onPreviousStep}
                onNextStep={nextStep}
                stepForwardButtonDisabled={!selectedBackground}
                previousButtonText="PREVIOUS"
                nextButtonText="NEXT"
                stepForwardButtontooltipTextForDisabledState="First you need to select background photo."
              />
            </>
          )}

          {preparationStep === PreparePhotoSteps.addFrame && (
            <>
              <AddFrame onAddFrame={setSelectedFrame} selectedFrame={selectedFrame} />

              <BottomBar
                onPreviousStep={onPreviousStep}
                onNextStep={nextStep}
                previousButtonText="PREVIOUS"
                nextButtonText="NEXT"
              />
            </>
          )}

          {preparationStep === PreparePhotoSteps.dragSticker && (
            <>
              <DragSticker />

              <BottomBar
                onPreviousStep={onPreviousStep}
                onNextStep={handleDragSticker}
                stepForwardButtonDisabled={finalizingImage}
                previousButtonText="PREVIOUS"
                nextButtonText="SUBMIT"
              />
            </>
          )}
        </PreparePhotoWrapper>
      )}

      {!disableGroupPhotos && photoBoothIndexName && (
        <>
          {navigationPlace === NavigationPlaces.groupPhotoCreation && (
            <GroupPhotoCreation
              photoBoothIndexName={photoBoothIndexName}
              groupPhotoUtils={groupPhotoUtils}
              goToPreparePhoto={goToPreparePhoto}
            />
          )}

          {navigationPlace === NavigationPlaces.groupPhotoConfirmation && (
            <GroupPhotoConfirmation
              groupPhotoToConfirm={groupPhotoUtils.groupPhotoToConfirm}
              handleConfirmGroupPhoto={groupPhotoUtils.handleConfirmGroupPhoto}
              goBack={goBack}
            />
          )}
        </>
      )}

      {navigationPlace === NavigationPlaces.photoboothPhotos && (
        <PhotoboothPhotos
          usersPhotos={photoboothPhotosFetcher.usersPhotos}
          groupPhotos={photoboothPhotosFetcher.groupPhotos}
          otherUsersPhotos={photoboothPhotosFetcher.otherUsersPhotos}
          onPhotoClick={handlePhotoClick}
        />
      )}

      {navigationPlace === NavigationPlaces.singlePhoto && previewPhoto && (
        <PhotoPreview previewPhoto={previewPhoto} onClickShareToMessages={goToWritePhotoMessage} />
      )}

      {navigationPlace === NavigationPlaces.writePhotoMessage && previewPhoto && (
        <PhotoMessageWriter onClose={goBack} previewPhoto={previewPhoto} onRequestClose={onRequestClose} />
      )}
    </Wrapper>
  )
}
