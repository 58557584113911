import React from "react"
import styled from "styled-components"
import { Box } from "@material-ui/core"
import lgVideo from "lightgallery/plugins/video"
import { LightGallery } from "components/LightGallery"
import { getThumbnailUrlFromVideoUrl } from "./utils"

const Wrapper = styled(Box)``

const LinkStyled = styled.a`
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
`

const ImageOrThumbnail = styled.img`
  border-radius: 7px;
  max-width: 100%;
  height: 150px;
`

export const ExpoboothMedia: React.FC<{ imageUrls: string[]; videoUrls: string[] }> = ({ imageUrls, videoUrls }) => {
  return (
    <Wrapper>
      <LightGallery speed={200} plugins={[lgVideo]}>
        {imageUrls.map((currentImageUrl) => (
          <LinkStyled data-src={currentImageUrl} key={currentImageUrl}>
            <ImageOrThumbnail src={currentImageUrl} />
          </LinkStyled>
        ))}

        {videoUrls.map((currentVideoUrl) => (
          <LinkStyled data-src={currentVideoUrl} key={currentVideoUrl}>
            <ImageOrThumbnail src={getThumbnailUrlFromVideoUrl(currentVideoUrl)} />
          </LinkStyled>
        ))}
      </LightGallery>
    </Wrapper>
  )
}
