import { useSelector } from "store/setup/useSelector"
import { useCallback } from "react"
import { getMessageChannelsById, pickMessageChannelsByFunction } from "../messageChannels"
import { createSelector } from "@reduxjs/toolkit"
import { StageChannel } from "../channelTypes"
import mapValues from "lodash/mapValues"
import { getEvent } from "store/domain/event"

export const isStageChannelId = (channelId: string) => channelId.startsWith("space.")

export const getStageChannelsById = createSelector([getMessageChannelsById, getEvent], (messageChannelsById, event) =>
  mapValues(
    pickMessageChannelsByFunction<StageChannel>(messageChannelsById, (channel) => isStageChannelId(channel.id)),
    (stageChannel) => ({
      ...stageChannel,
      custom: {
        ...stageChannel.custom,
        icon_url: `${stageChannel.custom.icon_url}?q=${event.lastCacheInvalidationAtAsInteger}`,
      },
    }),
  ),
)

export const getStageChannels = createSelector(getStageChannelsById, (stageChannelsById) =>
  Object.values(stageChannelsById).sort((stageChannelA, stageChannelB) => {
    const aPosition = stageChannelA.custom.position
    const bPosition = stageChannelB.custom.position

    return aPosition - bPosition
  }),
)

export const useDoesStageChannelExist = () => {
  const stageChannelsById = useSelector(getStageChannelsById)

  return useCallback(
    (stageChannelId: string) => Object.keys(stageChannelsById).includes(stageChannelId),
    [stageChannelsById],
  )
}
