import React from "react"
import styled from "styled-components"
import Badge from "@material-ui/core/Badge"

export const BadgeStyled = styled(Badge)`
  .MuiBadge-badge {
    background: #ff1744;
  }
`

export const UnreadMessagesBadge: React.FC<{ invisible: boolean; className?: string }> = ({
  invisible = false,
  children,
  className,
}) => (
  <BadgeStyled invisible={invisible} variant="dot" className={className} overlap="rectangular">
    {children}
  </BadgeStyled>
)
