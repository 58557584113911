import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { PANEL_HEADER_HEIGHT } from "../constants"

const AccordionStyled = styled(Accordion)`
  background: transparent;
  box-shadow: unset;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .MuiAccordionSummary-root {
    min-height: ${PANEL_HEADER_HEIGHT}px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    :not(.Mui-expanded) {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .MuiAccordionSummary-content {
      margin: 4px 0;
    }

    .MuiIconButton-root {
      padding: 0 12px 0 8px;
    }
  }
`

const AccordionSummaryStyled = styled(AccordionSummary)`
  background: ${({ theme }) => theme.palette.primary.main};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;

  .MuiAccordionSummary-content {
    cursor: pointer;
    flex-grow: initial;
  }
`

const ExpandMoreIconStyled = styled(ExpandMoreIcon)`
  color: white;
`

const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
  height: calc(100% - ${PANEL_HEADER_HEIGHT}px);
`

export const AccordionCustom: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <AccordionStyled defaultExpanded>
      <AccordionSummaryStyled expandIcon={<ExpandMoreIconStyled />}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummaryStyled>

      <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionStyled>
  )
}
