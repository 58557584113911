import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { PollResults } from "runtypes/pollResults"
import { AppState } from "store/setup/storeTypes"

type PollsState = {
  [pollUuid: string]: {
    results?: PollResults
    vote?: string
  }
}

const initialState: PollsState = {}

export const pollsSlice = createSlice({
  name: "polls",
  initialState,
  reducers: {
    voteOnPoll: (state, action: PayloadAction<{ pollUuid: string; vote: string }>) => {
      const { pollUuid, vote } = action.payload

      state[pollUuid] = { ...state[pollUuid], vote }
    },
    setPollResults: (state, action: PayloadAction<{ pollUuid: string; results: PollResults }>) => {
      const { pollUuid, results } = action.payload

      state[pollUuid] = { ...state[pollUuid], results }
    },
  },
})

export const { voteOnPoll, setPollResults } = pollsSlice.actions

const getPolls = (state: AppState) => state[pollsSlice.name]

export const getCurrentStageChannelPoll = (state: AppState) => {
  const currentPollUuid = getCurrentStageChannel(state).custom.poll_uuid

  return currentPollUuid ? getPolls(state)[currentPollUuid] : null
}
