import DailyIframe, { DailyRoomInfo } from "@daily-co/daily-js"
import { constructDailyExitUrl, DAILY_URL } from "constants/general"
import styled from "styled-components"
import React, { useEffect, useRef, useState } from "react"
import { RemainingTime } from "./RemainingTime"
import { useTheme } from "@material-ui/core"

const Wrapper = styled.section`
  display: flex;
  box-align: flex-end;
  align-items: flex-start;
  box-pack: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #eff3f5;
`

type Props = {
  showRemainingTime?: boolean
  roomName: string
  token: string
  streamKey?: string
  className?: string
  onLeftMeeting?: () => void
}

export const VideoDaily: React.FC<Props> = ({
  showRemainingTime = false,
  roomName,
  token,
  streamKey,
  className,
  onLeftMeeting,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [hasLeftMeeting, setHasLeftMeeting] = useState(false)
  const [roomInfo, setRoomInfo] = useState<DailyRoomInfo | null>(null)
  const theme = useTheme()

  useEffect(() => {
    if (iframeRef.current) {
      const daily = DailyIframe.wrap(iframeRef.current, {
        showFullscreenButton: true,
        showLeaveButton: true,
      })

      daily.on("left-meeting", () => {
        setHasLeftMeeting(true)

        if (onLeftMeeting) {
          onLeftMeeting()
        }
      })

      if (streamKey) {
        daily.on("joined-meeting", () => {
          daily.startLiveStreaming({
            rtmpUrl: `rtmps://rtmp-global.cloud.vimeo.com:443/live/${streamKey}`,
          })
        })
      }

      daily
        .join({
          url: `${DAILY_URL}/${roomName}?t=${token}`,
          showLocalVideo: true,
          showParticipantsBar: true,
          theme: theme.custom?.colors?.dailyThemeConfig,
        })
        .then(() => {
          if (showRemainingTime) {
            daily.room().then((roomInfoArg) => {
              const roomInfoToSet = roomInfoArg as DailyRoomInfo | null

              if (roomInfoToSet) {
                setRoomInfo(roomInfoToSet)
              }
            })
          }
        })
    }
  }, [iframeRef, roomName, token, showRemainingTime, onLeftMeeting, streamKey, theme])

  return (
    <Wrapper className={className}>
      <iframe
        {...(hasLeftMeeting && { src: constructDailyExitUrl(roomName) })}
        width="100%"
        className="Video-Frame"
        height="100%"
        scrolling="no"
        frameBorder={0}
        ref={iframeRef}
        allow="microphone;camera;autoplay;display-capture;fullscreen"
      />
      {roomInfo && showRemainingTime && !hasLeftMeeting && <RemainingTime roomInfo={roomInfo} />}
    </Wrapper>
  )
}
