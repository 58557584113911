import React from "react"
import styled from "styled-components"
import { Box, CssBaseline } from "@material-ui/core"
import { useIntercomGuestBoot } from "hooks/useIntercomGuestBoot"
import { GlobalStyles } from "components/styles/GlobalStyles"

const WrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const RouterWrapper: React.FC = ({ children }) => {
  useIntercomGuestBoot()

  return (
    <WrapperBox>
      <CssBaseline />

      <GlobalStyles />

      {children}
    </WrapperBox>
  )
}
