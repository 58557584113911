import React from "react"
import { TextMessageDisplay } from "./TextMessageDisplay"
import { GiphyMessageDisplay } from "./GiphyMessageDisplay"
import { AppMessage, MessageType } from "runtypes/message"

type MessageProps = {
  message: AppMessage
}

export const MessageTypeSwitch: React.FC<MessageProps> = ({ message }) => {
  switch (message.type) {
    case MessageType.Text:
      return <TextMessageDisplay message={message} />

    case MessageType.Giphy:
      return <GiphyMessageDisplay message={message} />

    default:
      return null
  }
}
