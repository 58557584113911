import { createContext } from "react"

export type AirbrakeDefaultInfo = { stageId: string; userUuid: string }

export type AirbrakeNotifierObjectType = {
  airbrakeNotifier: {
    loadAirbrakeDefaultInfo: (defaultInfo: AirbrakeDefaultInfo) => void
    notify: ({ error, params }: { error: unknown; params?: Record<string, unknown> }) => void
    isAirbrakeNotifierActive: boolean
  }
}

export const inactiveAirbrakeNotifierObject: AirbrakeNotifierObjectType = {
  airbrakeNotifier: {
    loadAirbrakeDefaultInfo: () => undefined,
    notify: () => undefined,
    isAirbrakeNotifierActive: false,
  },
}
export const AirbrakeNotifierContext = createContext<AirbrakeNotifierObjectType>(inactiveAirbrakeNotifierObject)
