import { createGlobalStyle, css } from "styled-components"
import { whiteFlash } from "independent/styles/whiteFlash"
import { Theme } from "@material-ui/core"

const scrollbar = css`
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 9px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
`

const lightGalleryCss = css`
  ${({ theme }) => `
    .lg-backdrop {
      z-index: ${theme.zIndex.modal + 1};
    }

    .lg-outer {
      z-index: ${theme.zIndex.modal + 2};
    }
  `}
`

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  body,
  html,
  #root {
    height: 100%;
    position: relative;
  }

  ${whiteFlash}

  ${scrollbar}

  ${lightGalleryCss}
`
