import React, { useState } from "react"
import ReactPlayer from "react-player"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import { getFeaturedVideos, getVideosByCategory } from "store/domain/videoLibrary"
import { FeaturedSlider } from "./FeaturedSlider"
import { CategorySlider } from "./CategorySlider"
import { Dialog } from "@material-ui/core"
import { useFetchVideoLibraryContent } from "./useFetchVideoLibraryContent"
import { mainContentWrapperCss } from "components/styles/mainContentWrapperCss"

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(0, 1)};
  ${mainContentWrapperCss}

  ${({ theme }) => theme.breakpoints.only("xs")} {
    background: initial;
  }
`

const InnerWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
`

const ReactPlayerStyled = styled(ReactPlayer)`
  max-width: 100%;
`

export const VideoLibrary: React.FC = () => {
  useFetchVideoLibraryContent()
  const featuredVideos = useSelector(getFeaturedVideos)
  const videosByCategory = useSelector(getVideosByCategory)
  const [showPlayerModal, setShowPlayerModal] = useState(false)
  const [playerUrl, setPlayerUrl] = useState<string | null>(null)

  const handleClosePlayerModal = (): void => setShowPlayerModal(false)

  const handleOpenPlayerModal = (url: string): void => {
    setPlayerUrl(url)

    setShowPlayerModal(true)
  }

  return (
    <Wrapper>
      <InnerWrapper>
        <Dialog maxWidth={false} open={showPlayerModal} onClose={handleClosePlayerModal}>
          {playerUrl && <ReactPlayerStyled url={playerUrl} controls playing muted={false} />}
        </Dialog>

        <FeaturedSlider videos={featuredVideos} handleOpenPlayerModal={handleOpenPlayerModal} />

        {videosByCategory.map(({ id: categoryId, title, videos }) => (
          <CategorySlider
            key={categoryId}
            categoryTitle={title}
            videos={videos}
            handleOpenPlayerModal={handleOpenPlayerModal}
          />
        ))}
      </InnerWrapper>
    </Wrapper>
  )
}
