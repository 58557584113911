import { combineReducers } from "@reduxjs/toolkit"

import { appChannelsReducer } from "store/domain/pubnubStore/channels/appChannels"
import { messagesReducer } from "store/domain/pubnubStore/messages"
import { currentStageChannelIdSlice } from "store/domain/currentStageContent/currentStageChannel"
import { pollsSlice } from "store/domain/currentStageContent/polls"
import { eventSlice } from "store/domain/event"
import { authenticationSlice } from "store/domain/authentication"
import { layoutSlice } from "store/domain/layout"
import { intercomSlice } from "store/domain/intercom"
import { textMessageDraftsSlice } from "store/domain/textMessageDrafts"
import { allMessageReactionsSlice } from "store/domain/allMessageReactions"
import { videoLibrarySlice } from "store/domain/videoLibrary"
import { setupRoomsSlice } from "store/domain/chatAndVideoRoom"
import { lastReadTimetokensSlice } from "store/domain/lastReadTimetokens"
import { openChatRoomsSlice } from "store/domain/openChatRooms"
import { messageUpdatesSlice } from "../domain/messageUpdates"
import { messageDeletionsSlice } from "../domain/messageDeletions"

export const rootReducer = combineReducers({
  appChannels: appChannelsReducer,
  messages: messagesReducer,
  [currentStageChannelIdSlice.name]: currentStageChannelIdSlice.reducer,
  [pollsSlice.name]: pollsSlice.reducer,
  [eventSlice.name]: eventSlice.reducer,
  [authenticationSlice.name]: authenticationSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [intercomSlice.name]: intercomSlice.reducer,
  [textMessageDraftsSlice.name]: textMessageDraftsSlice.reducer,
  [allMessageReactionsSlice.name]: allMessageReactionsSlice.reducer,
  [messageUpdatesSlice.name]: messageUpdatesSlice.reducer,
  [messageDeletionsSlice.name]: messageDeletionsSlice.reducer,
  [videoLibrarySlice.name]: videoLibrarySlice.reducer,
  [setupRoomsSlice.name]: setupRoomsSlice.reducer,
  [lastReadTimetokensSlice.name]: lastReadTimetokensSlice.reducer,
  [openChatRoomsSlice.name]: openChatRoomsSlice.reducer,
})
