export const useDisableOnContextMenu = () => {
  if (process.env.NODE_ENV !== "development") {
    document.oncontextmenu = function (e) {
      if (e.button == 2) {
        e.preventDefault()
        return false
      }
    }
  }
}
