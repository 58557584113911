import React from "react"
import styled from "styled-components"
import { Fab, Grid } from "@material-ui/core"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import ClearIcon from "@material-ui/icons/Clear"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const GridWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

type Props = { goBack: () => void; onRequestClose: () => void; canGoBack: boolean }

export const PhotoboothHeader: React.FC<Props> = ({ goBack, onRequestClose, children, canGoBack }) => {
  return (
    <Wrapper>
      <Grid container direction="row" justifyContent="space-around" alignItems="center">
        <GridWrapper item xs={2} sm={1}>
          {canGoBack && (
            <Fab size="small" color="secondary" onClick={goBack}>
              <NavigateBeforeIcon />
            </Fab>
          )}
        </GridWrapper>
        <Grid item xs={8} sm={10}>
          <ContentWrapper>{children}</ContentWrapper>
        </Grid>
        <GridWrapper item xs={2} sm={1}>
          <Fab size="small" color="secondary" onClick={onRequestClose}>
            <ClearIcon />
          </Fab>
        </GridWrapper>
      </Grid>
    </Wrapper>
  )
}
