import React from "react"
import { Button, Typography } from "@material-ui/core"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import styled from "styled-components"

const ButtonStyled = styled(Button)`
  ${({ theme }) => `
    min-width: 0px;

    ${theme.breakpoints.up("sm")} {
      margin-right: ${theme.spacing(1.5)}px;
    }
  `}
`

export const PhotoboothTrigger: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <ButtonStyled onClick={onClick} color="primary" variant="contained" size="medium">
      <PhotoCameraIcon />

      <Typography>&nbsp;PHOTO BOOTH</Typography>
    </ButtonStyled>
  )
}
