import React from "react"
import { MessagesPanel } from "features/baseBlocks/MessagesPanel"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { useSelector } from "store/setup/useSelector"
import { useWelcomeMessage, WelcomeMessageTypes } from "hooks/useWelcomeMessage"

export const StageMessages: React.FC<{ forMobile?: boolean }> = ({ forMobile = false }) => {
  const welcomeMessage = useWelcomeMessage({ type: WelcomeMessageTypes.Stage })
  const currentStageChannel = useSelector(getCurrentStageChannel)

  return (
    <MessagesPanel
      welcomeMessage={welcomeMessage}
      channelId={currentStageChannel.id}
      inputLabel="Write a comment"
      focusAutomatically={false}
      forMobile={forMobile}
      scrollDownButtonText="Unread comments"
    />
  )
}
