import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"

export const fetchMingleRoomNameAndToken =
  (eventUuid: string) => (): Promise<{ mingleRoomName: string; mingleRoomToken: string }> =>
    baseApi
      .post<[string, string]>({
        url: apiUrls.mingle.roomNameAndToken,
        headers: {
          EventToken: eventUuid,
        },
      })
      .then(({ data }) => data)
      .then((data) => ({ mingleRoomName: data[0], mingleRoomToken: data[1] }))

export const fetchMingleAttendeesCount = (eventUuid: string) => (): Promise<number> =>
  baseApi
    .get<number>({
      url: apiUrls.mingle.attendeesCount,
      headers: {
        EventToken: eventUuid,
      },
    })
    .then(({ data }) => data)
