import React, { useState } from "react"
import styled from "styled-components"
import { Box, Button, Dialog, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import IFrame from "react-iframe"
import { transparentize } from "polished"

const InfoDialogTrigger = styled(Button)`
  color: ${({ theme }) => theme.custom.colors.currentStage.color};
  border-color: ${({ theme }) => transparentize(0.5, theme.custom.colors.currentStage.color)};
`

const DialogStyled = styled(Dialog)<{ $isFullScreen: boolean }>`
  .MuiDialog-paper {
    ${({ $isFullScreen }) => ($isFullScreen ? "" : "height: 80%; width: 80%;")}
  }
`

const DialogTitleStyled = styled(Box)`
  padding: 15px 15px 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const DialogContentStyled = styled(Box)`
  padding: 6px;
  height: 100%;
`

const IFrameStyled = styled(IFrame)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`

type Props = {
  iframeLinkText: string
  iframeTitle: string
  iframeSrc: string
}

export const AdditionalInfoDialog: React.FC<Props> = ({ iframeLinkText, iframeTitle, iframeSrc }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const isFullScreen = isXs

  return (
    <>
      <InfoDialogTrigger
        onClick={() => {
          setDialogOpen(true)
        }}
        variant="outlined"
        size="small"
      >
        <b>{iframeLinkText}</b>
      </InfoDialogTrigger>

      <DialogStyled
        fullScreen={isFullScreen}
        $isFullScreen={isFullScreen}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
      >
        <DialogTitleStyled>
          <Typography>{iframeTitle}</Typography>

          <CloseIconStyled
            onClick={() => {
              setDialogOpen(false)
            }}
          />
        </DialogTitleStyled>

        <DialogContentStyled>
          <IFrameStyled url={iframeSrc} frameBorder={0} />
        </DialogContentStyled>
      </DialogStyled>
    </>
  )
}
