import { Box, Button, Fade, Paper, Popper, PopperPlacementType, Typography } from "@material-ui/core"
import styled, { css } from "styled-components"
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined"
import {
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinShareButton as LinkedinShareButtonCommon,
  TwitterShareButton as TwitterShareButtonCommon,
  FacebookShareButton as FacebookShareButtonCommon,
  WhatsappShareButton as WhatsappShareButtonCommon,
  EmailShareButton as EmailShareButtonCommon,
} from "react-share"
import React from "react"
import { GalleryPhoto } from "api/apiFunctions/photobooth/photoGallery"
import { GroupPhoto } from "api/apiFunctions/photobooth/groupPhoto"

const tooltipItem = css`
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2)}px;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  svg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`

const PopperWrapper = styled(Popper)`
  z-index: 10000;
`

const PaperWrapper = styled(Paper)`
  padding: 10px;
`

const TypographyWrapper = styled(Typography)`
  margin-left: 10px;
`

const LinkedinShareButton = styled(LinkedinShareButtonCommon)`
  ${tooltipItem}
`

const TwitterShareButton = styled(TwitterShareButtonCommon)`
  ${tooltipItem}
`

const FacebookShareButton = styled(FacebookShareButtonCommon)`
  ${tooltipItem}
`

const WhatsappShareButton = styled(WhatsappShareButtonCommon)`
  ${tooltipItem}
`

const EmailShareButton = styled(EmailShareButtonCommon)`
  ${tooltipItem}
`

type Props = {
  previewPhoto: GalleryPhoto | GroupPhoto
}

export const PhotoShare: React.FC<Props> = ({ previewPhoto }) => {
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [openShares, setOpenShares] = React.useState(false)
  const [popperPlacement, setPopperPlacement] = React.useState<PopperPlacementType>()

  const handleShareClick =
    (newPopperPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopperAnchorEl(event.currentTarget)
      setOpenShares((prev) => popperPlacement !== newPopperPlacement || !prev)
      setPopperPlacement(newPopperPlacement)
    }
  return (
    <>
      {true && (
        <Box>
          <PopperWrapper open={openShares} anchorEl={popperAnchorEl} placement={popperPlacement} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <PaperWrapper>
                  <LinkedinShareButton url={previewPhoto.imageUrl}>
                    <LinkedinIcon />
                    <TypographyWrapper variant="body1">Share to Linkedin</TypographyWrapper>
                  </LinkedinShareButton>
                  <TwitterShareButton url={previewPhoto.imageUrl}>
                    <TwitterIcon />
                    <TypographyWrapper variant="body1">Share to Twitter</TypographyWrapper>
                  </TwitterShareButton>
                  <FacebookShareButton url={previewPhoto.imageUrl}>
                    <FacebookIcon />
                    <TypographyWrapper variant="body1">Share to Facebook</TypographyWrapper>
                  </FacebookShareButton>
                  <WhatsappShareButton url={previewPhoto.imageUrl}>
                    <WhatsappIcon />
                    <TypographyWrapper variant="body1">Send on WhatsApp</TypographyWrapper>
                  </WhatsappShareButton>
                  <EmailShareButton url={previewPhoto.imageUrl}>
                    <EmailIcon />
                    <TypographyWrapper variant="body1">Send via mail</TypographyWrapper>
                  </EmailShareButton>
                </PaperWrapper>
              </Fade>
            )}
          </PopperWrapper>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ShareOutlinedIcon />}
            onClick={handleShareClick("top")}
          >
            Share
          </Button>
        </Box>
      )}
    </>
  )
}
