import { snackbarUtils } from "components/snackbar/snackbarUtils"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

export const addSnackbarInThen = (data: string) => {
  snackbarUtils.success(data)

  return data
}

export const addSnackbarInCatch = (requestConfig?: AxiosRequestConfig) => (error: AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 409) {
      const errorMessage = error.response.data

      if (requestConfig && requestConfig.responseType === "blob") {
        errorMessage.text().then((text: string) => {
          snackbarUtils.warning(text)
        })
      } else {
        snackbarUtils.warning(errorMessage)
      }
    }
  }

  return Promise.reject(error)
}
