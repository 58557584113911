import React from "react"
import styled, { css } from "styled-components"
import Webcam from "react-webcam"
import { userSelectNone } from "independent/styles/userSelectNone"
import { Button, Typography } from "@material-ui/core"
import { TakePhotoButton } from "./TakePhotoButton"

export const nonDraggable = css`
  user-drag: none;
  -webkit-user-drag: none;
  ${userSelectNone}
`

export const photoboothImageDimensions = {
  width: 450,
  height: 450,
}

const webcamPlaceholderDimensions = {
  width: 400,
  height: 400,
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PreparePhotoWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  background: #ffffff34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 15px;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    padding: ${({ theme }) => theme.spacing(1)}px;
    margin-top: 0;
  }
`

export const TakeOrUploadPhotoWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
`

export const TakePhotoButtonStyled = styled(TakePhotoButton)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1.5)}px;

    ${theme.breakpoints.up("xs")} {
      margin-right: ${theme.spacing(1)}px;
    }
  `}
`

export const WebcamPhotoWrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;

  & video {
    ${({ theme }) => theme.breakpoints.only("xs")} {
      width: 100%;
      height: 100%;
    }
  }
`

export const ImageWrapper = styled.div`
  background: white;
`

const imageCss = css`
  max-width: 100%;
  height: auto;
  width: ${photoboothImageDimensions.width}px;
`

export const TakenOrUploadedImage = styled.img`
  ${imageCss}
  z-index: 2;
  display: block;
  position: relative;
`

const OverlappingImage = styled.img`
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 10px;

  ${nonDraggable}
`

export const BackgroundImage = styled(OverlappingImage)`
  ${imageCss}
  z-index: 1;
`

export const FrameImage = styled(OverlappingImage)`
  ${imageCss}
  z-index: 3;
`

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const MainTitle = styled(Typography)`
  color: white;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const SelfieAndGroupPhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    flex-direction: column;
  }
`

const selfieAndGroupButtonCss = css`
  width: 250px;
  margin: ${({ theme }) => theme.spacing(1.5, 1, 0)};

  ${({ theme }) => theme.breakpoints.only("xs")} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

export const SelfiePhotoNavigationButton = styled(Button)`
  ${selfieAndGroupButtonCss}
`

export const GroupPhotoNavigationButton = styled(Button)`
  ${selfieAndGroupButtonCss}
`

export const WebcamPlaceholder = styled.div`
  background: white;
  padding: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #4b4b4b;
  width: ${webcamPlaceholderDimensions.width}px;
  height: ${webcamPlaceholderDimensions.height}px;
`

export const WebcamPlaceholderTitle = styled(Typography)`
  text-align: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-top: ${({ theme }) => theme.spacing(7.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;

  :after {
    padding-top: ${({ theme }) => theme.spacing(4)}px;
    content: "";
    display: block;
    margin: 0 auto;
    width: 80%;
    border-bottom: 2px solid #cacaca;
  }
`

export const WebcamPlaceholderContent = styled.div``

export const WebcamStyled = styled(Webcam)<{ ref: React.RefObject<Webcam>; $isVisibility: boolean }>`
  display: ${({ $isVisibility }) => `${$isVisibility ? "initial" : "none"}`};

  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
`

export const BottomBarWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

export const TakePhotoCountdown = styled.div`
  display: flex;
  z-index: 999;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 160px;
  color: white;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.custom.colors.photobooth.countdown.color};
`
