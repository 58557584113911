import React, { useContext, useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as ThemeProviderMui, StylesProvider, Theme } from "@material-ui/core/styles"
import { constructTheme } from "./constructTheme"
import { SnackbarSetup } from "components/snackbar/SnackbarSetup"
import { EventSetupContext } from "../EventSetupContextProvider"

export const ThemeSetup: React.FC = ({ children }) => {
  const [theme, setTheme] = React.useState<Theme | null>(null)

  const eventSetup = useContext(EventSetupContext)

  useEffect(() => {
    if (eventSetup) {
      setTheme(constructTheme(eventSetup.brandingJson))
    }
  }, [eventSetup])

  return (
    <>
      {theme && (
        <StylesProvider injectFirst>
          <ThemeProviderMui theme={theme}>
            <ThemeProvider theme={theme}>
              <SnackbarSetup>{children}</SnackbarSetup>
            </ThemeProvider>
          </ThemeProviderMui>
        </StylesProvider>
      )}
    </>
  )
}
