import React from "react"
import { connectHits } from "react-instantsearch-dom"
import { HitsProvided } from "react-instantsearch-core"
import styled from "styled-components"
import { Box, Button, Card, Grid, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { ExpoboothHit } from "components/algolia/types"
import { routerPaths } from "main/App/Router/routerPaths"
import useEventSlug from "hooks/useEventSlug"

const WrapperBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const CardWrapper = styled(Card)`
  margin: ${({ theme }) => theme.spacing(0.88)}px;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.breakpoints.only("xs")} {
    flex: 0 1 calc(100% - 1em);
  }
  ${({ theme }) => theme.breakpoints.only("sm")} {
    flex: 0 1 calc(100% - 1em);
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 0 1 calc(49.9% - 1em);
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex: 0 1 calc(33.3% - 1em);
  }
`

const Image = styled.img`
  width: 100%;
  position: relative;
`

const CardButton = styled(Button)`
  width: 100%;
`

const EnterButtonWrapper = styled(Grid)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(0.7)}px;
`

const TierImageBorder = styled.div`
  position: absolute;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 50%;
  border: 0.5px solid black;
  padding: ${({ theme }) => theme.spacing(1.2)}px;
  background-color: rgba(255, 255, 255, 0.6);
`

const TierImage = styled.img`
  width: ${({ theme }) => theme.spacing(3.75)}px;
  height: ${({ theme }) => theme.spacing(2.5)}px;
`

const LogoImage = styled.img`
  margin-top: ${({ theme }) => theme.spacing(0.7)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.7)}px;
`

const ImageSection = styled.section`
  display: flex;
  box-align: flex-end;
  align-items: flex-start;
  box-pack: flex-end;
  justify-content: flex-end;
`

type OwnProps = { isSearchInProgress: boolean }

type Props = HitsProvided<ExpoboothHit> & OwnProps

const SearchedExpoboothsRaw: React.FC<Props> = ({ hits: expoboothHits, isSearchInProgress }) => {
  const history = useHistory()
  const eventSlug = useEventSlug()

  return (
    <WrapperBox mb={1}>
      {expoboothHits.length === 0 && isSearchInProgress && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography color="secondary" variant="h6">
            No results found
          </Typography>
        </Grid>
      )}

      {expoboothHits.map((currentExpoboothHit) => (
        <CardWrapper key={currentExpoboothHit.uuid}>
          <ImageSection>
            <Image src={currentExpoboothHit.search_image_url} alt="main image" />
            <TierImageBorder>
              <TierImage src={currentExpoboothHit.tier_image_url} alt={currentExpoboothHit.tier_name} />
            </TierImageBorder>
          </ImageSection>
          <Box mx={2}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <LogoImage src={currentExpoboothHit.logo_url} alt="logo" />
              </Grid>
              <Grid item>
                <Box ml={1} my={1}>
                  <Typography variant="h6">{currentExpoboothHit.title}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={7} px={2}>
            <Typography variant="caption">{currentExpoboothHit.summary}</Typography>
          </Box>
          <EnterButtonWrapper container direction="row">
            <Grid item xs={12}>
              <Box mx={1} my={1}>
                <CardButton
                  onClick={() =>
                    history.push({
                      pathname: routerPaths.expobooth.assemblePath(eventSlug, currentExpoboothHit.uuid),
                    })
                  }
                  color="primary"
                  variant="contained"
                >
                  ENTER
                </CardButton>
              </Box>
            </Grid>
          </EnterButtonWrapper>
        </CardWrapper>
      ))}
    </WrapperBox>
  )
}

// @ts-ignore
export const SearchedExpobooths: React.FC<OwnProps> = connectHits(SearchedExpoboothsRaw)
