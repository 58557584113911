import { useState, useCallback } from "react"

export enum NavigationPlaces {
  preparePhoto,
  photoboothPhotos,
  singlePhoto,
  writePhotoMessage,
  groupPhotoCreation,
  groupPhotoConfirmation,
}

const navigationPlacePreviousLocations = {
  [NavigationPlaces.preparePhoto]: null,
  [NavigationPlaces.photoboothPhotos]: NavigationPlaces.preparePhoto,
  [NavigationPlaces.singlePhoto]: NavigationPlaces.photoboothPhotos,
  [NavigationPlaces.writePhotoMessage]: NavigationPlaces.singlePhoto,
  [NavigationPlaces.groupPhotoCreation]: NavigationPlaces.preparePhoto,
  [NavigationPlaces.groupPhotoConfirmation]: NavigationPlaces.groupPhotoCreation,
}

export const useNavigation = () => {
  const [navigationPlace, setNavigationPlace] = useState<NavigationPlaces>(NavigationPlaces.preparePhoto)

  const previousLocation = navigationPlacePreviousLocations[navigationPlace]

  const goBack = useCallback(() => {
    if (previousLocation !== null) {
      setNavigationPlace(previousLocation)
    }
  }, [previousLocation])

  const goToPreparePhoto = useCallback(() => {
    setNavigationPlace(NavigationPlaces.preparePhoto)
  }, [setNavigationPlace])

  const goToPhotoGallery = useCallback(() => {
    setNavigationPlace(NavigationPlaces.photoboothPhotos)
  }, [setNavigationPlace])

  const goToSinglePhoto = useCallback(() => {
    setNavigationPlace(NavigationPlaces.singlePhoto)
  }, [setNavigationPlace])

  const goToWritePhotoMessage = useCallback(() => {
    setNavigationPlace(NavigationPlaces.writePhotoMessage)
  }, [setNavigationPlace])

  const goToGroupPhotoCreation = useCallback(() => {
    setNavigationPlace(NavigationPlaces.groupPhotoCreation)
  }, [setNavigationPlace])

  const goToGroupPhotoConfirmation = useCallback(() => {
    setNavigationPlace(NavigationPlaces.groupPhotoConfirmation)
  }, [setNavigationPlace])

  return {
    navigationPlace,
    goToPreparePhoto,
    goToPhotoGallery,
    goToSinglePhoto,
    goToWritePhotoMessage,
    goToGroupPhotoCreation,
    goToGroupPhotoConfirmation,
    goBack,
    canGoBack: previousLocation !== null,
  }
}
