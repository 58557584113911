import { Box, Typography } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  svg {
    margin-bottom: 6px;
  }
`

const ItemText = styled(Typography)`
  ${() => `
    font-weight: bold;
  `}
`

export const SocializeItem: React.FC<{ name: string; onClick: () => void; iconComponent: React.ReactNode }> = ({
  name,
  onClick,
  iconComponent,
}) => {
  return (
    <Wrapper onClick={onClick} className="socialize-item">
      {iconComponent}

      <ItemText>{name}</ItemText>
    </Wrapper>
  )
}
