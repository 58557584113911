import React, { useRef, useCallback } from "react"
import styled from "styled-components"
import { BottomBarWrapper } from "../PrepareAndViewPhotos.style"
import { MAX_IMAGE_SIZE_BYTES } from "../constants"
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined"
import { Button } from "@material-ui/core"

const UploadImageInput = styled.input`
  display: none;
`

const BottomBarWrapperStyled = styled(BottomBarWrapper)`
  margin-top: 0;
`

export const UploadPhotoButton: React.FC<{ handleUploadImage: (src: File) => void }> = ({ handleUploadImage }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedImage = event?.target?.files?.[0]

      if (uploadedImage) {
        if (uploadedImage.size > MAX_IMAGE_SIZE_BYTES) {
          alert("Image is too large.")

          return
        }

        handleUploadImage(uploadedImage)

        if (inputRef && inputRef.current) {
          inputRef.current.value = ""
        }
      }
    },
    [handleUploadImage],
  )

  return (
    <BottomBarWrapperStyled>
      <label htmlFor="contained-button-file">
        <UploadImageInput
          ref={inputRef}
          accept="image/png, image/jpg, image/jpeg"
          id="contained-button-file"
          type="file"
          onChange={handleUploadImageChange}
        />
        <Button variant="contained" color="primary" startIcon={<PublishOutlinedIcon />} component="span">
          UPLOAD PICTURE
        </Button>
      </label>
    </BottomBarWrapperStyled>
  )
}
