import { Box, Drawer, IconButton } from "@material-ui/core"
import { BellNotifications } from "features/baseBlocks/BellNotifications"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import MenuIcon from "@material-ui/icons/Menu"
import { LogoImage } from "components/LogoImage"
import { useSelector } from "store/setup/useSelector"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { StagesList } from "features/baseBlocks/StagesList"
import { darken } from "polished"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { getEvent } from "store/domain/event"

const Wrapper = styled(Box)`
  ${({ theme }) => `
    color: ${theme.custom.colors.appBar.color};
    display: flex;
    padding: 12px;
    width: 100%;
    position: relative;
    white-space: nowrap;
  `}
`

const Side = styled(Box)`
  display: flex;
  height: min-content;
  min-width: 0;
  width: 50%;
`

const sideContentCss = css`
  ${({ theme }) => `
    background: ${theme.custom.colors.appBar.background};
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
  `}
`

const LeftSideContent = styled(Box)`
  ${sideContentCss}
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 4px 0 15px;
`

const RightSideContent = styled(Box)`
  ${sideContentCss}
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px 8px;
`

const StagesDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${({ theme }) => theme.custom.colors.headsUpDisplay.mainMenu.background};
    padding: 10px 20px;
    min-width: 300px;
  }
`

const CloseButtonStyled = styled(IconButton)`
  color: ${({ theme }) => theme.custom.colors.headsUpDisplay.mainMenu.color};
  width: min-content;
`

const StagesListStyled = styled(StagesList)`
  color: ${({ theme }) => theme.custom.colors.headsUpDisplay.mainMenu.color};

  #stages-list-divider {
    background: ${({ theme }) => darken(0.5, theme.custom.colors.headsUpDisplay.mainMenu.color)};
  }
`

const MenuIconButton = styled(IconButton)`
  padding: 6px 8px;
`

const StageName = styled(Box)`
  opacity: 0.7;
  font-weight: bold;
  margin-left: auto;
`

const LogoImageStyled = styled(LogoImage)`
  ${({ theme }) => `
    padding: 10px;
    background: ${theme.custom.colors.headsUpDisplay.logoImage.background};
    border-radius: 10px;
    margin: 0 6px;
    max-width: 220px;
  `}
`

const BellNotificationsStyled = styled(BellNotifications)`
  margin: 2px 0 2px 6px;
`

const triangleCss = css<{ isOnTheLeftSide?: boolean }>`
  ${({
    theme: {
      custom: {
        colors: {
          appBar: { background },
        },
      },
    },
    isOnTheLeftSide = false,
  }) => `
    width: 40px;
    border-bottom-${isOnTheLeftSide ? "right" : "left"}-radius: 3px;

    background: linear-gradient(
      to top ${isOnTheLeftSide ? "right" : "left"},
      ${background} 0%,
      ${background} calc(50% - 0.8px),
      ${background} 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  `}
`

const TriangleOrnament = styled.div`
  ${triangleCss}
`

export const Header: React.FC = () => {
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const { name: eventName } = useSelector(getEvent)
  const [stagesDrawerOpen, setStagesDrawerOpen] = useState(false)

  return (
    <Wrapper>
      <Side>
        <LeftSideContent>
          <StagesDrawer
            open={stagesDrawerOpen}
            onClose={() => {
              setStagesDrawerOpen(false)
            }}
          >
            <CloseButtonStyled onClick={() => setStagesDrawerOpen(false)}>
              <CloseRoundedIcon />
            </CloseButtonStyled>

            <StagesListStyled
              onClickStage={() => {
                setStagesDrawerOpen(false)
              }}
            />
          </StagesDrawer>

          <MenuIconButton
            color="inherit"
            edge="start"
            onClick={() => {
              setStagesDrawerOpen(true)
            }}
          >
            <MenuIcon />
          </MenuIconButton>

          <Box overflow="hidden" textOverflow="ellipsis" pr={1}>
            <b>{eventName}</b>
          </Box>

          <StageName>{currentStageChannel.name}</StageName>
        </LeftSideContent>

        <TriangleOrnament isOnTheLeftSide />
      </Side>

      <LogoImageStyled />

      <Side>
        <TriangleOrnament />

        <RightSideContent>
          <Box textOverflow="ellipsis" overflow="hidden">
            Some message displayed to user.
          </Box>
        </RightSideContent>

        <BellNotificationsStyled />
      </Side>
    </Wrapper>
  )
}
