import React from "react"
import { useHistory } from "react-router-dom"
import { Photobooth } from "features/baseBlocks/Photobooth"
import useEventSlug from "hooks/useEventSlug"

export const PhotoboothRoute: React.FC = () => {
  const history = useHistory()
  const eventSlug = useEventSlug()

  return (
    <Photobooth
      open={true}
      onRequestClose={() => {
        history.push({ pathname: `/${eventSlug}` })
      }}
    />
  )
}
