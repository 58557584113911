import React, { useState } from "react"
import { Box, Button } from "@material-ui/core"
import { ChipsPanel } from "components/ChipsPanel"
import { GroupPhotoUtils } from "../../useGroupPhotoUtils"

type Props = {
  addedPhotosOfGroup: GroupPhotoUtils["addedPhotosOfGroup"]
  removePhotoOfGroup: GroupPhotoUtils["removePhotoOfGroup"]
  handleCreateGroupPhotoForConfirmation: GroupPhotoUtils["handleCreateGroupPhotoForConfirmation"]
}

export const AddedPhotosPanel: React.FC<Props> = ({
  addedPhotosOfGroup,
  removePhotoOfGroup,
  handleCreateGroupPhotoForConfirmation,
}) => {
  const [creationLoading, setCreationLoading] = useState(false)

  const onClickCreate = async () => {
    setCreationLoading(true)

    await handleCreateGroupPhotoForConfirmation({ onFinish: () => setCreationLoading(false) })
  }

  return (
    <Box mt={2} mb={3}>
      <ChipsPanel
        addedChips={addedPhotosOfGroup.map(({ uuid, username }) => ({
          id: uuid,
          label: username,
        }))}
        removeChip={removePhotoOfGroup}
        isAccordionBorderOn
      >
        <Box>
          <Button variant="contained" color="primary" onClick={onClickCreate} disabled={creationLoading}>
            CONFIRM
          </Button>
        </Box>
      </ChipsPanel>
    </Box>
  )
}
