import React, { useCallback, useEffect, useState } from "react"
import { AuthenticationUtils } from "./useAuthenticationUtils"
import { Box, Button, Checkbox, FormControlLabel, Tab, TextField, Typography } from "@material-ui/core"
import { Field, Form } from "react-final-form"
import styled from "styled-components"
import { PrimaryTabs } from "components/PrimaryTabs"
import { ContactEmailLink } from "components/ContactEmailLink"
import { InformationLinks } from "./InformationLinks"
import { MicrosoftIcon } from "independent/icons/loginWidget/MicrosoftIcon"
import { SlackIcon } from "independent/icons/loginWidget/SlackIcon"
import { GoogleIcon } from "independent/icons/loginWidget/GoogleIcon"
import { AppleIcon } from "independent/icons/loginWidget/AppleIcon"
import { FacebookIcon } from "independent/icons/loginWidget/FacebookIcon"
import { ErrorsBanner } from "components/ErrorsBanner"
import { FORM_ERROR } from "final-form"
import * as EmailValidator from "email-validator"

const Wrapper = styled(Box)`
  width: 420px;
  height: min-content;
  padding: 56px 48px 48px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  ${({ theme }) => theme.breakpoints.only("xs")} {
    margin-bottom: 60px;
    width: 100%;
    max-width: 500px;
    padding: 40px 15px;
  }
`

const AlternativeLoginOptionsText = styled.div`
  display: flex;
  align-items: center;

  .MuiTypography-root {
    white-space: nowrap;
    opacity: 0.4;
    padding: 0 8px;
  }

  #decorative-line {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    height: 0;
  }
`

const AlternativeLoginOptionIconsWrapper = styled(Box)`
  svg {
    margin: 0 4px;
  }
`

const ResendCodeToEmailLink = styled.a<{ $disabled: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled
      ? `
        pointer-events: none;
        opacity: 0.3;
      `
      : `
        color: #2a5ee4;
      `}
`

type LoginFormValues = {
  email: string
  otc: string
  firstName: string
  lastName: string
  marketingCommunication: boolean
}

type Props = { authenticationUtils: AuthenticationUtils }

export const LoginWidget: React.FC<Props> = ({
  authenticationUtils: {
    isLoading,
    isOtcVerification,
    onlyLoggedIn,
    handleSignInWithEmail,
    handleFetchOtcAgain,
    handleLoginWithOtc,
    handleCompletingTheProfile,
  },
}) => {
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (onlyLoggedIn) {
      setActiveStep(2)
    }
  }, [onlyLoggedIn])

  const onSubmit = useCallback(
    async ({ email, firstName, lastName, marketingCommunication, otc }: LoginFormValues) => {
      if (activeStep === 0) {
        await handleSignInWithEmail({ email })

        setActiveStep(1)
      } else if (activeStep === 1 && isOtcVerification) {
        const result = await handleLoginWithOtc({ email, otc })

        if (result.loggedIn) {
          setActiveStep(2)
        } else {
          return { [FORM_ERROR]: "error" }
        }
      } else if (activeStep === 2) {
        await handleCompletingTheProfile({ firstName, lastName, marketingCommunication })
      }
    },
    [activeStep, handleLoginWithOtc, handleSignInWithEmail, handleCompletingTheProfile, isOtcVerification],
  )

  const getHandleResentCodeToEmail = useCallback(
    ({ email }: { email: string }) => {
      return async () => {
        await handleFetchOtcAgain({ email })
      }
    },
    [handleFetchOtcAgain],
  )

  /*
    Currently auth flow only has "LOG IN" tab, in future if we add more, I don't
    know how it will work out with form widget logic, so for now, temporarily,
    this will be just decorative Tab header element, with no real logic behind it.
  */
  const tabForDecoration = (
    <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
      <Box width="min-content">
        <PrimaryTabs value={0}>
          <Tab label="LOG IN" />
        </PrimaryTabs>
      </Box>
    </Box>
  )

  return (
    <Wrapper>
      <Form
        initialValues={{
          email: "",
          otc: "",
          firstName: "",
          lastName: "",
          marketingCommunication: false,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            {(activeStep === 0 || activeStep === 2) && <>{tabForDecoration}</>}

            {submitError && (
              <Box mb={4}>
                <ErrorsBanner headerText="Something wend wrong" />
              </Box>
            )}

            {activeStep === 0 && (
              <>
                <Box mb={3}>
                  <Typography>Enter email to log in</Typography>
                </Box>

                <Box mb={3}>
                  <Field
                    name="email"
                    validate={(value) => {
                      if (!value) {
                        return "Please enter email address"
                      } else if (!EmailValidator.validate(value)) {
                        return "You must enter valid email"
                      }
                    }}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        error={!!(meta.touched && meta.error)}
                        helperText={meta.touched && meta.error}
                        fullWidth
                        label="Email"
                        variant="outlined"
                      />
                    )}
                  </Field>
                </Box>

                <Box mb={3}>
                  <AlternativeLoginOptionsText>
                    <div id="decorative-line" />

                    <Typography>OR LOG IN WITH </Typography>

                    <div id="decorative-line" />
                  </AlternativeLoginOptionsText>
                </Box>

                <AlternativeLoginOptionIconsWrapper display="flex" justifyContent="space-between" mb={3}>
                  <MicrosoftIcon />

                  <SlackIcon />

                  <GoogleIcon />

                  <AppleIcon />

                  <FacebookIcon />
                </AlternativeLoginOptionIconsWrapper>

                <Box mb={1}>
                  <Typography>
                    By login you are agreeing to the{" "}
                    <InformationLinks
                      href="https://assets-public-vstage.s3.eu-west-1.amazonaws.com/platform/demo.vstage.com/terms.pdf"
                      title="terms & conditions"
                    />{" "}
                    and{" "}
                    <InformationLinks
                      href="https://assets-public-vstage.s3.eu-west-1.amazonaws.com/platform/demo.vstage.com/privacy.pdf"
                      title="privacy & cookies"
                    />{" "}
                    notice.
                  </Typography>
                </Box>

                <Box mb={3} textAlign="center">
                  <Typography>
                    Contact <ContactEmailLink /> for any query.
                  </Typography>
                </Box>

                <Button variant="contained" color="primary" type="submit" disabled={isLoading} fullWidth>
                  {isLoading ? "LOADING..." : "GET OTC TO LOG IN"}
                </Button>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Box mb={3}>
                  <Typography>Enter One Time Code to acess the event</Typography>
                </Box>

                {isOtcVerification && (
                  <Box mb={3}>
                    <Field
                      name="otc"
                      validate={(value) => {
                        if (!value) {
                          return "Please enter one time code"
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          error={!!(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          fullWidth
                          label="One Time Code"
                          variant="outlined"
                          type="number"
                          className="input-type-number-without-arrows"
                        />
                      )}
                    </Field>
                  </Box>
                )}

                <Box mb={3}>
                  <ResendCodeToEmailLink
                    $disabled={isLoading}
                    onClick={getHandleResentCodeToEmail({ email: values.email })}
                  >
                    Resend code to email
                  </ResendCodeToEmailLink>
                </Box>

                <Button variant="contained" color="primary" type="submit" disabled={isLoading} fullWidth>
                  {isLoading ? "LOADING..." : "LOG IN"}
                </Button>
              </>
            )}

            {activeStep === 2 && (
              <>
                <Box mb={3}>
                  <Typography>Complete your profile</Typography>
                </Box>

                <Box mb={3}>
                  <Field
                    name="firstName"
                    validate={(value) => {
                      if (!value) {
                        return "Please enter first name"
                      }
                    }}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        error={!!(meta.touched && meta.error)}
                        helperText={meta.touched && meta.error}
                        fullWidth
                        label="First name"
                        variant="outlined"
                      />
                    )}
                  </Field>
                </Box>

                <Box mb={3}>
                  <Field
                    name="lastName"
                    validate={(value) => {
                      if (!value) {
                        return "Please enter last name"
                      }
                    }}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        error={!!(meta.touched && meta.error)}
                        helperText={meta.touched && meta.error}
                        fullWidth
                        label="Last name"
                        variant="outlined"
                      />
                    )}
                  </Field>
                </Box>

                <Box mb={1} width="100%">
                  <Typography>Would you like to receive marketing updates?</Typography>
                </Box>

                <Box mb={3}>
                  <Field name="marketingCommunication" type="checkbox">
                    {({ input }) => (
                      <FormControlLabel
                        control={<Checkbox {...input} name="marketingCommunicationCheckbox" />}
                        label="Yes"
                      />
                    )}
                  </Field>
                </Box>

                <Button variant="contained" color="primary" type="submit" disabled={isLoading} fullWidth>
                  {isLoading ? "LOADING..." : "CONTINUE"}
                </Button>
              </>
            )}
          </form>
        )}
      />
    </Wrapper>
  )
}
