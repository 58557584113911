import { useEffect } from "react"
import { useSelectStage } from "hooks/useSelectStage"
import { useDispatch } from "store/setup/useDispatch"
import { setEventAppDialogOpen, setLeaderboard } from "store/domain/layout"

const OPEN_STAGE_EVENT_TYPE = "openStage"
const CLOSE_STAGE_EVENT_TYPE = "closeStage"
const OPEN_LEADERBOARD_EVENT_TYPE = "openLeaderboard"
const CLOSE_LEADERBOARD_EVENT_TYPE = "closeLeaderboard"

type PostMessageData =
  | { command: typeof OPEN_STAGE_EVENT_TYPE; stageChannelId: string }
  | { command: typeof CLOSE_STAGE_EVENT_TYPE }
  | { command: typeof OPEN_LEADERBOARD_EVENT_TYPE }
  | { command: typeof CLOSE_LEADERBOARD_EVENT_TYPE }

export const usePostMessageListeners = () => {
  const selectStage = useSelectStage()
  const dispatch = useDispatch()

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent<PostMessageData>) => {
      if (event.data.command === OPEN_STAGE_EVENT_TYPE) {
        selectStage(event.data.stageChannelId)

        dispatch(setEventAppDialogOpen(true))
      }

      if (event.data.command === OPEN_LEADERBOARD_EVENT_TYPE) {
        dispatch(setLeaderboard(true))
      }
    }

    window.addEventListener("message", handleMessageEvent)

    return () => {
      window.removeEventListener("message", handleMessageEvent)
    }
  }, [selectStage, dispatch])
}

export const postCloseStageMessage = (element: HTMLIFrameElement) => {
  element.contentWindow?.postMessage({ command: CLOSE_STAGE_EVENT_TYPE }, "*")
}

export const postCloseLeaderboardMessage = (element: HTMLIFrameElement) => {
  element.contentWindow?.postMessage({ command: CLOSE_LEADERBOARD_EVENT_TYPE }, "*")
}
