import React from "react"
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import WebIcon from "@material-ui/icons/Web"

const ListItemStyled = styled(ListItem)`
  align-items: flex-start;
`

const ImageItem = styled.img`
  ${({ theme }) => `
    width: ${theme.spacing(4)}px;
  `}
`

const WebIconStyled = styled(WebIcon)`
  width: 32px;
  height: 32.8px;
  color: inherit;
`

const ListItemIconStyled = styled(ListItemIcon)`
  color: inherit;
`

const ListItemTextStyled = styled(ListItemText)<{ $horizontallySmall: boolean }>`
  flex: unset;
  margin: 2px 8px 8px 0;

  .MuiTypography-root {
    ${({ $horizontallySmall }) =>
      $horizontallySmall
        ? `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;  
      `
        : `
        white-space: normal;
    `}
  }
`

const OpenInNewIconStyled = styled(OpenInNewIcon)`
  width: 16px;
  margin-top: 4px;
  margin-left: auto;
`

type Props = { showIcon?: boolean; className?: string; horizontallySmall?: boolean }

export const ExternalLinks: React.FC<Props> = ({ showIcon, className, horizontallySmall = false }) => {
  const { externalLinks } = useSelector(getEvent)

  return (
    <>
      {externalLinks && (
        <List className={className}>
          {Object.values(externalLinks)
            .sort((linkA, linkB) => linkA.position - linkB.position)
            .map((externalLink) => (
              <ListItemStyled
                key={externalLink.id}
                button
                onClick={(): void => {
                  window.open(externalLink.url, "_blank", "noopener,noreferrer")
                }}
              >
                {showIcon && (
                  <Tooltip title={externalLink.text} enterDelay={600}>
                    <ListItemIconStyled>
                      {externalLink.iconUrl ? (
                        <ImageItem src={externalLink.iconUrl} alt={externalLink.text} />
                      ) : (
                        <WebIconStyled />
                      )}
                    </ListItemIconStyled>
                  </Tooltip>
                )}

                <ListItemTextStyled primary={externalLink.text} $horizontallySmall={horizontallySmall} />

                <OpenInNewIconStyled />
              </ListItemStyled>
            ))}
        </List>
      )}
    </>
  )
}
