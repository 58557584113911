const assembleAssetSrcs = (prefix: string, srcSuffixes: string[]): string[] =>
  srcSuffixes.map((suffix: string) => prefix + suffix)

const assetsUrl = "/photobooth"
const backgroundSrcPrefix = assetsUrl + "/backgrounds"
const frameSrcPrefix = assetsUrl + "/frames"
const stickerSrcPrefix = assetsUrl + "/stickers"

const backgroundSrcSuffixes = ["/virtualbackground_01.png", "/virtualbackground_02.png", "/virtualbackground_03.png"]

const frameSrcSuffixes = ["/frame_01.png", "/frame_02.png", "/frame_03.png"]

const stickerSrcSuffixes = [
  "/sticker_01.png",
  "/sticker_02.png",
  "/sticker_03.png",
  "/sticker_04.png",
  "/sticker_05.png",
  "/sticker_06.png",
  "/sticker_07.png",
  "/sticker_08.png",
  "/sticker_09.png",
  "/sticker_10.png",
  "/sticker_11.png",
]

export const backgroundSrcs = assembleAssetSrcs(backgroundSrcPrefix, backgroundSrcSuffixes)
export const frameSrcs = assembleAssetSrcs(frameSrcPrefix, frameSrcSuffixes)
export const stickerSrcs = assembleAssetSrcs(stickerSrcPrefix, stickerSrcSuffixes)
