import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "store/setup/storeTypes"
import { useSelector } from "store/setup/useSelector"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useLocation } from "react-router-dom"

interface IntercomState {
  isIntercomOn: boolean
}

const initialState: IntercomState = {
  isIntercomOn: true,
}
export const intercomSlice = createSlice({
  name: "intercom",
  initialState,
  reducers: {
    turnOnIntercom: (state) => {
      state.isIntercomOn = true
    },
    turnOffIntercom: (state) => {
      state.isIntercomOn = false
    },
  },
})

export const { turnOnIntercom, turnOffIntercom } = intercomSlice.actions

const isIntercomOnSelector = (state: AppState): boolean => state[intercomSlice.name].isIntercomOn

export const useIsIntercomActive = () => {
  const isIntercomOn = useSelector(isIntercomOnSelector)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const location = useLocation()

  return isIntercomOn && !isXs && !location.pathname.startsWith("/video-room")
}
