import { INTERCOM_CUSTOM_LAUNCHER_ID } from "constants/general"
import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"

export const useIntercomGuestBoot = (): void => {
  const { boot } = useIntercom()

  useEffect(() => {
    boot({
      name: "Guest",
      customLauncherSelector: `#${INTERCOM_CUSTOM_LAUNCHER_ID}`,
    })
  }, [boot])
}
