/**
 * Our project redefines sendMessage here.
 * so only occasion allowed importing sendMessage directly from pubnub-redux is here where we redefine it.
 */
// eslint-disable-next-line no-restricted-imports
import { sendMessage as sendPubnubMessage } from "pubnub-redux"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"
import { useContext } from "react"
import { IGif } from "runtypes/giphy"
import { AppMessage, MessageType } from "runtypes/message"
import { getEvent } from "store/domain/event"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useDispatch } from "store/setup/useDispatch"
import { useSelector } from "store/setup/useSelector"

export const useSendMessage = () => {
  const dispatch = useDispatch()
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const { anonymiseChats } = useSelector(getEvent)
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)

  const sharedMessageData = {
    senderId: currentPlatformUser.uuid,
    meta: {
      senderName: anonymiseChats ? "" : currentPlatformUser.userName,
    },
  }

  const sendAppMessage = ({ channelId, message }: { channelId: string; message: AppMessage }) => {
    dispatch(
      sendPubnubMessage({
        channel: channelId,
        message,
      }),
    ).catch((error) => {
      airbrakeNotifier.notify({ error: "sendPubnubMessage failed.", params: { error } })
    })
  }

  return {
    sendTextMessage: ({
      text,
      photoboothImageUrl,
      channelId,
    }: {
      text: string
      photoboothImageUrl?: string
      channelId: string
    }) => {
      sendAppMessage({
        channelId,
        message: {
          type: MessageType.Text,
          text,
          ...(photoboothImageUrl && { photoboothImageUrl }),
          ...sharedMessageData,
        },
      })
    },

    sendGifMessage: ({ query, gif, channelId }: { query: string; gif: IGif; channelId: string }) => {
      sendAppMessage({
        channelId,
        message: {
          type: MessageType.Giphy,
          query,
          gif,
          ...sharedMessageData,
        },
      })
    },
  }
}
