import React from "react"
import { ImageAllTypes } from "runtypes/giphy"
import { GifDisplay } from "../../../../shared/GifDisplay"
import styled from "styled-components"

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 390px;
  color: ${({ theme }) => theme.custom.colors.messagesPanel.message.color};

  img,
  video {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    cursor: pointer;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

interface PlaceholderMessageObject {
  text: string
  image: ImageAllTypes
}

// pre selected gifs and messages for when there are no results
export const placeholderMessages: {
  [name: string]: PlaceholderMessageObject
} = {
  notFound: {
    text: "No Gifs Found",
    image: {
      height: 168,
      mp4: "https://media3.giphy.com/media/9J7tdYltWyXIY/200w.mp4?cid=d375303d93d6fe4ede336d9eb0a9a8fc2738c9a516275300&rid=200w.mp4",
      mp4_size: "60460",
      size: "237606",
      url: "https://media3.giphy.com/media/9J7tdYltWyXIY/200w.gif?cid=d375303d93d6fe4ede336d9eb0a9a8fc2738c9a516275300&rid=200w.gif",
      webp: "https://media3.giphy.com/media/9J7tdYltWyXIY/200w.webp?cid=d375303d93d6fe4ede336d9eb0a9a8fc2738c9a516275300&rid=200w.webp",
      webp_size: "109816",
      width: 200,
    },
  },
  empty: {
    text: "Search for Gifs",
    image: {
      height: 133,
      mp4: "https://media1.giphy.com/media/26n6WywJyh39n1pBu/200w.mp4",
      mp4_size: "90845",
      size: "367411",
      url: "https://media2.giphy.com/media/26n6WywJyh39n1pBu/200w.gif",
      webp: "https://media1.giphy.com/media/26n6WywJyh39n1pBu/200w.webp",
      webp_size: "240142",
      width: 200,
    },
  },
}

type Props = { message: PlaceholderMessageObject }

export const PlaceholderMessage: React.FC<Props> = ({ message: { text, image } }) => {
  return (
    <Message>
      <GifDisplay source={image} title={text + " gif"} />
      {text}
    </Message>
  )
}
