import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { SLIDER_ARROW_WIDTH } from "../../constants"

const Wrapper = styled.div<{ right?: boolean }>`
  justify-content: center;
  display: flex !important;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.main};
  height: calc(100% - 20px);
  width: ${SLIDER_ARROW_WIDTH}px;
  ${({ theme, right }) => `${right ? "right" : "left"}: -${theme.spacing(4)}px`};
  z-index: 1;
  top: calc(50% - 3px);

  :hover {
    background: ${({ theme }) => lighten(0.1, theme.palette.primary.main)};
  }

  :before {
    content: unset;
  }
`

export const SliderArrow: React.FC<any> = ({ children, ...restProps }) => {
  const { className, style, onClick, right } = restProps

  return (
    <Wrapper className={className} style={{ ...style }} onClick={onClick} right={right}>
      {children}
    </Wrapper>
  )
}
