import React from "react"
import { ChatRoomsMenu } from "features/assembledBlocks/ChatRoomsMenu"
import { IconButton } from "@material-ui/core"
import { SetupRoomsDialogOpener } from "features/baseBlocks/rooms/SetupRoomsDialogOpener"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"
import { AppBarActionTooltip } from "../AppBarActionTooltip"

export const RoomsSetupDialogAndMenu: React.FC = () => {
  return (
    <>
      <SetupRoomsDialogOpener
        OpenerComponent={({ onClick }) => (
          <AppBarActionTooltip title="Attendees">
            <IconButton color="inherit" onClick={onClick}>
              <SearchRoundedIcon />
            </IconButton>
          </AppBarActionTooltip>
        )}
      />

      <ChatRoomsMenu TooltipForIcon={AppBarActionTooltip} />
    </>
  )
}
