import { addMessageReaction } from "store/domain/allMessageReactions"
import { messageActionEvent as messageActionEventType, MessageActionEvent } from "runtypes/messageAction"
import { AppDispatch } from "store/setup/storeTypes"
import { addMessageUpdate } from "store/domain/messageUpdates"
import { deleteMessage } from "store/domain/messageDeletions"

export const messageActionListener = (dispatch: AppDispatch) => (messageActionEvent: MessageActionEvent) => {
  console.log(messageActionEvent)

  if (messageActionEventType.guard(messageActionEvent)) {
    switch (messageActionEvent.data.type) {
      case "reaction":
        dispatch(addMessageReaction(messageActionEvent))
        break
      case "updated":
        dispatch(addMessageUpdate(messageActionEvent))
        break
      case "deleted":
        dispatch(deleteMessage(messageActionEvent))
        break
    }
  }
}
