import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MessageActionEvent } from "runtypes/messageAction"
import { AppState } from "store/setup/storeTypes"

type MessageUpdatesSlice = {
  [messageTimetoken: string]: string
}

const initialState: MessageUpdatesSlice = {}

export const messageUpdatesSlice = createSlice({
  name: "messageUpdates",
  initialState,
  reducers: {
    addMessageUpdate: (state, action: PayloadAction<MessageActionEvent>) => {
      const {
        payload: {
          data: { messageTimetoken, value },
        },
      } = action

      state[messageTimetoken] = value
    },
  },
})

export const { addMessageUpdate } = messageUpdatesSlice.actions

const getAllMessageUpdates = (state: AppState) => state[messageUpdatesSlice.name]

export const getMessageUpdate = (timetoken: number) => (state: AppState) => getAllMessageUpdates(state)[timetoken]
