import { createSelector } from "@reduxjs/toolkit"
import mapValues from "lodash/mapValues"
import { getMessagesByChannelId } from "../../messages"
import { getMessageChannelsById, pickMessageChannelsByFunction } from "../messageChannels"
import { RoomChannel, RoomChannelInitial } from "../channelTypes"
import find from "lodash/find"

export const isRoomChannelId = (channelId: string) => channelId.startsWith("chat.event_")

export const getRoomChannelsById = createSelector([getMessageChannelsById], (messageChannelsById): {
  [id: string]: RoomChannel
} => {
  const channelsRaw = pickMessageChannelsByFunction<RoomChannelInitial>(messageChannelsById, (channel) =>
    isRoomChannelId(channel.id),
  )

  return mapValues(channelsRaw, (messageChannel) => ({
    ...messageChannel,
    custom: {
      ...messageChannel.custom,
      participant_uuids: messageChannel.custom.participant_uuids
        ? messageChannel.custom.participant_uuids.split(",")
        : [],
    },
  }))
})

export const getRoomChannels = createSelector(
  [getRoomChannelsById, getMessagesByChannelId],
  (roomChannelsById, messagesByChannelId): RoomChannel[] =>
    Object.values(roomChannelsById).sort((firstChannel, secondChannel) => {
      const firstChannelMessages = messagesByChannelId[firstChannel.id] || []
      const secondChannelMessages = messagesByChannelId[secondChannel.id] || []

      const convertTimeToTen = (time: number): number => parseInt(time.toString().slice(0, 10))

      const firstDate = firstChannelMessages.length
        ? convertTimeToTen(firstChannelMessages[firstChannelMessages.length - 1].timetoken)
        : firstChannel.custom.created_at

      const secondDate = secondChannelMessages.length
        ? convertTimeToTen(secondChannelMessages[secondChannelMessages.length - 1].timetoken)
        : secondChannel.custom.created_at

      return secondDate - firstDate
    }),
)

export const isUnreadMessageInAnyRoomChannelsSelector = createSelector(
  [getRoomChannels],
  (roomChannels) =>
    find(roomChannels, (currentRoomChannel) => currentRoomChannel.additions.isUnreadMessage) !== undefined,
)
