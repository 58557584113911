import { fetchMemberships } from "store/domain/pubnubStore/memberships/fetchMemberships"
import Pubnub, { ObjectsEvent } from "pubnub"
import { AppDispatch } from "store/setup/storeTypes"
import { AirbrakeNotifierObjectType } from "contextApi/AirbrakeNotifierContext"
import { isRoomChannelId } from "store/domain/pubnubStore/channels/roomChannels"

// todo check is this works, because BE changes some stuff  regarding memberships
export const objectsListener =
  (
    pubnubListener: any,
    pubnub: Pubnub,
    dispatch: AppDispatch,
    airbrakeNotifier: AirbrakeNotifierObjectType["airbrakeNotifier"],
    displaySearch: boolean,
    currentPlatformUserUuid: string,
  ) =>
  (objectsEvent: ObjectsEvent) => {
    if (displaySearch) {
      if (objectsEvent.message.type === "membership" && objectsEvent.message.event === "set") {
        const userUuid = objectsEvent.message.data.uuid.id
        const channelId = objectsEvent.message.data.channel.id

        if (currentPlatformUserUuid === userUuid && isRoomChannelId(channelId)) {
          pubnub.subscribe({ channels: [channelId] })

          dispatch(async (...args) => {
            try {
              const fetchMembershipsActionCreator = fetchMemberships(currentPlatformUserUuid)

              return await fetchMembershipsActionCreator(...args)
            } catch (e) {
              airbrakeNotifier.notify({
                error: "fetchMemberships Error in objectsListener.",
                params: {
                  currentPlatformUserUuidUsed: currentPlatformUserUuid,
                  objectsEvent,
                  error: e,
                  locationHref: window.location.href,
                },
              })
            }
          })
        }
      }
    }

    return pubnubListener(objectsEvent)
  }
