import { Tooltip, Typography, Box, IconButton, TooltipProps } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { obtainInitialsFromUserName } from "utils/obtainInitialsFromUserName"

const TypographyStyled = styled(Typography)`
  text-transform: uppercase;
` as typeof Typography

export const UserInitialsButton: React.FC<{ userName: string; TooltipForIcon?: React.FC<TooltipProps> }> = ({
  userName,
  TooltipForIcon,
}) => {
  const initials = obtainInitialsFromUserName(userName)

  const TooltipConditional = TooltipForIcon || Tooltip

  return (
    <>
      {initials && (
        <TooltipConditional title={userName}>
          <IconButton color="inherit">
            <TypographyStyled component="span">
              <Box fontSize={24} fontWeight={600}>
                {initials}
              </Box>
            </TypographyStyled>
          </IconButton>
        </TooltipConditional>
      )}
    </>
  )
}
