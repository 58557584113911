import React from "react"
import { MessageList } from "./MessageList"
import { MessageWriter } from "./MessageWriter"
import { MessageFragment } from "store/domain/pubnubStore/messages"
import { css } from "styled-components"
import { useMessagesAndScrolling } from "./useMessagesAndScrolling"

export const messagesPanelContainerCss = css`
  display: flex;
  flex-direction: column;
`

type Props = {
  channelId: string
  welcomeMessage?: MessageFragment
  inputLabel: string
  focusAutomatically: boolean
  scrollDownButtonText: string
  forMobile?: boolean
}

export const MessagesPanel: React.FC<Props> = ({
  channelId,
  welcomeMessage,
  inputLabel,
  focusAutomatically,
  scrollDownButtonText,
  forMobile = false,
}) => {
  const messagesAndScrolling = useMessagesAndScrolling({
    channelId,
    welcomeMessage,
  })

  return (
    <>
      <MessageList
        channelId={channelId}
        isWelcomeMessagePresent={!!welcomeMessage}
        messagesAndScrolling={messagesAndScrolling}
        scrollDownButtonText={scrollDownButtonText}
        forMobile={forMobile}
      />

      <MessageWriter
        inputLabel={inputLabel}
        channelId={channelId}
        focusAutomatically={focusAutomatically}
        forMobile={forMobile}
        scrollToBottomMessage={messagesAndScrolling.scrollToBottomMessage}
      />
    </>
  )
}
