import React from "react"
import { Redirect, useLocation } from "react-router-dom"
import { LoginWidget } from "features/baseBlocks/LoginWidget"
import { Box } from "@material-ui/core"
import { AuthenticationUtils } from "features/baseBlocks/LoginWidget/useAuthenticationUtils"
import useEventSlug from "hooks/useEventSlug"

export const Login: React.FC<{ authenticationUtils: AuthenticationUtils }> = ({ authenticationUtils }) => {
  const { state } = useLocation<{ from: { pathname: string; search?: string } } | undefined>()
  const eventSlug = useEventSlug()

  return (
    <>
      {authenticationUtils.loginCompleted ? (
        <Redirect
          to={{
            pathname: state?.from?.pathname ?? `/${eventSlug}`,
            search: state?.from?.search ?? "",
          }}
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <LoginWidget authenticationUtils={authenticationUtils} />
        </Box>
      )}
    </>
  )
}
