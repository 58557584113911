import React, { useCallback, useEffect, useState } from "react"
import { Notifier, INotice } from "@airbrake/browser"
import { isStagingEnvironment, ENV_TYPE_STAGING, isDevelopmentEnvironment } from "utils/environment"
import {
  AirbrakeDefaultInfo,
  AirbrakeNotifierContext,
  AirbrakeNotifierObjectType,
  inactiveAirbrakeNotifierObject,
} from "contextApi/AirbrakeNotifierContext"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "./ErrorFallback"

type Props = {
  eventUuid: string
  frontendAirbrakeProjectId: number
  frontendAirbrakeProjectKey: string
}

const useSetupAirbrake = (eventUuid: string, frontendAirbrakeProjectId: number, frontendAirbrakeProjectKey: string) => {
  const [notifier, setNotifier] = useState<Notifier | null>(null)
  const [airbrakeNotifierNotify, setAirbrakeNotifierNotify] = useState(
    () => inactiveAirbrakeNotifierObject.airbrakeNotifier.notify,
  )
  const [isAirbrakeNotifierActive, setIsAirbrakeNotifierActive] = useState<boolean>(false)

  const userScriptFilter = useCallback((notice: INotice): INotice | null => {
    const fileName = notice?.errors?.[0]?.backtrace?.[0]?.file

    if (fileName?.includes("user-script")) {
      return null
    }

    return notice
  }, [])

  const setupNotify = useCallback(
    (notifier: Notifier, airbrakeDefaultInfo?: AirbrakeDefaultInfo) => {
      const notifyToSet: AirbrakeNotifierObjectType["airbrakeNotifier"]["notify"] = ({ error, params }) => {
        notifier.notify({
          error: error,
          params: { eventToken: eventUuid, ...airbrakeDefaultInfo, ...params },
        })
      }

      setAirbrakeNotifierNotify(() => notifyToSet)
    },
    [eventUuid],
  )

  const loadAirbrakeDefaultInfo = useCallback(
    (airbrakeDefaultInfo: AirbrakeDefaultInfo) => {
      if (notifier) {
        setupNotify(notifier, airbrakeDefaultInfo)
      }
    },
    [notifier, setupNotify],
  )

  useEffect(() => {
    if (!isDevelopmentEnvironment && !isAirbrakeNotifierActive) {
      const createdNotifier = new Notifier({
        projectId: frontendAirbrakeProjectId,
        projectKey: frontendAirbrakeProjectKey,
        environment: isStagingEnvironment ? ENV_TYPE_STAGING : process.env.NODE_ENV,
        instrumentation: { onerror: true },
      })

      createdNotifier.addFilter(userScriptFilter)

      setNotifier(createdNotifier)

      setupNotify(createdNotifier)

      setIsAirbrakeNotifierActive(true)
    }
  }, [frontendAirbrakeProjectId, frontendAirbrakeProjectKey, userScriptFilter, isAirbrakeNotifierActive, setupNotify])

  const onBoundaryError = useCallback(
    (
      error: Error,
      info: {
        componentStack: string
      },
    ) => {
      airbrakeNotifierNotify({
        error: error,
        params: { info: info },
      })
    },
    [airbrakeNotifierNotify],
  )

  return {
    airbrakeNotifierObject: {
      airbrakeNotifier: { loadAirbrakeDefaultInfo, notify: airbrakeNotifierNotify, isAirbrakeNotifierActive },
    },
    onBoundaryError,
  }
}

export const AirbrakeAndErrorBoundary: React.FC<Props> = ({
  children,
  eventUuid,
  frontendAirbrakeProjectId,
  frontendAirbrakeProjectKey,
}) => {
  const { airbrakeNotifierObject, onBoundaryError } = useSetupAirbrake(
    eventUuid,
    frontendAirbrakeProjectId,
    frontendAirbrakeProjectKey,
  )

  return (
    <AirbrakeNotifierContext.Provider value={airbrakeNotifierObject}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={onBoundaryError}>
        {children}
      </ErrorBoundary>
    </AirbrakeNotifierContext.Provider>
  )
}
