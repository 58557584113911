import { GalleryPhoto } from "api/apiFunctions/photobooth/photoGallery"
import { UserInitialsButton } from "components/UserInitialsButton"
import { getStageChannelsWithMessages } from "store/domain/pubnubStore/channels/stageChannelsMeta"
import { useSelectStage } from "hooks/useSelectStage"
import { useSendMessage } from "store/domain/pubnubStore/messages/useSendMessage"
import React, { useState } from "react"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import {
  Card,
  Typography,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  TextField,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GroupPhoto } from "api/apiFunctions/photobooth/groupPhoto"

const CardWrapper = styled(Card)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

const Header = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const HeaderText = styled(Typography)`
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

const TopBarWrapper = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;
`

const Photo = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;
`

const SelectWrapper = styled(Select)`
  min-width: ${({ theme }) => theme.spacing(11)}px;
`

const ButtonWrapper = styled(Button)`
  width: 100%;
`

const TextFieldStyled = styled(TextField)`
  width: 100%;
`

export const PhotoMessageWriter: React.FC<{
  onClose: () => void
  previewPhoto: GalleryPhoto | GroupPhoto
  onRequestClose: () => void
}> = ({ onClose, previewPhoto, onRequestClose }) => {
  const stageChannelsWithMessages = useSelector(getStageChannelsWithMessages)
  const [selectedStageId, setSelectedStageId] = useState<string>(stageChannelsWithMessages[0].id)
  const selectStage = useSelectStage()
  const [messageText, setMessageText] = useState<string>("")
  const { sendTextMessage } = useSendMessage()

  const onClickPostMessage = () => {
    sendTextMessage({
      text: messageText ? messageText : "Photo Booth Photo!",
      photoboothImageUrl: previewPhoto.imageUrl,
      channelId: selectedStageId,
    })

    selectStage(selectedStageId)

    onRequestClose()
  }

  const handleStageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStageId(e.target.value as string)
  }

  const handleMessageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setMessageText(e.target.value as string)
  }

  return (
    <CardWrapper>
      <Header>
        <HeaderText>Share to Comments</HeaderText>

        <IconButton color="default" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Header>

      <TopBarWrapper>
        <Box mb={1}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <UserInitialsButton userName={previewPhoto.username} />
                <Typography>{previewPhoto.username}</Typography>
              </Box>
            </Grid>

            <Grid item>
              <FormControl>
                <InputLabel id="stage-channel-select-label">Share on</InputLabel>

                <SelectWrapper
                  labelId="stage-channel-select-label"
                  onChange={handleStageChange}
                  value={selectedStageId}
                >
                  {stageChannelsWithMessages.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectWrapper>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Grid container direction="row" alignItems="center">
          <TextFieldStyled
            label="Write a comment"
            multiline
            minRows={2}
            variant="outlined"
            onChange={handleMessageChange}
          />
        </Grid>
      </TopBarWrapper>

      <Photo src={previewPhoto.imageUrl} alt="Photo to message" />

      <Box mt={2}>
        <ButtonWrapper variant="contained" size="large" color="primary" onClick={onClickPostMessage}>
          POST TO COMMENTS
        </ButtonWrapper>
      </Box>
    </CardWrapper>
  )
}
