import { BottomNavigation, BottomNavigationAction, Box, Typography } from "@material-ui/core"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Socialize } from "./Socialize"
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople"
import BlurOnIcon from "@material-ui/icons/BlurOn"
import { TabPanel } from "independent/components/TabPanel"
import { MobileCurrentStage } from "./CurrentStage"
import { StagesList } from "./StagesList"
import { ContentToShow } from "../AppTemplate"
import { darken, transparentize } from "polished"
import { MobileHeader } from "./MobileHeader"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"

const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.custom.colors.mobile.main.background};
  color: ${({ theme }) => theme.custom.colors.mobile.main.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const TabPanelStyled = styled(TabPanel)`
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const StagesListStyled = styled(StagesList)`
  overflow: auto;
  margin-top: 8px;
  padding-bottom: 20px;
`

const BottomNavigationStyled = styled(BottomNavigation)`
  flex: 0 1 auto;
  background: ${({ theme }) => theme.custom.colors.mobile.bottomNavigation.background};

  button {
    color: ${({ theme }) => darken(0.05, theme.custom.colors.mobile.bottomNavigation.color)};
  }

  button.Mui-selected {
    color: ${({ theme }) => theme.custom.colors.mobile.bottomNavigation.color};
    font-weight: bold;
  }
`

const BottomNavigationActionInvisible = styled(BottomNavigationAction)`
  display: none;
`

const FooterText = styled(Typography)`
  background: ${({ theme }) => transparentize(0.4, theme.custom.colors.mobile.bottomNavigation.background)};
  color: ${({ theme }) => theme.custom.colors.mobile.bottomNavigation.color};
  white-space: nowrap;
  padding: 2px 12px;
`

export const MobileTemplate: React.FC<{ contentToShow: ContentToShow }> = ({ contentToShow }) => {
  const { footerText } = useSelector(getEvent)
  const { hideNavMenu } = useSelector(getEvent)
  const [navigationValue, setNavigationValue] = useState(hideNavMenu ? "currentStage" : "stages")

  const navigateToCurrentStage = useCallback(() => {
    setNavigationValue("currentStage")
  }, [])

  return (
    <Wrapper>
      {!hideNavMenu && (
        <TabPanelStyled value={navigationValue} index={"stages"}>
          <MobileHeader />

          <StagesListStyled navigateToCurrentStage={navigateToCurrentStage} />
        </TabPanelStyled>
      )}

      <TabPanelStyled value={navigationValue} index={"currentStage"}>
        <MobileHeader />

        <MobileCurrentStage contentToShow={contentToShow} />
      </TabPanelStyled>

      <TabPanelStyled value={navigationValue} index={"socialize"}>
        <MobileHeader />

        <Socialize onStageSelect={navigateToCurrentStage} />
      </TabPanelStyled>

      {footerText && <FooterText>{footerText}</FooterText>}

      <BottomNavigationStyled
        value={navigationValue}
        onChange={(event, newValue) => {
          setNavigationValue(newValue)
        }}
        showLabels
      >
        {!hideNavMenu && <BottomNavigationAction label="Stages" value="stages" icon={<BlurOnIcon />} />}

        <BottomNavigationActionInvisible label="Stage" value="currentStage" icon={<BlurOnIcon />} />

        <BottomNavigationAction label="Socialize" value="socialize" icon={<EmojiPeopleIcon />} />
      </BottomNavigationStyled>
    </Wrapper>
  )
}
