import React, { useState } from "react"
import { Dialog, Slide, useMediaQuery, useTheme } from "@material-ui/core"
import styled from "styled-components"
import { DialogContent } from "@material-ui/core"
import { SearchPanel } from "./SearchPanel"
import { useSelector } from "store/setup/useSelector"
import { Grid } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { getEvent } from "store/domain/event"
import { AddUsersPanel } from "./AddUsersPanel/AddUsersPanel"
import { SearchedUsers } from "./SearchedUsers"
import { AlgoliaPagination } from "components/algolia/AlgoliaPagination"
import { TransitionProps } from "@material-ui/core/transitions"
import { useAddedUsersUtils } from "./useAddedUsersUtils"
import { closeSetupRoomsDialog, isSetupRoomsDialogOpenSelector } from "store/domain/chatAndVideoRoom"
import { useDispatch } from "store/setup/useDispatch"
import { AlgoliaInstantSearch } from "components/algolia/AlgoliaInstantSearch"

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export type RoomUser = {
  guest_id: string
  firstName: string
  lastName: string
}

export const SetupRoomsDialog: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isSetupRoomsDialogOpen = useSelector(isSetupRoomsDialogOpenSelector)
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const { displaySearch, attendeeIndexName } = useSelector(getEvent)
  const [isSearchInProgress, setIsSearchInProgress] = useState(false)
  const { addedUsers, addRoomUser, removeRoomUser, clearAddedUsers } = useAddedUsersUtils()

  const closeDialog = () => {
    clearAddedUsers()
    dispatch(closeSetupRoomsDialog())
  }

  if (!displaySearch || !attendeeIndexName) {
    return null
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={smDown}
      fullWidth
      maxWidth={"md"}
      onClose={closeDialog}
      open={isSetupRoomsDialogOpen}
    >
      <DialogContent dividers>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <CloseIconStyled onClick={closeDialog} />
        </Grid>

        <AlgoliaInstantSearch indexName={attendeeIndexName} setIsSearchInProgress={setIsSearchInProgress}>
          <SearchPanel />

          <AddUsersPanel closeDialog={closeDialog} addedUsers={addedUsers} removeRoomUser={removeRoomUser} />

          <SearchedUsers addRoomUser={addRoomUser} isSearchInProgress={isSearchInProgress} />

          <AlgoliaPagination />
        </AlgoliaInstantSearch>
      </DialogContent>
    </Dialog>
  )
}
