import React from "react"
import { connectHits } from "react-instantsearch-dom"
import styled from "styled-components"
import { Box, Card, Grid, Typography } from "@material-ui/core"
import { AddUserButton } from "./AddUserButton"
import { RoomUser } from ".."
import { UserInitialsButton } from "components/UserInitialsButton"
import { UserHit } from "components/algolia/types"
import { HitsProvided } from "react-instantsearch-core"

const WrapperBox = styled(Box)`
  width: 100%;
`
const UserInitialsGrid = styled(Grid)`
  background-color: rgba(229, 229, 229);
`

const UserNameBox = styled(Box)`
  font-weight: bold;
`

type OwnProps = { addRoomUser: (newUser: RoomUser) => void; isSearchInProgress: boolean }

type Props = HitsProvided<UserHit> & OwnProps

const SearchedUsersRaw: React.FC<Props> = ({ hits: userHits, addRoomUser, isSearchInProgress }) => (
  <WrapperBox>
    {userHits.length === 0 && isSearchInProgress && (
      <Box width="100%" textAlign="center" pt={1}>
        <Typography color="secondary" variant="h6">
          No results found
        </Typography>
      </Box>
    )}

    <Grid container direction="row">
      {userHits.map((currentUserHit) => (
        <Grid item xs={12} sm={6} md={4} key={currentUserHit.id}>
          <Box m={2}>
            <Card>
              <Box width="100%" boxShadow={3}>
                <UserInitialsGrid container direction="row">
                  <Grid item sm={4} md={3}>
                    <Box p={1}>
                      <UserInitialsButton userName={currentUserHit.username} />
                    </Box>
                  </Grid>
                  <Grid item sm={8} md={9}>
                    <UserNameBox mx={1} mt={2.5}>
                      {currentUserHit.first_name} {currentUserHit.surname}
                    </UserNameBox>
                  </Grid>
                </UserInitialsGrid>
              </Box>
              <Grid container direction="row">
                <Box mx={1} my={1}>
                  <AddUserButton
                    onClick={() => {
                      addRoomUser({
                        guest_id: currentUserHit.uuid,
                        firstName: currentUserHit.first_name,
                        lastName: currentUserHit.surname,
                      })
                    }}
                  />
                </Box>
              </Grid>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
  </WrapperBox>
)

// @ts-ignore
export const SearchedUsers: React.FC<OwnProps> = connectHits(SearchedUsersRaw)
