import React from "react"
import { Drawer } from "@material-ui/core"
import { useSelector } from "store/setup/useSelector"
import { getLayout, setLeaderboard } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"
import { LeaderboardContent } from "./LeaderboardContent"
import styled from "styled-components"
import { postCloseLeaderboardMessage } from "main/App/Router/EstablishedRoute/WrapperWithStageSet/usePostMessage"
import { getEvent } from "store/domain/event"
import { interactWithConnectTemplateIframe } from "constants/general"

const DrawerStyled = styled(Drawer)`
  ${({ theme }) => `
    max-width: 420px;
    width: 420px;

    .MuiDrawer-paper {
      max-width: 420px;
      width: 420px;
      background: ${theme.custom.colors.leaderboard.background};
    }

    ${theme.breakpoints.only("xs")} {
      width: 100%;

      .MuiDrawer-paper {
        width: 100%;
      }
    }
  `}
`

export const LeaderboardDrawer: React.FC = () => {
  const open = useSelector(getLayout).leaderboard
  const dispatch = useDispatch()
  const { leaderboardPresent } = useSelector(getEvent)

  if (!leaderboardPresent) {
    return null
  }

  return (
    <DrawerStyled
      anchor="right"
      open={open}
      onClose={() => {
        dispatch(setLeaderboard(false))

        interactWithConnectTemplateIframe((iframeElement) => {
          postCloseLeaderboardMessage(iframeElement)
        })
      }}
    >
      {open && (
        <>
          <LeaderboardContent />
        </>
      )}
    </DrawerStyled>
  )
}
