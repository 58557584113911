import { Box, Typography } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { PANEL_HEADER_HEIGHT } from "../constants"
import { SocializeList } from "features/assembledBlocks/SocializeList"

const Wrapper = styled(Box)`
  ${({ theme }) => `
    .socialize-item {
      background: ${theme.custom.colors.headsUpDisplay.generic.background};
      color: ${theme.custom.colors.headsUpDisplay.generic.color};
      padding: 12px;

      :not(:last-child) {
        margin-bottom: 1px;
      }
    }
  `}
`

const Header = styled(Box)`
  ${({ theme }) => `
    background: ${theme.palette.primary.main};
    color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 0 8px;
    height: ${PANEL_HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
  `}
`

export const Socialize: React.FC = () => {
  return (
    <Wrapper>
      <Header>
        <Typography variant="button">
          <b>SOCIALIZE</b>
        </Typography>
      </Header>

      <SocializeList />
    </Wrapper>
  )
}
