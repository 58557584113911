import { useCallback, useEffect, useState } from "react"
import { EventUser, PlatformUserToPut } from "api/apiFunctions/authentication"
import { useInitialApi } from "api/useInitialApi"
import useEventSetup from "hooks/useEventSetup"

export type AuthenticationUtils = {
  isLoading: boolean
  isOtcVerification: boolean
  onlyLoggedIn: boolean
  eventUser?: EventUser
  loginCompleted: boolean
  handleSignInWithEmail: ({ email }: { email: string }) => Promise<void>
  handleFetchOtcAgain: ({ email }: { email: string }) => Promise<void>
  handleLoginWithOtc: ({ email, otc }: { email: string; otc: string }) => Promise<{
    loggedIn: boolean
  }>
  handleCompletingTheProfile: (platformUserToPut: PlatformUserToPut) => Promise<void>
}

export const useAuthenticationUtils = (): AuthenticationUtils => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOtcVerification, setIsOtcVerification] = useState(false)
  const [onlyLoggedIn, setOnlyLoggedIn] = useState(false)
  const [eventUser, setEventUser] = useState<EventUser | undefined>(undefined)

  const { initialApi } = useInitialApi()

  const eventSetup = useEventSetup()

  useEffect(() => {
    const recoverSession = async () => {
      setIsLoading(true)
      const { loggedIn } = await initialApi.authentication.recoverAccess()

      if (loggedIn) {
        const currentPlatformUser = await initialApi.authentication.fetchEventUser(eventSetup.uuid)

        setEventUser(currentPlatformUser)

        setOnlyLoggedIn(true)
      }

      setIsLoading(false)
    }

    recoverSession()
  }, [initialApi, eventSetup])

  return {
    isLoading,
    isOtcVerification,
    onlyLoggedIn,
    eventUser,
    loginCompleted: onlyLoggedIn && !!eventUser?.profileCompleted, // todo onlyLoggedIn can be removed maybe
    handleSignInWithEmail: useCallback(
      async ({ email }: { email: string }) => {
        try {
          setIsLoading(true)

          await initialApi.authentication.signInWithEmail({ email })

          setIsOtcVerification(true)
        } catch (e) {
          console.error(e)
        }
        setIsLoading(false)
      },
      [initialApi],
    ),
    handleFetchOtcAgain: useCallback(
      async ({ email }: { email: string }) => {
        try {
          setIsLoading(true)

          await initialApi.authentication.fetchOtcAgain({ email })
        } catch (e) {
          console.error(e)
        }
        setIsLoading(false)
      },
      [initialApi],
    ),
    handleLoginWithOtc: useCallback(
      async ({ email, otc }: { email: string; otc: string }) => {
        try {
          setIsLoading(true)

          const result = await initialApi.authentication.loginWithOtc({ email, otc })

          if (result.loggedIn) {
            setOnlyLoggedIn(true)
          }

          return result
        } catch (e) {
          console.error(e)

          return { loggedIn: false }
        } finally {
          setIsLoading(false)
        }
      },
      [initialApi],
    ),
    handleCompletingTheProfile: useCallback(
      async (platformUserToPut: PlatformUserToPut) => {
        try {
          setIsLoading(true)

          await initialApi.authentication.putPlatformUser(platformUserToPut)

          const currentEventUser = await initialApi.authentication.fetchEventUser(eventSetup.uuid)

          setEventUser(currentEventUser)
        } catch (e) {
          console.error(e)
        }
        setIsLoading(false)
      },
      [initialApi, eventSetup],
    ),
  }
}
