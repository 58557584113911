import React from "react"
import styled from "styled-components"
import { Box } from "@material-ui/core"

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-radius: 6px;
  margin-bottom: 6px;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    padding: 8px;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    background: ${({ theme }) => theme.custom.colors.currentStage.background};
    color: ${({ theme }) => theme.custom.colors.currentStage.color};
  }
`

export const MainContentHeader: React.FC = ({ children }) => {
  return <Wrapper>{children} </Wrapper>
}
