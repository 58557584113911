import { css } from "styled-components"
import { rgba } from "polished"

export const mainContentWrapperCss = css`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    border: 1px solid ${({ theme }) => rgba(theme.palette.secondary.main, 0.45)};
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    background: ${({ theme }) => theme.custom.colors.currentStage.background};
  }
`
