import { AppState } from "store/setup/storeTypes"
import { createChannelDataReducer } from "pubnub-redux"
import { createSelector } from "@reduxjs/toolkit"
import { AppChannel } from "../channelTypes"

export const appChannelsReducer = createChannelDataReducer()

export const getAppChannelsById = (state: AppState) => state.appChannels.byId as { [id: string]: AppChannel }

export const getAppChannelIds = createSelector(getAppChannelsById, (appChannelsById) => Object.keys(appChannelsById))
