import React, { ChangeEvent, useCallback, useEffect, useRef } from "react"
import { EmojiInput } from "components/EmojiInput"
import { GifInput } from "../../gifs/GifInput"
import { TextAreaWrapper, TextAreaTypography, SendButton, EditorActions } from "./MessageEditor.style"
import SendIcon from "@material-ui/icons/Send"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { OnGifSelect } from "../../gifs/GifInput/GifInputContent/GifPicker"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"

/**
 * Expand the height of the input box as multiple lines of text are entered.
 */
const autoExpand = (el: HTMLTextAreaElement, reset = false): void => {
  window.setTimeout(function () {
    let height = 20
    if (el.scrollHeight > height && !reset) {
      height = el.scrollHeight
    }
    el.style.cssText = "height: auto; padding: 0"
    el.style.cssText = `height: ${height}px`
    el.focus()
  }, 0)
}

const obtainNewText = (text: string) => text.substring(0, 500)

type MessageEditorProps = {
  text: string
  onTextChange: (text: string) => void
  onGifSelect: OnGifSelect
  onSendTextMessage: () => void
  inputLabel: string
  focusAutomatically: boolean
}

export const MessageEditor: React.FC<MessageEditorProps> = ({
  text,
  onTextChange,
  onGifSelect,
  onSendTextMessage,
  inputLabel,
  focusAutomatically,
}) => {
  const theme = useTheme()
  const touch = useMediaQuery(theme.custom.utils.hoverNoneMedia)
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const textareaRef = useRef<HTMLTextAreaElement>(document.createElement("textarea"))
  const event = useSelector(getEvent)

  useEffect(() => {
    autoExpand(textareaRef.current)
  }, [textareaRef, focusAutomatically])

  const handleTextChange = useCallback(
    (desiredNewText: string) => {
      const newText = obtainNewText(desiredNewText)

      if (newText !== text) {
        onTextChange(newText)
      }
    },
    [text, onTextChange],
  )

  const handleSendTextMessage = useCallback(() => {
    if (text !== "") {
      onSendTextMessage()
    }
  }, [text, onSendTextMessage])

  return (
    <>
      <TextAreaWrapper>
        <TextAreaTypography
          ref={textareaRef}
          component="textarea"
          autoFocus={!isXs}
          rows={1}
          value={text}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            handleTextChange(e.target.value)
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
            if (e.key === "Enter" && !(e.shiftKey || touch)) {
              handleSendTextMessage()

              e.preventDefault()

              autoExpand(e.target as HTMLTextAreaElement, true)
            }

            autoExpand(e.target as HTMLTextAreaElement)
          }}
          placeholder={inputLabel}
        />
      </TextAreaWrapper>

      <EditorActions>
        {!event.hideGifs && <GifInput onSelection={onGifSelect} />}

        <EmojiInput
          onSelection={(emoji: string): void => {
            handleTextChange(`${text}${emoji}`)

            textareaRef.current.focus()
          }}
        />

        <SendButton
          onClick={(): void => {
            handleSendTextMessage()

            autoExpand(textareaRef.current, true)
          }}
        >
          <SendIcon />
        </SendButton>
      </EditorActions>
    </>
  )
}
