import styled from "styled-components"
import React, { useCallback, useLayoutEffect, useRef, useState } from "react"
import { STAGE_VIDEO_DIMENSIONS_RATIO } from "constants/general"
import { VideoIframe } from "features/baseBlocks/VideoIframe"
import useResizeObserver from "@react-hook/resize-observer"

const useAspectRatioHelper = () => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [makeWidthOrHeight100Percent, setMakeWidthOrHeight100Percent] = useState<"width" | "height" | null>(null)

  const setWidthOrHeight = useCallback(() => {
    if (wrapperRef.current) {
      setMakeWidthOrHeight100Percent(
        wrapperRef.current.clientWidth / wrapperRef.current.clientHeight > STAGE_VIDEO_DIMENSIONS_RATIO
          ? "height"
          : "width",
      )
    }
  }, [])

  useLayoutEffect(setWidthOrHeight, [setWidthOrHeight])

  useResizeObserver(wrapperRef, setWidthOrHeight)

  return { wrapperRef, makeWidthOrHeight100Percent }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
`

const IframeStyled = styled(VideoIframe)<{
  makeWidthOrHeight100Percent: "width" | "height"
}>`
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;

  ${({ makeWidthOrHeight100Percent }) => `
      aspect-ratio: ${STAGE_VIDEO_DIMENSIONS_RATIO};
      ${makeWidthOrHeight100Percent}: 100%;
    `}
`

type Props = { occupyFullDimensions?: boolean }

export const VideoIframeWithAspectRatio: React.FC<Props> = () => {
  const { wrapperRef, makeWidthOrHeight100Percent } = useAspectRatioHelper()

  return (
    <Wrapper ref={wrapperRef}>
      {makeWidthOrHeight100Percent && <IframeStyled makeWidthOrHeight100Percent={makeWidthOrHeight100Percent} />}
    </Wrapper>
  )
}
