import React from "react"
import { getEvent } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"
import { LobbyStageIFrame } from "./LobbyStageIFrame"
import { LobbyStagePage } from "./LobbyStagePage"

export const LobbyStage: React.FC = () => {
  const { iframeLobbyUrl } = useSelector(getEvent)

  return <>{iframeLobbyUrl ? <LobbyStageIFrame iframeLobbyUrl={iframeLobbyUrl} /> : <LobbyStagePage />}</>
}
