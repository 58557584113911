import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"

type WebinarDailyData = { room: string; token: string; stream: string }

export const fetchWebinarDailyData = (eventUuid: string) => (): Promise<WebinarDailyData> =>
  baseApi
    .get<WebinarDailyData>({
      url: apiUrls.webinar.dailyData,
      headers: {
        EventToken: eventUuid,
      },
    })
    .then(({ data }) => data)
