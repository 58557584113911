import React from "react"

export const FacebookIcon: React.FC = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.891602" width="52" height="52" rx="26" fill="#1877F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 26.8916C17 31.3416 20.25 35.0416 24.5 35.7916L24.5502 35.7515C24.5334 35.7482 24.5167 35.7449 24.5 35.7416V29.3916H22.25V26.8916H24.5V24.8916C24.5 22.6416 25.95 21.3916 28 21.3916C28.65 21.3916 29.35 21.4916 30 21.5916V23.8916H28.85C27.75 23.8916 27.5 24.4416 27.5 25.1416V26.8916H29.9L29.5 29.3916H27.5V35.7416C27.4833 35.7449 27.4666 35.7482 27.4498 35.7515L27.5 35.7916C31.75 35.0416 35 31.3416 35 26.8916C35 21.9416 30.95 17.8916 26 17.8916C21.05 17.8916 17 21.9416 17 26.8916Z"
        fill="white"
      />
    </svg>
  )
}
