import * as t from "runtypes"

export const messageActionEvent = t.Record({
  publisher: t.String,
  data: t.Record({
    messageTimetoken: t.String,
    type: t.String,
    value: t.String,
    actionTimetoken: t.String,
    uuid: t.Optional(t.String),
  }),
})

export type MessageActionEvent = t.Static<typeof messageActionEvent>
