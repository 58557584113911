import { Box } from "@material-ui/core"
import styled from "styled-components"

export const LobbyStageCardBackground = styled(Box)`
  ${({ theme }) =>
    `
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: ${theme.spacing(1)}px;
    margin: ${theme.spacing(1)}px;

    ${theme.breakpoints.only("xs")} {
      background-color: transparent;
      margin: 0;
      padding: 1px;
    }
  `}
`
