import React from "react"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { constructNewRelicCode } from "./constructNewRelicCode"
import { Helmet } from "react-helmet"

export const NewRelicScript: React.FC = () => {
  const { newrelicApplicationId, newrelicLicenseKey } = useSelector(getEvent)

  return (
    <>
      {newrelicApplicationId && newrelicLicenseKey && (
        <Helmet>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: constructNewRelicCode({ newrelicApplicationId, newrelicLicenseKey }),
            }}
          />
        </Helmet>
      )}
    </>
  )
}
