import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"
import { useContext, useEffect } from "react"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useSelector } from "store/setup/useSelector"

export const useFinalizeSetupAirbrake = () => {
  const {
    airbrakeNotifier: { loadAirbrakeDefaultInfo, isAirbrakeNotifierActive },
  } = useContext(AirbrakeNotifierContext)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const currentStageChannel = useSelector(getCurrentStageChannel)

  useEffect(() => {
    if (isAirbrakeNotifierActive) {
      loadAirbrakeDefaultInfo({ userUuid: currentPlatformUser.uuid, stageId: currentStageChannel.id })
    }
  }, [loadAirbrakeDefaultInfo, currentPlatformUser, currentStageChannel, isAirbrakeNotifierActive])
}
