import Pubnub from "pubnub"
import { appMessage, MessageType, GiphyMessage } from "runtypes/message"

const isValidGiphy = (message: GiphyMessage): boolean => {
  const urls = [
    message.gif.images.fixed_width.url,
    message.gif.images.fixed_width.webp,
    message.gif.images.fixed_width.mp4,
    message.gif.images.original.url,
    message.gif.images.original.webp,
    message.gif.images.original.mp4,
  ]

  try {
    let i
    for (i = 0; i < urls.length; i++) {
      const domain = urls[i].replace("https://", "").split(/[/?#]/)[0]

      if (!domain.endsWith(".giphy.com")) {
        return false
      }
    }
  } catch (error) {
    return false
  }

  return true
}

export const messageListener = (pubnubListener: any) => (messageEvent: Pubnub.MessageEvent) => {
  if (appMessage.guard(messageEvent.message)) {
    if (messageEvent.message.type === MessageType.Giphy) {
      if (!isValidGiphy(messageEvent.message)) {
        return null
      }
    }

    return pubnubListener(messageEvent)
  }
}
