export const getConditionalValue =
  <T>(...checkArgs: T[]) =>
  <K>(...resultArgs: K[]) =>
  (checkValue: T): K | null => {
    if (checkArgs.length !== resultArgs.length || checkArgs.length === 0) {
      return null
    }

    let i
    for (i = 0; i < checkArgs.length; i++) {
      if (checkArgs[i] === checkValue) {
        return resultArgs[i]
      }
    }

    return null
  }
