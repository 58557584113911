import { AppState } from "store/setup/storeTypes"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EventUser } from "api/apiFunctions/authentication"
import invariant from "invariant"
import { shouldNotBeSelectedUntilPresentErrorMessage } from "store/setup/guaranteedSelectorsUtils"

type AuthenticationState = {
  eventUser?: EventUser
}

const initialState: AuthenticationState = {}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setEventUser: (state, action: PayloadAction<EventUser | undefined>) => {
      state.eventUser = action.payload
    },
  },
})

export const { setEventUser } = authenticationSlice.actions

const getAuthentication = (state: AppState) => state[authenticationSlice.name]

export const getCurrentPlatformUser = (state: AppState): EventUser => {
  const { eventUser } = getAuthentication(state)

  invariant(eventUser !== undefined, shouldNotBeSelectedUntilPresentErrorMessage)

  return eventUser
}
