import React, { useContext } from "react"
import { Box } from "@material-ui/core"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { currentStageDisplayTypeSelector } from "store/domain/currentStageContent/currentStageChannel"
import { LobbyStage } from "features/mainContentBlocks/LobbyStage"
import { VideoLibrary } from "features/mainContentBlocks/VideoLibrary"
import { ExpoboothSearch } from "features/mainContentBlocks/ExpoboothSearch"
import { BreakoutSearch } from "features/mainContentBlocks/BreakoutSearch"
import { Mingle } from "features/mainContentBlocks/Mingle"
import { DanceFloor } from "features/mainContentBlocks/DanceFloor"
import { Webinar } from "features/mainContentBlocks/Webinar"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"
import { WrapperWithSidebarComments } from "features/assembledBlocks/WrapperWithSidebarComments"
import { VideoIframeResized } from "features/assembledBlocks/VideoIframeResized"
import { StageMessagesButton } from "features/baseBlocks/StageMessagesButton"

const Wrapper = styled(Box)`
  height: 100%;
  overflow-y: auto;
`

export const CurrentStageContent: React.FC = () => {
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)

  const renderContent = (): JSX.Element => {
    switch (true) {
      case currentStageDisplayType.iframe || currentStageDisplayType.connect:
        return (
          <WrapperWithSidebarComments>
            <Box display="flex" flexDirection="column" width="100%" height="100%" position="relative">
              <Box display="flex" justifyContent="flex-end" position="absolute" top="9px" right="9px" zIndex="99">
                <StageMessagesButton />
              </Box>

              <VideoIframeResized occupyFullDimensions={currentStageDisplayType.connect} />
            </Box>
          </WrapperWithSidebarComments>
        )
      case currentStageDisplayType.dancefloor:
        return <DanceFloor />
      case currentStageDisplayType.webinar:
        return <Webinar />
      case currentStageDisplayType.mingle:
        return <Mingle />
      case currentStageDisplayType.lobby:
        return <LobbyStage />
      case currentStageDisplayType.expo:
        return <ExpoboothSearch />
      case currentStageDisplayType.breakout:
        return <BreakoutSearch />
      case currentStageDisplayType.videoLibrary:
        return <VideoLibrary />
      default: {
        airbrakeNotifier.notify({ error: "Stage displayType is not correct, or unset." })

        return <>Stage not found.</>
      }
    }
  }

  return <Wrapper>{renderContent()}</Wrapper>
}
