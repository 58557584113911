import { transparentize } from "polished"
import styled from "styled-components"

import { Tabs } from "@material-ui/core"

export const PrimaryTabs = styled(Tabs)`
  border-bottom: 2px solid ${({ theme }) => transparentize(0.1, theme.palette.secondary.main)};

  .MuiTab-root {
    max-width: unset;
    flex-grow: 2;
  }

  .MuiTab-root.Mui-selected {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-weight: bold;
  }

  .MuiTabs-indicator {
    height: 1px;
    background-color: ${({ theme }) => transparentize(0.1, theme.palette.secondary.main)};
  }
`
