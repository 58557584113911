import { MessageType } from "runtypes/message"
import { convertJSDateToPubnubTimestamp } from "independent/utils/convertJSDateToPubnubTimestamp"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { MessageFragment } from "store/domain/pubnubStore/messages"

export enum WelcomeMessageTypes {
  Stage,
  Chat,
}

type Props = {
  type: WelcomeMessageTypes
}

export const useWelcomeMessage = ({ type }: Props): MessageFragment => {
  const { name: eventName, stageWelcomeMessage, chatWelcomeMessage } = useSelector(getEvent)
  const { firstName } = useSelector(getCurrentPlatformUser)

  const getText = () => {
    switch (type) {
      case WelcomeMessageTypes.Chat:
        return chatWelcomeMessage
      case WelcomeMessageTypes.Stage:
        return stageWelcomeMessage
          ? stageWelcomeMessage.replace("{first_name}", ` ${firstName}`).replace("{name}", eventName)
          : `Welcome ${firstName}`
      default: {
        return "Hello."
      }
    }
  }

  const text = getText()

  return {
    timetoken: convertJSDateToPubnubTimestamp(new Date()),
    message: {
      type: MessageType.Text,
      senderId: "WELCOME",
      text,
      meta: { senderName: eventName },
    },
  }
}
