import React, { useState } from "react"
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core"
import { getRoomChannelsById } from "store/domain/pubnubStore/channels/roomChannels"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import { MessagesPanel, messagesPanelContainerCss } from "features/baseBlocks/MessagesPanel"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { removeOpenChatRoom } from "store/domain/openChatRooms"
import { useDispatch } from "store/setup/useDispatch"
import { useWelcomeMessage, WelcomeMessageTypes } from "hooks/useWelcomeMessage"

const WrapperBox = styled(Box)`
  ${({ theme }) =>
    `
    background: ${theme.custom.colors.openChatRooms.chatRoomPanel.background};
    width: 300px;
    height: 100%;
    margin: ${theme.spacing(0, 1)};
    border-top-left-radius: ${theme.spacing(1)}px;
    border-top-right-radius: ${theme.spacing(1)}px;
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.only("xs")} {
      width: 280px;
    }
  `}
`

const HeaderStyled = styled(Box)`
  ${({ theme }) =>
    `
    border-bottom: 2px solid #80808070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${theme.custom.colors.openChatRooms.chatRoomPanel.color};
  `}
`

const DescriptionTooltip = styled(Tooltip)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  cursor: pointer;
`

const Description = styled(Typography)`
  ${({ theme }) =>
    `
    padding: ${theme.spacing(1)}px;
    font-weight: ${theme.typography.fontWeightBold};
  `}
`

const BackIcon = styled(IconButton)`
  ${({ theme }) =>
    `
    color: ${theme.custom.colors.openChatRooms.chatRoomPanel.color};
    padding: ${theme.spacing(1)}px;
  `}
`

const MessagesPanelWrapper = styled.div<{ minimized: boolean }>`
  ${({ theme, minimized }) =>
    `
    padding: ${minimized ? 0 : theme.spacing(1)}px;
    overflow: auto;
    height: ${minimized ? 0 : 320}px;
    transition: height 0.15s ease-out;
  `}
`

const MessagesPanelInnerWrapper = styled.div`
  ${() =>
    `
    ${messagesPanelContainerCss}
    overflow: auto;
    height: 100%;
  `}
`

type Props = {
  channelId: string
}

export const ChatRoomPanel: React.FC<Props> = ({ channelId }) => {
  const roomChannel = useSelector(getRoomChannelsById)[channelId]
  const dispatch = useDispatch()
  const [minimized, setMinimized] = useState(false)
  const welcomeMessage = useWelcomeMessage({ type: WelcomeMessageTypes.Chat })

  return (
    <WrapperBox boxShadow={4}>
      <HeaderStyled>
        <DescriptionTooltip enterDelay={500} title={roomChannel.description}>
          <Description
            onClick={() => {
              setMinimized(!minimized)
            }}
          >
            {roomChannel.description}
          </Description>
        </DescriptionTooltip>

        <BackIcon
          size="small"
          onClick={() => {
            dispatch(removeOpenChatRoom(channelId))
          }}
        >
          <CloseRoundedIcon />
        </BackIcon>
      </HeaderStyled>

      <MessagesPanelWrapper minimized={minimized}>
        <MessagesPanelInnerWrapper>
          <MessagesPanel
            channelId={roomChannel.id}
            welcomeMessage={welcomeMessage}
            inputLabel="Write a message"
            focusAutomatically={true}
            scrollDownButtonText="Unread comments"
          />
        </MessagesPanelInnerWrapper>
      </MessagesPanelWrapper>
    </WrapperBox>
  )
}
