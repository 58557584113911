import React from "react"

export const GoogleIcon: React.FC = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.3916" width="51" height="51" rx="25.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4 27.0906C34.4 26.4701 34.3443 25.8735 34.2409 25.3008H26V28.6854H30.7091C30.5063 29.7792 29.8898 30.7059 28.9631 31.3264V33.5218H31.7909C33.4455 31.9985 34.4 29.7553 34.4 27.0906Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 35.6417C28.3625 35.6417 30.3432 34.8582 31.7909 33.5218L28.9631 31.3264C28.1795 31.8514 27.1773 32.1616 26 32.1616C23.721 32.1616 21.792 30.6224 21.104 28.5542H18.1807V30.8212C19.6204 33.6809 22.5795 35.6417 26 35.6417Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.104 28.5542C20.929 28.0292 20.8296 27.4684 20.8296 26.8917C20.8296 26.315 20.929 25.7542 21.104 25.2292V22.9622H18.1807C17.5881 24.1434 17.25 25.4798 17.25 26.8917C17.25 28.3036 17.5881 29.64 18.1807 30.8213L21.104 28.5542Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 21.6217C27.2847 21.6217 28.4381 22.0632 29.3449 22.9302L31.8546 20.4206C30.3392 19.0086 28.3585 18.1416 26 18.1416C22.5795 18.1416 19.6204 20.1024 18.1807 22.9621L21.104 25.2291C21.792 23.1609 23.721 21.6217 26 21.6217Z"
        fill="#EA4335"
      />
      <rect x="0.5" y="1.3916" width="51" height="51" rx="25.5" stroke="#E2E8F0" />
    </svg>
  )
}
