import React, { useCallback, useState } from "react"
import { VideoIframeResized } from "features/assembledBlocks/VideoIframeResized"
import { WrapperWithSidebarComments } from "features/assembledBlocks/WrapperWithSidebarComments"
import { DanceFloorDaily } from "features/baseBlocks/DanceFloorDaily"
import { Box } from "@material-ui/core"
import { DanceFloorJoinButton } from "features/baseBlocks/DanceFloorJoinButton"
import { StageMessagesButton } from "features/baseBlocks/StageMessagesButton"
import { setStageMessages } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"
import { MainContentHeader } from "components/MainContentHeader"
import styled from "styled-components"
import { DanceFloorAdditionalInfoDialog } from "features/assembledBlocks/DanceFloorAdditionalInfoDialog"

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const DanceFloor: React.FC = () => {
  const [userClickedJoinDanceFloor, setUserClickedJoinDanceFloor] = useState(false)
  const dispatch = useDispatch()

  const onClickJoinDanceFloor = useCallback(() => {
    setUserClickedJoinDanceFloor(true)

    dispatch(setStageMessages(true))
  }, [dispatch])

  const onLeftDanceFloor = useCallback(() => setUserClickedJoinDanceFloor(false), [])

  return (
    <Wrapper>
      <MainContentHeader>
        <DanceFloorAdditionalInfoDialog />

        <Box display="flex" marginLeft="auto">
          {!userClickedJoinDanceFloor && (
            <Box mr={1}>
              <DanceFloorJoinButton onClickJoinDanceFloor={onClickJoinDanceFloor} />
            </Box>
          )}

          <StageMessagesButton />
        </Box>
      </MainContentHeader>

      <WrapperWithSidebarComments>
        <Box display="flex" width="100%" height="100%" flexDirection="column" position="relative">
          {userClickedJoinDanceFloor ? (
            <Box p={0.8} display="flex" width="100%" height="100%">
              <DanceFloorDaily onLeftDanceFloor={onLeftDanceFloor} />
            </Box>
          ) : (
            <VideoIframeResized />
          )}
        </Box>
      </WrapperWithSidebarComments>
    </Wrapper>
  )
}
