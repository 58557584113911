import React from "react"
import { useConfigurePubnub } from "hooks/useConfigurePubnub"

export const PubnubConfiguration: React.FC = ({ children }) => {
  const { isPubnubConfigured } = useConfigurePubnub()

  if (!isPubnubConfigured) {
    return null
  }

  return <>{children}</>
}
