import { AddedChipList } from "./AddedChipList"
import React from "react"
import { Accordion, AccordionSummary, AccordionDetails, Box } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import styled from "styled-components"

const AccordionDetailsStyled = styled(AccordionDetails)`
  max-height: 250px;
  overflow: auto;
`

const AccordionStyled = styled(Accordion)<{ $isAccordionBorderOn: boolean }>`
  ${({ theme, $isAccordionBorderOn }) =>
    $isAccordionBorderOn
      ? `
        border: 2px solid ${theme.palette.primary.light};
      `
      : ""}
`

export type ChipType = { id: string; label: string }

type Props = {
  addedChips: ChipType[]
  removeChip: (guestIdArg: string) => void
  reverseXSLayout?: boolean
  isAccordionBorderOn?: boolean
}

export const ChipsPanel: React.FC<Props> = ({
  addedChips,
  removeChip,
  reverseXSLayout = false,
  isAccordionBorderOn = false,
  children,
}) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const addedChipList = <AddedChipList addedChips={addedChips} removeChip={removeChip} />

  return (
    <Box>
      {addedChips.length > 0 && (
        <>
          {isXs ? (
            <Box display="flex" flexDirection={reverseXSLayout ? "column-reverse" : "column"}>
              <Box mb={1.5}>
                <AccordionStyled defaultExpanded={!isXs} $isAccordionBorderOn={isAccordionBorderOn}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{addedChips.length + " selected"}</AccordionSummary>

                  <AccordionDetailsStyled>{addedChipList}</AccordionDetailsStyled>
                </AccordionStyled>
              </Box>

              <Box mb={1.5}>{children}</Box>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" mx={1}>
              <Box mr={1}>{addedChipList}</Box>

              <Box display="flex">{children}</Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
