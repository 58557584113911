import React, { useState } from "react"
import styled from "styled-components"
import { VideoIframe } from "features/baseBlocks/VideoIframe"
import { STAGE_VIDEO_DIMENSIONS_RATIO } from "constants/general"

const VideoIframeStyled = styled(VideoIframe)<{ windowWidth: number }>`
  width: 100%;
  height: ${({ windowWidth }) => windowWidth / STAGE_VIDEO_DIMENSIONS_RATIO}px;
`

export const MobileVideoIframe: React.FC = () => {
  const [windowWidth] = useState<number>(window.innerWidth)

  return <VideoIframeStyled windowWidth={windowWidth} />
}
