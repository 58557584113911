export const CONTACT_EMAIL = "info@vstage.com"

export const CONTACT_EMAIL_LINK = "CONTACT SUPPORT"

export const VIP_AREA_LINK = "https://zoom.us/wc/join/91037321662"

export const STAGE_VIDEO_DIMENSIONS_RATIO = 16 / 9

export const BELL_NOTIFICATION_CATEGORIES = { callRequest: "CallRequest", stageLink: "stage_link" }

export const STAGE_DISPLAY_TYPES = {
  iframe: "iframe",
  lobby: "lobby",
  breakout: "breakout",
  expo: "expo",
  mingle: "mingle",
  videoLibrary: "video_gallery",
  dancefloor: "dancefloor",
  connect: "connect",
  webinar: "webinar",
} as const

export type TypeOfStageDisplayType = typeof STAGE_DISPLAY_TYPES[keyof typeof STAGE_DISPLAY_TYPES]

export const DAILY_URL = "https://vstage.daily.co"

export const constructDailyExitUrl = (room: string) => `https://dashboard.daily.co/exit?domain=vstage&room=${room}`

export const TEMPLATE_TYPES = {
  standard: "standard",
  headsUpDisplay: "heads_up_display",
  connect: "connect",
  singleStage: "single_stage",
} as const

export type TypeOfTemplateType = typeof TEMPLATE_TYPES[keyof typeof TEMPLATE_TYPES]

export const INTERCOM_CUSTOM_LAUNCHER_ID = "intercom-custom-launcher"

export const USER_SUPPORT_EMAIL = "support@vstage.com"

export const CONNECT_TEMPLATE_IFRAME_ID = "connect-template-iframe"

export const interactWithConnectTemplateIframe = (fn: (iframeElement: HTMLIFrameElement) => void) => {
  setTimeout(() => {
    const element = document.getElementById(CONNECT_TEMPLATE_IFRAME_ID) as HTMLIFrameElement

    if (element) {
      fn(element)
    }
  }, 0)
}

export const EVENT_BACKGROUND_TYPE = {
  color: "color",
  image: "image",
} as const

export type TypeOfEventBackgroundType = typeof EVENT_BACKGROUND_TYPE[keyof typeof EVENT_BACKGROUND_TYPE]
