import React from "react"
import { getEvent } from "store/domain/event"
import { AdditionalInfoDialog } from "features/baseBlocks/AdditionalInfoDialog"
import { useSelector } from "store/setup/useSelector"

export const WebinarAdditionalInfoDialog: React.FC = () => {
  const { webinarIframeLinkText, webinarIframeTitle, webinarIframeSrc } = useSelector(getEvent)

  return (
    <>
      {webinarIframeLinkText && webinarIframeTitle && webinarIframeSrc && (
        <AdditionalInfoDialog
          iframeLinkText={webinarIframeLinkText}
          iframeTitle={webinarIframeTitle}
          iframeSrc={webinarIframeSrc}
        />
      )}
    </>
  )
}
