import { Box, Fade, Grid, IconButton, Popper, PopperPlacementType } from "@material-ui/core"
import { ReactionImage } from "components/ReactionImage"
import React, { useMemo } from "react"
import { getMessageReactionsByUser } from "store/domain/allMessageReactions"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon"

const ChoiceIconButton = styled(IconButton)`
  padding: 5px;
`

const PopperWrapper = styled(Popper)`
  z-index: 2000;
`

const BoxWrapper = styled(Box)`
  background-color: rgba(0, 0, 0, 0.6);
`

const TriggerIconButton = styled(IconButton)`
  padding: 0;
  color: inherit;
`

type MessageReactionProps = {
  onSelection(index: number): void
  timetoken: number
}

export const MessageReactionPicker: React.FC<MessageReactionProps> = ({ onSelection, timetoken }) => {
  const messageReactionsByUser = useSelector(getMessageReactionsByUser(String(timetoken)))
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState<PopperPlacementType>()

  const emojiIndex = useMemo(() => new Array(5).fill(undefined).map((_, index) => index + 1), [])

  const handleClick = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  return (
    <Box flexShrink={0}>
      <PopperWrapper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <BoxWrapper mb={1} p={0.3} borderRadius={15}>
              <Grid container>
                {emojiIndex.map((index) => (
                  <ChoiceIconButton
                    color="primary"
                    disabled={messageReactionsByUser.includes(`${index}`)}
                    key={index}
                    size="small"
                    onClick={() => {
                      onSelection(index)
                      setOpen(false)
                    }}
                  >
                    <ReactionImage index={index} disabled={messageReactionsByUser.includes(`${index}`)} />
                  </ChoiceIconButton>
                ))}
              </Grid>
            </BoxWrapper>
          </Fade>
        )}
      </PopperWrapper>

      <TriggerIconButton onClick={handleClick("top-end")}>
        <InsertEmoticonIcon />
      </TriggerIconButton>
    </Box>
  )
}
