import { EventType } from "api/apiFunctions/event"
import Pubnub from "pubnub"
import { isPubnubLogVerbosityOffFromEnv } from "utils/environment"

export const createPubnubInstance = (event: EventType) => {
  return new Pubnub({
    publishKey: event.pubnubPublishKey,
    subscribeKey: event.pubnubSubscribeKey,
    origin: event.pubnubCustomDomain,
    logVerbosity: !isPubnubLogVerbosityOffFromEnv && event.logVerbosity,
    ssl: true,
    // Ensure that subscriptions will be retained if the network connection is lost
    restore: true,
    heartbeatInterval: 0,
  })
}
