import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { AlgoliaInstantSearch } from "components/algolia/AlgoliaInstantSearch"
import { AlgoliaSearchInput } from "components/algolia/AlgoliaSearchInput"
import { SearchedPhotos } from "./SearchedPhotos"
import { AlgoliaPagination } from "components/algolia/AlgoliaPagination"
import { AddedPhotosPanel } from "./AddedPhotosPanel"
import { GroupPhotoUtils } from "../useGroupPhotoUtils"
import { GROUP_PHOTO_CREATION_WRAPPER_WIDTH } from "./constants"
import { snackbarUtils } from "components/snackbar/snackbarUtils"

const Wrapper = styled.div`
  background: white;
  border-radius: 10px;
  width: ${GROUP_PHOTO_CREATION_WRAPPER_WIDTH}px;
  max-width: 100%;
  padding: 16px;
`

export const GroupPhotoCreation: React.FC<{
  photoBoothIndexName: string
  groupPhotoUtils: GroupPhotoUtils
  goToPreparePhoto: () => void
}> = ({
  photoBoothIndexName,
  groupPhotoUtils: {
    addedPhotosOfGroup,
    addPhotoOfGroup,
    removePhotoOfGroup,
    handleCreateGroupPhotoForConfirmation,
    defaultRefinement,
  },
  goToPreparePhoto,
}) => {
  const [isSearchInProgress, setIsSearchInProgress] = useState(false)

  useEffect(() => {
    if (addedPhotosOfGroup.length === 0) {
      snackbarUtils.info("You need to select at least 1 of your own Selfies to create a Group Photo")
    }
  }, [addedPhotosOfGroup])

  return (
    <Wrapper>
      <AlgoliaInstantSearch indexName={photoBoothIndexName} setIsSearchInProgress={setIsSearchInProgress}>
        <AlgoliaSearchInput
          variantFilled
          autoFocusTurnedOn
          label="Search for Selfies to add to Group Photo"
          {...(defaultRefinement && { defaultRefinement })}
        />

        <AddedPhotosPanel
          addedPhotosOfGroup={addedPhotosOfGroup}
          removePhotoOfGroup={removePhotoOfGroup}
          handleCreateGroupPhotoForConfirmation={handleCreateGroupPhotoForConfirmation}
        />

        <SearchedPhotos
          addPhotoOfGroup={addPhotoOfGroup}
          removePhotoOfGroup={removePhotoOfGroup}
          addedPhotosOfGroup={addedPhotosOfGroup}
          goToPreparePhoto={goToPreparePhoto}
          isSearchInProgress={isSearchInProgress}
        />

        <AlgoliaPagination />
      </AlgoliaInstantSearch>
    </Wrapper>
  )
}
