import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import { DeepPartial } from "independent/typescript"

export const signInWithEmail =
  () =>
  ({ email }: { email: string }) =>
    baseApi
      .post({
        url: apiUrls.authentication.signInWithEmail,
        data: {
          platform_user: {
            email,
          },
        },
      })
      .then(({ data }) => data)

export const fetchOtcAgain =
  () =>
  ({ email }: { email: string }) =>
    baseApi
      .post({
        url: apiUrls.authentication.fetchOtcAgain,
        data: {
          platform_user: {
            email,
          },
        },
      })
      .then(({ data }) => data)

export const loginWithOtc =
  ({ fingerprintHash }: { fingerprintHash: string }) =>
  ({ email, otc }: { email: string; otc: string }) =>
    baseApi
      .post<{ loggedIn: boolean }>({
        url: apiUrls.authentication.loginWithOtc,
        data: {
          platform_user: {
            email,
            otc,
            fingerprint: fingerprintHash,
          },
        },
      })
      .then(({ data }) => data)

export const recoverAccess =
  ({ fingerprintHash }: { fingerprintHash: string }) =>
  () =>
    baseApi
      .post<{ loggedIn: boolean }>({
        url: apiUrls.authentication.recoverAccess,
        data: {
          platform_user: {
            fingerprint: fingerprintHash,
          },
        },
      })
      .then(({ data }) => data)

export type EventUser = {
  firstName?: string
  lastName?: string
  email: string
  profileCompleted: boolean
  marketingCommunication: boolean
  attendanceUuid: string
  uuid: string
  grantToken: string
  authKey: string
  intercomHash: string
  notificationsHash: string
  userName: string
  webinarModerator: boolean
  defaultPubnubChannels: string[]
}

type EventUsersResponseType = {
  user: EventUser
}

export const fetchEventUser = () => (eventUuid: string) =>
  baseApi
    .get<EventUsersResponseType>({
      url: apiUrls.authentication.user,
      headers: {
        EventToken: eventUuid,
      },
    })
    .then(({ data }) => data.user)

export type PlatformUserToPut = DeepPartial<EventUser>

export const putPlatformUser =
  () =>
  ({ firstName, lastName, marketingCommunication }: PlatformUserToPut) =>
    baseApi
      .put<{ platformUser: EventUser }>({
        url: apiUrls.authentication.putPlatformUser,
        data: {
          platform_user: {
            first_name: firstName,
            last_name: lastName,
            marketing_communication: marketingCommunication,
          },
        },
      })
      .then(({ data }) => data.platformUser)

export const logout = () => (eventUuid: string) =>
  baseApi.post({
    url: apiUrls.authentication.logout,
    headers: {
      EventToken: eventUuid,
    },
  })
