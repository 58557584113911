import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: ${({ theme }) => theme.spacing(10.5)}px;
  height: ${({ theme }) => theme.spacing(10.5)}px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid white;
  border-radius: 50%;
  color: white;
  text-shadow: 1px 1px 12px #000000;
  -webkit-box-shadow: -2px 0px 26px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 0px 26px 2px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 0px 26px 2px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: transform 0.1s ease-out;

  :hover {
    transform: scale(1.15);
  }
`

const ArrowWrapper = styled.div`
  margin: 5% 0 0 10%;
`

export const PlayButton: React.FC<{ handleOpenPlayerMedia: () => void }> = ({ handleOpenPlayerMedia }) => {
  return (
    <Wrapper onClick={handleOpenPlayerMedia}>
      <ArrowWrapper>&#9658;</ArrowWrapper>
    </Wrapper>
  )
}
