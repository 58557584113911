import React, { RefObject } from "react"
import Webcam from "react-webcam"
import {
  photoboothImageDimensions,
  WebcamPhotoWrapper,
  WebcamPlaceholder,
  WebcamPlaceholderTitle,
  WebcamPlaceholderContent,
  WebcamStyled,
  TakenOrUploadedImage,
  ImageWrapper,
  BackgroundImage,
  FrameImage,
} from "../PrepareAndViewPhotos.style"
import { AddedStickers, StickerAdder } from "../AddedStickers"

export const WebcamPhoto: React.FC<{
  webcamRef: RefObject<Webcam>
  selectedBackground: string | null
  selectedFrame: string | null
  imageSrc: File | null
  stickerAdder: StickerAdder
  cameraPermissionGranted: boolean
}> = ({ webcamRef, selectedBackground, selectedFrame, imageSrc, stickerAdder, children, cameraPermissionGranted }) => {
  return (
    <WebcamPhotoWrapper>
      {imageSrc && (
        <ImageWrapper ref={stickerAdder.imageWrapperRef}>
          <AddedStickers stickerAdder={stickerAdder} />

          {selectedBackground && <BackgroundImage src={selectedBackground || undefined} alt="background" />}

          {selectedFrame && <FrameImage src={selectedFrame || undefined} alt="frame" />}

          <TakenOrUploadedImage alt="Selfie" src={imageSrc ? URL.createObjectURL(imageSrc) : undefined} />
        </ImageWrapper>
      )}

      {!imageSrc && !cameraPermissionGranted && (
        <WebcamPlaceholder>
          <WebcamPlaceholderTitle variant="h4">Almost There!</WebcamPlaceholderTitle>

          <WebcamPlaceholderContent>
            Photo Booth uses your camera, to continue you’ll need to allow access. Thanks!
          </WebcamPlaceholderContent>
        </WebcamPlaceholder>
      )}

      <WebcamStyled
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={photoboothImageDimensions.width}
        height={photoboothImageDimensions.height}
        videoConstraints={photoboothImageDimensions}
        $isVisibility={!imageSrc && cameraPermissionGranted}
      />
      {children}
    </WebcamPhotoWrapper>
  )
}
