import React from "react"
import { useSelector } from "store/setup/useSelector"
import { Helmet } from "react-helmet"
import { NewRelicScript } from "./NewRelicScript"
// import { GetClickyScript } from "./GetClickyScript"
import { GetSiteControlScript } from "./GetSiteControl/GetSiteControl"
import { getEvent } from "store/domain/event"

export const HeadTags: React.FC = () => {
  const { name } = useSelector(getEvent)

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>

      {/* <GetClickyScript /> */}

      <GetSiteControlScript />

      <NewRelicScript />
    </>
  )
}
