import React, { useEffect, useRef, useState } from "react"
import { getEvent } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"
import { InstantSearch, InstantSearchProps } from "react-instantsearch-dom"
import algoliasearch, { SearchClient } from "algoliasearch"

type OwnProps = {
  setIsSearchInProgress?: (value: boolean) => void
}

export const AlgoliaInstantSearch: React.FC<Omit<InstantSearchProps, "searchClient"> & OwnProps> = ({
  children,
  setIsSearchInProgress,
  ...restProps
}) => {
  const { algoliaSearchKey, algoliaApplicationId } = useSelector(getEvent)
  const searchClientRef = useRef<SearchClient | null>(null)
  const [isAlgoliaPresent, setIsAlgoliaPresent] = useState(false)

  useEffect(() => {
    if (algoliaApplicationId && algoliaSearchKey) {
      searchClientRef.current = algoliasearch(algoliaApplicationId, algoliaSearchKey)
      setIsAlgoliaPresent(true)
    }
  }, [algoliaApplicationId, algoliaSearchKey])

  if (!algoliaSearchKey || !algoliaApplicationId || !isAlgoliaPresent || !searchClientRef.current) {
    return null
  }

  return (
    <InstantSearch
      searchClient={searchClientRef.current}
      {...(setIsSearchInProgress && {
        onSearchStateChange: (searchParams) => {
          setIsSearchInProgress(!!searchParams?.query)
        },
      })}
      {...restProps}
    >
      {children}
    </InstantSearch>
  )
}
