import { useEffect } from "react"
import { useDispatch } from "store/setup/useDispatch"
import { saveVideoLibrary } from "store/domain/videoLibrary"
import { useApi } from "api/useApi"

export const useFetchVideoLibraryContent = (): void => {
  const dispatch = useDispatch()
  const { api } = useApi()

  useEffect(() => {
    const asyncFn = async (): Promise<void> => {
      try {
        const videoLibraryContent = await api.videoLibrary.getVideLibraryContent()

        dispatch(saveVideoLibrary(videoLibraryContent))
      } catch (error) {
        console.error(error)
      }
    }

    asyncFn()
  }, [dispatch, api])
}
