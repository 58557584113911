import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import { StageMessages } from "features/assembledBlocks/StageMessages"
import { VideoIframe } from "features/baseBlocks/VideoIframe"
import React from "react"
import styled from "styled-components"
import { ContentToShow } from "../AppTemplate"
import { Header } from "./Header"
import { AccordionCustom } from "./AccordionCustom"
import { HEADER_OBSERVED_HEIGHT, BORDER_SPACING, SOCIALIZE_OBSERVED_WIDTH } from "./constants"
import { PollByStage } from "features/baseBlocks/PollByStage"
import { useSelector } from "store/setup/useSelector"
import {
  currentStageDisplayTypeSelector,
  getCurrentStageChannel,
  getCurrentStagePoll,
} from "store/domain/currentStageContent/currentStageChannel"
import { Socialize } from "./Socialize"
import { ReactionsList } from "features/baseBlocks/ReactionsList"
import { CurrentStageContent } from "features/mainContentBlocks/CurrentStageContent"
import { Expobooth } from "features/baseBlocks/Expobooth"
import { mainContentWrapperCss } from "components/styles/mainContentWrapperCss"
import { MobileTemplate } from "../MobileTemplate"
import { BackgroundWrapper } from "components/BackgroundWrapper"

const fullAvailablePanelHeight = `calc(100% - (${HEADER_OBSERVED_HEIGHT}px + ${BORDER_SPACING}px))`

const VideoIframeStyled = styled(VideoIframe)`
  width: 100%;
  height: 100%;
`

const SocializeWrapper = styled(Box)`
  position: absolute;
  left: ${BORDER_SPACING}px;
  bottom: 95px;
  display: flex;
  flex-direction: row;
`

const CurrentStageContentWrapper = styled(Box)`
  position: absolute;
  height: ${fullAvailablePanelHeight};
  left: ${SOCIALIZE_OBSERVED_WIDTH + 2 * BORDER_SPACING}px;
  width: calc(100% - ${SOCIALIZE_OBSERVED_WIDTH + 3 * BORDER_SPACING}px);
`

const ExpoboothStyled = styled(Expobooth)`
  ${mainContentWrapperCss}
`

const StageMessagesWrapper = styled(Box)`
  max-height: ${fullAvailablePanelHeight};
  height: ${fullAvailablePanelHeight};
  position: absolute;
  right: ${BORDER_SPACING}px;
  top: ${HEADER_OBSERVED_HEIGHT}px;
  display: flex;
  align-items: flex-end;
  width: 250px;
  overflow: auto;
`

const BottomPanelWrapper = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: ${BORDER_SPACING}px;
  width: min-content;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
`

const PollsWrapper = styled(Box)`
  display: flex;
  max-width: 260px;
  justify-content: center;
  margin-bottom: 12px;
`

const ReactionsWrapper = styled(Box)`
  display: flex;

  .MuiIconButton-root {
    padding: 8px;
  }
`

export const HeadsUpDisplayTemplate: React.FC<{ contentToShow: ContentToShow }> = ({ contentToShow }) => {
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const currentStagePoll = useSelector(getCurrentStagePoll)
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <BackgroundWrapper>
      {isXs ? (
        <MobileTemplate contentToShow={contentToShow} />
      ) : (
        <Box position="relative" height="100%">
          {contentToShow === ContentToShow.CurrentStage && currentStageDisplayType.iframe && (
            <Box position="absolute" height="100%" width="100%" top={0} left={0}>
              <VideoIframeStyled />
            </Box>
          )}

          <Header />

          <SocializeWrapper>
            <Socialize />
          </SocializeWrapper>

          {!currentStageDisplayType.iframe && (
            <CurrentStageContentWrapper>
              {contentToShow === ContentToShow.CurrentStage && <CurrentStageContent />}

              {contentToShow === ContentToShow.Expobooth && <ExpoboothStyled />}
            </CurrentStageContentWrapper>
          )}

          {currentStageDisplayType.iframe && !currentStageChannel.custom.hide_messages && (
            <StageMessagesWrapper>
              <AccordionCustom title="COMMENTS">
                <Box width="100%" pt={1} height="100%" display="flex" flexDirection="column">
                  <StageMessages />
                </Box>
              </AccordionCustom>
            </StageMessagesWrapper>
          )}

          {currentStageDisplayType.iframe && (currentStagePoll || !currentStageChannel.custom.hide_reactions) && (
            <BottomPanelWrapper>
              {currentStagePoll && (
                <PollsWrapper>
                  <AccordionCustom title={currentStagePoll.pollQuestion}>
                    <Box width="100%" mt={0.25} borderRadius={3} overflow="hidden">
                      <PollByStage />
                    </Box>
                  </AccordionCustom>
                </PollsWrapper>
              )}

              {!currentStageChannel.custom.hide_reactions && (
                <ReactionsWrapper>
                  <ReactionsList />
                </ReactionsWrapper>
              )}
            </BottomPanelWrapper>
          )}
        </Box>
      )}
    </BackgroundWrapper>
  )
}
