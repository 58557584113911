import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import { addSnackbarInCatch, addSnackbarInThen } from "api/utils"
import { sortByCreatedAt } from "utils/sortByCreatedAt"
import isArray from "lodash/isArray"
import isEmpty from "lodash/isEmpty"
import { AxiosRequestConfig } from "axios"

export type GroupPhoto = {
  type: "group"
  id: string
  username: string
  imageUrl: string
  thumbnailUrl: string
  downloadUrl: string
  attendanceUuids: string[]
  createdAt: string
}

type GroupPhotoResponseType = {
  groupPhotos: Record<string, GroupPhoto>
}

export const fetchGroupPhotos =
  ({ eventUuid, attendanceUuid }: { eventUuid: string; attendanceUuid: string }) =>
  (): Promise<GroupPhoto[]> =>
    baseApi
      .get<GroupPhotoResponseType>({
        url: apiUrls.photobooth.groupPhoto.list(attendanceUuid),
        headers: {
          EventToken: eventUuid,
        },
      })
      .then(({ data }) => data)
      .then((data) => {
        const guaranteedData = isArray(data) && isEmpty(data) ? { groupPhotos: {} } : data

        const sortedData = sortByCreatedAt(Object.values(guaranteedData.groupPhotos))

        const photosWithType = sortedData.map((photo) => ({ ...photo, type: "group" as const }))

        return photosWithType
      })

export const createGroupPhoto =
  (eventUuid: string) =>
  ({ preview, photoUuids }: { preview: boolean; photoUuids: string[] }) => {
    const requestConfig: AxiosRequestConfig = {
      url: apiUrls.photobooth.groupPhoto.create,
      headers: {
        EventToken: eventUuid,
      },
      params: {
        preview: preview ? "yes" : "no",
        photo_uuids: JSON.stringify(photoUuids),
      },
      ...(preview && { responseType: "blob" }),
    }

    return baseApi
      .post<string>(requestConfig)
      .then(({ data }) => data)
      .then((data) => (preview ? URL.createObjectURL(data as unknown as Blob) : data))
      .then((data) => (preview ? data : addSnackbarInThen(data)))
      .catch(addSnackbarInCatch(requestConfig))
  }
