import React from "react"
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import { ActionButton } from "../shared.style"
import MessageRoundedIcon from "@material-ui/icons/MessageRounded"

type Props = { onClick: () => void; loading: boolean }

export const SetupChatButton: React.FC<Props> = ({ onClick, loading }) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <>
      {isXs ? (
        <ActionButton color="primary" disabled={loading} variant="contained" onClick={onClick}>
          <b>CHAT</b>
        </ActionButton>
      ) : (
        <IconButton size="small" disabled={loading} color="primary" onClick={onClick}>
          <MessageRoundedIcon />
        </IconButton>
      )}
    </>
  )
}
