import { useEffect } from "react"
import ReactGA from "react-ga"
import { useSelector } from "store/setup/useSelector"
import ReactPixel from "react-facebook-pixel"
import LinkedInTag from "react-linkedin-insight"
import { getEvent } from "store/domain/event"
import posthog from "posthog-js"

export const useInitializeReactGA = (): void => {
  const googleAnalyticsCode = useSelector(getEvent).googleAnalyticsCode

  if (googleAnalyticsCode) {
    ReactGA.initialize(googleAnalyticsCode)

    ReactGA.pageview(window.location.pathname + window.location.search)

    ReactGA.event({
      category: "Event",
      action: "Sign up",
      label: "VStage",
    })
  }
}

export const useInitializeAnalytics = (): void => {
  const { linkedinId, facebookTrackingId, posthogKey, linkedinIdEvent } = useSelector(getEvent)

  useEffect(() => {
    if (facebookTrackingId) {
      ReactPixel.init(facebookTrackingId)

      ReactPixel.pageView()

      ReactPixel.track("CompleteRegistration")
    }
    if (linkedinId) {
      LinkedInTag.init(linkedinId, "dc")

      if (linkedinIdEvent) {
        LinkedInTag.track(linkedinIdEvent)
      }
    }
  }, [linkedinId, facebookTrackingId, linkedinIdEvent])

  useEffect(() => {
    if (posthogKey) {
      posthog.init(posthogKey, { api_host: "https://app.posthog.com" })
    }
  }, [posthogKey])
}
