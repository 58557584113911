import React from "react"
import { Grid } from "@material-ui/core"
import { LobbyStageMainCardFirstLogo } from "./LobbyStageMainCardFirstLogo"
import { LobbyStageCardBackground } from "../LobbyStageCardBackground"

export const LobbyStageMainCard: React.FC = () => {
  return (
    <LobbyStageCardBackground>
      <Grid container direction="row">
        <Grid item sm={12}>
          <LobbyStageMainCardFirstLogo />
        </Grid>
      </Grid>
    </LobbyStageCardBackground>
  )
}
