import { Box } from "@material-ui/core"
import { BellNotifications } from "features/baseBlocks/BellNotifications"
import React from "react"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { LeaderboardOpenButton } from "features/baseBlocks/leaderboard/LeaderboardOpenButton"
import LiveHelpIcon from "@material-ui/icons/LiveHelp"
import { INTERCOM_CUSTOM_LAUNCHER_ID } from "constants/general"
import { LogoImage } from "components/LogoImage"

const Wrapper = styled(Box)`
  ${({ theme }) => `
    background: ${theme.custom.colors.mobile.header.background};
    color: ${theme.custom.colors.mobile.header.color};
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
  `}
`

const LogoImageStyled = styled(LogoImage)`
  width: 250px;
`

const IntercomIcon = styled(LiveHelpIcon)`
  color: ${({ theme }) => theme.custom.colors.mobile.header.color};
  margin: 0 14px;
`

const BellNotificationsStyled = styled(BellNotifications)`
  margin: 0;
`

const LeaderboardOpenButtonStyled = styled(LeaderboardOpenButton)`
  #leaderboard-trigger-icon-button {
    padding: 0;
    margin-left: 6px;

    #leaderboard-icon {
      background: ${({ theme }) => theme.custom.colors.mobile.header.color};
    }
  }
`

export const MobileHeader: React.FC = () => {
  const { intercomCode, leaderboardPresent, hideNotifications } = useSelector(getEvent)

  return (
    <Wrapper>
      <LogoImageStyled />

      <Box display="flex" alignItems="center">
        {intercomCode && <IntercomIcon id={INTERCOM_CUSTOM_LAUNCHER_ID} />}

        {!hideNotifications && <BellNotificationsStyled />}

        {leaderboardPresent && <LeaderboardOpenButtonStyled />}
      </Box>
    </Wrapper>
  )
}
