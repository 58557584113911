import React from "react"
import { convertTimestampToTime } from "independent/utils/convertTimestampToTime"
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from "@material-ui/core"
import styled from "styled-components"
import { MessageFragment } from "store/domain/pubnubStore/messages"
import { MessageListItemContent } from "./MessageListItemContent"

const TooltipStyled = styled(Tooltip)`
  :first-child {
    margin-top: auto;
  }

  margin-bottom: 6px;
`

type Props = {
  channelId: string
  messageFragment: MessageFragment
  disableReactions?: boolean
  forMobile: boolean
}

export const MessageListItem: React.FC<Props> = ({
  channelId,
  messageFragment,
  disableReactions = false,
  forMobile,
}) => {
  const [timeTooltipOpen, setTimeTooltipOpen] = React.useState(false)
  const theme = useTheme()
  const timeOfMessage = convertTimestampToTime(messageFragment.timetoken)
  const touch = useMediaQuery(theme.custom.utils.hoverNoneMedia)

  const handleTimeTooltipClose = () => {
    setTimeTooltipOpen(false)
  }

  const handleTimeTooltipOpen = () => {
    setTimeTooltipOpen(true)
  }

  const content = (
    <MessageListItemContent
      channelId={channelId}
      messageFragment={messageFragment}
      disableReactions={disableReactions}
      forMobile={forMobile}
      {...(forMobile && { timeOfMessage })}
    />
  )

  return (
    <>
      {forMobile ? (
        content
      ) : (
        <>
          {touch ? (
            <ClickAwayListener onClickAway={handleTimeTooltipClose}>
              <TooltipStyled
                onClick={handleTimeTooltipOpen}
                title={timeOfMessage}
                placement="left-start"
                open={timeTooltipOpen}
                PopperProps={{
                  disablePortal: true,
                }}
              >
                <div>{content}</div>
              </TooltipStyled>
            </ClickAwayListener>
          ) : (
            <TooltipStyled
              placement="top"
              title={timeOfMessage}
              PopperProps={{
                disablePortal: true,
              }}
              enterDelay={1000}
            >
              <div>{content}</div>
            </TooltipStyled>
          )}
        </>
      )}
    </>
  )
}
