import { Box, Button } from "@material-ui/core"
import React, { useState } from "react"
import styled from "styled-components"
import { GroupPhotoUtils } from "../useGroupPhotoUtils"

const Wrapper = styled.div``

const ImageStyled = styled.img`
  ${({ theme }) => `
    max-width: 100%;
    border: 4px solid ${theme.palette.primary.main};
  `}
`

const ButtonStyled = styled(Button)`
  ${({ theme }) => `
    width: 200px;
    margin: ${theme.spacing(1, 1)};

    ${theme.breakpoints.only("xs")} {
      width: 100%;
    }
  `}
`

type Props = {
  groupPhotoToConfirm: GroupPhotoUtils["groupPhotoToConfirm"]
  handleConfirmGroupPhoto: GroupPhotoUtils["handleConfirmGroupPhoto"]
  goBack: () => void
}

export const GroupPhotoConfirmation: React.FC<Props> = ({ groupPhotoToConfirm, goBack, handleConfirmGroupPhoto }) => {
  const [confirmationLoading, setConfirmationLoading] = useState(false)

  const onClickConfirm = async () => {
    setConfirmationLoading(true)

    await handleConfirmGroupPhoto({ onFinish: () => setConfirmationLoading(false) })
  }

  return (
    <>
      {groupPhotoToConfirm && (
        <Wrapper>
          <ImageStyled src={groupPhotoToConfirm} />

          <Box display="flex" justifyContent="center" mt={1} width="100%" flexWrap="wrap">
            <ButtonStyled disabled={confirmationLoading} variant="contained" color="secondary" onClick={goBack}>
              PREVIOUS
            </ButtonStyled>

            <ButtonStyled disabled={confirmationLoading} variant="contained" color="primary" onClick={onClickConfirm}>
              SUBMIT
            </ButtonStyled>
          </Box>
        </Wrapper>
      )}
    </>
  )
}
