import { MagicBellProvider } from "@magicbell/magicbell-react"
import React from "react"
import { getEvent } from "store/domain/event"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useSelector } from "store/setup/useSelector"

export const MagicBellWrapper: React.FC = ({ children }) => {
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const event = useSelector(getEvent)

  return (
    <MagicBellProvider
      apiKey={event.notificationsKey}
      userExternalId={currentPlatformUser.attendanceUuid}
      userKey={currentPlatformUser.notificationsHash}
      theme={event.notificationsTheming}
    >
      <>{children}</>
    </MagicBellProvider>
  )
}
