import { Box } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { SocializeList } from "features/assembledBlocks/SocializeList"
import { transparentize } from "polished"
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded"

const Wrapper = styled(Box)`
  overflow: auto;
  padding: 14px 16px 30px;
`

const SocializeListWrapper = styled(Box)`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
    overflow: auto;

    .socialize-item {
      color: ${theme.custom.colors.mobile.main.color};
      background: ${transparentize(0.9, theme.custom.colors.mobile.main.color)};
      padding: 12px;
      border-radius: 10px;

      svg {
        font-size: 40px;
      }
    }
  `}
`

const ArrowBackStyled = styled(ArrowBackRoundedIcon)`
  margin-bottom: 6px;
`

export const Socialize: React.FC<{ onStageSelect: () => void }> = ({ onStageSelect }) => {
  return (
    <Wrapper>
      <ArrowBackStyled onClick={onStageSelect} fontSize="large" />

      <SocializeListWrapper>
        <SocializeList onStageSelect={onStageSelect} />
      </SocializeListWrapper>
    </Wrapper>
  )
}
