import { Box } from "@material-ui/core"
import React from "react"
import { useAssets } from "store/domain/event"
import styled from "styled-components"

const Image = styled.img`
  ${({ theme }) => `
    max-width: 100%;

    @media (min-width: 0px) and (max-width: 400px) {
      width: ${theme.spacing(20)}px;
    }
  `}
`

export const LobbyStageMainCardFirstLogo: React.FC = () => {
  const assets = useAssets()

  return (
    <Box p={3} display="flex" justifyContent="center">
      <Image src={assets.lobbyStage.mainCardFirstLogo} alt="main card logo" />
    </Box>
  )
}
