import React from "react"
import { useSelectStage } from "hooks/useSelectStage"
import { useSelector } from "store/setup/useSelector"
import { getStageChannels } from "store/domain/pubnubStore/channels/stageChannels"
import { STAGE_DISPLAY_TYPES } from "constants/general"
import MessageIcon from "@material-ui/icons/Message"
import VideoCallIcon from "@material-ui/icons/VideoCall"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import { PhotoboothOpener } from "features/baseBlocks/PhotoboothOpener"
import CasinoIcon from "@material-ui/icons/Casino"
import { getEvent } from "store/domain/event"
import { SetupRoomsDialogOpener } from "features/baseBlocks/rooms/SetupRoomsDialogOpener"
import QueueMusicIcon from "@material-ui/icons/QueueMusic"
import { SocializeItem } from "./SocializeItem"

export const SocializeList: React.FC<{ onStageSelect?: () => void }> = ({ onStageSelect }) => {
  const selectStage = useSelectStage()
  const { hidePhotobooth, displaySearch } = useSelector(getEvent)

  const mingleChannel = useSelector(getStageChannels).find(
    (item) => item.custom.display_type === STAGE_DISPLAY_TYPES.mingle,
  )
  const dancefloorChannel = useSelector(getStageChannels).find(
    (item) => item.custom.display_type === STAGE_DISPLAY_TYPES.dancefloor,
  )

  return (
    <>
      {displaySearch && (
        <SocializeItem
          name="CHAT"
          onClick={() => {
            // empty
          }}
          iconComponent={<MessageIcon />}
        />
      )}

      {displaySearch && (
        <SetupRoomsDialogOpener
          OpenerComponent={({ onClick }) => (
            <SocializeItem name="CALL" onClick={onClick} iconComponent={<VideoCallIcon />} />
          )}
        />
      )}

      {mingleChannel && (
        <SocializeItem
          name="MINGLE"
          onClick={() => {
            selectStage(mingleChannel.id)

            if (onStageSelect) {
              onStageSelect()
            }
          }}
          iconComponent={<CasinoIcon />}
        />
      )}

      {!hidePhotobooth && (
        <PhotoboothOpener
          OpenerComponent={({ onClick }) => (
            <SocializeItem name="PHOTO" onClick={onClick} iconComponent={<PhotoCameraIcon />} />
          )}
        />
      )}

      {dancefloorChannel && (
        <SocializeItem
          name="DANCE"
          onClick={() => {
            selectStage(dancefloorChannel.id)

            if (onStageSelect) {
              onStageSelect()
            }
          }}
          iconComponent={<QueueMusicIcon />}
        />
      )}
    </>
  )
}
