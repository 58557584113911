import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MessageActionEvent } from "runtypes/messageAction"
import { AppState } from "store/setup/storeTypes"
import map from "lodash/map"
import { getCurrentPlatformUser } from "store/domain/authentication"
import reduce from "lodash/reduce"

type AllMessageReactionsState = {
  [messageTimetoken: string]: {
    [index: string]: {
      [publisher: string]: { actionTimetoken: string }
    }
  }
}

const initialState: AllMessageReactionsState = {}

export const allMessageReactionsSlice = createSlice({
  name: "allMessageReactions",
  initialState,
  reducers: {
    addMessageReaction: (state, action: PayloadAction<MessageActionEvent>) => {
      const {
        payload: {
          publisher,
          data: { messageTimetoken, value, actionTimetoken },
        },
      } = action

      if (!state[messageTimetoken]) {
        state[messageTimetoken] = {}
      }

      if (!state[messageTimetoken][value]) {
        state[messageTimetoken][value] = {}
      }

      if (!state[messageTimetoken][value][publisher]) {
        state[messageTimetoken][value][publisher] = { actionTimetoken }
      }
    },
  },
})

export const { addMessageReaction } = allMessageReactionsSlice.actions

const getAllMessageReactions = (state: AppState) => state[allMessageReactionsSlice.name]

export const getMessageReactions = (timetoken: number) => (state: AppState) =>
  map(getAllMessageReactions(state)[timetoken], (singleMessageReaction, index) => ({
    index: parseInt(index, 10),
    count: Object.keys(singleMessageReaction).length,
  }))

export const getMessageReactionsByUser = (timetoken: string) => (state: AppState) => {
  const publisher = getCurrentPlatformUser(state).uuid
  const allMessagesByTimetoken = getAllMessageReactions(state)[timetoken] || []
  return reduce(
    allMessagesByTimetoken,
    (acc: string[], current, publisherKey) => (Object.keys(current).includes(publisher) ? [...acc, publisherKey] : acc),
    [],
  )
}
