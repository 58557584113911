import { routerPaths } from "main/App/Router/routerPaths"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { getStageChannelsById } from "store/domain/pubnubStore/channels/stageChannels"
import { useSelector } from "store/setup/useSelector"
import useEventSlug from "./useEventSlug"

export const useSelectStage = () => {
  const history = useHistory()
  const stageChannelsById = useSelector(getStageChannelsById)
  const eventSlug = useEventSlug()

  return useCallback(
    (stageChannelId: string) => {
      const stageChannel = stageChannelsById[stageChannelId]

      if (window.ga) {
        window.ga("send", "pageview", stageChannel.name)
      }

      history.push({
        pathname: routerPaths.stage.assemblePath(eventSlug, stageChannel.id),
        search: history.location.search,
      })
    },
    [history, stageChannelsById, eventSlug],
  )
}
