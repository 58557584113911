import styled from "styled-components"
import React from "react"
import { VideoIframe } from "features/baseBlocks/VideoIframe"
import { VideoIframeWithAspectRatio } from "./VideoIframeWithAspectRatio"

const VideoIframeFullDimensions = styled(VideoIframe)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  height: 100%;
  width: 100%;
`

type Props = { occupyFullDimensions?: boolean }

export const VideoIframeResized: React.FC<Props> = ({ occupyFullDimensions = false }) => {
  return <>{occupyFullDimensions ? <VideoIframeFullDimensions /> : <VideoIframeWithAspectRatio />}</>
}
