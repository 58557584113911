import React, { useRef } from "react"
import { IGif } from "runtypes/giphy"
import { useVisibility } from "independent/hooks/useVisibility"
import styled from "styled-components"
import { GifDisplay } from "../shared/GifDisplay"

const Placeholder = styled.div<{ width: number; height: number }>`
  background: ${({ theme }) => theme.palette.secondary.main};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`

const Wrapper = styled.div<{ $customHeight: number }>`
  display: flex;
  height: ${({ $customHeight }) => $customHeight}px;
  & > img,
  & > video {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
`

export enum GifSize {
  Preview,
  Full,
}

type GifProps = {
  gif: IGif
  size: GifSize
  container?: React.RefObject<HTMLElement> | null
  onClick?: (gif: IGif) => void
}

// lazy load a gif, waiting until it is nearly visible to being loading
export const Gif: React.FC<GifProps> = ({ gif, size, container = null, onClick }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useVisibility(ref, container, 0, "50px 0px", true)
  const source = size === GifSize.Full ? gif.images.original : gif.images.fixed_width

  return (
    <Wrapper
      ref={ref}
      onClick={(): void => {
        onClick && onClick(gif)
      }}
      $customHeight={gif.images.fixed_width.height}
    >
      {isVisible ? (
        <GifDisplay source={source} title={gif.title} />
      ) : (
        <Placeholder width={source.width} height={source.height} />
      )}
    </Wrapper>
  )
}
