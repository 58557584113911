import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"

type DanceFloorDailyData = { room: string; token: string }

export const fetchDanceFloorDailyData = (eventUuid: string) => (): Promise<DanceFloorDailyData> =>
  baseApi
    .get<DanceFloorDailyData>({
      url: apiUrls.danceFloor.dailyData,
      headers: {
        EventToken: eventUuid,
      },
    })
    .then(({ data }) => data)
