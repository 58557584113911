import React, { useEffect, useState } from "react"
import { useApi } from "api/useApi"
import { VideoDaily } from "components/VideoDaily"
import styled from "styled-components"

const VideoDailyStyled = styled(VideoDaily)`
  border-radius: 4px;
  overflow: hidden;
`

type Props = { onLeftWebinar?: () => void }

export const WebinarDaily: React.FC<Props> = ({ onLeftWebinar }) => {
  const { api } = useApi()
  const [roomToken, setRoomToken] = useState<string | null>(null)
  const [roomName, setRoomName] = useState<string | null>(null)
  const [streamKey, setStreamKey] = useState<string | null>(null)

  useEffect(() => {
    const innerFn = async () => {
      const { room, token, stream } = await api.webinar.fetchWebinarDailyData()

      setRoomName(room)
      setRoomToken(token)
      setStreamKey(stream)
    }

    innerFn()
  }, [api])

  return (
    <>
      {roomToken && roomName && streamKey && (
        <VideoDailyStyled token={roomToken} roomName={roomName} streamKey={streamKey} onLeftMeeting={onLeftWebinar} />
      )}
    </>
  )
}
