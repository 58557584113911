import { AppState } from "store/setup/storeTypes"
import { createMessageReducer, Message as PubNubMessageEnvelope } from "pubnub-redux"
import { AppMessage } from "runtypes/message"

type MessageEnvelope = Required<Pick<PubNubMessageEnvelope, "channel" | "message" | "timetoken">> & {
  message: AppMessage
}

export const messagesReducer = createMessageReducer<MessageEnvelope>()

export type MessageFragment = {
  timetoken: number
  message: AppMessage
}

export type MessagesByChannelId = {
  [channelId: string]: MessageFragment[]
}

export const getMessagesByChannelId = (state: AppState): MessagesByChannelId => state.messages.byId
