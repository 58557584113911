import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { ListItemText, Typography } from "@material-ui/core"

const Wrapper = styled.div`
  text-align: center;
`

const Answer = styled(ListItemText)<{ $isAnswerGiven: boolean }>`
  background: ${({ theme }) => theme.custom.colors.poll.background};
  color: ${({ theme }) => theme.custom.colors.poll.color};
  width: 100%;
  padding: 3px;
  font-weight: bold;
  line-height: 1.15;
  margin-top: 0;

  :not(:last-child) {
    margin-bottom: 1px;
  }

  :last-child {
    margin-bottom: 0;
  }

  ${({ $isAnswerGiven, theme }) =>
    !$isAnswerGiven
      ? `
        cursor: pointer;
        :hover,
        :active {
          background: ${lighten(0.3, theme.custom.colors.poll.background)};
        }
      `
      : ""};
`

const Result = styled.div`
  position: relative;
`

const PercentageBar = styled.div<{ widthPercentage: number }>`
  height: 100%;
  border-radius: 3px;
  transition: all 0.5s ease;
  background: ${({ theme }) => lighten(0.6, theme.custom.colors.poll.background)};
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
  position: absolute;
  display: flex;
  align-items: center;
`

const ResultHtmlWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VotePercentage = styled.span`
  margin-left: 6px;
  font-weight: bold;
`

const ChosenAnswerMark = styled(Typography)`
  margin-left: 8px;
  font-weight: bold;
`

export type PollAnswers = { option: string; votePercentage: number }[]

type Props = {
  answers: string[]
  votePercentages?: number[]
  onVote: (answer: string) => void
  vote?: string
}

export const Poll: React.FC<Props> = ({ answers, votePercentages, onVote, vote }) => {
  const [chosenVote, setChosenVote] = useState<string | null>(null)

  const setChosenVoteFromProps = useCallback(() => {
    if (vote && answers.find((currentAnswer) => currentAnswer === vote)) {
      setChosenVote(vote)
    }
  }, [vote, answers])

  useEffect(() => {
    setChosenVoteFromProps()
  }, [setChosenVoteFromProps])

  const handleVote = (voteArg: string) => {
    setChosenVote(voteArg)

    onVote(voteArg)
  }

  return (
    <Wrapper>
      {answers.map((answer, index) => {
        const answerHTML = <div dangerouslySetInnerHTML={{ __html: answer }} />

        return (
          <Answer key={answer} $isAnswerGiven={chosenVote !== null}>
            {chosenVote ? (
              <Result>
                <PercentageBar widthPercentage={votePercentages ? votePercentages[index] : 0}>
                  {votePercentages && <VotePercentage>{votePercentages[index]}%</VotePercentage>}

                  {answer === chosenVote && <ChosenAnswerMark variant="h5">✓</ChosenAnswerMark>}
                </PercentageBar>

                <ResultHtmlWrapper>{answerHTML}</ResultHtmlWrapper>
              </Result>
            ) : (
              <div onClick={() => handleVote(answer)}>{<div dangerouslySetInnerHTML={{ __html: answer }} />}</div>
            )}
          </Answer>
        )
      })}
    </Wrapper>
  )
}
