import { Box, Typography } from "@material-ui/core"
import React from "react"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import styled from "styled-components"

const Wrapper = styled(Box)`
  padding: 12px 14px;
  background: ${({ theme }) => theme.custom.colors.errorsBanner.background};
  color: ${({ theme }) => theme.custom.colors.errorsBanner.color};
`

const ErrorOutlineIconStyled = styled(ErrorOutlineIcon)`
  display: flex;
`

const MessageText = styled(Box)<{ $isBold?: boolean }>`
  ${({ $isBold }) =>
    $isBold
      ? `
        font-weight: bold;
      `
      : ""}
`

type Props = {
  headerText?: string
  apiErrors?: string[]
}

export const ErrorsBanner: React.FC<Props> = ({ headerText, apiErrors = [] }) => {
  return (
    <Wrapper>
      {headerText && (
        <Box display="flex" mb={apiErrors.length ? 0.8 : 0}>
          <Box mr={1.4}>
            <ErrorOutlineIconStyled />
          </Box>

          <Typography>
            <b>{headerText}</b>
          </Typography>
        </Box>
      )}

      <Box ml={0.8}>
        {apiErrors.map((currentError, index) => (
          <Box key={currentError} mb={index === apiErrors.length - 1 ? 0 : 1}>
            <Typography component="div">
              <Box display="flex">
                {apiErrors.length > 1 ? (
                  <Box mr={0.5}>{index + 1}.</Box>
                ) : (
                  <>
                    {apiErrors.length === 1 && !headerText && (
                      <Box mr={1.4}>
                        <ErrorOutlineIconStyled />
                      </Box>
                    )}
                  </>
                )}

                <MessageText $isBold={apiErrors.length === 1 && !headerText}>{currentError}</MessageText>
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>
    </Wrapper>
  )
}
