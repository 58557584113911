const animationName = "white-flash"
const animationDurationMs = 400

export const whiteFlash = `
  @keyframes ${animationName} {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
  }
`

export const triggerWhiteFlash = (): void => {
  document.body.style.animation = `${animationName} ${animationDurationMs}ms linear`

  window.setTimeout(() => {
    document.body.style.animation = `none`
  }, animationDurationMs)
}
