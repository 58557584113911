import React from "react"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { currentStageDisplayTypeSelector } from "store/domain/currentStageContent/currentStageChannel"
import { useAssets } from "store/domain/event"
import { Box } from "@material-ui/core"

const Wrapper = styled(Box)`
  cursor: pointer;
  display: flex;
`

const Image = styled.img`
  ${({ theme }) => `
    width: 120px;

    ${theme.breakpoints.down("sm")} {
      width: 100px;
    }
  `}
`

type Props = { onClickJoinDanceFloor: () => void }

export const DanceFloorJoinButton: React.FC<Props> = ({ onClickJoinDanceFloor }) => {
  const assets = useAssets()
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)

  return (
    <>
      {currentStageDisplayType.dancefloor && (
        <Wrapper onClick={onClickJoinDanceFloor}>
          <Image src={assets.getDancing} alt="Show your Dance moves off to others here" />
        </Wrapper>
      )}
    </>
  )
}
