import React, { createContext, useEffect, useState } from "react"
import { EventSetupType, fetchEventSetup } from "api/apiFunctions/eventSetup"
import useEventSlug from "hooks/useEventSlug"

export const EventSetupContext = createContext<EventSetupType>(null as unknown as EventSetupType)

export const EventSetupContextProvider: React.FC = ({ children }) => {
  const [eventSetup, setEventSetup] = useState<EventSetupType | null>(null)
  const eventSlug = useEventSlug()

  useEffect(() => {
    const asyncFn = async () => {
      setEventSetup(await fetchEventSetup(eventSlug))
    }

    asyncFn()
  }, [eventSlug])

  if (!eventSetup) {
    return null
  }

  return <EventSetupContext.Provider value={eventSetup}>{children}</EventSetupContext.Provider>
}
