import React from "react"
import {
  CarouselWrapper,
  CarouselStyled,
  CarouselItem,
  FrameCarouselImage,
  CarouselImageWrapper,
} from "../shared.style"
import { frameSrcs } from "../carouselAssetSrcs"
import { useAssets } from "store/domain/event"

type Props = {
  onAddFrame: (src: string) => void
  selectedFrame: string | null
}

export const AddFrame: React.FC<Props> = ({ onAddFrame, selectedFrame }) => {
  const assets = useAssets()

  const onClickSelectFrame = (imageSrc: string): void => {
    onAddFrame(imageSrc)
  }

  return (
    <CarouselWrapper>
      <CarouselStyled
        onClickItem={(index) => {
          onClickSelectFrame(frameSrcs[index])
        }}
        itemList={frameSrcs}
        ItemComponent={({ item }) => (
          <CarouselItem key={item}>
            <CarouselImageWrapper isSelected={selectedFrame === item}>
              <FrameCarouselImage src={item} backgroundSrc={assets.photobooth.frameBackgroundImage} />
            </CarouselImageWrapper>
          </CarouselItem>
        )}
      />
    </CarouselWrapper>
  )
}
