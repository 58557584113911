import React from "react"
import { Route, RouteProps } from "react-router-dom"
import { useSetStageFromParams } from "./useSetStageFromParams"
import { WrapperWithStageSet } from "./WrapperWithStageSet"

const ChildrenWrapper: React.FC = ({ children }) => {
  const { stageSet } = useSetStageFromParams()

  if (!stageSet) {
    return null
  }

  return <WrapperWithStageSet>{children}</WrapperWithStageSet>
}

export const EstablishedRoute: React.FC<Omit<RouteProps, "render">> = ({ children, ...rest }) => (
  <Route {...rest} render={() => <ChildrenWrapper>{children}</ChildrenWrapper>} />
)
