import React from "react"
import { useHandleOpeningOfSetupRoomsDialog } from "./useHandleOpeningOfSetupRoomsDialog"

type Props = { OpenerComponent: React.FC<{ onClick: () => void }> }

export const SetupRoomsDialogOpener: React.FC<Props> = ({ OpenerComponent }) => {
  const { handleOpeningOfSetupRoomsDialog } = useHandleOpeningOfSetupRoomsDialog()

  if (!handleOpeningOfSetupRoomsDialog) {
    return null
  }

  return <OpenerComponent onClick={handleOpeningOfSetupRoomsDialog} />
}
