import { AppState } from "store/setup/storeTypes"
import { getStageChannels } from "../pubnubStore/channels/stageChannels"
import { getEvent } from "../event"

export const defaultStageIdSelector = (state: AppState): string => {
  const { defaultOpeningStageId } = getEvent(state)
  const stageChannels = getStageChannels(state)

  return defaultOpeningStageId ? defaultOpeningStageId : stageChannels[0].id
}
