import React from "react"
import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import StarIcon from "@material-ui/icons/Star"
import { getConditionalValue } from "independent/utils/getConditionalValue"
import { LastLeaderboardMovement } from "typescript/leaderboard"
import { UserBadges } from "../UserBadges"
import { LeaderboardMeta } from "api/apiFunctions/leaderboard"

const Wrapper = styled.div<{ highlighted: boolean }>`
  ${({
    highlighted,
    theme: {
      custom: {
        colors: { leaderboard },
      },
    },
  }) => `
    width: 100%;
    padding: 10px 18px;
    display: flex;
    color: ${highlighted ? leaderboard.predominant.color : "inherit"};
    background: ${highlighted ? leaderboard.predominant.background : "initial"};
  `}
`

const PositionTypography = styled(Typography)`
  padding-right: 14px;
`
const ImageStyled = styled.img`
  width: 26px;
`

const ArrowDownwardIconStyled = styled(ArrowDownwardIcon)`
  opacity: 0.5;
`

const InvisibleStar = styled(StarIcon)`
  visibility: hidden;
  margin-right: 12px;
`

const InvisibleArrow = styled(ArrowUpwardIcon)`
  visibility: hidden;
`

type Props = {
  username: string
  score: number
  lastLeaderboardMovement?: LastLeaderboardMovement
  leaderboardPosition?: number
  isSearchInProgress?: boolean
  receivedBadgeUuids: string[]
  leaderboardMeta: LeaderboardMeta
}

export const UserScore: React.FC<Props> = ({
  username,
  score,
  leaderboardPosition,
  lastLeaderboardMovement,
  receivedBadgeUuids,
  isSearchInProgress,
  leaderboardMeta,
  leaderboardMeta: { goldPositionUrl, silverPositionUrl, bronzePositionUrl },
}) => {
  const isTopPosition = leaderboardPosition === 1 || leaderboardPosition === 2 || leaderboardPosition === 3

  const topPositionImageUrl = leaderboardPosition
    ? getConditionalValue(1, 2, 3)(goldPositionUrl, silverPositionUrl, bronzePositionUrl)(leaderboardPosition)
    : null

  return (
    <Wrapper highlighted={isTopPosition || !!isSearchInProgress}>
      <PositionTypography>{leaderboardPosition}</PositionTypography>

      {isTopPosition ? (
        <Box mr={1.5}>{topPositionImageUrl ? <ImageStyled src={topPositionImageUrl} /> : <StarIcon />}</Box>
      ) : (
        <InvisibleStar />
      )}

      <Box width="100%">
        <Box display="flex" width="100%">
          <Box flex={1} mr={1.5}>
            <Typography>
              <b>{username}</b>
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography>
              <b>{score}</b>
            </Typography>
          </Box>

          <>
            {lastLeaderboardMovement === "up" || lastLeaderboardMovement === "down" ? (
              <>
                {lastLeaderboardMovement === "up" && <ArrowUpwardIcon />}

                {lastLeaderboardMovement === "down" && <ArrowDownwardIconStyled />}
              </>
            ) : (
              <InvisibleArrow />
            )}
          </>
        </Box>

        <Box display="flex" mt={0.4} flexWrap="wrap">
          <UserBadges receivedBadgeUuids={receivedBadgeUuids} leaderboardMeta={leaderboardMeta} />
        </Box>
      </Box>
    </Wrapper>
  )
}
