import React from "react"
import { AlgoliaSearchInput } from "components/algolia/AlgoliaSearchInput"
import { SearchedUsers } from "./SearchedUsers"
import { AlgoliaPagination } from "components/algolia/AlgoliaPagination"
import styled from "styled-components"
import { lighten } from "polished"
import { LeaderboardMeta } from "api/apiFunctions/leaderboard"

const SearchInputWrapper = styled.div`
  background: ${({ theme }) => theme.custom.colors.leaderboard.predominant.background};
  color: ${({ theme }) => theme.custom.colors.leaderboard.predominant.color};
  padding: 8px 16px 12px;
  .MuiFilledInput-underline:after {
    border-color: ${({ theme }) => theme.custom.colors.leaderboard.background};
  }
`

const SearchedUsersWrapper = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.custom.colors.leaderboard.background};
  color: ${({ theme }) => theme.custom.colors.leaderboard.color};
  overflow: auto;
`

const PaginationWrapper = styled.div`
  padding: 10px 16px;
  background: ${({ theme }) => lighten(0.1, theme.custom.colors.leaderboard.background)};
  color: ${({ theme }) => theme.custom.colors.leaderboard.color};
`

const AlgoliaPaginationStyled = styled(AlgoliaPagination)`
  .MuiPaginationItem-root {
    color: ${({ theme }) => theme.custom.colors.leaderboard.color};
    opacity: 0.5;
  }

  .Mui-selected {
    background-color: initial;
    opacity: 1;
  }
`

type Props = {
  isSearchInProgress: boolean
  leaderboardMeta: LeaderboardMeta
}

export const ScoreBoard: React.FC<Props> = ({ isSearchInProgress, leaderboardMeta }) => {
  return (
    <>
      <SearchInputWrapper>
        <AlgoliaSearchInput label="Search by name" variantFilled smallSize />
      </SearchInputWrapper>

      <SearchedUsersWrapper>
        <SearchedUsers isSearchInProgress={isSearchInProgress} leaderboardMeta={leaderboardMeta} />
      </SearchedUsersWrapper>

      <PaginationWrapper>
        <AlgoliaPaginationStyled />
      </PaginationWrapper>
    </>
  )
}
