import { Box } from "@material-ui/core"
import React from "react"

type Props = {
  children?: React.ReactNode
  index: number | string
  value: number | string
  className?: string
}

export const TabPanel: React.FC<Props> = ({ children, value, index, className }) => (
  <>{value === index && <Box className={className}>{children}</Box>}</>
)
