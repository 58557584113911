import React from "react"
import { Box, Typography, useMediaQuery } from "@material-ui/core"
import { useSelector } from "store/setup/useSelector"
import { useAssets } from "store/domain/event"
import { LobbyStageCardItem } from "./LobbyStageCardItem/LobbyStageCardItem"
import { LobbyStageCardBackground } from "../LobbyStageCardBackground"
import { getStageChannels } from "store/domain/pubnubStore/channels/stageChannels"
import { useSelectStage } from "hooks/useSelectStage"
import { STAGE_DISPLAY_TYPES, VIP_AREA_LINK } from "constants/general"
import { StageChannel } from "store/domain/pubnubStore/channels/channelTypes"

const stagesCardTitle = "The Journey"
const vipStagesCardTitle = "Included in the ticket"

const cardMainStageText = "Paradise Island"
const cardMainStageDescription = "This is your main stage where you will see all of the entertainment."
const cardMainStageButtonText = "VISIT PARADISE ISLAND"

const cardEntertainmentText = "Dance Deck"
const cardEntertainmentDescription =
  "When your host announces your afterparty, turn on your camera and join our virtual dance floor with special guest DJ."
const cardEntertainmentButtonText = "VISIT DANCE DECK"

const cardVipAreaText = "Vip Area"
const cardVipAreaDescription =
  "Be part of the event as a VIP member of our exclusive virtual audience delivered through Zoom!"
const cardVipAreaButtonText = "VISIT VIP AREA"

export const LobbyStageCard: React.FC = () => {
  const match600 = useMediaQuery("(min-width:600px)")
  const match960 = useMediaQuery("(min-width:960px)")
  const selectStage = useSelectStage()

  const assets = useAssets()

  const mainStageBackgroundImage = match960
    ? assets.lobbyStage.cardMainStageBackgroundImage
    : assets.lobbyStage.cardMainStageBackgroundImageMobile
  const entertainmentBackgroundImage = match960
    ? assets.lobbyStage.cardEntertainmentBackgroundImage
    : assets.lobbyStage.cardEntertainmentBackgroundImageMobile
  const vipAreaBackgroundImage = match960
    ? assets.lobbyStage.vipAreaShopBackgroundImage
    : assets.lobbyStage.vipAreaShopBackgroundImageMobile

  const stageParentCardMargin = match600 ? 1 : 0
  const stageParentCardPadding = match960 ? 0.3 : 0

  const stageChannels = useSelector(getStageChannels)

  const onClickRedirectToStage = (stageChecker: (stageChannelName: StageChannel) => boolean) => () => {
    const stageChannel = stageChannels.find((stageChannel) => stageChecker(stageChannel))

    if (stageChannel) {
      selectStage(stageChannel.id)
    }
  }

  const onClickRedirectToVip = () => {
    window.open(VIP_AREA_LINK, "_blank")
  }

  return (
    <>
      <LobbyStageCardBackground>
        <Box flex="1 1 auto" p={stageParentCardPadding} m={stageParentCardMargin}>
          <Box ml={2} mt={2} color={"white"}>
            <Typography variant="h5">
              <Box fontWeight="fontWeightBold">{stagesCardTitle}</Box>
            </Typography>
          </Box>

          <LobbyStageCardItem
            match600={match600}
            match960={match960}
            cardMainText={cardMainStageText}
            cardDescriptionText={cardMainStageDescription}
            cardButtonText={cardMainStageButtonText}
            backgroundImage={mainStageBackgroundImage}
            onClick={onClickRedirectToStage(
              (stageChannel) => stageChannel.custom.display_type === STAGE_DISPLAY_TYPES.iframe,
            )}
          />

          <LobbyStageCardItem
            match600={match600}
            match960={match960}
            cardMainText={cardEntertainmentText}
            cardDescriptionText={cardEntertainmentDescription}
            cardButtonText={cardEntertainmentButtonText}
            backgroundImage={entertainmentBackgroundImage}
            onClick={onClickRedirectToStage(
              (stageChannel) => stageChannel.custom.display_type === STAGE_DISPLAY_TYPES.lobby,
            )}
          />
        </Box>
      </LobbyStageCardBackground>

      <LobbyStageCardBackground>
        <Box flex="1 1 auto" p={stageParentCardPadding} m={stageParentCardMargin}>
          <Box ml={2} mt={2} color={"white"}>
            <Typography variant="h5">
              <Box fontWeight="fontWeightBold">{vipStagesCardTitle}</Box>
            </Typography>
          </Box>

          <LobbyStageCardItem
            match600={match600}
            match960={match960}
            cardMainText={cardVipAreaText}
            cardDescriptionText={cardVipAreaDescription}
            cardButtonText={cardVipAreaButtonText}
            backgroundImage={vipAreaBackgroundImage}
            onClick={onClickRedirectToVip}
          />
        </Box>
      </LobbyStageCardBackground>
    </>
  )
}
