import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "store/setup/storeTypes"
import { VideoLibraryContent } from "api/apiFunctions/videoLibrary"

const initialState: VideoLibraryContent = {
  featuredVideos: [],
  videosByCategory: [],
}
export const videoLibrarySlice = createSlice({
  name: "videoLibrary",
  initialState,
  reducers: {
    saveVideoLibrary: (state, action: PayloadAction<VideoLibraryContent>) => action.payload,
  },
})

export const { saveVideoLibrary } = videoLibrarySlice.actions

const getVideoLibrary = (state: AppState) => state[videoLibrarySlice.name]

export const getFeaturedVideos = (state: AppState) => getVideoLibrary(state).featuredVideos

export const getVideosByCategory = (state: AppState) => getVideoLibrary(state).videosByCategory
