import React from "react"
import styled from "styled-components"

const AnchorStyled = styled.a`
  color: inherit;
`

type Props = {
  href: string
  title: string
}

export const InformationLinks: React.FC<Props> = ({ href, title }) => (
  <AnchorStyled href={href} target="_blank" rel="noopener, noreferrer">
    {title}
  </AnchorStyled>
)
