import React from "react"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { connectPagination } from "react-instantsearch-dom"
import { Pagination } from "@material-ui/lab"

const PaginationStyled = styled(Pagination)`
  .Mui-selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: white;
  }
`
type PaginationConnected = {
  nbPages: number
  currentRefinement: number
  refine: (newValue: number) => void
}

type OwnProps = { className?: string }

const AlgoliaPaginationRaw: React.FC<PaginationConnected & OwnProps> = ({
  nbPages,
  currentRefinement,
  refine,
  className,
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    refine(value)
  }

  if (nbPages === 0) {
    return null
  }

  return (
    <Grid className={className} alignItems="center" justifyContent="center" container spacing={0} direction="column">
      <PaginationStyled count={nbPages} page={currentRefinement} onChange={handleChange} />
    </Grid>
  )
}

export const AlgoliaPagination = connectPagination(AlgoliaPaginationRaw)
