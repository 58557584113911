import React, { useState, useCallback, useRef } from "react"
import { useClickOutside } from "independent/hooks/useClickOutside"
import { GifPicker, OnGifSelect } from "./GifPicker"
import GifIcon from "@material-ui/icons/Gif"
import styled from "styled-components"

const Dialog = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
`

const GifButton = styled.div`
  cursor: pointer;
  svg {
    vertical-align: top;
  }
`

export const GifInputContent: React.FC<{ onSelection: OnGifSelect }> = ({ onSelection }) => {
  const [showPicker, setPickerState] = useState(false)
  const picker = useRef<HTMLDivElement>(null)

  const dismissPicker = useCallback(() => {
    setPickerState(false)
  }, [setPickerState])

  useClickOutside([picker], dismissPicker)

  const togglePicker = (): void => {
    setPickerState(!showPicker)
  }

  const onSelected: OnGifSelect = (gif, query) => {
    onSelection(gif, query)
    dismissPicker()
  }

  return (
    <div ref={picker}>
      <Dialog>{showPicker && <GifPicker onSelected={onSelected} />}</Dialog>

      <GifButton onClick={togglePicker}>
        <GifIcon fontSize="large" />
      </GifButton>
    </div>
  )
}
