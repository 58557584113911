import React from "react"

import styled from "styled-components"
import CircularProgress from "@material-ui/core/CircularProgress"

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CircularProgressStyled = styled(CircularProgress)`
  color: ${({ theme }) => theme.custom.colors.leaderboard.color};
`

export const LeaderboardCircularProgress: React.FC = () => {
  return (
    <Wrapper>
      <CircularProgressStyled size={50} />
    </Wrapper>
  )
}
