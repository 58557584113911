import React from "react"
import IFrame from "react-iframe"
import styled from "styled-components"

const IFrameStyled = styled(IFrame)`
  width: 100%;
  height: 100%;
  background: white;
`

type Props = {
  iframeLobbyUrl: string
}

export const LobbyStageIFrame: React.FC<Props> = ({ iframeLobbyUrl }) => {
  return (
    <>
      <IFrameStyled
        url={iframeLobbyUrl}
        allow="microphone;camera;autoplay;display-capture;fullscreen"
        frameBorder={0}
      />
    </>
  )
}
