import React from "react"
import { useSelector } from "store/setup/useSelector"
import { getCurrentStagePoll } from "store/domain/currentStageContent/currentStageChannel"
import { PollActive } from "./PollActive"

export const PollByStage: React.FC = () => {
  const currentStagePoll = useSelector(getCurrentStagePoll)

  if (!currentStagePoll) {
    return null
  }

  return <PollActive pollUuid={currentStagePoll.pollUuid} pollAnswersArray={currentStagePoll.pollAnswersArray} />
}
