import React from "react"
import { AppFavicon } from "./AppFavicon"
import { PubnubConfiguration } from "./PubnubConfiguration"
import { SetEventAndUserInStore } from "./SetEventAndUserInStore"
import { ConditionallyProvideAirbrakeAndErrorBoundary } from "./ConditionallyProvideAirbrakeAndErrorBoundary"
import { DndSetup } from "./DndSetup"
import { AnalyticsAndInitialSetup } from "./AnalyticsAndInitialSetup"
import { useStoreAndEventSetup } from "./useStoreAndEventSetup"
import { Provider as ReactReduxProvider } from "react-redux"
import { PubNubProvider } from "pubnub-react"
import { EventUser } from "api/apiFunctions/authentication"
import { MagicBellWrapper } from "./MagicBellWrapper"
import { IntercomEnhancer } from "./IntercomEnhancer"

type Props = { eventUser: EventUser }

export const AuthorizedContainer: React.FC<Props> = ({ eventUser, children }) => {
  const storeAndEvent = useStoreAndEventSetup()

  if (!storeAndEvent) {
    return null
  }

  const { event, pubnubInstance, store } = storeAndEvent

  return (
    <ReactReduxProvider store={store}>
      <SetEventAndUserInStore event={event} eventUser={eventUser}>
        <IntercomEnhancer>
          <ConditionallyProvideAirbrakeAndErrorBoundary>
            <AnalyticsAndInitialSetup>
              <PubNubProvider client={pubnubInstance}>
                <PubnubConfiguration>
                  <DndSetup>
                    <MagicBellWrapper>
                      <AppFavicon />

                      {children}
                    </MagicBellWrapper>
                  </DndSetup>
                </PubnubConfiguration>
              </PubNubProvider>
            </AnalyticsAndInitialSetup>
          </ConditionallyProvideAirbrakeAndErrorBoundary>
        </IntercomEnhancer>
      </SetEventAndUserInStore>
    </ReactReduxProvider>
  )
}
