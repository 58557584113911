import React, { useState } from "react"
import { VideoIframeResized } from "features/assembledBlocks/VideoIframeResized"
import { WrapperWithSidebarComments } from "features/assembledBlocks/WrapperWithSidebarComments"
import { Box } from "@material-ui/core"
import { StageMessagesButton } from "features/baseBlocks/StageMessagesButton"
import { setStageMessages } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { WebinarDaily } from "features/baseBlocks/WebinarDaily"
import { WebinarJoinButton } from "features/baseBlocks/WebinarJoinButton"
import styled from "styled-components"
import { MainContentHeader } from "components/MainContentHeader"
import { WebinarAdditionalInfoDialog } from "features/assembledBlocks/WebinarAdditionalInfoDialog"

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Webinar: React.FC = () => {
  const [userClickedJoinButton, setUserClickedJoinButton] = useState(false)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const dispatch = useDispatch()

  return (
    <Wrapper>
      <MainContentHeader>
        <WebinarAdditionalInfoDialog />

        <Box marginLeft="auto" display="flex">
          {!userClickedJoinButton && currentPlatformUser.webinarModerator && (
            <Box mx={1}>
              <WebinarJoinButton
                onClick={() => {
                  setUserClickedJoinButton(true)

                  dispatch(setStageMessages(true))
                }}
              />
            </Box>
          )}

          {!userClickedJoinButton && <StageMessagesButton />}
        </Box>
      </MainContentHeader>

      <WrapperWithSidebarComments>
        <Box display="flex" width="100%" height="100%" flexDirection="column" position="relative">
          <Box display="flex" position="absolute" top="9px" zIndex="99" width="100%" px="9px">
            {" "}
          </Box>

          {userClickedJoinButton ? (
            <Box p={0.8} display="flex" flexDirection="column" width="100%" height="100%">
              <WebinarDaily
                onLeftWebinar={() => {
                  setUserClickedJoinButton(false)
                }}
              />
            </Box>
          ) : (
            <VideoIframeResized />
          )}
        </Box>
      </WrapperWithSidebarComments>
    </Wrapper>
  )
}
