import { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import Evaporate from "evaporate"
import mimeTypes from "mime-types"
import { useFileUploader } from "hooks/useFileUploader"
import { imageUrlToFile } from "../utils/imageUrlToFile"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useApi } from "api/useApi"

const uploadFolderNames = {
  original: "backgrounds",
  published: "published",
}

type ImageUploader = {
  getBackgroundRemovedImage: (imageSrc: File) => Promise<{ imageSrc: File; imageId: string } | undefined>
  backgroundRemovalPending: boolean
  uploadImageToGallery: ({
    imageSrc,
    imageId,
  }: {
    imageSrc: File
    imageId: string
  }) => Promise<{ status: number } | "error">
  galleryUploadPending: boolean
}

export const useImageUploader = (): ImageUploader => {
  const [imageUploader, setImageUploader] = useState<Evaporate | null>(null)
  const [backgroundRemovalPending, setBackgroundRemovalPending] = useState<boolean>(false)
  const [galleryUploadPending, setGalleryUploadPending] = useState<boolean>(false)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const { api } = useApi()

  const fileUploaderPromise = useFileUploader()

  useEffect(() => {
    const innerFn = async (): Promise<void> => {
      const fileUploader = await fileUploaderPromise

      setImageUploader(fileUploader)
    }

    innerFn()
  }, [fileUploaderPromise])

  const getExtension = (imageSrc: File): string => {
    const possibleExtension = mimeTypes.extension(imageSrc.type)

    return possibleExtension ? `.${possibleExtension}` : ""
  }

  const uploadOriginalImage = async (imageSrc: File): Promise<{ imageKey: string; imageId: string } | undefined> => {
    const fileName = uuidv4()

    const imageId = `${currentPlatformUser.attendanceUuid}/${fileName}`

    const finalName = `${uploadFolderNames.original}/${imageId}${getExtension(imageSrc)}`

    if (imageUploader) {
      return {
        imageKey: await imageUploader.add({ file: imageSrc, name: finalName }),
        imageId,
      }
    }
  }

  const uploadPublishingImage = async ({
    imageSrc,
    imageId,
  }: {
    imageSrc: File
    imageId: string
  }): Promise<{ imageKey: string } | undefined> => {
    const imageName = `${uploadFolderNames.published}/${imageId}${getExtension(imageSrc)}`

    if (imageUploader) {
      return {
        imageKey: await imageUploader.add({ file: imageSrc, name: imageName }),
      }
    }
  }

  const getBackgroundRemovedImage = async (
    imageSrc: File,
  ): Promise<{ imageSrc: File; imageId: string } | undefined> => {
    try {
      setBackgroundRemovalPending(true)

      const uploadResult = await uploadOriginalImage(imageSrc)

      if (uploadResult) {
        const { imageKey, imageId } = uploadResult

        const backgroundRemovedImageUrl = await api.photobooth.photoGallery.removeImageBackground({ imageKey })

        const imageSrc = await imageUrlToFile({
          url: backgroundRemovedImageUrl,
        })

        return { imageSrc, imageId }
      }
    } finally {
      setBackgroundRemovalPending(false)
    }
  }

  const uploadImageToGallery = async ({ imageSrc, imageId }: { imageSrc: File; imageId: string }) => {
    setGalleryUploadPending(true)

    const uploadResult = await uploadPublishingImage({ imageSrc, imageId })

    let returnValue: { status: number } | "error" = "error"

    if (uploadResult) {
      const { imageKey } = uploadResult

      returnValue = await api.photobooth.photoGallery.publishImageToGallery({ imageKey: imageKey })
    }

    setGalleryUploadPending(false)

    return returnValue
  }

  return {
    getBackgroundRemovedImage,
    backgroundRemovalPending,
    uploadImageToGallery,
    galleryUploadPending,
  }
}
