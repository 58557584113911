import styled from "styled-components"
import { Typography } from "@material-ui/core"

export const TextAreaWrapper = styled.div`
  padding: 5px 5px 5px 7px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`

export const TextAreaTypography = styled(Typography)`
  width: 100%;
  resize: none;
  overflow: hidden;
  border: none;
  max-height: ${({ theme }) => theme.spacing(32.5)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  background: transparent;
  white-space: nowrap;
  color: inherit;

  &::placeholder {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
` as typeof Typography

export const EditorActions = styled.div`
  display: flex;
  padding: 5px 0;
  align-items: center;

  svg {
    width: 25px;
    margin-right: 7px;
  }
`

export const SendButton = styled.div`
  display: none;

  ${({ theme }) => theme.custom.utils.hoverNoneMedia} {
    display: flex;
  }

  svg {
    cursor: pointer;
  }
`
