import { useEffect, useContext, useCallback, useState } from "react"
import { useDispatch } from "store/setup/useDispatch"
import { createPubNubListener, fetchChannelData } from "pubnub-redux"
import { usePubNub } from "pubnub-react"
import { messageListener } from "./messageListener"
import { messageActionListener } from "./messageActionListener"
import { objectsListener } from "./objectsListener"
import { statusListener } from "./statusListener"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { getAppChannelIds } from "store/domain/pubnubStore/channels/appChannels"

export const useConfigurePubnub = () => {
  const pubnub = usePubNub()
  const dispatch = useDispatch()
  const [isInitialConfigurationFinished, setIsInitialConfigurationFinished] = useState(false)
  const [listenersAdded, setListenersAdded] = useState(false)
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)
  const { displaySearch } = useSelector(getEvent)
  const appChannelIds = useSelector(getAppChannelIds)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)

  const leaveApplication = useCallback(() => {
    // This is required to show the current user leave immediately rather than
    // waiting for the timeout period
    pubnub.unsubscribeAll()
  }, [pubnub])

  useEffect(() => {
    const innerFn = async () => {
      if (!isInitialConfigurationFinished) {
        try {
          const userUuid = currentPlatformUser.uuid

          // has to be before login
          pubnub.setAuthKey(currentPlatformUser.grantToken)

          // Set the Uuid of the current user
          pubnub.setUUID(userUuid)

          // this was in apiV1/auth0 version of app so not sure if needed, just left is as comment to remind use of this, if everything work we can delete it
          // dispatch(fetchUserData({ uuid: userUuid }))

          // Subscribe to the user's channel to receive events involving this user
          pubnub.subscribe({
            channels: [userUuid],
          })

          await Promise.all(
            currentPlatformUser.defaultPubnubChannels.map((currentChannel) =>
              dispatch(fetchChannelData({ channel: currentChannel })),
            ),
          )

          pubnub.subscribe({
            channels: currentPlatformUser.defaultPubnubChannels,
          })

          setIsInitialConfigurationFinished(true)
        } catch (err) {
          console.error(err)
        }
      }
    }

    innerFn()
  }, [airbrakeNotifier, dispatch, appChannelIds, pubnub, currentPlatformUser, isInitialConfigurationFinished])

  useEffect(() => {
    if (!listenersAdded && isInitialConfigurationFinished) {
      const pubnubListener = createPubNubListener(dispatch)

      pubnub.addListener({
        message: messageListener(pubnubListener.message),
        messageAction: messageActionListener(dispatch),
        objects: objectsListener(
          pubnubListener.objects,
          pubnub,
          dispatch,
          airbrakeNotifier,
          displaySearch,
          currentPlatformUser.uuid,
        ),
        signal: pubnubListener.signal,
        status: statusListener(pubnubListener.status, pubnub, airbrakeNotifier),
      })

      setListenersAdded(true)
    }
  }, [
    pubnub,
    dispatch,
    leaveApplication,
    airbrakeNotifier,
    listenersAdded,
    displaySearch,
    currentPlatformUser,
    isInitialConfigurationFinished,
  ])

  useEffect(() => {
    window.addEventListener("beforeunload", leaveApplication)
  }, [leaveApplication])

  return { isPubnubConfigured: listenersAdded && isInitialConfigurationFinished }
}
