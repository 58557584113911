import { Dialog, IconButton } from "@material-ui/core"
import { VideoIframe } from "features/baseBlocks/VideoIframe"
import React from "react"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import { SingleStageTemplate } from "../SingleStageTemplate"
import { eventAppDialogOpenSelector } from "store/domain/layout"
import { CONNECT_TEMPLATE_IFRAME_ID } from "constants/general"
import { useCloseEventAppDialog } from "../common/useCloseEventAppDialog"
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded"

const VideoIframeStyled = styled(VideoIframe)`
  height: 100%;
  background: #c38457;
`

const DialogCloseButton = styled(IconButton)`
  position: absolute;
  color: white;
  background: #00000081;
  top: -13px;
  right: -13px;
  padding: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
`

const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    height: 100%;
    overflow: visible;
    position: relative;
    color: white;
  }
`

export const ConnectTemplate: React.FC = () => {
  const eventAppDialogOpen = useSelector(eventAppDialogOpenSelector)
  const closeEventAppDialog = useCloseEventAppDialog()

  return (
    <>
      <VideoIframeStyled showConnectStage id={CONNECT_TEMPLATE_IFRAME_ID} />

      <DialogStyled open={eventAppDialogOpen} maxWidth="xl" fullWidth onClose={closeEventAppDialog}>
        <DialogCloseButton onClick={closeEventAppDialog}>
          <HighlightOffRoundedIcon fontSize="large" />
        </DialogCloseButton>

        <SingleStageTemplate />
      </DialogStyled>
    </>
  )
}
