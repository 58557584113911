import { DragObjectWithType } from "react-dnd"

export const dndItemTypes = {
  STICKER: "sticker",
}
export interface StickerItemType extends DragObjectWithType {
  src: string
}

export const stickerDimensions = {
  width: 100,
  height: 100,
}
