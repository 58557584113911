import React from "react"
import { TextMessage } from "runtypes/message"
import emojiRegex from "emoji-regex"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

const Wrapper = styled(Typography)<{ $emphasize: boolean }>`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.5;
  text-align: left;

  ${({ $emphasize }) => ($emphasize ? `font-size: 1.5rem;` : "")}
`

type TextMessageProps = {
  message: TextMessage
}

// make the message larger if there are only emoji and 3 or less emoji
const isEmphasized = (messageContent: string): boolean => {
  if (messageContent === undefined) {
    return false
  }
  const trimmed = messageContent.trim()
  if (trimmed.length <= 15) {
    const emoji = messageContent.match(emojiRegex())
    return emoji ? emoji.length <= 3 && emoji.join("") === trimmed : false
  } else {
    return false
  }
}

export const TextMessageDisplay: React.FC<TextMessageProps> = ({ message }) => (
  <Wrapper variant="body2" $emphasize={isEmphasized(message.text)}>
    {message.text}
  </Wrapper>
)
