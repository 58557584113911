import React, { useEffect, useState } from "react"
import { useApi } from "api/useApi"
import { VideoDaily } from "components/VideoDaily"
import styled from "styled-components"

const VideoDailyStyled = styled(VideoDaily)`
  border-radius: 4px;
  overflow: hidden;
`

type Props = { onLeftDanceFloor?: () => void }

export const DanceFloorDaily: React.FC<Props> = ({ onLeftDanceFloor }) => {
  const { api } = useApi()
  const [roomToken, setRoomToken] = useState<string | null>(null)
  const [roomName, setRoomName] = useState<string | null>(null)

  useEffect(() => {
    const innerFn = async () => {
      const { room, token } = await api.danceFloor.fetchDanceFloorDailyData()

      setRoomName(room)
      setRoomToken(token)
    }

    innerFn()
  }, [api])

  return (
    <>
      {roomToken && roomName && (
        <VideoDailyStyled token={roomToken} roomName={roomName} onLeftMeeting={onLeftDanceFloor} />
      )}
    </>
  )
}
