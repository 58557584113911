import React, { createContext } from "react"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import { useEffect, useState } from "react"

export const FingerprintHash = createContext<string>(
  "dummyValueWhichWillNeverBeUsedBecauseProviderWrapsAlmostEntireApp",
)

export const FingerprintHashContextProvider: React.FC = ({ children }) => {
  const [fingerprintHash, setFingerprintHash] = useState<string | null>(null)

  useEffect(() => {
    const innerFn = async () => {
      const fingerprintInstance = await FingerprintJS.load()

      const { visitorId } = await fingerprintInstance.get()

      setFingerprintHash(visitorId)
    }

    innerFn()
  }, [])

  if (!fingerprintHash) {
    return null
  }
  return <FingerprintHash.Provider value={fingerprintHash}>{children}</FingerprintHash.Provider>
}
