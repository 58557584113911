import React from "react"
import styled from "styled-components"
import { useAppLogout } from "store/domain/authentication/useAppLogout"

const NotLoggedInInfo = styled.div`
  background: white;
  padding: 5px;
  border: 5px solid lightgray;
`

type Props = { loginCompleted: boolean }

export const AuthLogout: React.FC<Props> = ({ loginCompleted }) => {
  const appLogout = useAppLogout()

  return (
    <>
      {loginCompleted ? (
        <button onClick={appLogout}>Auth logout</button>
      ) : (
        <NotLoggedInInfo>You are not logged in.</NotLoggedInInfo>
      )}
    </>
  )
}
