import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "store/setup/storeTypes"

interface SetupRoomsState {
  isDialogOpen: boolean
}

const initialState: SetupRoomsState = {
  isDialogOpen: false,
}
export const setupRoomsSlice = createSlice({
  name: "setupRooms",
  initialState,
  reducers: {
    openSetupRoomsDialog: (state) => {
      state.isDialogOpen = true
    },
    closeSetupRoomsDialog: (state) => {
      state.isDialogOpen = false
    },
  },
})

export const { openSetupRoomsDialog, closeSetupRoomsDialog } = setupRoomsSlice.actions

export const isSetupRoomsDialogOpenSelector = (state: AppState): boolean => state[setupRoomsSlice.name].isDialogOpen
