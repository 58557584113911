import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "store/setup/storeTypes"

type OpenChatRoomsState = string[]

const initialState: OpenChatRoomsState = []

const OPEN_CHAT_ROOMS_MAX_NUMBER = 10

export const openChatRoomsSlice = createSlice({
  name: "openChatRooms",
  initialState: initialState as OpenChatRoomsState,
  reducers: {
    addOpenChatRoom: (state, action: PayloadAction<string>) => {
      state = state.filter((currentChannelId) => currentChannelId !== action.payload)

      if (state.length === OPEN_CHAT_ROOMS_MAX_NUMBER) {
        state.splice(0, 1)
      }

      return [...state, action.payload]
    },
    removeOpenChatRoom: (state, action: PayloadAction<string>) =>
      state.filter((currentChannelId) => currentChannelId !== action.payload),
  },
})

export const { addOpenChatRoom, removeOpenChatRoom } = openChatRoomsSlice.actions

export const openChatRoomsSelector = (state: AppState) => state[openChatRoomsSlice.name]
