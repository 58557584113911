export const routerPaths = {
  login: { path: "/:eventSlug/login", assemblePath: (eventSlug: string) => `/${eventSlug}/login/` },
  authLogout: {
    path: "/:eventSlug/auth-logout",
    doesMatch: (eventSlug: string, pathname: string) => pathname === `/${eventSlug}/auth-logout`,
  },
  stage: {
    path: "/:eventSlug/stage/:stageId",
    assemblePath: (eventSlug: string, stageId: string) => `/${eventSlug}/stage/${stageId}`,
  },
  photobooth: {
    path: "/:eventSlug/photo_booth",
    doesMatch: (eventSlug: string, pathname: string) => pathname === `/${eventSlug}/photo_booth`,
  },
  videoRoom: {
    path: "/:eventSlug/video-room/:roomName",
    assemblePath: (eventSlug: string, roomName: string) => `/${eventSlug}/video-room/${roomName}`,
    doesMatch: (eventSlug: string, pathname: string) => pathname.startsWith(`/${eventSlug}/video-room`),
  },
  expobooth: {
    path: "/:eventSlug/booth/:expoboothUuid",
    assemblePath: (eventSlug: string, expoboothUuid: string) => `/${eventSlug}/booth/${expoboothUuid}`,
    doesMatch: (eventSlug: string, pathname: string) => pathname.startsWith(`/${eventSlug}/booth`),
  },
}
