import React from "react"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"
import { Grid, FormControl, TextField, InputAdornment } from "@material-ui/core"
import styled from "styled-components"
import { connectSearchBox } from "react-instantsearch-dom"
import { SearchBoxProvided } from "react-instantsearch-core"

const SearchBoxGrid = styled(Grid)`
  width: 100%;
`

const TextFieldStyled = styled(TextField)`
  color: ${({ theme }) => theme.palette.primary.main};
  .MuiFilledInput-root {
    background-color: white;
  }
`

type Props = {
  label: string
  variantFilled?: boolean
  smallSize?: boolean
  autoFocusTurnedOn?: boolean
}

const AlgoliaSearchInputRaw: React.FC<SearchBoxProvided & Props> = ({
  currentRefinement,
  refine,
  label,
  variantFilled = false,
  smallSize = false,
  autoFocusTurnedOn = false,
}) => {
  return (
    <SearchBoxGrid container direction="row">
      <FormControl fullWidth>
        <TextFieldStyled
          hiddenLabel
          autoFocus={autoFocusTurnedOn}
          value={currentRefinement}
          placeholder={label}
          onChange={(e) => refine(e.target.value)}
          variant={variantFilled ? "filled" : "standard"}
          {...(smallSize && { size: "small" })}
          color="primary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </SearchBoxGrid>
  )
}

export const AlgoliaSearchInput = connectSearchBox(AlgoliaSearchInputRaw)
