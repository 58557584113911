import React from "react"
import { CircularProgress } from "@material-ui/core"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CircularProgressStyled = styled(CircularProgress)`
  color: #ffc400;
`

export const FullPageCircularProgress: React.FC<{ isSpinning: boolean }> = ({ isSpinning }) => (
  <>{isSpinning && <Wrapper>{isSpinning && <CircularProgressStyled size={60} />}</Wrapper>}</>
)
