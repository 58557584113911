import React, { useEffect, useState } from "react"
import { useDispatch } from "store/setup/useDispatch"
import { setEvent } from "store/domain/event"
import { EventType } from "api/apiFunctions/event"
import { EventUser } from "api/apiFunctions/authentication"
import { setEventUser } from "store/domain/authentication"

type Props = { event: EventType; eventUser: EventUser }

export const SetEventAndUserInStore: React.FC<Props> = ({ event, eventUser, children }) => {
  const [isDataSet, setIsDataSet] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const asyncFn = async (): Promise<void> => {
      dispatch(setEvent(event))

      dispatch(setEventUser(eventUser))

      setIsDataSet(true)
    }

    asyncFn()
  }, [dispatch, event, eventUser])

  if (!isDataSet) {
    return null
  }

  return <>{children}</>
}
