import { useEffect } from "react"
import { useDispatch } from "store/setup/useDispatch"
import { setMenu } from "store/domain/layout"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"

export const useSetNavMenuOpenness = () => {
  const event = useSelector(getEvent)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!event.hideNavMenu) {
      dispatch(setMenu(!event.navMenuClosed))
    }
  }, [event, dispatch])
}
