import { Button, Box } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const AddPersonButton = styled(Button)`
  font-weight: bold;
`

export const AddUserButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Box my={1}>
      <AddPersonButton color="secondary" onClick={onClick} variant="contained">
        ADD PERSON
      </AddPersonButton>
    </Box>
  )
}
