import React, { useState, useEffect, useRef } from "react"
import { Wrapper, Search, Marker, Results, Column, Group, GifWrapper, Scroll } from "./GifPicker.style"
import { useSelector } from "store/setup/useSelector"
import { PlaceholderMessage, placeholderMessages } from "./PlaceholderMessage"
import { IGif } from "runtypes/giphy"
import { Gif, GifSize } from "../../../Gif"
import { useVisibility } from "independent/hooks/useVisibility"
import { useDebounce } from "independent/hooks/useDebounce"
import { getEvent } from "store/domain/event"
// separate an array into count sub-arrays
const splitColumns = <T,>(page: T[], count: number): T[][] => {
  return page.reduce((columns, item, index) => {
    columns[index % count].push(item)
    return columns
  }, new Array(count).fill(0).map(() => []) as T[][])
}

const PAGE_SIZE = 24
// make a request to the giphy search api
const search = async (query: string, page: number, giphyApiKey: string): Promise<IGif[]> => {
  try {
    const response = await fetch(
      `https://api.giphy.com/v1/gifs/search?api_key=${giphyApiKey}&q=${encodeURIComponent(
        query,
      )}&limit=${PAGE_SIZE}&offset=${page * PAGE_SIZE}&rating=G&lang=en`,
    )

    const results = (await response.json()) as { data: IGif[] }

    results.data.forEach((currentGif) => {
      currentGif.images.fixed_width.width = Number(currentGif.images.fixed_width.width)
      currentGif.images.fixed_width.height = Number(currentGif.images.fixed_width.height)

      currentGif.images.original.width = Number(currentGif.images.original.width)
      currentGif.images.original.height = Number(currentGif.images.original.height)
    })

    return results.data
  } catch {
    // fail softly
    return []
  }
}

type GifsType = IGif[][] | null

// hook to fetch gifs with paging and waiting status (wether page should be allowed to increment)
const useGifs = (query: string, page: number): [boolean, IGif[][] | null] => {
  const [gifs, setGifs] = useState<GifsType>(null)
  const [waiting, setWaiting] = useState(false)
  const giphyApiKey = useSelector(getEvent).giphyApiKey

  // reset when the query changes
  useEffect(() => {
    setGifs(null)
  }, [query])

  useEffect(() => {
    let mounted = true
    let scheduled: number
    ;(async (): Promise<void> => {
      // query cannot be empty
      if (query.length < 1) {
        return
      }
      setWaiting(true)
      const data = await search(query, page, giphyApiKey)
      // add the retrieved gifs to the results
      if (mounted) {
        // split the page into two columns and add
        setGifs((gifs: GifsType) => (gifs && page > 0 ? gifs : []).concat(splitColumns<IGif>(data, 2)))
        // add a buffer on the end before marking as loaded
        scheduled = window.setTimeout(() => {
          mounted && setWaiting(false)
        }, 500)
      }
    })()

    // prevent state updates against unmounted component
    return (): void => {
      mounted = false
      if (scheduled) {
        clearTimeout(scheduled)
      }
    }
  }, [page, query, giphyApiKey])

  return [waiting, gifs]
}

// chunking the results allows for minimal re-renders as gifs are added

// all the gifs from a request that should go into a single column (i.e. half the results)
const ResultsGroup = React.memo(
  ({
    results,
    container,
    id,
    onSelected,
  }: {
    results: IGif[]
    container: React.RefObject<HTMLDivElement>
    id: number
    onSelected: onClickHandler
  }) => {
    return (
      <Group>
        {results.map((gif, index) => (
          <GifWrapper key={`gif-results.group.${id}.gif.${index}`}>
            <Gif gif={gif} size={GifSize.Preview} onClick={onSelected} container={container} />
          </GifWrapper>
        ))}
      </Group>
    )
  },
)
ResultsGroup.displayName = "ResultsGroup"

type Props = {
  results: IGif[][]
  container: React.RefObject<HTMLDivElement>
  id: number
  markerRef: React.RefObject<HTMLDivElement>
  onSelected: onClickHandler
}

// all of the groups that go in the same column
const ResultsColumn: React.FC<Props> = ({ results, container, id, markerRef, onSelected }) => {
  return (
    <Column>
      {results.map((column, index) => (
        <ResultsGroup
          results={column}
          container={container}
          id={id + 2 * index}
          key={`gif-results.column.${id}.group.${index}`}
          onSelected={onSelected}
        />
      ))}
      <Marker ref={markerRef} />
    </Column>
  )
}

const SearchResults = React.memo(
  ({
    results,
    container,
    refs,
    onSelected,
  }: {
    results: IGif[][]
    container: React.RefObject<HTMLDivElement>
    refs: React.RefObject<HTMLDivElement>[]
    onSelected: onClickHandler
  }) => {
    const columns = splitColumns<IGif[]>(results, 2)
    return (
      <>
        {columns.map((column, index) => (
          <ResultsColumn
            results={column}
            container={container}
            id={index}
            key={`gif-results.column.${index}`}
            markerRef={refs[index]}
            onSelected={onSelected}
          />
        ))}
      </>
    )
  },
)
SearchResults.displayName = "SearchResults"

export type OnGifSelect = (gif: IGif, query: string) => void

type onClickHandler = (gif: IGif) => void

interface GifPickerOptions {
  onSelected: OnGifSelect
}

// gif picker with infinite loading and search
export const GifPicker: React.FC<GifPickerOptions> = ({ onSelected }) => {
  const [query, setQuery] = useState("")
  // prevent excessive fetching
  const debouncedQuery = useDebounce(query, 400)
  const [page, setPage] = useState(0)
  const [waiting, results] = useGifs(debouncedQuery, page)
  // refs for lazy loading
  const picker = useRef<HTMLDivElement>(null)
  // refs and intersection observers for infinite scroll
  const scrollBottomLeft = useRef<HTMLDivElement>(null)
  const scrollBottomRight = useRef<HTMLDivElement>(null)
  const atBottomRight = useVisibility(scrollBottomLeft, picker, 0, "0px 0px 0px 100px")
  const atBottomLeft = useVisibility(scrollBottomRight, picker, 0, "0px 0px 0px 100px")

  const curried = (gif: IGif): void => onSelected(gif, query)

  // if the search changes, reset the page
  useEffect(() => {
    setPage(0)
  }, [query])

  // infinite scroll
  useEffect(() => {
    // not waiting, at the bottom, and the last page of results was full
    if (
      !waiting &&
      (atBottomLeft || atBottomRight) &&
      results &&
      results.length > 0 &&
      results[results.length - 1].length >= PAGE_SIZE / 2
    ) {
      // increase the page (new gifs will be fetched)
      setPage((page) => page + 1)
    }
  }, [waiting, atBottomLeft, atBottomRight, results])

  return (
    <Wrapper ref={picker}>
      <Search
        placeholder="Search GIPHY"
        value={query}
        onChange={(e): void => {
          setQuery(e.target.value)
        }}
      />
      <Scroll>
        {!query && <PlaceholderMessage message={placeholderMessages.empty} />}
        {query && results && results[0].length === 0 && <PlaceholderMessage message={placeholderMessages.notFound} />}
        <Results>
          <SearchResults
            results={results || [[], []]}
            container={picker}
            refs={[scrollBottomLeft, scrollBottomRight]}
            onSelected={curried}
          />
        </Results>
      </Scroll>
    </Wrapper>
  )
}
