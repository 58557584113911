import { createSelector } from "@reduxjs/toolkit"
import { STAGE_DISPLAY_TYPES } from "constants/general"
import { getStageChannels } from "../stageChannels/stageChannels"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { getEvent } from "store/domain/event"
import { useTheme } from "@material-ui/core"

export const getStageChannelsWithMessages = createSelector([getStageChannels], (stageChannels) =>
  stageChannels.filter(({ custom: { hide_messages } }) => !hide_messages),
)

export const getConnectStageChannel = createSelector([getStageChannels], (stageChannels) =>
  stageChannels.find((item) => item.custom.display_type === STAGE_DISPLAY_TYPES.connect),
)

export const getMingleStageChannel = createSelector([getStageChannels], (stageChannels) =>
  stageChannels.find((item) => item.custom.display_type === STAGE_DISPLAY_TYPES.mingle),
)

const randomValue = Math.random().toString()

export const useConnectStageChannelDescription = () => {
  const connectChannel = useSelector(getConnectStageChannel)
  const { authKey, uuid } = useSelector(getCurrentPlatformUser)
  const { connectEventId, connectMapId } = useSelector(getEvent)

  if (connectChannel && connectEventId && connectMapId) {
    const url = new URL(connectChannel.description)

    url.searchParams.set("auth_token", authKey)
    url.searchParams.set("event_id", connectEventId)
    url.searchParams.set("map_id", connectMapId)
    url.searchParams.set("user_id", uuid)
    // FIXME: drop after setting correct CDN settings for index.html
    url.searchParams.set("no_cache", randomValue)

    return url.toString()
  } else {
    return null
  }
}

export const useMingleStageChannelDescription = () => {
  const mingleChannel = useSelector(getMingleStageChannel)

  const { attendanceUuid } = useSelector(getCurrentPlatformUser)
  const theme = useTheme()

  if (mingleChannel) {
    const url = new URL(mingleChannel.description)

    url.searchParams.set("url_api_token", attendanceUuid)
    url.searchParams.set("brand_color", theme.custom.colors.currentStage.mingle.brandColor)
    url.searchParams.set("primary_color", theme.palette.primary.main)
    url.searchParams.set("secondary_color", theme.palette.secondary.main)
    url.searchParams.set("icon_main_color", theme.custom.colors.currentStage.mingle.iconMainColor)

    return url.toString()
  } else {
    return null
  }
}
