import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "store/setup/storeTypes"

type LayoutState = {
  menu: boolean
  stageMessages: boolean
  leaderboard: boolean
  connectTemplate: {
    eventAppDialogOpen: boolean
  }
}

const initialState: LayoutState = {
  menu: false,
  stageMessages: false,
  leaderboard: false,
  connectTemplate: {
    eventAppDialogOpen: false,
  },
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<boolean>) => {
      state.menu = action.payload
    },
    hideMenu: (state) => {
      state.menu = false
    },
    toggleMenu: (state) => {
      state.menu = !state.menu
    },
    setStageMessages: (state, action: PayloadAction<boolean>) => {
      state.stageMessages = action.payload
    },
    toggleStageMessages: (state) => {
      state.stageMessages = !state.stageMessages
    },
    setLeaderboard: (state, action: PayloadAction<boolean>) => {
      state.leaderboard = action.payload
    },
    setEventAppDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.connectTemplate.eventAppDialogOpen = action.payload
    },
  },
})

export const {
  setMenu,
  hideMenu,
  toggleMenu,
  setStageMessages,
  toggleStageMessages,
  setLeaderboard,
  setEventAppDialogOpen,
} = layoutSlice.actions

export const getLayout = (state: AppState) => state[layoutSlice.name]

export const eventAppDialogOpenSelector = (state: AppState) => getLayout(state).connectTemplate.eventAppDialogOpen
