import { useState, useEffect, useCallback } from "react"
import moment from "moment"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"

type TimeLeft = {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
  totalMinutes?: number
} | null

export const useTimeBeforeStart = (): TimeLeft => {
  const [days, setDays] = useState<number | undefined>(undefined)
  const [hours, setHours] = useState<number | undefined>(undefined)
  const [minutes, setMinutes] = useState<number | undefined>(undefined)
  const [seconds, setSeconds] = useState<number | undefined>(undefined)
  const [totalMinutes, setTotalMinutes] = useState<number | undefined>(undefined)
  const [hasEventStarted, setHasEventStarted] = useState(false)
  const event = useSelector(getEvent)

  const calculateTimeLeft = useCallback(() => {
    const nowDate = moment()

    const startTime = moment(event.startTime)

    const hasEventStarted = nowDate.isAfter(startTime)

    setHasEventStarted(hasEventStarted)

    if (hasEventStarted) {
      return
    }

    const diffDuration = moment.duration(startTime.diff(nowDate))

    const days = Math.floor(diffDuration.asDays())

    const hrs = Math.floor(diffDuration.hours())

    const mins = Math.floor(diffDuration.minutes())

    const seconds = Math.floor(diffDuration.seconds())

    const asMins = Math.floor(diffDuration.asMinutes())

    setDays(days)
    setHours(hrs)
    setMinutes(mins)
    setSeconds(seconds)
    setTotalMinutes(asMins)
  }, [event])

  useEffect(() => {
    calculateTimeLeft()

    if (!hasEventStarted) {
      const timer = setInterval(calculateTimeLeft, 1000)

      return (): void => {
        clearInterval(timer)
      }
    }
  }, [hasEventStarted, calculateTimeLeft])

  return !hasEventStarted ? { days, minutes, hours, seconds, totalMinutes } : null
}
