import React from "react"

export const MicrosoftIcon: React.FC = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.3916" width="51" height="51" rx="25.5" fill="white" />
      <g clipPath="url(#clip0_2833_179037)">
        <path d="M25.4903 26.3819H16V16.8916H25.4903V26.3819Z" fill="#F1511B" />
        <path d="M35.9687 26.3819H26.4785V16.8916H35.9687V26.3819Z" fill="#80CC28" />
        <path d="M25.49 36.864H16V27.3738H25.49V36.864Z" fill="#00ADEF" />
        <path d="M35.9687 36.864H26.4785V27.3738H35.9687V36.864Z" fill="#FBBC09" />
      </g>
      <rect x="0.5" y="1.3916" width="51" height="51" rx="25.5" stroke="#C2C8D0" />
      <defs>
        <clipPath id="clip0_2833_179037">
          <rect width="20" height="20" fill="white" transform="translate(16 16.8916)" />
        </clipPath>
      </defs>
    </svg>
  )
}
