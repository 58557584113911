import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import { AppTemplate, ContentToShow } from "features/AppTemplate"

import { PhotoboothRoute } from "./routes/PhotoboothRoute"
import { VideoRoom } from "./routes/VideoRoom"
import { Login } from "./routes/Login"
import { AuthLogout } from "./routes/AuthLogout"
import { EmptyRoute } from "./routes/EmptyRoute"

import { RouterWrapper } from "./RouterWrapper"
import { RoutesLoginProtection } from "./RoutesLoginProtection"
import { RoutesDuringEventProtection } from "./RoutesDuringEventProtection"
import { EstablishedRoute } from "./EstablishedRoute"
import { routerPaths } from "./routerPaths"
import { useAuthenticationUtils } from "features/baseBlocks/LoginWidget/useAuthenticationUtils"
import useEventSlug from "hooks/useEventSlug"

export const Router: React.FC = () => {
  const authenticationUtils = useAuthenticationUtils()
  const eventSlug = useEventSlug()

  return (
    <RouterWrapper>
      <Switch>
        <Route path={routerPaths.login.path} render={() => <Login authenticationUtils={authenticationUtils} />} />

        <RoutesLoginProtection authenticationUtils={authenticationUtils}>
          <EstablishedRoute path="/:eventSlug" exact>
            <EmptyRoute />
          </EstablishedRoute>

          <EstablishedRoute path={routerPaths.authLogout.path}>
            <AuthLogout loginCompleted={authenticationUtils.loginCompleted} />
          </EstablishedRoute>

          <RoutesDuringEventProtection>
            <EstablishedRoute path={routerPaths.stage.path}>
              <AppTemplate contentToShow={ContentToShow.CurrentStage} />
            </EstablishedRoute>

            <EstablishedRoute path={routerPaths.photobooth.path}>
              <PhotoboothRoute />
            </EstablishedRoute>

            <EstablishedRoute path={routerPaths.videoRoom.path}>
              <VideoRoom />
            </EstablishedRoute>

            <EstablishedRoute path={routerPaths.expobooth.path}>
              <AppTemplate contentToShow={ContentToShow.Expobooth} />
            </EstablishedRoute>

            <Route render={() => <Redirect to={`/${eventSlug}`} />} />
          </RoutesDuringEventProtection>
        </RoutesLoginProtection>
      </Switch>
    </RouterWrapper>
  )
}
