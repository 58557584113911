import React from "react"
import styled from "styled-components"
import { nonDraggable } from "../PrepareAndViewPhotos.style"
import { Carousel } from "./Carousel"

export const CarouselWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }): string => theme.spacing(0, 3)};
`

export const CarouselStyled = styled(Carousel)`` as typeof Carousel

export const CarouselItem = styled.div<{ cursorPointer?: boolean }>`
  ${({ cursorPointer }) => (cursorPointer ? "cursor: pointer;" : "")};
`

const StickerImageRaw = styled.img`
  width: 90px;
  height: 90px;
`

export const StickerDragPreviewImg = styled(StickerImageRaw)`
  z-index: ${({ theme }) => theme.zIndex.tooltip + 1};
  opacity: 0.7;
`

export const CarouselImageWrapper = styled.div<{ isSelected: boolean }>`
  opacity: 0.7;
  border: 4px solid rgb(255 255 255 / 0);
  border-radius: 4px;

  ${({ isSelected }) =>
    isSelected &&
    `
    border: 4px solid #ffffffb8;
    opacity: 1;
  `}
`

export const CarouselImage = styled(StickerImageRaw)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  ${nonDraggable}
`

export const FrameCarouselImage = styled(({ backgroundSrc, ...restProps }) => <CarouselImage {...restProps} />)<{
  isSelected: boolean
  backgroundSrc: string
}>`
  background-image: url("${({ backgroundSrc }): string => backgroundSrc}");
  background-size: contain;
`
