import Evaporate from "evaporate"
import sparkMD5 from "spark-md5"
import { useSelector } from "store/setup/useSelector"
import sha256 from "js-sha256"
import { EventType } from "api/apiFunctions/event"
import { getEvent } from "store/domain/event"
import { useEffect, useRef } from "react"
import { useForceUpdate } from "independent/utils/useForceUpdate"

const createFileUploader = (event: EventType) =>
  Evaporate.create({
    aws_url: event.awsUrl,
    logging: false,
    cloudfront: false,
    s3Acceleration: false,
    aws_key: event.uploadsKey,
    bucket: event.uploadsBucket as string,
    awsRegion: event.awsRegion,
    signerUrl: event.awsSignerUrl,
    awsSignatureVersion: "4",
    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
    // @ts-ignore
    cryptoHexEncodedHash256: sha256,
  })

type FileUploader = Promise<Evaporate> | null

export const useFileUploader = (): FileUploader => {
  const event = useSelector(getEvent)
  const fileUploaderPromise = useRef<FileUploader>(null)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    fileUploaderPromise.current = createFileUploader(event)
    forceUpdate()
  }, [event, forceUpdate])

  return fileUploaderPromise.current
}
