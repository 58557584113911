export const apiUrls = {
  baseApiUrl: `${process.env.REACT_APP_BACKEND_API}/api/v2`,
  workspace: "workspace",
  eventSetup: (eventSlug: string) => `event_setup?slug=${eventSlug}`,
  authentication: {
    signInWithEmail: "auth/sign_in",
    fetchOtcAgain: "auth/request_code",
    loginWithOtc: "auth/login",
    recoverAccess: "auth/recover",
    user: "event/user",
    putPlatformUser: "platform_user",
    logout: "auth/logout",
  },
  event: "event?include=external_links",
  user: "users",
  photobooth: {
    removeImageBackground: "background_photos",
    publishImageToGallery: "gallery_photos",
    photoGallery: {
      list: (attendanceUuid: string) => `gallery_photos/${attendanceUuid}`,
    },
    groupPhoto: {
      list: (attendanceUuid: string) => `group_photos/${attendanceUuid}`,
      create: "group_photos",
    },
  },
  videoLibrary: {
    videos: "videos",
  },
  chatRoom: "chat_room",
  videoRoom: "daily_room",
  expobooth: {
    single: (expoboothUuid: string) => `booth/${expoboothUuid}`,
    shareYourDetails: "booth_lead",
    videoCallToken: "video_booth",
  },
  leaderboardMeta: "leaderboards?include=badges",
  leaderboardPersonal: (attendanceUuid: string) => `leaderboards/${attendanceUuid}`,
  breakout: "breakout",
  mingle: { roomNameAndToken: "mingles", attendeesCount: "mingles/pending_count" },
  danceFloor: { dailyData: "dancefloors" },
  webinar: { dailyData: "webinars" },
}
