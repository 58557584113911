import { DAILY_URL } from "constants/general"

const isDescriptionVimeo = (description: string): boolean => description.includes("https://player.vimeo.com/video/")
const isDescriptionVimeoEvent = (description: string): boolean => description.includes("https://vimeo.com/event/")
const isDescriptionYoutube = (description: string): boolean => description.includes("https://www.youtube.com/embed/")
const isDescriptionMicrosoftStream = (description: string): boolean =>
  description.includes("https://web.microsoftstream.com")
const isDescriptionTestingGallery = (description: string): boolean =>
  description.includes("https://gallery.fromdublin.com")
const isDescriptionCloudflare = (description: string): boolean =>
  description.includes("https://watch.videodelivery.net")
const isDescriptionMingle = (description: string): boolean => description.includes("mingle")
const isDescriptionVirtualHangout = (description: string): boolean => description.includes("https://app.teamflowhq.com")
const isDescriptionFormstack = (description: string): boolean => description.includes("https://fuelhq.formstack.com/")
const isDescriptionDacast = (description: string): boolean => description.includes("https://iframe.dacast.com/")
const isDescriptionSchedule = (description: string): boolean => description.includes("/schedule/")
const isDescriptionStreamingTest = (description: string): boolean => description.includes("streamingasaservice.net")
const isDescriptionPreviewGamification = (description: string): boolean => description.includes(".xeopreview.com/xeo/")
const isDescriptionDailyVideo = (description: string): boolean => description.includes(DAILY_URL)
const isDescriptionGamification = (description: string): boolean => description.includes(".xeoclient.com/xeo/")
const isDescriptionS3AssetsUrl = (description: string): boolean => description.includes("https://assets.vstage.com")
const isDescriptionConnect = (description: string): boolean => description.includes("https://connect")

export const isValidStageChannelDescription = (description: string): boolean => {
  return (
    !!description &&
    (isDescriptionVimeo(description) ||
      isDescriptionVimeoEvent(description) ||
      isDescriptionYoutube(description) ||
      isDescriptionMicrosoftStream(description) ||
      isDescriptionTestingGallery(description) ||
      isDescriptionMingle(description) ||
      isDescriptionCloudflare(description) ||
      isDescriptionFormstack(description) ||
      isDescriptionStreamingTest(description) ||
      isDescriptionVirtualHangout(description) ||
      isDescriptionDailyVideo(description) ||
      isDescriptionDacast(description) ||
      isDescriptionPreviewGamification(description) ||
      isDescriptionGamification(description) ||
      isDescriptionSchedule(description) ||
      isDescriptionS3AssetsUrl(description) ||
      isDescriptionConnect(description))
  )
}
