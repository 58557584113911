import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { useDispatch } from "store/setup/useDispatch"
import { openSetupRoomsDialog } from "store/domain/chatAndVideoRoom"

export const useHandleOpeningOfSetupRoomsDialog = () => {
  const { algoliaSearchKey, algoliaApplicationId, attendeeIndexName } = useSelector(getEvent)
  const dispatch = useDispatch()

  return algoliaSearchKey && algoliaApplicationId && attendeeIndexName
    ? {
        handleOpeningOfSetupRoomsDialog: () => {
          dispatch(openSetupRoomsDialog())
        },
      }
    : {}
}
