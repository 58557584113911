import "emoji-mart/css/emoji-mart.css"
import React, { useState, useCallback, useRef } from "react"
import { useClickOutside } from "independent/hooks/useClickOutside"
import { Picker, EmojiData } from "emoji-mart"
import styled from "styled-components"
import { useTheme } from "@material-ui/core"
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon"

const Dialog = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
`

const InsertEmoticonIconStyled = styled(InsertEmoticonIcon)`
  cursor: pointer;
  vertical-align: top;

  && {
    margin-right: 10px;
  }
`

interface EmojiInputProps {
  onSelection(emoji: string): void
}

export const EmojiInput: React.FC<EmojiInputProps> = ({ onSelection }) => {
  const [showPicker, setPickerState] = useState(false)
  const picker = useRef<HTMLDivElement>(null)

  const theme = useTheme()

  const dismissPicker = useCallback(() => {
    setPickerState(false)
  }, [setPickerState])

  useClickOutside([picker], dismissPicker)

  const togglePicker = (): void => {
    setPickerState(!showPicker)
  }

  const addEmoji = (emoji: EmojiData): void => {
    if ("native" in emoji) {
      onSelection(emoji.native)
      dismissPicker()
    }
  }

  return (
    <div ref={picker}>
      <Dialog>
        {showPicker && (
          <Picker emoji="" title="" native={true} onSelect={addEmoji} color={theme.palette.primary.main} />
        )}
      </Dialog>

      <InsertEmoticonIconStyled onClick={togglePicker} />
    </div>
  )
}
