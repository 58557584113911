import { useState, useEffect } from "react"

export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const update = window.setTimeout(() => {
      setDebounced(value)
    }, delay)
    return (): void => {
      clearTimeout(update)
    }
  }, [value, delay])

  return debounced
}
