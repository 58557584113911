import React, { useState } from "react"
import { getEvent } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"
import { AlgoliaPagination } from "components/algolia/AlgoliaPagination"
import { SearchedBreakouts } from "./SearchedBreakouts"
import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import { AlgoliaSearchInput } from "components/algolia/AlgoliaSearchInput"
import { AlgoliaInstantSearch } from "components/algolia/AlgoliaInstantSearch"

const OuterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  height: fit-content;
  min-height: 100%;
  overflow: auto;
`

const InnerWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1.2)}px;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  max-width: 1300px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled(Typography)`
  font-weight: bold;
`

const PaginationWrapper = styled(Box)`
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const BreakoutSearch: React.FC = () => {
  const { breakoutIndexName } = useSelector(getEvent)
  const [isSearchInProgress, setIsSearchInProgress] = useState(false)

  return (
    <OuterWrapper>
      <InnerWrapper>
        <AlgoliaInstantSearch indexName={breakoutIndexName} setIsSearchInProgress={setIsSearchInProgress}>
          <Box mb={2}>
            <Header color="primary" variant="h6">
              Rooms
            </Header>
          </Box>

          <Box mb={4}>
            <AlgoliaSearchInput variantFilled label="SEARCH FOR ROOM" autoFocusTurnedOn />
          </Box>

          <Box mb={6}>
            <SearchedBreakouts isSearchInProgress={isSearchInProgress} />
          </Box>

          <PaginationWrapper>
            <AlgoliaPagination />
          </PaginationWrapper>
        </AlgoliaInstantSearch>
      </InnerWrapper>
    </OuterWrapper>
  )
}
