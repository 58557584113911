import React from "react"
import { CarouselWrapper, CarouselStyled } from "../shared.style"
import { stickerSrcs } from "../carouselAssetSrcs"
import { StickerItem } from "./StickerItem"
import { createGlobalStyle } from "styled-components"
import { StickerDragPreview } from "./StickerDragPreview"

/*
For some reason react-dnd does not allow item to be dropped if it's in modal
(in this case both drop item and container is in modal, no info on other cases) and there is iframe right underneath where u are dropping,
so since stickers use react-dnd then if sticker is rendered then there is a global style which makes every iframe width zero, which makes
it impossible to drop sticker on top of iframe. This is a hack solution.
*/
const IFrameReactDndConflictResolution = createGlobalStyle`
  iframe {
    width: 0 !important;
  }
`

export const DragSticker: React.FC = () => {
  return (
    <CarouselWrapper>
      <IFrameReactDndConflictResolution />

      <StickerDragPreview />

      <CarouselStyled itemList={stickerSrcs} ItemComponent={({ item }) => <StickerItem key={item} src={item} />} />
    </CarouselWrapper>
  )
}
