import React, { useEffect, useState } from "react"
import { RoomUser } from ".."
import { SetupChatButton } from "./SetupChatButton"
import { SetupVideoButton } from "./SetupVideoButton"
import { ChipsPanel } from "components/ChipsPanel"
import { Box } from "@material-ui/core"
import { addOpenChatRoom } from "store/domain/openChatRooms"
import { useDispatch } from "store/setup/useDispatch"
import { useSelector } from "store/setup/useSelector"
import { getRoomChannels } from "store/domain/pubnubStore/channels/roomChannels"
import { useApi } from "api/useApi"

type Props = {
  closeDialog: () => void
  addedUsers: RoomUser[]
  removeRoomUser: (userIdArg: string) => void
}

export const AddUsersPanel: React.FC<Props> = ({ closeDialog, addedUsers, removeRoomUser }) => {
  const [setupLoading, setSetupLoading] = useState(false)
  const roomChannels = useSelector(getRoomChannels)
  const [newlySetupChannelId, setNewlySetupChannelId] = useState<string | null>(null)
  const dispatch = useDispatch()
  const { api } = useApi()

  useEffect(() => {
    if (newlySetupChannelId) {
      const roomChannel = roomChannels.find(({ id }) => id === newlySetupChannelId)

      if (roomChannel) {
        dispatch(addOpenChatRoom(roomChannel.id))

        setNewlySetupChannelId(null)
      }
    }
  }, [newlySetupChannelId, roomChannels, dispatch])

  if (addedUsers.length <= 0) {
    return null
  }

  const userUuids = addedUsers.map((user) => user.guest_id)

  const onClickSetupChat = async () => {
    try {
      setSetupLoading(true)

      const channelId = await api.chatAndVideoRoom.setupChatRoom({ attendanceUuids: userUuids })

      setNewlySetupChannelId(channelId)

      closeDialog()
    } catch (e) {
      console.error(e)
    } finally {
      setSetupLoading(false)
    }
  }

  const onClickSetupVideo = async () => {
    try {
      setSetupLoading(true)

      await api.chatAndVideoRoom.setupDailyRoom({ attendanceUuids: userUuids })

      closeDialog()
    } catch (e) {
      console.error(e)
    } finally {
      setSetupLoading(false)
    }
  }

  return (
    <>
      <ChipsPanel
        addedChips={addedUsers.map(({ guest_id, firstName, lastName }) => ({
          id: guest_id,
          label: `${firstName} ${lastName}`,
        }))}
        removeChip={removeRoomUser}
        reverseXSLayout
      >
        <Box display="flex" height="fit-content">
          <Box mr={1.5} width="100%">
            <SetupVideoButton loading={setupLoading} addedUsers={addedUsers} onClickSetupVideo={onClickSetupVideo} />
          </Box>

          <SetupChatButton loading={setupLoading} onClick={onClickSetupChat} />
        </Box>
      </ChipsPanel>
    </>
  )
}
