import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getMessagesByChannelId } from "../pubnubStore/messages"
import { AppState, ThunkAction } from "store/setup/storeTypes"

export type LastReadTimetokensState = {
  [channelId: string]: number
}

const initialState: LastReadTimetokensState = {}

export const lastReadTimetokensSlice = createSlice({
  name: "lastReadTimetokens",
  initialState,
  reducers: {
    setLastReadTimetoken: (
      state,
      { payload: { channelId, timetoken } }: PayloadAction<{ channelId: string; timetoken: number }>,
    ) => {
      state[channelId] = timetoken
    },
    clearLastReadTimetoken: (state, { payload: { channelId } }: PayloadAction<{ channelId: string }>) => {
      delete state[channelId]
    },
  },
})

const { setLastReadTimetoken } = lastReadTimetokensSlice.actions

export const setLastMessageTimetokenAsUnread = (channelId: string): ThunkAction => {
  return (dispatch, getState) => {
    const channelMessages = getMessagesByChannelId(getState())[channelId] || []

    if (channelMessages.length) {
      dispatch(setLastReadTimetoken({ channelId, timetoken: channelMessages[channelMessages.length - 1].timetoken }))
    }
  }
}

export const lastReadTimetokensSelector = (state: AppState) => state[lastReadTimetokensSlice.name]
