import { useSelector } from "store/setup/useSelector"
import React from "react"
import { StageMessages } from "../../StageMessages"
import { Box, Drawer, useMediaQuery, useTheme } from "@material-ui/core"
import { toggleStageMessages } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"
import { getLayout } from "store/domain/layout"
import styled from "styled-components"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { rgba } from "polished"
import { messagesPanelContainerCss } from "features/baseBlocks/MessagesPanel"
import { PollByStageWrapped } from "./PollByStageWrapped"

const DrawerContent = styled.div`
  ${({ theme }) =>
    `
    width: 280px;
    height: 100%;
    background: ${theme.palette.primary.light};
    padding: ${theme.spacing(1)}px;
    ${messagesPanelContainerCss}
  `}
`

const WrapperBox = styled(Box)`
  ${({ theme }) =>
    `
    width: 70%;
    max-width: 320px;
    padding: ${theme.spacing(1)}px;
    border-left: 1px solid ${rgba(theme.palette.secondary.main, 0.45)};
    ${messagesPanelContainerCss}
    overflow: auto;
  `}
`

export const AddedSidebar: React.FC = () => {
  const dispatch = useDispatch()
  const layout = useSelector(getLayout)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const currentStageChannel = useSelector(getCurrentStageChannel)

  const content = (
    <>
      <PollByStageWrapped />

      <StageMessages />
    </>
  )

  return (
    <>
      {!currentStageChannel.custom.hide_messages && (
        <>
          {isXs ? (
            <Drawer
              anchor="right"
              open={layout.stageMessages}
              onClose={() => {
                dispatch(toggleStageMessages())
              }}
            >
              <DrawerContent>{content}</DrawerContent>
            </Drawer>
          ) : (
            <>{layout.stageMessages && <WrapperBox>{content}</WrapperBox>}</>
          )}
        </>
      )}
    </>
  )
}
