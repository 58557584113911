import { useEffect, useRef, useState } from "react"
import { createAppStore } from "store/setup/createAppStore"
import Pubnub from "pubnub"
import { EventType } from "api/apiFunctions/event"
import { AppStore } from "store/setup/storeTypes"
import { createPubnubInstance } from "utils/createPubnubInstance"
import { useInitialApi } from "api/useInitialApi"
import useEventSetup from "hooks/useEventSetup"

export const useStoreAndEventSetup = () => {
  // "setupFinished" is needed to guarantee re-render when data is available, because ref updates don't do that
  const [setupFinished, setSetupFinished] = useState(false)
  const eventRef = useRef<EventType | null>(null)
  const pubnubInstanceRef = useRef<Pubnub | null>(null)
  const storeRef = useRef<AppStore | null>(null)
  const { initialApi } = useInitialApi()
  const eventSetup = useEventSetup()

  useEffect(() => {
    const innerFn = async () => {
      if (setupFinished) {
        return
      }

      const event = await initialApi.event.fetchEvent(eventSetup.uuid)

      console.log("Event - fetched: ", event)

      eventRef.current = event

      const pubnubInstance = createPubnubInstance(event)

      pubnubInstanceRef.current = pubnubInstance

      storeRef.current = createAppStore(pubnubInstance)

      setSetupFinished(true)
    }

    innerFn()
  }, [setupFinished, initialApi, eventSetup])

  return setupFinished && eventRef.current && pubnubInstanceRef.current && storeRef.current
    ? {
        event: eventRef.current,
        pubnubInstance: pubnubInstanceRef.current,
        store: storeRef.current,
      }
    : null
}
