import { fetchBreakoutVideoCallToken } from "./apiFunctions/breakout"
import { setupChatRoom, setupDailyRoom } from "./apiFunctions/chatAndVideoRoom"
import { fetchDanceFloorDailyData } from "./apiFunctions/danceFloor"
import { fetchWebinarDailyData } from "./apiFunctions/webinar"
import { fetchExpobooth, shareYourDetailsToExpobooth, fetchExpoboothVideoCallToken } from "./apiFunctions/expobooth"
import { fetchLeaderboardMeta, fetchLeaderboardPersonal } from "./apiFunctions/leaderboard"
import { fetchMingleAttendeesCount, fetchMingleRoomNameAndToken } from "./apiFunctions/mingle"
import { fetchGroupPhotos, createGroupPhoto } from "./apiFunctions/photobooth/groupPhoto"
import {
  removeImageBackground,
  publishImageToGallery,
  fetchGalleryPhotos,
} from "./apiFunctions/photobooth/photoGallery"
import { getVideLibraryContent } from "./apiFunctions/videoLibrary"
import { useContext } from "react"
import { FingerprintHash } from "main/App/FingerprintHashContextProvider"
import { EventSetupContext } from "main/App/EventSetupContextProvider"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"

// todo ola changed return shapes for some of the apis, needs testing, go feature by feature and see if they work
const constructApi = ({
  eventUuid,
  attendanceUuid,
}: {
  eventUuid: string
  attendanceUuid: string
  fingerprintHash: string
}) => ({
  api: {
    breakout: { fetchBreakoutVideoCallToken: fetchBreakoutVideoCallToken(eventUuid) },
    chatAndVideoRoom: { setupChatRoom: setupChatRoom(eventUuid), setupDailyRoom: setupDailyRoom(eventUuid) },
    expobooth: {
      fetchExpobooth: fetchExpobooth(eventUuid),
      shareYourDetailsToExpobooth: shareYourDetailsToExpobooth(eventUuid),
      fetchExpoboothVideoCallToken: fetchExpoboothVideoCallToken(eventUuid),
    },
    leaderboard: {
      fetchLeaderboardMeta: fetchLeaderboardMeta({ eventUuid }),
      fetchLeaderboardPersonal: fetchLeaderboardPersonal({ eventUuid, attendanceUuid }),
    },
    mingle: {
      fetchMingleRoomNameAndToken: fetchMingleRoomNameAndToken(eventUuid),
      fetchMingleAttendeesCount: fetchMingleAttendeesCount(eventUuid),
    },
    danceFloor: {
      fetchDanceFloorDailyData: fetchDanceFloorDailyData(eventUuid),
    },
    webinar: {
      fetchWebinarDailyData: fetchWebinarDailyData(eventUuid),
    },
    photobooth: {
      groupPhoto: {
        fetchGroupPhotos: fetchGroupPhotos({ eventUuid, attendanceUuid }),
        createGroupPhoto: createGroupPhoto(eventUuid),
      },
      photoGallery: {
        removeImageBackground: removeImageBackground(eventUuid),
        publishImageToGallery: publishImageToGallery(eventUuid),
        fetchGalleryPhotos: fetchGalleryPhotos({ eventUuid, attendanceUuid }),
      },
    },
    videoLibrary: { getVideLibraryContent: getVideLibraryContent({ eventUuid }) },
  },
})

let apiReturnValue: ReturnType<typeof constructApi> | null = null

export const useApi = () => {
  const fingerprintHash = useContext(FingerprintHash)
  const eventSetup = useContext(EventSetupContext)
  const { attendanceUuid } = useSelector(getCurrentPlatformUser)

  return (
    apiReturnValue || (apiReturnValue = constructApi({ eventUuid: eventSetup.uuid, attendanceUuid, fingerprintHash }))
  )
}
