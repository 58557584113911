import React, { useCallback, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import styled from "styled-components"
import PeopleIcon from "@material-ui/icons/People"
import ScheduleIcon from "@material-ui/icons/Schedule"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { DetailSection } from "./DetailSection"
import NotificationsIcon from "@material-ui/icons/Notifications"

const CloseIconWrapper = styled(CloseIcon)`
  cursor: pointer;
`

const ConfirmButton = styled(Button)`
  background-color: #ffd645;
  color: #001aff;
`

type Props = {
  OpenerComponent: React.FC<{ onClick: () => void }>
  onConfirm: () => void
  userNames: string
  userCount: number
}

export const SetupVideoRoomConfirmationDialog: React.FC<Props> = ({
  OpenerComponent,
  onConfirm,
  userNames,
  userCount,
}) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const { dailyMaxVideoLengthMinutes } = useSelector(getEvent)

  const openDialog = useCallback(() => {
    setOpen(true)
  }, [])

  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [])

  const handleConfirm = useCallback(async () => {
    setConfirmLoading(true)

    await onConfirm()

    setConfirmLoading(false)
  }, [onConfirm, setConfirmLoading])

  return (
    <>
      <OpenerComponent onClick={openDialog} />

      <Dialog
        fullScreen={isSm}
        fullWidth
        maxWidth={"xs"}
        onClose={closeDialog}
        open={open}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.7)" } }}
      >
        <DialogContent dividers>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <CloseIconWrapper onClick={closeDialog} />
          </Grid>

          <Box mb={2}>
            <Grid>
              <Typography variant="h5">
                <Box width="100%" textAlign="center" mb={2} fontWeight="fontWeightBold">
                  SUMMARY
                </Box>
              </Typography>

              <Typography variant="h5">
                <Box width="100%" textAlign="center" mb={2}>
                  Select Confirm to invite the following attendees to this Call.{" "}
                </Box>
              </Typography>

              <DetailSection
                icon={<PeopleIcon />}
                title={`Attendee${userCount > 1 ? "s" : ""}`}
                detailValue={userNames}
              />

              <DetailSection
                icon={<ScheduleIcon />}
                title="Duration"
                detailValue={`${dailyMaxVideoLengthMinutes} minutes`}
              />

              <Typography>
                You will receive a <b>notification</b> once the first attendee has accepted. <NotificationsIcon />
              </Typography>
            </Grid>
          </Box>

          <DialogActions>
            <Button onClick={closeDialog} color="primary" variant="outlined">
              <b>EDIT</b>
            </Button>

            <ConfirmButton disabled={confirmLoading} onClick={handleConfirm}>
              <b>CONFIRM</b>
            </ConfirmButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
