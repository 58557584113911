import React, { useContext } from "react"
import { IntercomProvider } from "react-use-intercom"
import { EventSetupContext } from "main/App/EventSetupContextProvider"

export const IntercomSetup: React.FC = ({ children }) => {
  const { intercomCode } = useContext(EventSetupContext)

  return (
    <IntercomProvider appId={intercomCode || ""} shouldInitialize={!!intercomCode}>
      {children}
    </IntercomProvider>
  )
}
