import React from "react"
import { useConstructedDescription } from "store/domain/currentStageContent/currentStageChannel"
import IFrame from "react-iframe"
import { useConnectStageChannelDescription } from "store/domain/pubnubStore/channels/stageChannelsMeta"

type Props = {
  className?: string
  showConnectStage?: boolean
  id?: string
}

export const VideoIframe: React.FC<Props> = ({ className, showConnectStage = false, id }) => {
  const constructedDescription = useConstructedDescription()
  const connectDescription = useConnectStageChannelDescription()

  const description = showConnectStage ? connectDescription : constructedDescription

  return (
    <>
      {description && (
        <IFrame
          id={id}
          url={description}
          allow="microphone;camera;autoplay;display-capture;fullscreen"
          scrolling="no"
          frameBorder={0}
          className={className}
        />
      )}
    </>
  )
}
