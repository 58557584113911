import React from "react"
import { useFinalizeSetupAirbrake } from "main/App/Router/RoutesLoginProtection/AuthorizedContainer/ConditionallyProvideAirbrakeAndErrorBoundary/useFinalizeSetupAirbrake"
import { usePostMessageListeners } from "./usePostMessage"

export const WrapperWithStageSet: React.FC = ({ children }) => {
  useFinalizeSetupAirbrake()
  usePostMessageListeners()

  return <>{children}</>
}
