import { Box, Button, Card, Dialog, Grid, Typography } from "@material-ui/core"
import React, { useState } from "react"
import styled from "styled-components"
import ScreenShareIcon from "@material-ui/icons/ScreenShare"
import CloseIcon from "@material-ui/icons/Close"
import PersonIcon from "@material-ui/icons/Person"
import EmailIcon from "@material-ui/icons/Email"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useApi } from "api/useApi"

const TopRightCardWrapper = styled(Card)`
  border-radius: ${({ theme }) => theme.spacing(1.9)}px;
  padding: ${({ theme }) => theme.spacing(1.9)}px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 8px 0px 8px 0px;
    width: 100%;
  }
`

const Image = styled.img`
  max-width: 180px;
`

const CloseIconWrapper = styled(CloseIcon)`
  cursor: pointer;
`

const ShareYourDetailsButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1.9)}px;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 5px 30px 5px 30px;
  }
  ${({ theme }) => theme.breakpoints.only("sm")} {
    padding: 5px 30px 5px 30px;
  }
`

const ConfirmButtonWrapper = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1.9)}px;
`

const TypographyBold = styled(Typography)`
  font-weight: bold;
`

type Props = {
  mainImageUrl?: string
  title?: string
  expoboothUuid: string
}

export const ExpoboothShareDetail: React.FC<Props> = ({ mainImageUrl, title, expoboothUuid }) => {
  const [shareDetailsDialogOpen, setShareDetailsDialogOpen] = useState(false)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const { api } = useApi()

  const handleClose = () => {
    setShareDetailsDialogOpen(false)
  }

  const confirmShareYourDetailsClick = async () => {
    await api.expobooth.shareYourDetailsToExpobooth({ expoboothUuid })

    handleClose()
  }

  return (
    <>
      <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} md={4}>
        <TopRightCardWrapper>
          <Box mb={1}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Image src={mainImageUrl} alt="logo" />

              <ShareYourDetailsButton
                onClick={() => setShareDetailsDialogOpen(true)}
                color="primary"
                variant="contained"
                startIcon={<ScreenShareIcon />}
              >
                SHARE YOUR DETAILS
              </ShareYourDetailsButton>
            </Grid>
          </Box>
        </TopRightCardWrapper>
      </Grid>

      <Dialog open={shareDetailsDialogOpen} onClose={handleClose}>
        <Box maxWidth={450} p={2}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <CloseIconWrapper onClick={handleClose} />
          </Grid>

          <Box px={3}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <TypographyBold variant="h6">SHARE YOUR DETAILS</TypographyBold>

              <Box mt={2}>
                <Typography align="center" variant="h6">
                  The following contact information will be shared with {title}
                </Typography>
              </Box>
            </Grid>

            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <PersonIcon />
              &nbsp;
              <TypographyBold variant="subtitle1">Name:&nbsp;</TypographyBold>
              <Typography variant="subtitle1">{currentPlatformUser.userName}</Typography>
            </Grid>

            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <EmailIcon />
              &nbsp;
              <TypographyBold variant="subtitle1">Email:&nbsp;</TypographyBold>
              <Typography variant="subtitle1">{currentPlatformUser.email}</Typography>
            </Grid>

            <ConfirmButtonWrapper onClick={confirmShareYourDetailsClick} color="primary" variant="contained">
              CONFIRM
            </ConfirmButtonWrapper>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
