import Jimp from "jimp"
import { AddedSticker } from "../AddedStickers"

export const assembleImage = async ({
  imageSrc,
  selectedBackground,
  selectedFrame,
  addedStickers,
  imageWrapper,
}: {
  imageSrc: File
  selectedBackground: string
  selectedFrame: string | null
  addedStickers: AddedSticker[]
  imageWrapper: HTMLElement
}): Promise<Jimp> => {
  const imagesPromises = [Jimp.read(selectedBackground), Jimp.read(URL.createObjectURL(imageSrc))]

  if (selectedFrame) {
    imagesPromises.push(Jimp.read(selectedFrame))
  }

  const images = await Promise.all(imagesPromises)

  const imageWrapperBoundingClientRect = imageWrapper.getBoundingClientRect()

  images.forEach((currentImage) => {
    currentImage.resize(imageWrapperBoundingClientRect.width, imageWrapperBoundingClientRect.height)
  })

  const composedImage = images.reduce((mergedImage, currentImage) => mergedImage.composite(currentImage, 0, 0))

  const stickerImagesRead = await Promise.all(addedStickers.map((currentSticker) => Jimp.read(currentSticker.src)))

  const stickersToMerge = addedStickers.map((currentImage, index) => ({
    ...currentImage,
    image: stickerImagesRead[index].rotate(-currentImage.angle).resize(currentImage.width, currentImage.height),
  }))

  const finalImage = stickersToMerge.reduce(
    (mergedImage, currentImage) =>
      mergedImage.composite(currentImage.image, currentImage.clientOffset.x, currentImage.clientOffset.y),
    composedImage,
  )

  return finalImage
}
