import React from "react"
import styled from "styled-components"
import { useAssets } from "store/domain/event"

const Image = styled.img<{ small: boolean; disabled: boolean }>`
  ${({ theme, small, disabled }) =>
    `
    display: block;
    ${
      disabled
        ? `
      opacity: 0.4;
      `
        : ""
    };
    width: ${theme.spacing(small ? 2 : 3.5)}px;
    height: ${theme.spacing(small ? 2 : 3.5)}px;
  `}
`

export const ReactionImage: React.FC<{ index: number; small?: boolean; disabled?: boolean; className?: string }> = ({
  index,
  small = false,
  disabled = false,
  className,
}) => {
  const assets = useAssets()

  return (
    <Image className={className} src={assets.reactions[index - 1]} alt={`${index}`} small={small} disabled={disabled} />
  )
}
