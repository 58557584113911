export const shouldNotBeSelectedUntilPresentErrorMessage =
  "This should not be selected if it's not present, the app should not be rendered if it's not present."

export const selectorErrorFallback =
  <T>(selector: (...args: T[]) => unknown, fallbackValue: unknown) =>
  (...args: T[]) => {
    try {
      return selector(...args)
    } catch (e) {
      return fallbackValue
    }
  }
