import { AppState } from "store/setup/storeTypes"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TextMessage } from "runtypes/message"

type DraftTextMessage = {
  text: TextMessage["text"]
  photoboothImageUrl?: TextMessage["photoboothImageUrl"]
}

type TextMessageDraftsState = { [channelId: string]: DraftTextMessage }

const initialState: TextMessageDraftsState = {}

export const textMessageDraftsSlice = createSlice({
  name: "textMessageDrafts",
  initialState,
  reducers: {
    updateTextMessageDraft: (
      state,
      action: PayloadAction<{
        channelId: string
        message: DraftTextMessage
      }>,
    ) => {
      const { channelId, message } = action.payload

      state[channelId] = message
    },
    discardTextMessageDraft: (state, action: PayloadAction<{ channelId: string }>) => {
      delete state[action.payload.channelId]
    },
  },
})

export const { updateTextMessageDraft, discardTextMessageDraft } = textMessageDraftsSlice.actions

const getTextMessageDrafts = (state: AppState) => state[textMessageDraftsSlice.name]

export const getChannelTextMessageDraft = (channelId: string) => (state: AppState) =>
  getTextMessageDrafts(state)[channelId]
