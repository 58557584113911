import { Box, Tab, Tabs, Typography } from "@material-ui/core"
import { LeaderboardBadge, LeaderboardMeta } from "api/apiFunctions/leaderboard"
import { TabPanel } from "independent/components/TabPanel"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { BadgesSection } from "./BadgesSection"

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ theme }) => theme.custom.colors.leaderboard.background};
  color: ${({ theme }) => theme.custom.colors.leaderboard.color};
  align-items: start;
  width: 100%;
  overflow: auto;
`

const TabsStyled = styled(Tabs)`
  color: ${({ theme }) => theme.custom.colors.leaderboard.color};
  padding: 8px 29px;
  margin-bottom: 12px;

  .MuiTab-wrapper {
    font-weight: bold;
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.custom.colors.leaderboard.color};
  }
`

const TabStyled = styled(Tab)`
  min-width: unset;
  padding: unset;

  :not(:last-child) {
    margin-right: 25px;
  }
`

const TabPanelStyled = styled(TabPanel)`
  width: 100%;
  overflow: auto;
`

const PointListWrapper = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0 28px;
`

const PointListPoints = styled(Typography)`
  padding: 10px 0;
`

const PointListChipWrapper = styled(Box)`
  padding: 12px 0 8px;
  margin: 0 18px;
  flex: 1;
`

const PointListChip = styled(Box)`
  padding: 3px 7px;
  background: ${({ theme }) => lighten(0.4, theme.custom.colors.leaderboard.predominant.background)};
  border-radius: 6px;
  width: fit-content;
`

const PointListDescription = styled(Typography)`
  padding: 10px 0;
`

type Props = {
  leaderboardMeta: LeaderboardMeta
  receivedBadgeUuids: string[]
}

export const LeaderboardDetails: React.FC<Props> = ({ leaderboardMeta, receivedBadgeUuids }) => {
  const [tabValue, setTabValue] = useState(0)
  const [receivedBadges, setReceivedBadges] = useState<LeaderboardBadge[]>([])
  const [notReceivedBadges, setNotReceivedBadges] = useState<LeaderboardBadge[]>([])

  const handleChange = (unusedEvent: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    setReceivedBadges(leaderboardMeta.badges.filter(({ uuid }) => receivedBadgeUuids.includes(uuid)))
    setNotReceivedBadges(leaderboardMeta.badges.filter(({ uuid }) => !receivedBadgeUuids.includes(uuid)))
  }, [leaderboardMeta.badges, receivedBadgeUuids])

  return (
    <Wrapper>
      <TabsStyled value={tabValue} onChange={handleChange}>
        <TabStyled label="BADGES" />

        <TabStyled label="POINT LIST" />
      </TabsStyled>

      <TabPanelStyled value={tabValue} index={0}>
        <BadgesSection
          badgesList={receivedBadges}
          sectionTitle="YOU ALREADY GOT"
          badgesTotalCount={leaderboardMeta.badges.length}
          highlightedBackground
        />

        <BadgesSection
          badgesList={notReceivedBadges}
          sectionTitle="WAITING FOR YOU"
          badgesTotalCount={leaderboardMeta.badges.length}
        />
      </TabPanelStyled>

      <TabPanelStyled value={tabValue} index={1}>
        <PointListWrapper>
          {leaderboardMeta.badges.map((badge) => (
            <React.Fragment key={badge.id}>
              <PointListPoints>{badge.points}</PointListPoints>

              <PointListChipWrapper>
                <PointListChip>{badge.category}</PointListChip>
              </PointListChipWrapper>

              <PointListDescription>{badge.description}</PointListDescription>
            </React.Fragment>
          ))}
        </PointListWrapper>
      </TabPanelStyled>
    </Wrapper>
  )
}
