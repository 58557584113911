import React from "react"
import { Box } from "@material-ui/core"
import { AddedSidebar } from "./AddedSidebar"
import styled from "styled-components"
import { mainContentWrapperCss } from "components/styles/mainContentWrapperCss"

const WrapperBox = styled(Box)`
  display: flex;
  height: 100%;
  overflow-y: auto;
  ${mainContentWrapperCss}
`

export const WrapperWithSidebarComments: React.FC = ({ children }) => {
  return (
    <WrapperBox>
      {children}

      <AddedSidebar />
    </WrapperBox>
  )
}
