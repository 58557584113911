import { Box, Grid, Typography } from "@material-ui/core"
import { ExpoboothType } from "api/apiFunctions/expobooth"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { VideoDaily } from "components/VideoDaily"
import { useParams } from "react-router-dom"
import { useApi } from "api/useApi"

const VideoWrapper = styled(Grid)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(0.7)}px;
`

const VideoCallCardWrapper = styled.div`
  height: 520px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  overflow: hidden;
`

export const ExpoboothVideoRoom: React.FC<{ expoboothDetail: ExpoboothType }> = ({ expoboothDetail }) => {
  const [videoCallToken, setVideoCallToken] = useState<string | null>(null)
  const { api } = useApi()
  const { expoboothUuid } = useParams<{ expoboothUuid: string }>()

  useEffect(() => {
    const innerFn = async () => {
      if (expoboothDetail?.displayVideoBooth && expoboothDetail?.dailyRoomName) {
        setVideoCallToken(await api.expobooth.fetchExpoboothVideoCallToken({ expoboothUuid }))
      }
    }

    innerFn()
  }, [api, expoboothUuid, expoboothDetail?.displayVideoBooth, expoboothDetail?.dailyRoomName])

  return (
    <>
      {expoboothDetail?.displayVideoBooth && expoboothDetail?.dailyRoomName && videoCallToken && (
        <VideoWrapper container direction="column">
          <Box mt={3} pl={1} mb={1}>
            <Typography variant="h6">Talk to the booth</Typography>
          </Box>
          <VideoCallCardWrapper>
            <VideoDaily roomName={expoboothDetail.dailyRoomName} token={videoCallToken} />
          </VideoCallCardWrapper>
        </VideoWrapper>
      )}
    </>
  )
}
