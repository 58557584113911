import React from "react"
import { Box } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import { useAssets } from "store/domain/event"
import { LobbyStageCardBackground } from "../LobbyStageCardBackground"

const TypographyStyled = styled(Typography)`
  color: white;
`

const Image = styled.img`
  max-width: 100%;
`

const agendaTitle = "Agenda"

export const LobbyStageAgenda: React.FC = () => {
  const assets = useAssets()

  return (
    <LobbyStageCardBackground>
      <Box m={2}>
        <Box pb={1.5}>
          <TypographyStyled variant="h5">
            <Box fontWeight="fontWeightBold">{agendaTitle}</Box>
          </TypographyStyled>
        </Box>
        <Image src={assets.lobbyStage.agendaImage} alt="komg logo" />
      </Box>
    </LobbyStageCardBackground>
  )
}
