import React, { Fragment, useContext, useState } from "react"
import styled from "styled-components"
import { Box, ClickAwayListener, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { LeaderboardMeta } from "api/apiFunctions/leaderboard"
import { BadgeDisplayed } from "../BadgeDisplayed"
import { lighten } from "polished"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"
import { TooltipStylable } from "components/TooltipStylable"

const BadgeTooltip = styled(TooltipStylable)`
  ${({ theme }) => `
    .MuiTooltip-tooltip {
      background: ${lighten(0.25, theme.custom.colors.leaderboard.predominant.background)};
      color: ${theme.custom.colors.leaderboard.predominant.color};
    }
  `}
`

const BadgeDisplayedInTooltip = styled(BadgeDisplayed)`
  margin: 4px 8px 4px 4px;
  width: 28px;
`

const BadgeDisplayedMain = styled(BadgeDisplayed)`
  margin: 3px;
`

type Props = {
  receivedBadgeUuids: string[]
  leaderboardMeta: LeaderboardMeta
}

export const UserBadges: React.FC<Props> = ({ receivedBadgeUuids, leaderboardMeta: { badges } }) => {
  const [openedBadgeTooltip, setOpenedBadgeTooltip] = useState<null | string>(null)
  const theme = useTheme()
  const touch = useMediaQuery(theme.custom.utils.hoverNoneMedia)
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)

  return (
    <>
      {receivedBadgeUuids.map((badgeUuid) => {
        const leaderboardBadge = badges.find(({ uuid }) => uuid === badgeUuid)

        if (!leaderboardBadge) {
          airbrakeNotifier.notify({ error: "User had nonexistent badge.", params: { badges, badgeUuid } })

          return null
        }

        const titleComponent = (
          <Box>
            <Box display="flex" alignItems="center">
              <BadgeDisplayedInTooltip imageUrl={leaderboardBadge.imageUrl} />

              <Typography variant="h6">
                <b>{leaderboardBadge.title}</b>
              </Typography>
            </Box>

            <Box ml={5}>
              <Typography>{leaderboardBadge.description}</Typography>
            </Box>
          </Box>
        )

        const badgeComponent = <BadgeDisplayedMain imageUrl={leaderboardBadge.imageUrl} />

        return (
          <Fragment key={badgeUuid}>
            {touch ? (
              <ClickAwayListener
                onClickAway={() => {
                  if (openedBadgeTooltip) {
                    setOpenedBadgeTooltip(null)
                  }
                }}
              >
                <div>
                  <BadgeTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => {
                      setOpenedBadgeTooltip(null)
                    }}
                    open={badgeUuid === openedBadgeTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={titleComponent}
                    placement="top-start"
                  >
                    <Box
                      onClick={() => {
                        setTimeout(() => {
                          setOpenedBadgeTooltip(badgeUuid)
                        }, 0)
                      }}
                    >
                      {badgeComponent}
                    </Box>
                  </BadgeTooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <BadgeTooltip title={titleComponent} placement="top-start">
                <Box>{badgeComponent}</Box>
              </BadgeTooltip>
            )}
          </Fragment>
        )
      })}
    </>
  )
}
