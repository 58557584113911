import React from "react"
import { getEvent } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"
import { AirbrakeAndErrorBoundary } from "./AirbrakeAndErrorBoundary"

export const ConditionallyProvideAirbrakeAndErrorBoundary: React.FC = ({ children }) => {
  const { uuid: eventUuid, frontendAirbrakeProjectId, frontendAirbrakeProjectKey } = useSelector(getEvent)

  return (
    <>
      {frontendAirbrakeProjectId && frontendAirbrakeProjectKey ? (
        <AirbrakeAndErrorBoundary
          eventUuid={eventUuid}
          frontendAirbrakeProjectId={frontendAirbrakeProjectId}
          frontendAirbrakeProjectKey={frontendAirbrakeProjectKey}
        >
          {children}
        </AirbrakeAndErrorBoundary>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
