import { useState, useCallback } from "react"

export enum PreparePhotoSteps {
  takePhoto,
  confirmTakenPhoto,
  selectBackground,
  addFrame,
  dragSticker,
}

type PreparePhotoStepsReturn = {
  preparationStep: PreparePhotoSteps
  nextStep: () => void
  previousStep: () => void
  goToFirstStep: () => void
}

export const usePreparePhotoSteps = (): PreparePhotoStepsReturn => {
  const stepsCount = Object.keys(PreparePhotoSteps).length / 2

  const [preparationStep, setPreparationStep] = useState<PreparePhotoSteps>(
    PreparePhotoSteps.takePhoto,
    // PreparePhotoSteps.confirmTakenPhoto,
    // PreparePhotoSteps.selectBackground,
    // PreparePhotoSteps.addFrame,
    // PreparePhotoSteps.dragSticker,
  )

  const previousStep = useCallback(() => {
    setPreparationStep((previousPreparationStep) =>
      previousPreparationStep >= 0 ? previousPreparationStep - 1 : previousPreparationStep,
    )
  }, [])

  const nextStep = useCallback(() => {
    setPreparationStep((previousPreparationStep) =>
      previousPreparationStep < stepsCount - 1 ? previousPreparationStep + 1 : previousPreparationStep,
    )
  }, [stepsCount])

  const goToFirstStep = useCallback(() => {
    setPreparationStep(0)
  }, [])

  return { preparationStep, nextStep, previousStep, goToFirstStep }
}
