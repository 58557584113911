import React from "react"
import { getEvent } from "store/domain/event"
import { AdditionalInfoDialog } from "features/baseBlocks/AdditionalInfoDialog"
import { useSelector } from "store/setup/useSelector"

export const MingleAdditionalInfoDialog: React.FC = () => {
  const { mingleIframeLinkText, mingleIframeTitle, mingleIframeSrc } = useSelector(getEvent)

  return (
    <>
      {mingleIframeLinkText && mingleIframeTitle && mingleIframeSrc && (
        <AdditionalInfoDialog
          iframeLinkText={mingleIframeLinkText}
          iframeTitle={mingleIframeTitle}
          iframeSrc={mingleIframeSrc}
        />
      )}
    </>
  )
}
