import React, { useState, useEffect, useMemo } from "react"
import { ImageAllTypes } from "runtypes/giphy"
import styled from "styled-components"

const Placeholder = styled.div<{ width: number; height: number }>`
  background: ${({ theme }) => theme.palette.secondary.main};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`

export enum GifSize {
  Preview,
  Full,
}

enum GifFormat {
  Image,
  Video,
}

interface LoadedGif {
  src: string
  format: GifFormat
}

// check for webp browser support (needed to support safari)
const supportsWebp = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const image = new Image()
    // check that the image is loaded successfully
    image.onload = (): void => {
      resolve(image.width > 0 && image.height > 0)
    }
    // data uri of animated webp to test for support
    image.src =
      "data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
  })
}

// preload the image/video and choose the optimal format
const load = async (source: ImageAllTypes): Promise<LoadedGif> => {
  let image = {
    src: source.mp4,
    format: GifFormat.Video,
  }
  if ((source.webp && !source.mp4) || (source.webp_size < source.mp4_size && (await supportsWebp()))) {
    image = {
      src: source.webp,
      format: GifFormat.Image,
    }
  }

  // fetch in the background

  // default to a placeholder giphy if the fetch fails, somehow, just to keep everyhting happy
  // we should change to have it show nothing when there's time
  let gifUrl =
    "https://media1.giphy.com/media/26xBIygOcC3bAWg3S/giphy.gif?cid=ecf05e47uuxjsfi4va3nd9k9iiz4scjjcm8t5hlc3qs2qwvz&rid=giphy.gif"
  await fetch(image.src)
    .then(async (res) => {
      const blob = await res.blob()
      gifUrl = URL.createObjectURL(blob)
    })
    .catch((error) => {
      console.log("error getting giphy - ", error)
    })
  // return a url for the blob, which will load immediately
  return {
    ...image,
    src: gifUrl,
  }
}

// null while loading, updated when complete
const useBackgroundLoad = (source: ImageAllTypes): null | LoadedGif => {
  const [data, setData] = useState<null | LoadedGif>(null)
  // prevent re-fetching the same image multiple times
  const image = useMemo(() => load(source), [source])
  useEffect(() => {
    let mounted = true
    let url: null | string = null
    ;(async (): Promise<void> => {
      const loaded = await image
      url = loaded.src
      if (mounted) {
        // only update state if the component is mounted
        setData(loaded)
      }
    })()
    return (): void => {
      mounted = false
      // free memory from the image
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
  }, [image])
  return data
}

interface GifDisplayProps {
  source: ImageAllTypes
  title: string
}

// load the gif in the background, swapping in for a placeholder when fully loaded
export const GifDisplay = React.memo(
  ({ source, title }: GifDisplayProps) => {
    const image = useBackgroundLoad(source)
    if (image !== null) {
      if (image.format === GifFormat.Image) {
        return <img src={image.src} alt={title} />
      } else {
        return <video autoPlay loop muted playsInline src={image.src} />
      }
    }
    return <Placeholder width={source.width} height={source.height} />
  },
  (prev, next) => {
    return prev.source.url === next.source.url
  },
)
GifDisplay.displayName = "GifDisplay"
