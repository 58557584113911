import { Button, Tooltip } from "@material-ui/core"
import React from "react"
import { BottomBarWrapper } from "../PrepareAndViewPhotos.style"
import styled from "styled-components"

const ButtonStyled = styled(Button)`
  &.Mui-disabled {
    // to fix hover issue
    pointer-events: auto;
    cursor: not-allowed;
  }
`

export const BottomBar: React.FC<{
  onPreviousStep: () => void
  onNextStep: () => void
  previousButtonText: string
  nextButtonText: string
  stepBackButtonDisabled?: boolean
  stepForwardButtonDisabled?: boolean
  stepForwardButtontooltipTextForDisabledState?: string
}> = ({
  onPreviousStep,
  onNextStep,
  previousButtonText,
  nextButtonText,
  stepBackButtonDisabled,
  stepForwardButtonDisabled,
  stepForwardButtontooltipTextForDisabledState,
}) => {
  return (
    <BottomBarWrapper>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        disabled={stepBackButtonDisabled}
        onClick={onPreviousStep}
      >
        {previousButtonText}
      </Button>

      <Tooltip
        placement="top"
        title={
          stepForwardButtonDisabled && !!stepForwardButtontooltipTextForDisabledState
            ? stepForwardButtontooltipTextForDisabledState
            : ""
        }
      >
        <ButtonStyled
          color="primary"
          variant="contained"
          size="large"
          disabled={stepForwardButtonDisabled}
          onClick={() => {
            if (!stepForwardButtonDisabled) {
              onNextStep()
            }
          }}
          // to fix hover issue
          {...{ component: stepForwardButtonDisabled ? "div" : undefined }}
        >
          {nextButtonText}
        </ButtonStyled>
      </Tooltip>
    </BottomBarWrapper>
  )
}
