import React from "react"
import { CarouselWrapper, CarouselItem, CarouselImage, CarouselImageWrapper, CarouselStyled } from "../shared.style"
import { backgroundSrcs } from "../carouselAssetSrcs"

type PropsType = {
  onSelectBackground: (src: string) => void
  selectedBackground: string | null
}

export const SelectBackground: React.FC<PropsType> = ({ onSelectBackground, selectedBackground }) => {
  const onClickSelectBackground = (imageSrc: string): void => {
    onSelectBackground(imageSrc)
  }

  return (
    <CarouselWrapper>
      <CarouselStyled
        itemList={backgroundSrcs}
        onClickItem={(index) => {
          onClickSelectBackground(backgroundSrcs[index])
        }}
        ItemComponent={({ item }) => (
          <CarouselItem key={item}>
            <CarouselImageWrapper isSelected={selectedBackground === item}>
              <CarouselImage src={item} />
            </CarouselImageWrapper>
          </CarouselItem>
        )}
      />
    </CarouselWrapper>
  )
}
