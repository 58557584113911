import { useAssets } from "store/domain/event"
import Sound, { ReactSoundProps } from "react-sound"
import { useState } from "react"

export const useCallRequestSound = () => {
  const assets = useAssets()
  const [playStatus, setPlayStatus] = useState<ReactSoundProps["playStatus"]>("STOPPED")

  return {
    callRequestSoundComponent: (
      <Sound
        url={assets.callRequestSound}
        playStatus={playStatus}
        onFinishedPlaying={() => {
          setPlayStatus("STOPPED")
        }}
      />
    ),
    playCallRequestSound: () => {
      setPlayStatus("PLAYING")
    },
  }
}
