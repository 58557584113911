import { AppState } from "store/setup/storeTypes"
import { shouldNotBeSelectedUntilPresentErrorMessage } from "store/setup/guaranteedSelectorsUtils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import invariant from "invariant"
import { getStageChannelsById } from "../../pubnubStore/channels/stageChannels"
import { STAGE_DISPLAY_TYPES } from "constants/general"
import mapValues from "lodash/mapValues"
import { useSelector } from "store/setup/useSelector"
import { isValidStageChannelDescription } from "./isValidStageChannelDescription"
import {
  useConnectStageChannelDescription,
  useMingleStageChannelDescription,
} from "store/domain/pubnubStore/channels/stageChannelsMeta"

type CurrentStageChannelIdState = string | null

const initialState: CurrentStageChannelIdState = null

export const currentStageChannelIdSlice = createSlice({
  name: "currentStageChannelId",
  initialState: initialState as CurrentStageChannelIdState,
  reducers: {
    setCurrentStageChannel: (unused: unknown, action: PayloadAction<string>) => action.payload,
  },
})

export const { setCurrentStageChannel } = currentStageChannelIdSlice.actions

const getCurrentStageChannelId = (state: AppState): string => {
  const { currentStageChannelId } = state

  invariant(currentStageChannelId !== null, shouldNotBeSelectedUntilPresentErrorMessage)

  return currentStageChannelId
}

export const getCurrentStageChannel = (state: AppState) => getStageChannelsById(state)[getCurrentStageChannelId(state)]

export const currentStageDisplayTypeSelector = (state: AppState) =>
  mapValues(
    STAGE_DISPLAY_TYPES,
    (stageDisplayType) => stageDisplayType === getCurrentStageChannel(state).custom.display_type,
  )

export const useConstructedDescription = () => {
  const description = useSelector(getCurrentStageChannel).description
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)
  const connectDescription = useConnectStageChannelDescription()
  const mingleDescription = useMingleStageChannelDescription()

  if (!isValidStageChannelDescription(description)) {
    return null
  } else if (currentStageDisplayType.mingle) {
    return mingleDescription
  } else if (currentStageDisplayType.connect) {
    return connectDescription
  }

  return description
}

export const getCurrentStagePoll = (state: AppState) => {
  const currentStageChannel = getStageChannelsById(state)[getCurrentStageChannelId(state)]

  if (!currentStageChannel.custom.poll_active) {
    return null
  }

  return {
    pollUuid: currentStageChannel.custom.poll_uuid as string,
    pollAnswersArray: JSON.parse(currentStageChannel.custom.poll_answers as string),
    pollQuestion: currentStageChannel.custom.poll_question as string,
  }
}
