import React, { ReactElement } from "react"
import { getEvent } from "store/domain/event"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useSelector } from "store/setup/useSelector"
import MagicBell, { FloatingNotificationInbox, Notification, IRemoteNotification } from "@magicbell/magicbell-react"
import { IconButton, Tooltip, TooltipProps } from "@material-ui/core"
import styled from "styled-components"
import { BELL_NOTIFICATION_CATEGORIES } from "constants/general"
import { useHistory } from "react-router-dom"
import { useCallRequestSound } from "./useCallRequestSound"
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined"
import { routerPaths } from "main/App/Router/routerPaths"
import useEventSlug from "hooks/useEventSlug"

const IconButtonStyled = styled(IconButton)`
  width: ${({ theme }) => theme.spacing(4)}px;
  height: ${({ theme }) => theme.spacing(4)}px;

  margin: 0 8px;

  svg {
    width: ${({ theme }) => theme.spacing(4)}px;
    height: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const BellNotifications: React.FC<{ className?: string; TooltipForIcon?: React.FC<TooltipProps> }> = ({
  className,
  TooltipForIcon,
}) => {
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const event = useSelector(getEvent)
  const history = useHistory()
  const { callRequestSoundComponent, playCallRequestSound } = useCallRequestSound()
  const eventSlug = useEventSlug()

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const handleNewNotification = (notification: IRemoteNotification): void => {
    if (notification.category && notification.category === BELL_NOTIFICATION_CATEGORIES.callRequest) {
      playCallRequestSound()
    }
  }

  const handleNotificationClick = (notification: Notification): void => {
    if (notification.actionUrl) {
      if (notification.category === BELL_NOTIFICATION_CATEGORIES.stageLink) {
        const stageIdToPushTo = notification.actionUrl.split("/").pop()

        if (stageIdToPushTo) {
          history.push({
            pathname: routerPaths.stage.assemblePath(eventSlug, stageIdToPushTo),
          })
        }
      } else {
        openInNewTab(notification.actionUrl)
      }
    }
  }

  const TooltipConditional = TooltipForIcon || Tooltip

  return (
    <>
      {currentPlatformUser.notificationsHash && (
        <>
          {callRequestSoundComponent}

          <TooltipConditional title="Notifications">
            <IconButtonStyled color="inherit" className={className}>
              <MagicBell
                BellIcon={<NotificationsNoneOutlinedIcon />}
                onNewNotification={handleNewNotification}
                apiKey={event.notificationsKey}
              >
                {(props): ReactElement => (
                  <FloatingNotificationInbox
                    onNotificationClick={handleNotificationClick}
                    width={400}
                    height={500}
                    layout={["header", "content"]}
                    {...props}
                  />
                )}
              </MagicBell>
            </IconButtonStyled>
          </TooltipConditional>
        </>
      )}
    </>
  )
}
