import React from "react"
import { useDispatch } from "store/setup/useDispatch"
import { getLayout, toggleMenu } from "store/domain/layout"
import { IconButton } from "@material-ui/core"
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded"
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded"
import { useSelector } from "store/setup/useSelector"

export const MenuButton: React.FC = () => {
  const dispatch = useDispatch()
  const layout = useSelector(getLayout)

  return (
    <IconButton
      color="inherit"
      size="small"
      onClick={(): void => {
        dispatch(toggleMenu())
      }}
    >
      {layout.menu ? <ArrowBackIosRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
    </IconButton>
  )
}
