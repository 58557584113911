import { createSelector } from "@reduxjs/toolkit"
import mapValues from "lodash/mapValues"
import { getMessagesByChannelId, MessagesByChannelId } from "../../messages"
import { lastReadTimetokensSelector, LastReadTimetokensState } from "store/domain/lastReadTimetokens"
import { getAppChannelsById } from "../appChannels"
import { MessageChannel } from "../channelTypes"
import pickBy from "lodash/pickBy"
import find from "lodash/find"

const isUnreadMessageInMessageChannelHelper = (
  channelId: string,
  messagesByChannelId: MessagesByChannelId,
  lastReadTimetokens: LastReadTimetokensState,
) => {
  const channelMessages = messagesByChannelId[channelId] || []

  if (channelMessages.length) {
    const lastMessage = channelMessages[channelMessages.length - 1]

    return lastMessage.timetoken !== lastReadTimetokens[channelId]
  }

  return false
}

export const getMessageChannelsById = createSelector(
  [getAppChannelsById, getMessagesByChannelId, lastReadTimetokensSelector],
  (channelsById, messagesByChannelId, lastReadTimetokens) =>
    mapValues(channelsById, (messageChannel) => ({
      ...messageChannel,
      additions: {
        isUnreadMessage: isUnreadMessageInMessageChannelHelper(
          messageChannel.id,
          messagesByChannelId,
          lastReadTimetokens,
        ),
      },
    })) as { [id: string]: MessageChannel },
)

export const pickMessageChannelsByFunction = <T>(
  channelsById: {
    [channelId: string]: MessageChannel
  },
  identifyingFn: (channelArg: MessageChannel) => boolean,
) => pickBy(channelsById, identifyingFn) as unknown as { [id: string]: T }

export const isUnreadMessageInAnyMessageChannelsSelector = createSelector(
  [getMessageChannelsById],
  (messageChannelsById) =>
    find(Object.values(messageChannelsById), (currentRoomChannel) => currentRoomChannel.additions.isUnreadMessage) !==
    undefined,
)
