import React from "react"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import { Fab } from "@material-ui/core"

const TakePhotoButtonRaw = styled(Fab)<{ $smallSize: boolean }>`
  ${({ theme, $smallSize }) => `
    width: ${theme.spacing(9)}px;
    height: ${theme.spacing(9)}px;

    ${
      $smallSize &&
      `
      width: ${theme.spacing(6)}px;
      height: ${theme.spacing(6)}px;

      span {
        font-size: 10px;
      }

      svg {
        font-size: 22px;
      }
    `
    }
  `};
`

type Props = { handleClick: () => void; smallSize?: boolean; className?: string }

export const TakePhotoButton: React.FC<Props> = ({ handleClick, smallSize = false, className }) => {
  return (
    <TakePhotoButtonRaw className={className} color="primary" onClick={handleClick} $smallSize={smallSize}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <PhotoCameraIcon fontSize="large" />

        <Typography variant="caption">SELFIE!</Typography>
      </Box>
    </TakePhotoButtonRaw>
  )
}
