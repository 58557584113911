import queryString from "query-string"
import isString from "lodash/isString"

export const getThumbnailUrlFromVideoUrl = (videoUrl: string) => {
  const embedIdInThumbnailUrl = (videoId: string) => `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`

  if (videoUrl.includes("youtube")) {
    const stringAfterQuestionMark = videoUrl.split("?")[1]

    if (stringAfterQuestionMark) {
      const videoId = queryString.parse(stringAfterQuestionMark).v

      if (isString(videoId)) {
        return embedIdInThumbnailUrl(videoId)
      }
    }
  }

  return embedIdInThumbnailUrl("nonexistent")
}
