import React from "react"
import { useSelector } from "store/setup/useSelector"
import { Box } from "@material-ui/core"
import styled from "styled-components"
import { getMessageReactions } from "store/domain/allMessageReactions"
import { useSendMessageReaction } from "./useSendMessageReaction"
import { MessageFragment } from "store/domain/pubnubStore/messages"
import { MessageReactionPicker } from "./MessageReactionPicker"
import { ReactionImage } from "components/ReactionImage"
import { AvatarGroup } from "@material-ui/lab"

const Wrapper = styled(Box)<{ $forMobile: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 2px;

  ${({ $forMobile }) =>
    !$forMobile
      ? `
        margin-top: 5px;
        `
      : `
        box-shadow: 10px 10px 11px -14px rgba(0, 0, 0, 0.75);
      `}
`

const ReactionEmojiWrapper = styled(Box)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  padding: 3px;
`

const Sum = styled(Box)`
  margin-left: 4px;
  display: flex;
  align-items: center;
`

type MessageProps = {
  channelId: string
  messageFragment: MessageFragment
  forMobile: boolean
}

export const MessageReactions: React.FC<MessageProps> = ({ channelId, messageFragment: { timetoken }, forMobile }) => {
  const messageReactions = useSelector(getMessageReactions(timetoken))
  const sendMessageReaction = useSendMessageReaction(timetoken, channelId)

  const reactionsSum = messageReactions.reduce((sum, currentReaction) => sum + currentReaction.count, 0)

  return (
    <Wrapper $forMobile={forMobile}>
      {!!messageReactions.length && (
        <Box display="flex" flexWrap="wrap" mr={0.8}>
          <AvatarGroup>
            {messageReactions.map(({ index }) => (
              <ReactionEmojiWrapper key={index}>
                <ReactionImage index={index} small />
              </ReactionEmojiWrapper>
            ))}
          </AvatarGroup>

          {reactionsSum > 1 && <Sum>{reactionsSum}</Sum>}
        </Box>
      )}

      <MessageReactionPicker onSelection={(index: number) => sendMessageReaction(index)} timetoken={timetoken} />
    </Wrapper>
  )
}
