import { addSnackbarInCatch, addSnackbarInThen } from "api/utils"
import { apiUrls } from "../../apiUrls"
import { baseApi } from "../../baseApi"

export const setupChatRoom =
  (eventUuid: string) =>
  ({ attendanceUuids }: { attendanceUuids: string[] }): Promise<string> =>
    baseApi
      .post<string>({
        url: apiUrls.chatRoom,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          attendance_uuids: JSON.stringify(attendanceUuids),
        },
      })
      .then(({ data }) => data)
      .catch(addSnackbarInCatch())

export const setupDailyRoom =
  (eventUuid: string) =>
  ({ attendanceUuids }: { attendanceUuids: string[] }): Promise<string> =>
    baseApi
      .post<string>({
        url: apiUrls.videoRoom,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          attendance_uuids: JSON.stringify(attendanceUuids),
        },
      })
      .then(({ data }) => data)
      .then(addSnackbarInThen)
      .catch(addSnackbarInCatch())
