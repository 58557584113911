import React from "react"
import styled from "styled-components"
import { GalleryPhoto } from "api/apiFunctions/photobooth/photoGallery"
import { ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { obtainInitialsFromUserName } from "utils/obtainInitialsFromUserName"
import { GroupPhoto } from "api/apiFunctions/photobooth/groupPhoto"
import { getEvent } from "store/domain/event"
import { useSelector } from "store/setup/useSelector"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 2, 10)};
`

const Section = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const ListTitle = styled(Typography)`
  color: white;
  margin-left: 7px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

const ImageListItemStyled = styled(ImageListItem)`
  cursor: pointer;
`

type GalleryPhotoList = GalleryPhoto[]

export const PhotoboothPhotos: React.FC<{
  usersPhotos: GalleryPhotoList
  groupPhotos: GroupPhoto[]
  otherUsersPhotos: GalleryPhotoList
  onPhotoClick: (id: GalleryPhoto | GroupPhoto) => void
}> = ({ usersPhotos, groupPhotos, otherUsersPhotos, onPhotoClick }) => {
  const imageCol = useMediaQuery(useTheme().breakpoints.up("md")) ? 4 : 2
  const { disableGroupPhotos } = useSelector(getEvent)

  const imageRowHeight = window.innerWidth / imageCol

  return (
    <Wrapper>
      <Section>
        {!!usersPhotos.length && <ListTitle>MY PHOTOS</ListTitle>}

        <ImageList rowHeight={imageRowHeight} cols={imageCol}>
          {usersPhotos.map((currentPhoto) => (
            <ImageListItemStyled key={currentPhoto.id}>
              <img src={currentPhoto.thumbnailUrl} alt="My Photo" onClick={() => onPhotoClick(currentPhoto)} />
            </ImageListItemStyled>
          ))}
        </ImageList>
      </Section>

      {!disableGroupPhotos && (
        <Section>
          {!!groupPhotos.length && <ListTitle>GROUP PHOTOS</ListTitle>}

          <ImageList rowHeight={(window.innerWidth / imageCol) * 0.8} cols={imageCol}>
            {groupPhotos.map((groupPhoto) => (
              <ImageListItemStyled key={groupPhoto.id}>
                <img src={groupPhoto.thumbnailUrl} alt="Group Photo" onClick={() => onPhotoClick(groupPhoto)} />
              </ImageListItemStyled>
            ))}
          </ImageList>
        </Section>
      )}
      <Section>
        {!!otherUsersPhotos.length && <ListTitle>EVENT GALLERY</ListTitle>}

        <ImageList rowHeight={imageRowHeight} cols={imageCol}>
          {otherUsersPhotos.map((currentPhoto) => (
            <ImageListItemStyled key={currentPhoto.id}>
              <img src={currentPhoto.thumbnailUrl} alt="Gallery Photo" onClick={() => onPhotoClick(currentPhoto)} />

              <ImageListItemBar title={obtainInitialsFromUserName(currentPhoto.username.toUpperCase())} />
            </ImageListItemStyled>
          ))}
        </ImageList>
      </Section>
    </Wrapper>
  )
}
