import React, { useCallback, useEffect, useState } from "react"
import { Box, Typography, IconButton, Switch } from "@material-ui/core"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { AlgoliaInstantSearch } from "components/algolia/AlgoliaInstantSearch"
import { useSelector } from "store/setup/useSelector"
import styled from "styled-components"
import { setLeaderboard } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"
import { LeaderboardMeta, LeaderboardPersonal } from "api/apiFunctions/leaderboard"
import { ScoreBoard } from "./ScoreBoard"
import { LeaderboardDetails } from "./LeaderboardDetails"
import { getCurrentPlatformUser } from "store/domain/authentication"
import moment from "moment"
import { UserScore } from "../common/UserScore"
import { getEvent } from "store/domain/event"
import { Configure } from "react-instantsearch-dom"
import { LeaderboardCircularProgress } from "./LeaderboardCircularProgress"
import { useApi } from "api/useApi"

const InnerContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LastUpdatedTypography = styled(Typography)`
  opacity: 0.5;
`

const Header = styled.div<{ isLeaderboardShowing: boolean }>`
  ${({
    theme: {
      custom: {
        colors: { leaderboard },
      },
    },
    isLeaderboardShowing,
  }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${isLeaderboardShowing ? leaderboard.predominant.background : leaderboard.background};
    color: ${isLeaderboardShowing ? leaderboard.predominant.color : leaderboard.color};
    padding: 16px 16px 0;
  `}
`

const HeaderBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
`

const NameTypography = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
` as typeof Typography

const CloseIconButton = styled(IconButton)`
  padding: 0;
`

const ContentSwitchWrapper = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;
`

const ContentSwitchLabel = styled(Typography)<{ $emphasize: boolean }>`
  ${({ $emphasize, theme }) => ($emphasize ? `color: ${theme.palette.primary.main};` : "opacity: 0.7;")}
  font-weight: bold;
`

const ContentSwitch = styled(Switch)`
  ${({ theme }) => `
    margin: 0 5px;

    .MuiButtonBase-root {
      color: ${theme.palette.primary.main};
    }

    .MuiSwitch-track {
      background-color: ${theme.palette.primary.main};
    }
  `}
`

const BottomBarWrapper = styled.div`
  padding: 12px 25px 15px;
  background: ${({ theme }) => theme.custom.colors.leaderboard.predominant.background};
  color: ${({ theme }) => theme.custom.colors.leaderboard.predominant.color};
`

export const LeaderboardContent: React.FC = () => {
  const { userName } = useSelector(getCurrentPlatformUser)
  const dispatch = useDispatch()
  const [leaderboardView, setLeaderboardView] = useState<"leaderboard" | "scoring">("leaderboard")
  const [isSearchInProgress, setIsSearchInProgress] = useState(false)
  const [leaderboardMeta, setLeaderboardMeta] = useState<LeaderboardMeta | null>(null)
  const [leaderboardPersonal, setLeaderboardPersonal] = useState<LeaderboardPersonal | null>(null)
  const { leaderboardIndexName } = useSelector(getEvent)
  const { api } = useApi()

  const fetchAndSaveLeaderboardPersonal = useCallback(async () => {
    setLeaderboardPersonal(await api.leaderboard.fetchLeaderboardPersonal())
  }, [api.leaderboard])

  const toggleLeaderboardView = useCallback(() => {
    setLeaderboardView(leaderboardView === "scoring" ? "leaderboard" : "scoring")
  }, [leaderboardView])

  useEffect(() => {
    const innerFn = async () => {
      setLeaderboardMeta(await api.leaderboard.fetchLeaderboardMeta())

      fetchAndSaveLeaderboardPersonal()
    }

    innerFn()
  }, [api, fetchAndSaveLeaderboardPersonal])

  useEffect(() => {
    if (leaderboardView === "scoring") {
      fetchAndSaveLeaderboardPersonal()
    }
  }, [leaderboardView, fetchAndSaveLeaderboardPersonal])

  const obtainLastUpdatedText = useCallback((leaderboardLastUpdatedAt?: string) => {
    const calculateLastUpdatedDuration = (leaderboardLastUpdatedAt: string) => {
      const diffDuration = moment.duration(moment().diff(moment(leaderboardLastUpdatedAt)))

      return {
        days: Math.floor(diffDuration.asDays()),
        hours: Math.floor(diffDuration.hours()),
        minutes: Math.floor(diffDuration.minutes()),
      }
    }

    if (!leaderboardLastUpdatedAt) {
      return null
    }

    const lastUpdatedDuration = calculateLastUpdatedDuration(leaderboardLastUpdatedAt)

    return `Last updated ${lastUpdatedDuration.days ? `${lastUpdatedDuration.days} days ` : ""} ${
      lastUpdatedDuration.hours ? `${lastUpdatedDuration.hours} hours ` : ""
    } ${lastUpdatedDuration.days || lastUpdatedDuration.hours ? "and " : ""} ${
      lastUpdatedDuration.minutes ? `${lastUpdatedDuration.minutes} minutes ago` : "just now"
    }`
  }, [])

  if (!leaderboardMeta || !leaderboardPersonal) {
    return <LeaderboardCircularProgress />
  }

  const lastUpdatedText = obtainLastUpdatedText(leaderboardMeta.lastUpdatedAt)

  return (
    <AlgoliaInstantSearch indexName={leaderboardIndexName} setIsSearchInProgress={setIsSearchInProgress}>
      <Configure filters="score>0" />

      <InnerContent>
        <Header isLeaderboardShowing={leaderboardView === "leaderboard"}>
          {lastUpdatedText && (
            <Box width="100%" display="flex" justifyContent="right">
              <LastUpdatedTypography variant="caption">{lastUpdatedText}</LastUpdatedTypography>
            </Box>
          )}

          <HeaderBar>
            <NameTypography component="div">
              <CloseIconButton
                color="inherit"
                onClick={() => {
                  dispatch(setLeaderboard(false))
                }}
              >
                <CloseRoundedIcon fontSize="large" />
              </CloseIconButton>

              <Box ml={1} mr={2}>
                <b>LEADERBOARD</b>
              </Box>
            </NameTypography>

            <ContentSwitchWrapper
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={toggleLeaderboardView}
            >
              <ContentSwitchLabel variant="body2" $emphasize={leaderboardView === "leaderboard"}>
                Leaderboard
              </ContentSwitchLabel>

              <ContentSwitch color="primary" size="small" checked={leaderboardView === "scoring"} />

              <ContentSwitchLabel variant="body2" $emphasize={leaderboardView === "scoring"}>
                Scoring
              </ContentSwitchLabel>
            </ContentSwitchWrapper>
          </HeaderBar>
        </Header>

        {leaderboardView === "leaderboard" && (
          <ScoreBoard isSearchInProgress={isSearchInProgress} leaderboardMeta={leaderboardMeta} />
        )}

        {leaderboardView === "scoring" && (
          <LeaderboardDetails
            leaderboardMeta={leaderboardMeta}
            receivedBadgeUuids={leaderboardPersonal.receivedBadgeUuids}
          />
        )}

        <BottomBarWrapper>
          <UserScore
            username={userName}
            score={leaderboardPersonal.score}
            lastLeaderboardMovement={leaderboardPersonal.lastLeaderboardMovement}
            leaderboardPosition={leaderboardPersonal.leaderboardPosition}
            receivedBadgeUuids={leaderboardPersonal.receivedBadgeUuids}
            isSearchInProgress={isSearchInProgress}
            leaderboardMeta={leaderboardMeta}
          />
        </BottomBarWrapper>
      </InnerContent>
    </AlgoliaInstantSearch>
  )
}
