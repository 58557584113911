import { Switch } from "@material-ui/core"
import React from "react"
import { connectToggleRefinement } from "react-instantsearch-dom"

const LoggedInUsersFilterRaw: React.FC<any> = ({ currentRefinement, refine, label }) => (
  <>
    <Switch
      checked={currentRefinement}
      onChange={() => {
        refine(!currentRefinement)
      }}
      color="secondary"
    />
    {label}
  </>
)

export const LoggedInUsersFilter = connectToggleRefinement(LoggedInUsersFilterRaw)
