import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"
import { usePubNub } from "pubnub-react"
import { useCallback, useContext } from "react"

export const useSendMessageReaction = (messageTimetoken: number, channelId: string) => {
  const pubnub = usePubNub()
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)

  return useCallback(
    (index: number) => {
      pubnub
        .addMessageAction({
          channel: channelId,
          messageTimetoken: String(messageTimetoken),
          action: {
            type: "reaction",
            value: `${index}`,
          },
        })
        .catch((e) => {
          airbrakeNotifier.notify({ error: "addMessageAction failed.", params: { error: e } })
        })
    },
    [pubnub, channelId, messageTimetoken, airbrakeNotifier],
  )
}
