import React from "react"
import { BrowserRouter, Route } from "react-router-dom"
import { useDisableOnContextMenu } from "../../hooks/useDisableOnContextMenu"
import { ThemeSetup } from "./ThemeSetup"
import { IntercomSetup } from "./IntercomSetup"
import { Router } from "./Router"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "@fontsource/roboto"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "lightgallery/css/lightgallery.css"
import "lightgallery/css/lg-video.css"
import { FingerprintHashContextProvider } from "./FingerprintHashContextProvider"
import { EventSetupContextProvider } from "./EventSetupContextProvider"

export const App: React.FC = () => {
  useDisableOnContextMenu()

  return (
    <BrowserRouter>
      <FingerprintHashContextProvider>
        <Route path="/:eventSlug">
          <EventSetupContextProvider>
            <IntercomSetup>
              <ThemeSetup>
                <Router />
              </ThemeSetup>
            </IntercomSetup>
          </EventSetupContextProvider>
        </Route>
      </FingerprintHashContextProvider>
    </BrowserRouter>
  )
}
