import React from "react"
import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { getStageChannels } from "store/domain/pubnubStore/channels/stageChannels"
import { useSelectStage } from "hooks/useSelectStage"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { transparentize } from "polished"
import { ExternalLinks } from "features/baseBlocks/ExternalLinks"
// import { useAssets } from "store/domain/event"

const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.custom.colors.mobile.main.background};
  color: ${({ theme }) => theme.custom.colors.mobile.main.color};
`

// const StageImage = styled.img`
//   width: 100%;
//   display: flex;
// `

const StageName = styled(Typography)`
  width: 100%;
  position: absolute;
  bottom: 0;
  color: white;
  background: #00000063;
  font-weight: bold;
  padding: 12px 16px;
`

const StageDescription = styled(Typography)`
  padding: 8px 14px;
  color: ${({ theme }) => transparentize(0.5, theme.custom.colors.mobile.main.color)};
`

const ExternalLinksStyled = styled(ExternalLinks)`
  color: ${({ theme }) => theme.custom.colors.mobile.main.color};
`

export const StagesList: React.FC<{ navigateToCurrentStage: () => void; className?: string }> = ({
  navigateToCurrentStage,
  className,
}) => {
  const stageChannels = useSelector(getStageChannels)
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const selectStage = useSelectStage()
  // const assets = useAssets()

  const handleStageSelect = (stageId: string) => {
    navigateToCurrentStage()
    selectStage(stageId)
  }

  return (
    <Wrapper className={className}>
      {stageChannels.map((stageChannelFromMap) => (
        <Box
          key={stageChannelFromMap.id + Math.random()}
          onClick={() => handleStageSelect(stageChannelFromMap.id)}
          mb={2}
        >
          <Box position="relative">
            {/* <StageImage src={assets.stageImages[stageChannelFromMap.custom.display_type]} /> // todo */}

            <StageName variant="h4">
              {stageChannelFromMap.name} {stageChannelFromMap.id === currentStageChannel.id && <>&nbsp;✓</>}
            </StageName>
          </Box>

          {stageChannelFromMap.custom.description && (
            <StageDescription>{stageChannelFromMap.custom.description}</StageDescription>
          )}
        </Box>
      ))}

      <ExternalLinksStyled showIcon />
    </Wrapper>
  )
}
