import React from "react"
import styled from "styled-components"
import IFrame from "react-iframe"

const IFrameStyled = styled(IFrame)`
  width: 100%;
  height: 100%;
  background: white;
`

export const PrePostEventIframe: React.FC<{ iframeHoldingPageUrl: string }> = ({ iframeHoldingPageUrl }) => {
  return (
    <>
      <IFrameStyled
        url={iframeHoldingPageUrl}
        allow="microphone;camera;autoplay;display-capture;fullscreen"
        frameBorder={0}
      />
    </>
  )
}
