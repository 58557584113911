import React from "react"
import { Box, Button, Grid } from "@material-ui/core"
import styled from "styled-components"

const ButtonStyled = styled(Button)`
  width: 100%;
  background-color: #ffd645;
  color: #001aff;
  border: 2px solid #001aff;
  margin-bottom: 10px;
`

type Props = {
  onClick: () => void
  buttonText: string
  cardMainTextBorderX: number
}

export const LobbyStageCardButton: React.FC<Props> = ({ onClick, buttonText, cardMainTextBorderX }) => (
  <Grid item sm={12} md={7} lg={5} xl={5}>
    <Box mx={cardMainTextBorderX} mt={3} borderRadius={10}>
      <ButtonStyled onClick={onClick} variant="contained">
        <b>{buttonText}</b>
      </ButtonStyled>
    </Box>
  </Grid>
)
