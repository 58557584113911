import React from "react"
/**
 * Our project redefines LightGallery here.
 * so only occasion allowed importing LightGallery directly from lightgallery is here where we redefine it.
 */
// eslint-disable-next-line no-restricted-imports
import LightGalleryOriginal, { LightGalleryProps } from "lightgallery/react"

type Props = Omit<LightGalleryProps, "licenseKey">

export const LightGallery: React.FC<Props> = ({ children, ...restProps }) => {
  return (
    <LightGalleryOriginal licenseKey="3EFF8366-B8AD4909-BEBCF767-47E5779B" {...restProps}>
      {children}
    </LightGalleryOriginal>
  )
}
