import { useEffect } from "react"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { useDispatch } from "store/setup/useDispatch"
import { setStageMessages } from "store/domain/layout"

export const useSetStageMessages = () => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setStageMessages(!isXs))
  }, [isXs, dispatch])
}
