import styled from "styled-components"

export const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.custom.colors.messagesPanel.gifs.picker.background};

  ${({ theme }) => theme.breakpoints.only("xs")} {
    width: ${({ theme }) => theme.spacing(40)}px;
  }
`

export const Search = styled.input`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  height: ${({ theme }) => theme.spacing(4.5)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-size: auto 18px;
  background-position: center right;
  background-repeat: no-repeat;

  background-image: unset;

  :focus {
    outline: none;
  }
`

export const Scroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`

export const Results = styled.div`
  display: flex;
`

export const Column = styled.div`
  width: 200px;
  :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const GifWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  cursor: pointer;
`

export const Marker = styled.div``
