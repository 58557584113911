import React from "react"
import { Box, Typography } from "@material-ui/core"

type Props = { icon: any; title: string; detailValue: string }

export const DetailSection: React.FC<Props> = ({ icon, title, detailValue }) => {
  return (
    <Box display="flex" width="100%" mb={2}>
      <Box px={1}>{icon}</Box>

      <Typography component="span">
        <Box display="flex">
          <Box px={1} fontWeight="fontWeightBold">
            {title}:{" "}
          </Box>
          <Box px={1}>{detailValue}</Box>
        </Box>
      </Typography>
    </Box>
  )
}
