import React from "react"
import { Chip, Box } from "@material-ui/core"
import styled from "styled-components"
import CloseIcon from "@material-ui/icons/Close"
import { ChipType } from "../ChipsPanel"

const ChipWrapper = styled(Chip)`
  background-color: white;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
`

type Props = { addedChips: ChipType[]; removeChip: (guestIdArg: string) => void }

export const AddedChipList: React.FC<Props> = ({ addedChips, removeChip }) => (
  <Box display="flex" flexWrap="wrap">
    {addedChips.map((chip) => (
      <Box key={chip.id} mb={1} ml={1}>
        <ChipWrapper
          variant="outlined"
          label={chip.label}
          onDelete={() => removeChip(chip.id)}
          deleteIcon={<CloseIcon />}
        />
      </Box>
    ))}
  </Box>
)
