import { css } from "styled-components"

export const reactSlickActiveDotColor = (color: string) => css`
  .slick-dots {
    .slick-active {
      button {
        :before {
          color: ${color};
        }
      }
    }
  }
`
