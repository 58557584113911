import React from "react"
import { HeadTags } from "./HeadTags"
import { useSetStageMessages } from "./useSetStageMessages"
import { useSetNavMenuOpenness } from "./useSetNavMenuOpenness"
import { useInitializeAnalytics, useInitializeReactGA } from "./analyticsHooks"

export const AnalyticsAndInitialSetup: React.FC = ({ children }) => {
  useInitializeReactGA()
  useInitializeAnalytics()
  useSetStageMessages()
  useSetNavMenuOpenness()

  return (
    <>
      <HeadTags />

      {children}
    </>
  )
}
