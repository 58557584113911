import { useEffect, RefObject } from "react"

type Props = {
  rotateHandle: RefObject<HTMLDivElement>
  rotateElement: RefObject<HTMLDivElement>
  onRotateStop: (angle: number) => void
  angle?: number
}

export const useRotateHandle = ({
  rotateHandle,
  rotateElement,
  onRotateStop,
  angle: startAngleArg = 0,
}: Props): void => {
  useEffect(() => {
    if (rotateHandle && rotateElement) {
      let active = false
      let angle = 0
      let rotation = 0
      let startAngle = startAngleArg
      let center = { x: 0, y: 0 }
      const R2D = 180 / Math.PI

      const rotateByAngle = (rotateAngle: number): void => {
        if (rotateElement.current) {
          rotateElement.current.style.webkitTransform = "rotate(" + (angle + rotateAngle) + "deg)"
        }
      }

      if (startAngleArg !== 0) {
        rotateByAngle(startAngleArg)

        angle = startAngleArg
      }

      const start = function (clientX: number, clientY: number): void {
        if (rotateElement.current) {
          const bb = rotateElement.current.getBoundingClientRect()
          const t = bb.top
          const l = bb.left
          const h = bb.height
          const w = bb.width

          center = {
            x: l + w / 2,
            y: t + h / 2,
          }

          const x = clientX - center.x
          const y = clientY - center.y

          startAngle = R2D * Math.atan2(y, x)

          active = true
        }
      }

      const rotate = function (clientX: number, clientY: number): void {
        const x = clientX - center.x
        const y = clientY - center.y
        const d = R2D * Math.atan2(y, x)

        rotation = d - startAngle

        rotateByAngle(rotation)
      }

      const stop = function (): void {
        if (active) {
          angle += rotation

          onRotateStop(angle)
        }

        active = false
      }

      const init = (): void => {
        if (rotateHandle.current) {
          const onStart = (event: MouseEvent | TouchEvent, clientX: number, clientY: number) => {
            event.preventDefault()
            event.stopImmediatePropagation()

            start(clientX, clientY)
          }

          rotateHandle.current.addEventListener(
            "mousedown",
            (event: MouseEvent) => {
              onStart(event, event.clientX, event.clientY)
            },
            false,
          )

          rotateHandle.current.addEventListener(
            "touchstart",
            (event: TouchEvent) => {
              onStart(event, event.touches[0].clientX, event.touches[0].clientX)
            },
            false,
          )

          document.addEventListener("mousemove", (event: MouseEvent) => {
            if (active === true) {
              event.preventDefault()
              rotate(event.clientX, event.clientY)
            }
          })

          document.addEventListener("touchmove", (event: TouchEvent) => {
            if (active === true) {
              rotate(event.touches[0].clientX, event.touches[0].clientX)
            }
          })

          document.addEventListener("mouseup", stop)

          document.addEventListener("touchend", stop)
        }
      }

      init()
    }
  }, [rotateHandle, rotateElement, startAngleArg, onRotateStop])
}
