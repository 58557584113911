import { useCallback, useEffect, useState } from "react"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { snackbarUtils } from "components/snackbar/snackbarUtils"
import { PhotoboothPhotoHits } from "components/algolia/types"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { useApi } from "api/useApi"

export const useGroupPhotoUtils = ({
  onSetGroupPhotoToConfirm,
  onConfirmGroupPhoto,
}: {
  onSetGroupPhotoToConfirm: () => void
  onConfirmGroupPhoto: () => void
}) => {
  const { userName } = useSelector(getCurrentPlatformUser)
  const { api } = useApi()
  const { maxNumberOfGroupPhotoSelections } = useSelector(getEvent)
  const [addedPhotosOfGroup, setAddedPhotosOfGroup] = useState<PhotoboothPhotoHits[]>([])
  const [groupPhotoToConfirm, setGroupPhotoToConfirm] = useState<string | null>(null)
  const [defaultRefinement, setDefaultRefinement] = useState<string | null>(null)

  useEffect(() => {
    setDefaultRefinement(addedPhotosOfGroup.length ? null : userName)
  }, [addedPhotosOfGroup, userName])

  const uuidsOfAddedPhotosOfGroup = addedPhotosOfGroup.map(({ uuid }) => uuid)

  const addPhotoOfGroup = useCallback(
    (newPhoto: PhotoboothPhotoHits) => {
      if (addedPhotosOfGroup.findIndex((photo) => photo.uuid === newPhoto.uuid) !== -1) {
        snackbarUtils.error("You have added this photo already")
      } else if (addedPhotosOfGroup.length >= (maxNumberOfGroupPhotoSelections || 0)) {
        snackbarUtils.error("Maximum number of Selfies selected")
      } else {
        setAddedPhotosOfGroup((oldPhotos) => [...oldPhotos, newPhoto])
      }
    },
    [addedPhotosOfGroup, maxNumberOfGroupPhotoSelections],
  )

  const removePhotoOfGroup = useCallback((uuidArg: string) => {
    setAddedPhotosOfGroup((oldPhotos) => oldPhotos.filter((photo) => photo.uuid !== uuidArg))
  }, [])

  const handleCreateGroupPhotoForConfirmation = async ({ onFinish }: { onFinish: () => void }) => {
    try {
      const groupPhotoResult = await api.photobooth.groupPhoto.createGroupPhoto({
        preview: true,
        photoUuids: uuidsOfAddedPhotosOfGroup,
      })

      onFinish()

      setGroupPhotoToConfirm(groupPhotoResult)

      onSetGroupPhotoToConfirm()
    } catch (e) {
      onFinish()

      console.error(e)
    }
  }

  const handleConfirmGroupPhoto = async ({ onFinish }: { onFinish: () => void }) => {
    try {
      await api.photobooth.groupPhoto.createGroupPhoto({ preview: false, photoUuids: uuidsOfAddedPhotosOfGroup })

      onFinish()

      setAddedPhotosOfGroup([])
      setGroupPhotoToConfirm(null)

      onConfirmGroupPhoto()
    } catch (e) {
      onFinish()

      console.error(e)
    }
  }

  return {
    addedPhotosOfGroup,
    addPhotoOfGroup,
    removePhotoOfGroup,
    groupPhotoToConfirm,
    handleCreateGroupPhotoForConfirmation,
    handleConfirmGroupPhoto,
    defaultRefinement,
  }
}

export type GroupPhotoUtils = ReturnType<typeof useGroupPhotoUtils>
