import { EventType } from "api/apiFunctions/event"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "store/setup/storeTypes"
import { shouldNotBeSelectedUntilPresentErrorMessage } from "store/setup/guaranteedSelectorsUtils"
import invariant from "invariant"
import { createSelector } from "@reduxjs/toolkit"
import { useSelector } from "store/setup/useSelector"

type EventState = EventType | null

const initialState: EventState = null

export const eventSlice = createSlice({
  name: "event",
  initialState: initialState as EventState,
  reducers: {
    setEvent: (state, action: PayloadAction<EventType>) => action.payload,
    updateEvent: (state, action: PayloadAction<Partial<EventType>>) => {
      if (state) {
        state = { ...state, ...action.payload }
      }
    },
  },
})

export const { setEvent, updateEvent } = eventSlice.actions

export const getEvent = createSelector(
  (state: AppState): EventType => {
    const { event } = state

    invariant(event !== null, shouldNotBeSelectedUntilPresentErrorMessage)

    return event
  },
  (value) => value,
)

export const useAssets = () => {
  const { assets } = useSelector(getEvent)

  return assets
}
