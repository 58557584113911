import { css } from "styled-components"

export const backgroundImageOrColor = css<{ $backgroundSrc: string; $setBackgroundImage: boolean }>`
  color: ${({ theme }) => theme.custom.colors.color};

  ${({ theme, $backgroundSrc, $setBackgroundImage }) =>
    $setBackgroundImage
      ? `
        background-image: url("${$backgroundSrc}");
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        height: 100%;
      `
      : `
        background-color: ${theme.custom.colors.background};
      `}
`
