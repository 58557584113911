import React from "react"
import { Preview } from "react-dnd-multi-backend"
import { dndItemTypes } from "../../../dndItemInfo"
import { StickerDragPreviewImg } from "../../shared.style"

const generatePreview = ({ itemType, item, style }: any) => {
  return itemType === dndItemTypes.STICKER ? <StickerDragPreviewImg src={item.src} style={{ ...style }} /> : null
}

export const StickerDragPreview: React.FC = () => <Preview generator={generatePreview} />
