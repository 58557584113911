import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"

export const fetchBreakoutVideoCallToken =
  (eventUuid: string) =>
  ({ breakoutUuid, attendanceUuid }: { breakoutUuid: string; attendanceUuid: string }): Promise<string> =>
    baseApi
      .post<string>({
        url: apiUrls.breakout,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          breakout_uuid: breakoutUuid,
          uuid: attendanceUuid,
        },
      })
      .then(({ data }) => data)
