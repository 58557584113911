import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { ExpoboothType } from "api/apiFunctions/expobooth"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import WebIcon from "@material-ui/icons/Web"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt"
import { useParams } from "react-router-dom"
import { ExpoboothShareDetail } from "./ExpoboothShareDetails"
import { ExpoboothSocials } from "./ExpoboothSocials"
import { useSelector } from "store/setup/useSelector"
import { getStageChannels } from "store/domain/pubnubStore/channels/stageChannels"
import { ExpoboothVideoRoom } from "./ExpoboothVideoRoom"
import { STAGE_DISPLAY_TYPES } from "constants/general"
import { useSelectStage } from "hooks/useSelectStage"
import { useApi } from "api/useApi"
import { ExpoboothMedia } from "./ExpoboothMedia"

const RootWrapper = styled(Box)`
  /* padding: ${({ theme }) => theme.spacing(1, 0)};

  ${({ theme }) => theme.breakpoints.only("sm")} {
    padding: ${({ theme }) => theme.spacing(3, 0.5)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(2, 1)};
  } */

  overflow-y: auto;
  height: 100%;
`

const InnerWrapper = styled(Box)`
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TopCardWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`

const BottomContentWrapper = styled(Box)`
  margin: ${({ theme }) => theme.spacing(1)}px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) => theme.spacing(2.5, 4)};
  }
  color: white;
`

const SponsorBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

const SponsorImage = styled.img`
  width: 30px;
  height: 20px;
`

const TopCardGridWrapper = styled(Grid)`
  ${({ theme }) => `
    background-color: #e3e3e3;
    border-top-left-radius: ${theme.spacing(1)}px;
    border-top-right-radius: ${theme.spacing(1)}px;
    padding: ${theme.spacing(2.5, 4)};
  `}
`

const ResourcesButtonWrapper = styled(Button)`
  margin: 5px 5px 0px 5px;
  padding: 6px 24px;
  ${({ theme }) => theme.breakpoints.only("xs")} {
    width: 100%;
  }
`

const DescriptionTypography = styled(Typography)`
  font-weight: 300;
  margin-top: ${({ theme }) => theme.spacing(0.7)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1.2)}px;
`

const TitleTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.2)}px;
`

const SummeryTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(0.7)}px;
`

export const Expobooth: React.FC<{ className?: string }> = ({ className }) => {
  const [expoboothDetail, setExpoboothDetail] = useState<ExpoboothType | null>(null)
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const { expoboothUuid } = useParams<{ expoboothUuid: string }>()
  const expoChannel = useSelector(getStageChannels).find(
    (item) => item.custom.display_type === STAGE_DISPLAY_TYPES.expo,
  )
  const selectStage = useSelectStage()
  const { api } = useApi()

  const topCardPadding = isXs ? 0 : 2

  useEffect(() => {
    const innerFn = async () => {
      try {
        const booth = await api.expobooth.fetchExpobooth({ expoboothUuid })

        setExpoboothDetail(booth)
      } catch (error) {
        console.error(error)
      }
    }

    innerFn()
  }, [expoboothUuid, api])

  return (
    <>
      {expoboothDetail && (
        <RootWrapper>
          <InnerWrapper>
            <Box>
              <Button
                onClick={() => {
                  if (expoChannel) {
                    selectStage(expoChannel.id)
                  }
                }}
                color="primary"
                variant="contained"
                startIcon={<ArrowBackIcon />}
              >
                BACK TO BOOTHS
              </Button>
            </Box>

            <Box mt={1} className={className} display="flex" overflow="auto" flexDirection="column">
              <TopCardWrapper mb={2}>
                <TopCardGridWrapper container direction="row">
                  <Grid item xs={12} md={8}>
                    <Box px={topCardPadding}>
                      <Box sx={{ borderBottom: "1px solid black" }} pb={1} mb={2}>
                        <TitleTypography variant="h4">{expoboothDetail.title}</TitleTypography>

                        <SummeryTypography variant="body1">{expoboothDetail.summary}</SummeryTypography>

                        {isDownSm && (
                          <ExpoboothShareDetail
                            expoboothUuid={expoboothUuid}
                            mainImageUrl={expoboothDetail.mainImageUrl}
                            title={expoboothDetail.title}
                          />
                        )}
                        <DescriptionTypography variant="body2">{expoboothDetail.description}</DescriptionTypography>
                      </Box>

                      <Grid container direction="row">
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            startIcon={<WebIcon />}
                            onClick={() => window.open(expoboothDetail.website, "_blank", "noopener,noreferrer")}
                          >
                            VISIT WEBSITE
                          </Button>
                        </Grid>

                        <ExpoboothSocials
                          facebook={expoboothDetail.facebook}
                          instagram={expoboothDetail.instagram}
                          twitter={expoboothDetail.twitter}
                          youtube={expoboothDetail.youtube}
                          pinterest={expoboothDetail.pinterest}
                          linkedin={expoboothDetail.linkedin}
                          tiktok={expoboothDetail.tiktok}
                        />

                        <SponsorBox>
                          <Grid container direction="row">
                            <Box mt={0.7}>
                              <SponsorImage src={expoboothDetail.tierImageUrl} alt="tier image" />
                            </Box>
                            <Box mt={0.7} ml={0.3}>
                              <Typography variant="caption">{expoboothDetail.tierName}</Typography>
                            </Box>
                          </Grid>
                        </SponsorBox>
                      </Grid>
                    </Box>
                  </Grid>
                  {!isDownSm && (
                    <ExpoboothShareDetail
                      expoboothUuid={expoboothUuid}
                      mainImageUrl={expoboothDetail.mainImageUrl}
                      title={expoboothDetail.title}
                    />
                  )}
                </TopCardGridWrapper>
              </TopCardWrapper>

              <BottomContentWrapper>
                {expoboothDetail && <ExpoboothVideoRoom expoboothDetail={expoboothDetail} />}

                {(expoboothDetail.imageUrls.length !== 0 || expoboothDetail.videoUrls.length !== 0) && (
                  <Box>
                    <Box mt={3} pl={1} mb={1}>
                      <Typography variant="h6">Media</Typography>
                    </Box>

                    <ExpoboothMedia imageUrls={expoboothDetail.imageUrls} videoUrls={expoboothDetail.videoUrls} />
                  </Box>
                )}

                {expoboothDetail.documentUrlsDetails.length !== 0 && (
                  <Box pb={2}>
                    <Box mt={3} pl={1} mb={1}>
                      <Typography variant="h6">Resource(s)</Typography>
                    </Box>

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Box mr={1} mb={2}>
                        {expoboothDetail.documentUrlsDetails.map((currentDocument, index) => (
                          <ResourcesButtonWrapper
                            key={index}
                            onClick={() => window.open(currentDocument.link, "_blank", "noopener,noreferrer")}
                            color="primary"
                            variant="contained"
                            startIcon={<SystemUpdateAltIcon />}
                          >
                            {currentDocument.name}
                          </ResourcesButtonWrapper>
                        ))}
                      </Box>
                    </Grid>
                  </Box>
                )}
              </BottomContentWrapper>
            </Box>
          </InnerWrapper>
        </RootWrapper>
      )}
    </>
  )
}
