import React, { useCallback } from "react"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { snackbarUtils } from "components/snackbar/snackbarUtils"
import { RoomUser } from "."
import { getCurrentPlatformUser } from "store/domain/authentication"

export const useAddedUsersUtils = () => {
  const { maxNumberInVideoRoom } = useSelector(getEvent)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const [addedUsers, setAddedUsers] = React.useState<RoomUser[]>([])

  const addRoomUser = useCallback(
    (newUser: RoomUser) => {
      if (currentPlatformUser.attendanceUuid === newUser.guest_id) {
        snackbarUtils.warning("You cannot add yourself to a Chat.")
      } else if (addedUsers.findIndex((user) => user.guest_id === newUser.guest_id) !== -1) {
        snackbarUtils.warning("You have added this person already")
      } else if (addedUsers.length >= (maxNumberInVideoRoom || 0)) {
        snackbarUtils.warning("You reached the maximum number of attendees for a Call/Chat")
      } else {
        setAddedUsers((oldAddedUsers) => [...oldAddedUsers, newUser])
      }
    },
    [setAddedUsers, addedUsers, maxNumberInVideoRoom, currentPlatformUser],
  )

  const removeRoomUser = useCallback(
    (guestIdArg: string) => {
      setAddedUsers((oldAddedUsers) => oldAddedUsers.filter((user) => user.guest_id !== guestIdArg))
    },
    [setAddedUsers],
  )

  return {
    addedUsers,
    addRoomUser,
    removeRoomUser,
    clearAddedUsers: () => {
      setAddedUsers([])
    },
  }
}
