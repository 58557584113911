import React from "react"
import { useSelector } from "store/setup/useSelector"
import { useDispatch } from "store/setup/useDispatch"
import {
  getChannelTextMessageDraft,
  updateTextMessageDraft,
  discardTextMessageDraft,
} from "store/domain/textMessageDrafts"
import { useSendMessage } from "store/domain/pubnubStore/messages/useSendMessage"
import { MessageEditor } from "./MessageEditor"
import styled from "styled-components"
import { OnGifSelect } from "../gifs/GifInput/GifInputContent/GifPicker"
import { IGif } from "runtypes/giphy"

const Wrapper = styled.div<{ forMobile: boolean }>`
  ${({ forMobile, theme }) =>
    forMobile
      ? `
        background: ${theme.custom.colors.mobile.messagesPanel.messageWriter.background};
        color: ${theme.custom.colors.mobile.messagesPanel.messageWriter.color};
      `
      : `
        background: ${theme.custom.colors.messagesPanel.message.background};
        color: ${theme.custom.colors.messagesPanel.message.color};
        margin-top: 2px;
        border-radius: ${theme.spacing(2)}px;
    `};
`

const InnerWrapper = styled.div`
  height: auto;
  display: flex;
`

type Props = {
  channelId: string
  inputLabel: string
  focusAutomatically: boolean
  forMobile: boolean
  scrollToBottomMessage: () => void
}

export const MessageWriter: React.FC<Props> = ({
  channelId,
  inputLabel,
  focusAutomatically,
  forMobile,
  scrollToBottomMessage,
}) => {
  const message = useSelector(getChannelTextMessageDraft(channelId)) || { text: "" }
  const dispatch = useDispatch()
  const { sendGifMessage, sendTextMessage } = useSendMessage()

  const onTextChange = (text: string): void => {
    dispatch(updateTextMessageDraft({ channelId, message: { ...message, text } }))
  }

  const onGifSelect: OnGifSelect = (gif: IGif, query: string): void => {
    sendGifMessage({
      query,
      gif,
      channelId,
    })
    scrollToBottomMessage()
  }

  const onSendTextMessage = () => {
    sendTextMessage({ text: message.text, channelId })
    dispatch(discardTextMessageDraft({ channelId }))
    scrollToBottomMessage()
  }

  return (
    <Wrapper forMobile={forMobile}>
      <InnerWrapper>
        <MessageEditor
          text={message.text}
          onTextChange={onTextChange}
          onGifSelect={onGifSelect}
          onSendTextMessage={onSendTextMessage}
          inputLabel={inputLabel}
          focusAutomatically={focusAutomatically}
        />
      </InnerWrapper>
    </Wrapper>
  )
}
