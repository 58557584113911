import { baseApi } from "../../baseApi"
import { apiUrls } from "../../apiUrls"
import { getObjectSingleValue } from "independent/utils/getObjectSingleValue"
import { addSnackbarInCatch, addSnackbarInThen } from "api/utils"

export type ExpoboothType = {
  title: string
  description: string
  summary: string
  facebook: string
  twitter: string
  instagram: string
  youtube: string
  tiktok: string
  pinterest: string
  linkedin: string
  website: string
  tierName: string
  tierImageUrl: string
  logoUrl: string
  uuid: string
  displayVideoBooth: boolean
  attendanceUuids: string[]
  mainImageUrl: string
  documentUrlsDetails: {
    name: string
    link: string
  }[]
  imageUrls: string[]
  videoUrls: string[]
  id: number
  dailyRoomName: string
}

type ExpoboothResponseType = {
  booths: Record<string, ExpoboothType>
}

export const fetchExpobooth =
  (eventUuid: string) =>
  ({ expoboothUuid }: { expoboothUuid: string }): Promise<ExpoboothType> =>
    baseApi
      .get<ExpoboothResponseType>({
        url: apiUrls.expobooth.single(expoboothUuid),
        headers: {
          EventToken: eventUuid,
        },
      })
      .then(({ data }) => data)
      .then((data) => getObjectSingleValue(data.booths) as ExpoboothType)

export const shareYourDetailsToExpobooth =
  (eventUuid: string) =>
  ({ expoboothUuid }: { expoboothUuid: string }) => {
    baseApi
      .post<string>({
        url: apiUrls.expobooth.shareYourDetails,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          booth_uuid: expoboothUuid,
        },
      })
      .then(({ data }) => data)
      .then(addSnackbarInThen)
      .catch(addSnackbarInCatch())
  }

export const fetchExpoboothVideoCallToken =
  (eventUuid: string) =>
  ({ expoboothUuid }: { expoboothUuid: string }): Promise<string> =>
    baseApi
      .post<string>({
        url: apiUrls.expobooth.videoCallToken,
        headers: {
          EventToken: eventUuid,
        },
        params: {
          booth_uuid: expoboothUuid,
        },
      })
      .then(({ data }) => data)
