import React from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import { LobbyStageCardButton } from "./LobbyStageCardButton/LobbyStageCardButton"
import styled from "styled-components"

const Wrapper = styled(Box)<{ $backgroundImage: string }>`
  ${({ $backgroundImage }) => `
    background-image: url(${$backgroundImage});
    background-repeat: round;
  `}
`

const TypographyStyled = styled(Typography)`
  font-weight: 600;
`
const DescriptionTypography = styled(Typography)`
  color: white;
`

interface Props {
  onClick: () => void
  match600: boolean
  match960: boolean
  cardMainText: string
  cardButtonText: string
  backgroundImage: string
  cardDescriptionText: string
}

export const LobbyStageCardItem: React.FC<Props> = ({
  onClick,
  match600,
  match960,
  cardMainText,
  cardButtonText,
  backgroundImage,
  cardDescriptionText,
}) => {
  const cardMainTextBorderX = !match960 && match600 ? 1.5 : 3

  return (
    <Wrapper borderRadius={10} lineHeight={1.6} m={3} $backgroundImage={backgroundImage}>
      <Grid container>
        <Grid item sm={12} md={5} lg={7} xl={7}>
          <Box mx={cardMainTextBorderX} mt={3} color={"white"}>
            <TypographyStyled variant="h4">{cardMainText}</TypographyStyled>
          </Box>
        </Grid>
        {match960 && (
          <LobbyStageCardButton
            onClick={onClick}
            buttonText={cardButtonText}
            cardMainTextBorderX={cardMainTextBorderX}
          />
        )}
      </Grid>
      <Grid container direction="row">
        <Box mb={2} mx={3}>
          <DescriptionTypography variant="body2" gutterBottom>
            {cardDescriptionText}
          </DescriptionTypography>
        </Box>
      </Grid>
      {!match960 && (
        <LobbyStageCardButton onClick={onClick} buttonText={cardButtonText} cardMainTextBorderX={cardMainTextBorderX} />
      )}
    </Wrapper>
  )
}
