import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MessageActionEvent } from "runtypes/messageAction"
import { AppState } from "store/setup/storeTypes"

type MessageDeletionsSlice = {
  deletedMessages: string[]
}

const initialState: MessageDeletionsSlice = {
  deletedMessages: [],
}

export const messageDeletionsSlice = createSlice({
  name: "messageDeletions",
  initialState,
  reducers: {
    deleteMessage: (state, action: PayloadAction<MessageActionEvent>) => {
      const {
        payload: {
          data: { messageTimetoken },
        },
      } = action

      state.deletedMessages.push(messageTimetoken)
    },
  },
})

export const { deleteMessage } = messageDeletionsSlice.actions

const getAllMessageDeletions = (state: AppState) => state[messageDeletionsSlice.name]

export const getDeletedMessages = (state: AppState) => getAllMessageDeletions(state).deletedMessages
