import React from "react"
import styled from "styled-components"
import { BottomBarWrapper } from "../PrepareAndViewPhotos.style"
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined"
import { Button } from "@material-ui/core"

const BottomBarWrapperStyled = styled(BottomBarWrapper)`
  margin-top: 0;
`

export const BottomBarOfTakePhoto: React.FC<{ handleGoToPhotoGallery: () => void }> = ({ handleGoToPhotoGallery }) => {
  return (
    <BottomBarWrapperStyled>
      <Button variant="contained" color="secondary" startIcon={<PhotoOutlinedIcon />} onClick={handleGoToPhotoGallery}>
        PHOTO GALLERY
      </Button>
    </BottomBarWrapperStyled>
  )
}
