import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useAnimate } from "react-simple-animate"
import styled from "styled-components"
import { userSelectNone } from "independent/styles/userSelectNone"
import { getRandomBoolean, getRandomFloatBetween, getRandomInteger } from "independent/utils/math"
import { ReactionImage } from "components/ReactionImage"

const Wrapper = styled.span`
  position: absolute;
  z-index: 99;
  cursor: pointer;
  ${userSelectNone}
`

export const useAnimatedReactions = ({ index }: { index: number }) => {
  const [animatedReactions, setAnimatedReactions] = useState<{ index: number; id: string }[]>([])

  const removeAnimatedReaction = (id: string) => {
    setAnimatedReactions((prevReaction) => prevReaction.filter((reactionItem) => reactionItem.id !== id))
  }

  const addAnimatedReaction = () => {
    setAnimatedReactions([...animatedReactions, { index, id: uuidv4() }])
  }

  return { animatedReactions, removeAnimatedReaction, addAnimatedReaction }
}

export const AnimatedReaction: React.FC<{ index: number; remove: () => void }> = ({ index, remove }) => {
  const { play, style } = useAnimate({
    start: {
      transform: "translate(0px, 0px)",
      opacity: 1,
    },
    end: {
      transform: `translate(${getRandomBoolean() ? "+" : "-"}${getRandomInteger(8, 80)}px, -${getRandomInteger(
        150,
        250,
      )}px)`,
      opacity: 0,
    },
    duration: getRandomFloatBetween(0.8, 1.5),
    // unless "complete" is not at least present "onComplete" does not run
    complete: {},
    onComplete: remove,
  })

  useEffect(() => {
    setTimeout(() => {
      play(true)
    }, 20)
  }, [play])

  return (
    <Wrapper id={`${index}`} style={{ ...style, fontSize: `${getRandomInteger(20, 40)}px` }}>
      <ReactionImage index={index} />
    </Wrapper>
  )
}
