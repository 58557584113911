import React from "react"
import { useIntercomUpdateOnce } from "hooks/useIntercomUpdateOnce"
import { useIsIntercomActive } from "store/domain/intercom"
import { createGlobalStyle } from "styled-components"

const IntercomTurnOnOffStyles = createGlobalStyle<{ isIntercomActive: boolean }>`
  ${({ isIntercomActive }) => `
    .intercom-launcher-frame, .intercom-lightweight-app {
      ${
        !isIntercomActive
          ? `
          visibility: hidden;
        `
          : ""
      }
    }
  `}
`

export const IntercomEnhancer: React.FC = ({ children }) => {
  useIntercomUpdateOnce()

  const isIntercomActive = useIsIntercomActive()

  return (
    <>
      <IntercomTurnOnOffStyles isIntercomActive={isIntercomActive} />

      {children}
    </>
  )
}
