import moment from "moment"
import { useTimeBeforeStart } from "hooks/useTimeBeforeStart"
import { useTimeLeftGeneral } from "hooks/useTimeLeftGeneral"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { useIsDuringEventQueryParam } from "./queryParams"

type EventTime = {
  duringEventTime: null | boolean
  hasEventEnded: null | boolean
}

export const useEventTime = (): EventTime => {
  const timeBeforeStart = useTimeBeforeStart()
  const { hasEventEnded } = useTimeLeftGeneral()
  const { isDuringEvent } = useIsDuringEventQueryParam()
  const event = useSelector(getEvent)

  const isPreStartTime =
    timeBeforeStart?.totalMinutes !== undefined && timeBeforeStart.totalMinutes < event.preStartAccessMinutes

  const hasEventStarted = moment().isAfter(event.startTime) || isPreStartTime

  return {
    duringEventTime: isDuringEvent || (hasEventEnded === null ? null : hasEventStarted && !hasEventEnded),
    hasEventEnded: !isDuringEvent && hasEventEnded,
  }
}
