import React from "react"
import { getEvent } from "store/domain/event"
import { AdditionalInfoDialog } from "features/baseBlocks/AdditionalInfoDialog"
import { useSelector } from "store/setup/useSelector"

export const DanceFloorAdditionalInfoDialog: React.FC = () => {
  const { dancefloorIframeLinkText, dancefloorIframeTitle, dancefloorIframeSrc } = useSelector(getEvent)

  return (
    <>
      {dancefloorIframeLinkText && dancefloorIframeTitle && dancefloorIframeSrc && (
        <AdditionalInfoDialog
          iframeLinkText={dancefloorIframeLinkText}
          iframeTitle={dancefloorIframeTitle}
          iframeSrc={dancefloorIframeSrc}
        />
      )}
    </>
  )
}
