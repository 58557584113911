import { useContext, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from "store/setup/useDispatch"
import { useSelector } from "store/setup/useSelector"
import { selectorErrorFallback } from "store/setup/guaranteedSelectorsUtils"
import { setCurrentStageChannel, getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { getStageChannels, useDoesStageChannelExist } from "store/domain/pubnubStore/channels/stageChannels"
import { defaultStageIdSelector } from "store/domain/defaultStageIdSelector"
import { STAGE_DISPLAY_TYPES } from "constants/general"
import { routerPaths } from "../routerPaths"
import { AirbrakeNotifierContext } from "contextApi/AirbrakeNotifierContext"

export const useSetStageFromParams = () => {
  const dispatch = useDispatch()
  const { airbrakeNotifier } = useContext(AirbrakeNotifierContext)

  const history = useHistory()
  const { eventSlug, stageId } = useParams<{ eventSlug: string; stageId: string }>()

  const currentStageChannel = useSelector(selectorErrorFallback(getCurrentStageChannel, null))
  const defaultStageId = useSelector(defaultStageIdSelector)
  const stageChannels = useSelector(getStageChannels)

  const doesStageChannelExist = useDoesStageChannelExist()

  useEffect(() => {
    const expoboothStage =
      routerPaths.expobooth.doesMatch(eventSlug, history.location.pathname) &&
      stageChannels.find((stageChannel) => stageChannel.custom.display_type === STAGE_DISPLAY_TYPES.expo)

    if (expoboothStage) {
      dispatch(setCurrentStageChannel(expoboothStage.id))
    } else if (doesStageChannelExist(stageId)) {
      dispatch(setCurrentStageChannel(stageId))
    } else if (
      routerPaths.authLogout.doesMatch(eventSlug, history.location.pathname) ||
      routerPaths.photobooth.doesMatch(eventSlug, history.location.pathname) ||
      routerPaths.videoRoom.doesMatch(eventSlug, history.location.pathname)
    ) {
      if (doesStageChannelExist(defaultStageId)) {
        dispatch(setCurrentStageChannel(defaultStageId))
      }
    } else {
      if (stageId) {
        airbrakeNotifier.notify({
          error: "Attempt to reach unidentified stage.",
          params: { stageAttempted: stageId },
        })
      }

      if (doesStageChannelExist(defaultStageId)) {
        history.push({
          pathname: routerPaths.stage.assemblePath(eventSlug, defaultStageId),
        })
      }
    }
  }, [
    dispatch,
    doesStageChannelExist,
    eventSlug,
    stageId,
    currentStageChannel,
    history,
    defaultStageId,
    stageChannels,
    airbrakeNotifier,
  ])

  return { stageSet: currentStageChannel !== null }
}
