import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"

export const useIntercomUpdateOnce = (): void => {
  const { update } = useIntercom()
  const currentPlatformUser = useSelector(getCurrentPlatformUser)

  useEffect(() => {
    update({
      name: currentPlatformUser.userName,
      userId: currentPlatformUser.uuid,
      userHash: currentPlatformUser.intercomHash,
    })
  }, [update, currentPlatformUser])
}
