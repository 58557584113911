import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: fit-content;
  text-align: left;
`
