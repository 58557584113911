import React, { useMemo } from "react"
import { AppMessage, MessageType } from "runtypes/message"
import { MessageFragment } from "store/domain/pubnubStore/messages"
import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import { MessageReactions } from "./MessageReactions"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { MessageTypeSwitch } from "./MessageTypeSwitch"
import { transparentize } from "polished"
import { getMessageUpdate } from "store/domain/messageUpdates"

const Wrapper = styled.div<{ isOwnersMessage: boolean; $forMobile: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 5px 10px;
  position: relative;

  ${({
    $forMobile,
    theme: {
      custom: {
        colors: {
          messagesPanel: { message },
        },
      },
    },
    isOwnersMessage,
  }) => ($forMobile ? "" : `background: ${isOwnersMessage ? message.ownersBackground : message.background}`)};
`

const Header = styled.div`
  display: flex;
  align-items: baseline;
`

const SenderName = styled(Typography)`
  text-transform: capitalize;
  font-weight: bold;
`

const TimeOfMessage = styled(Typography)<{ $forMobile: boolean }>`
  margin-left: 8px;

  ${({ theme, $forMobile }) =>
    $forMobile
      ? `
        color: ${transparentize(0.6, theme.custom.colors.mobile.messagesPanel.messageList.color)};
      `
      : ""}
`

const PhotoboothImage = styled.img`
  width: 100%;
`

type Props = {
  channelId: string
  messageFragment: MessageFragment
  disableReactions?: boolean
  timeOfMessage?: string
  forMobile: boolean
}

export const MessageListItemContent: React.FC<Props> = ({
  channelId,
  messageFragment,
  disableReactions = false,
  forMobile,
  timeOfMessage,
}) => {
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const messageUpdate = useSelector(getMessageUpdate(messageFragment.timetoken))

  const messageUpdated = useMemo(
    (): AppMessage =>
      messageUpdate
        ? {
            type: MessageType.Text,
            senderId: messageFragment.message.senderId,
            text: messageUpdate,
            meta: messageFragment.message.meta,
          }
        : messageFragment.message,
    [messageFragment, messageUpdate],
  )

  return (
    <Wrapper isOwnersMessage={messageFragment.message.senderId === currentPlatformUser.uuid} $forMobile={forMobile}>
      <Header>
        <SenderName>{messageFragment.message.meta?.senderName}</SenderName>

        {timeOfMessage && (
          <TimeOfMessage variant="caption" $forMobile={forMobile}>
            {timeOfMessage}
          </TimeOfMessage>
        )}
      </Header>

      <Box pt={0.5}>
        <MessageTypeSwitch message={messageUpdated} />
      </Box>

      {messageFragment.message.type === MessageType.Text && messageFragment.message.photoboothImageUrl && (
        <PhotoboothImage src={messageFragment.message.photoboothImageUrl} alt="Photo Booth image." />
      )}

      <Box display="flex" justifyContent="flex-end" width="100%">
        {!disableReactions && (
          <MessageReactions channelId={channelId} messageFragment={messageFragment} forMobile={forMobile} />
        )}
      </Box>
    </Wrapper>
  )
}
