import React, { useMemo } from "react"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { useSelector } from "store/setup/useSelector"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import styled from "styled-components"
import { LogoImage } from "components/LogoImage"
import { ReactionsList } from "../../baseBlocks/ReactionsList"
import { ContentToShow } from "../AppTemplate"
import { MobileTemplate } from "../MobileTemplate"
import { BackgroundWrapper } from "components/BackgroundWrapper"
import { StageMessages } from "features/assembledBlocks/StageMessages"
import { UserNameButton } from "components/UserNameButton"
import { VideoIframe } from "../../baseBlocks/VideoIframe"
import { getEvent } from "../../../store/domain/event"
import moment from "moment"
import { EventType } from "../../../api/apiFunctions/event"

const AppBarStyled = styled(AppBar)`
  ${({ theme }) => `
    position: relative;
    background: ${theme.custom.colors.appBar.background};
    color: ${theme.custom.colors.appBar.color};
    box-shadow: unset;
  `}
` as typeof AppBar

const LogoImageStyled = styled(LogoImage)`
  ${({ theme }) => `
    padding: ${theme.spacing(1)}px;
    width: ${theme.spacing(40)}px;
  `}
`

const ToolbarStyled = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`
const ContentWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
`

const StageMessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 16px 0;
  min-width: 300px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-width: 220px;
  }
`

const BottomBarWrapper = styled(Box)`
  display: flex;
  padding: 16px 20px 16px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 10px;
  }
`

const EventInfoWrapper = styled(Box)`
  padding-right: 80px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 15px;
  }
`

const ReactionsWrapper = styled(Box)`
  ${({ theme }) => `
    display: flex;
    margin: ${theme.spacing(1, 0)};
    height: min-content;

    .MuiIconButton-root {
      padding: 9px;
    }
  `}

  ${({ theme }) => theme.breakpoints.down("md")} {
    justify-content: center;
  }
`

const VideoIframeFullDimensions = styled(VideoIframe)`
  padding: 8px 16px 0 0;
  height: 100%;
  width: 100%;
`

export const SingleStageTemplate: React.FC = () => {
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const event: EventType = useSelector(getEvent)
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const timeSlot = useMemo(() => {
    const startTime = moment(event.startTime)
    const endTime = moment(event.endTime)
    return `${startTime.format("ddd DD MMM H:mm")} - ${endTime.format(
      startTime.isSame(endTime, "date") ? "H:mm" : "ddd DD MMM H:mm",
    )}`
  }, [event])

  return (
    <BackgroundWrapper>
      {isXs ? (
        <MobileTemplate contentToShow={ContentToShow.CurrentStage} />
      ) : (
        <Box display="flex" height="100%" flexDirection="column">
          <AppBarStyled>
            <ToolbarStyled>
              <LogoImageStyled />

              <UserNameButton />
            </ToolbarStyled>
          </AppBarStyled>

          <ContentWrapper>
            <Box sx={{ flex: 1 }} display="flex" height="100%" flexDirection="column">
              <Box sx={{ flex: 1 }}>
                <VideoIframeFullDimensions />
              </Box>

              <BottomBarWrapper>
                <EventInfoWrapper>
                  <Typography variant="h5">{currentStageChannel.name}</Typography>
                  <Typography variant="caption">{timeSlot}</Typography>
                  <Typography>{currentStageChannel.custom.description}</Typography>
                </EventInfoWrapper>

                {!currentStageChannel.custom.hide_reactions && (
                  <ReactionsWrapper>
                    <ReactionsList />
                  </ReactionsWrapper>
                )}
              </BottomBarWrapper>
            </Box>

            {!currentStageChannel.custom.hide_messages && (
              <StageMessageWrapper>
                <StageMessages />
              </StageMessageWrapper>
            )}
          </ContentWrapper>
        </Box>
      )}
    </BackgroundWrapper>
  )
}
