import {
  signInWithEmail,
  fetchOtcAgain,
  loginWithOtc,
  recoverAccess,
  fetchEventUser,
  putPlatformUser,
  logout,
} from "./apiFunctions/authentication"
import { useContext } from "react"
import { FingerprintHash } from "main/App/FingerprintHashContextProvider"
import { fetchEvent } from "./apiFunctions/event"

const constructApi = ({ fingerprintHash }: { fingerprintHash: string }) => ({
  initialApi: {
    authentication: {
      signInWithEmail: signInWithEmail(),
      fetchOtcAgain: fetchOtcAgain(),
      loginWithOtc: loginWithOtc({ fingerprintHash }),
      recoverAccess: recoverAccess({ fingerprintHash }),
      fetchEventUser: fetchEventUser(),
      putPlatformUser: putPlatformUser(),
      logout: logout(),
    },
    event: { fetchEvent: fetchEvent() },
  },
})

let apiReturnValue: ReturnType<typeof constructApi> | null = null

export const useInitialApi = () => {
  const fingerprintHash = useContext(FingerprintHash)

  return apiReturnValue || (apiReturnValue = constructApi({ fingerprintHash }))
}
