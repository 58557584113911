import React, { useCallback, useEffect, useState } from "react"
import { VideoDaily } from "components/VideoDaily"
import styled from "styled-components"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import { useApi } from "api/useApi"
import { mainContentWrapperCss } from "components/styles/mainContentWrapperCss"
import { MainContentHeader } from "components/MainContentHeader"
import { AttendeesPresentCount } from "./AttendeesPresentCount"
import { MingleAdditionalInfoDialog } from "features/assembledBlocks/MingleAdditionalInfoDialog"

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 5px;
  }
`

const AdditionalInfoDialogWrapper = styled(Box)`
  ${({ theme }) => theme.breakpoints.only("xs")} {
    margin-bottom: 6px;
  }
`

const MingleContentWrapper = styled(Box)`
  height: 100%;
  overflow: hidden;

  ${mainContentWrapperCss};
`

const NoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LeftMeetingText = styled(Typography)`
  font-weight: bold;
  margin-bottom: 20px;
`

const CircularProgressStyled = styled(CircularProgress)`
  color: black;
`

export const Mingle: React.FC = () => {
  const { api } = useApi()
  const [roomName, setRoomName] = useState<string | null>(null)
  const [roomToken, setRoomToken] = useState<string | null>(null)
  const [showLeftMeetingText, setShowLeftMeetingText] = useState(false)

  const setRoomCredentials = useCallback(async () => {
    setTimeout(async () => {
      const { mingleRoomName, mingleRoomToken } = await api.mingle.fetchMingleRoomNameAndToken()

      setRoomName(mingleRoomName)
      setRoomToken(mingleRoomToken)

      setShowLeftMeetingText(false)
    }, 3000)
  }, [api])

  const clearRoomCredentials = useCallback(async () => {
    setRoomName(null)
    setRoomToken(null)
  }, [])

  useEffect(() => {
    setRoomCredentials()
  }, [setRoomCredentials])

  const onLeftMeeting = useCallback(() => {
    setShowLeftMeetingText(true)

    clearRoomCredentials()

    setRoomCredentials()
  }, [clearRoomCredentials, setRoomCredentials])

  return (
    <Wrapper>
      <MainContentHeader>
        <AdditionalInfoDialogWrapper>
          <MingleAdditionalInfoDialog />
        </AdditionalInfoDialogWrapper>

        <AttendeesPresentCount />
      </MainContentHeader>

      <MingleContentWrapper>
        {roomName && roomToken ? (
          <VideoDaily showRemainingTime roomName={roomName} token={roomToken} onLeftMeeting={onLeftMeeting} />
        ) : (
          <NoContent>
            {showLeftMeetingText && <LeftMeetingText variant="h5">You have left the meeting! 👋</LeftMeetingText>}

            <CircularProgressStyled />
          </NoContent>
        )}
      </MingleContentWrapper>
    </Wrapper>
  )
}
