import { AuthenticationUtils } from "features/baseBlocks/LoginWidget/useAuthenticationUtils"
import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { routerPaths } from "../routerPaths"
import { AuthorizedContainer } from "./AuthorizedContainer"
import useEventSlug from "hooks/useEventSlug"

type Props = { authenticationUtils: AuthenticationUtils }

export const RoutesLoginProtection: React.FC<Props> = ({
  authenticationUtils: { loginCompleted, eventUser },
  children,
}) => {
  const eventSlug = useEventSlug()

  return (
    <Route
      render={({ location }) =>
        loginCompleted && eventUser ? (
          <AuthorizedContainer eventUser={eventUser}>
            <Switch>{children}</Switch>
          </AuthorizedContainer>
        ) : (
          <Redirect
            to={{
              pathname: routerPaths.login.assemblePath(eventSlug),
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
