import styled from "styled-components"
import { TooltipStylable } from "components/TooltipStylable"

export const AppBarActionTooltip = styled(TooltipStylable)`
  ${({ theme }) => `
    .MuiTooltip-tooltip {
      background: ${theme.custom.colors.standard.appBarActions.tooltipPopper.background};
      color: ${theme.custom.colors.standard.appBarActions.tooltipPopper.color};
    }
  `}
`
