import React, { useEffect, useMemo } from "react"
import { useSelector } from "store/setup/useSelector"
import { Reaction } from "./Reaction"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { usePubNub } from "pubnub-react"
import { getChannelFromEmoji } from "./utils"

const useSubscribeToReactionChannels = () => {
  const pubnub = usePubNub()
  const emojiIndex = useMemo(() => new Array(5).fill(undefined).map((_, index) => index + 1), [])

  useEffect(() => {
    pubnub.subscribe({
      channels: [...emojiIndex.map((index) => getChannelFromEmoji(index))],
    })
  }, [pubnub, emojiIndex])
}

export const ReactionsList: React.FC = () => {
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const emojiIndex = useMemo(() => new Array(5).fill(undefined).map((_, index) => index + 1), [])

  useSubscribeToReactionChannels()

  return (
    <>
      {emojiIndex.map((index) => (
        <Reaction key={index} index={index} userId={currentPlatformUser.uuid} channelId={currentStageChannel.id} />
      ))}
    </>
  )
}
