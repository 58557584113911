import { interactWithConnectTemplateIframe } from "constants/general"
import { postCloseStageMessage } from "main/App/Router/EstablishedRoute/WrapperWithStageSet/usePostMessage"
import { useCallback } from "react"
import { setEventAppDialogOpen } from "store/domain/layout"
import { useDispatch } from "store/setup/useDispatch"

export const useCloseEventAppDialog = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(setEventAppDialogOpen(false))

    interactWithConnectTemplateIframe((iframeElement) => {
      postCloseStageMessage(iframeElement)

      iframeElement.focus()
    })
  }, [dispatch])
}
