import React from "react"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { Button } from "@material-ui/core"
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp"

const ButtonStyled = styled(Button)`
  text-transform: unset;
`

export const UserNameButton: React.FC = () => {
  const { userName } = useSelector(getCurrentPlatformUser)

  return (
    <ButtonStyled color="inherit" startIcon={<AccountCircleSharpIcon fontSize="medium" />}>
      {userName}
    </ButtonStyled>
  )
}
