import React from "react"
import { Box, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import { getCurrentStageChannel } from "store/domain/currentStageContent/currentStageChannel"
import { useSelectStage } from "hooks/useSelectStage"
import { getStageChannels } from "store/domain/pubnubStore/channels/stageChannels"
import { ExternalLinks } from "../ExternalLinks"
import { transparentize } from "polished"

const ListItemStyled = styled(ListItem)`
  align-items: flex-start;
`

const ImageItem = styled.img<{ $isActive: boolean }>`
  width: ${({ theme }) => theme.spacing(4)}px;

  ${({ theme, $isActive }) =>
    $isActive
      ? `
        background: ${transparentize(0.7, theme.custom.colors.standard.mainMenu.color)};
        border-radius: 50%;
      `
      : ""}
`

const ListItemTextStyled = styled(ListItemText)<{ $horizontallySmall: boolean }>`
  .MuiTypography-root {
    ${({ $horizontallySmall }) =>
      $horizontallySmall
        ? `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;  
      `
        : `
        white-space: normal;
    `}
  }
`

type Props = {
  showIcon?: boolean
  className?: string
  onClickStage?: () => void
  horizontallySmall?: boolean
}

export const StagesList: React.FC<Props> = ({
  showIcon = false,
  className,
  onClickStage,
  horizontallySmall = false,
}) => {
  const stageChannels = useSelector(getStageChannels)
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const selectStage = useSelectStage()

  return (
    <Box display="flex" flexDirection="column" className={className}>
      <List>
        {stageChannels.map((stageChannel) => (
          <ListItemStyled
            button
            key={stageChannel.id}
            onClick={() => {
              selectStage(stageChannel.id)

              if (onClickStage) {
                onClickStage()
              }
            }}
          >
            {showIcon && (
              <Tooltip title={stageChannel.name} enterDelay={600}>
                <ListItemIcon>
                  <ImageItem
                    $isActive={currentStageChannel.id === stageChannel.id}
                    src={stageChannel.custom.icon_url}
                    alt={stageChannel.name}
                  />
                </ListItemIcon>
              </Tooltip>
            )}

            <ListItemTextStyled primary={stageChannel.name} $horizontallySmall={horizontallySmall} />
          </ListItemStyled>
        ))}
      </List>

      <Divider id="stages-list-divider" />

      <ExternalLinks showIcon={showIcon} horizontallySmall={horizontallySmall} />
    </Box>
  )
}
