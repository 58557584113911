import React from "react"
import { Wrapper } from "./GiphyMessageDisplay.style"
import { Gif, GifSize } from "../../../../../gifs/Gif"
import { GiphyMessage } from "runtypes/message"

type GiphyMessageProps = {
  message: GiphyMessage
}

/**
 * Display a GiphyMessage such as it would appear in a list of messages
 */
export const GiphyMessageDisplay: React.FC<GiphyMessageProps> = ({ message }) => {
  const gif = message.gif

  return (
    <Wrapper>
      <Gif gif={gif} size={GifSize.Preview} />
    </Wrapper>
  )
}
