import Pubnub from "pubnub"
import isArray from "lodash/isArray"
import { AirbrakeNotifierObjectType } from "contextApi/AirbrakeNotifierContext"

export const statusListener =
  (pubnubListener: any, pubnub: Pubnub, airbrakeNotifier: AirbrakeNotifierObjectType["airbrakeNotifier"]) =>
  (statusEvent: Pubnub.StatusEvent) => {
    if (statusEvent.category === "PNAccessDeniedCategory") {
      // @ts-ignore
      const channels = statusEvent?.errorData?.payload?.channels

      if (isArray(channels)) {
        pubnub.unsubscribe({ channels })

        airbrakeNotifier.notify({ error: statusEvent })
      }
    }

    return pubnubListener(statusEvent)
  }
