import * as t from "runtypes"
import { iGif } from "./giphy"

export enum MessageType {
  Text = "text",
  Giphy = "giphy",
}

export const textMessage = t.Record({
  type: t.Literal(MessageType.Text),
  senderId: t.String,
  text: t.String,
  photoboothImageUrl: t.String.optional(),
  meta: t
    .Record({
      senderName: t.String.optional(),
    })
    .optional(),
})

export const giphyMessage = t.Record({
  type: t.Literal(MessageType.Giphy),
  senderId: t.String,
  query: t.String,
  gif: iGif,
  meta: t
    .Record({
      senderName: t.String.optional(),
    })
    .optional(),
})

export const appMessage = t.Union(textMessage, giphyMessage)

export type TextMessage = t.Static<typeof textMessage>
export type GiphyMessage = t.Static<typeof giphyMessage>
export type AppMessage = t.Static<typeof appMessage>
