import { Box } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { ChatRoomPanel } from "./ChatRoomPanel"
import { useSelector } from "store/setup/useSelector"
import { openChatRoomsSelector } from "store/domain/openChatRooms"

const Wrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

export const OpenChatRooms: React.FC = () => {
  const openChatRooms = useSelector(openChatRoomsSelector)

  return (
    <Wrapper>
      {openChatRooms.map((openChatRoom) => (
        <ChatRoomPanel key={openChatRoom} channelId={openChatRoom} />
      ))}
    </Wrapper>
  )
}
