import { fetchMemberships as originalFetchMemberships } from "pubnub-redux"

export const fetchMemberships = (uuid: string) =>
  originalFetchMemberships({
    uuid,
    include: {
      channelFields: true,
      customChannelFields: true,
      customFields: false,
      totalCount: false,
    },
  })
