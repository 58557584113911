import React from "react"
import { useDrag } from "react-dnd"
import { CarouselItem, CarouselImage } from "../shared.style"
import { dndItemTypes, StickerItemType } from "../../dndItemInfo"

export const StickerItem: React.FC<{ src: string }> = ({ src }) => {
  // @ts-ignore
  const [, drag] = useDrag<StickerItemType>({
    item: { src, type: dndItemTypes.STICKER },
  })

  return (
    <CarouselItem ref={drag} cursorPointer>
      <CarouselImage src={src} />
    </CarouselItem>
  )
}
