import React from "react"
import { useSelector } from "store/setup/useSelector"
import { getEvent } from "store/domain/event"
import { OnGifSelect } from "./GifInputContent/GifPicker"
import { GifInputContent } from "./GifInputContent"

export const GifInput: React.FC<{ onSelection: OnGifSelect }> = ({ onSelection }) => {
  const { giphyApiKey } = useSelector(getEvent)

  return <> {giphyApiKey && <GifInputContent onSelection={onSelection} />}</>
}
