import React from "react"
import { VideoDaily } from "components/VideoDaily"
import { useParams } from "react-router-dom"
import { useQueryParams } from "hooks/queryParams"
import isString from "lodash/isString"

export const VideoRoom: React.FC = () => {
  const { roomName } = useParams<{ roomName: string }>()

  const token = useQueryParams().t

  return isString(token) ? <VideoDaily showRemainingTime={true} roomName={roomName} token={token} /> : null
}
