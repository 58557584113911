import React, { useCallback, useState } from "react"
import { connectHits } from "react-instantsearch-dom"
import { HitsProvided } from "react-instantsearch-core"
import styled from "styled-components"
import { Box, Button, Card, Dialog, Fab, Grid, Typography, useTheme } from "@material-ui/core"
import { BreakoutHit } from "components/algolia/types"
import PeopleIcon from "@material-ui/icons/People"
import { useSelector } from "store/setup/useSelector"
import { useApi } from "api/useApi"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { VideoDaily } from "components/VideoDaily"
import ClearIcon from "@material-ui/icons/Clear"
import moment from "moment"

const WrapperBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const CardWrapper = styled(Card)`
  margin: ${({ theme }) => theme.spacing(0.88)}px;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.breakpoints.only("xs")} {
    flex: 0 1 calc(100% - 1em);
  }
  ${({ theme }) => theme.breakpoints.only("sm")} {
    flex: 0 1 calc(100% - 1em);
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 0 1 calc(49.9% - 1em);
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex: 0 1 calc(33.3% - 1em);
  }
`

const Image = styled.img`
  width: 100%;
  position: relative;
`

const CardButton = styled(Button)`
  width: 100%;
`

const ImageSection = styled.section`
  display: box;
  display: flex;
  box-align: flex-end;
  align-items: flex-start;
  box-pack: flex-end;
  justify-content: flex-end;
  margin-bottom: 12px;
`

const TypographySecondaryColor = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

const VideoCallWrapper = styled(Box)`
  height: 100%;
  padding: 10px;

  ${({ theme }) => theme.breakpoints.only("xs")} {
    padding: 10px;
  }
`

const VideoCallStyled = styled(VideoDaily)`
  border-radius: 10px;
  overflow: hidden;
`

const DialogTitle = styled(Typography)`
  flex-grow: 1;
  text-align: center;
  color: white;
  line-height: inherit;
`

type OwnProps = { isSearchInProgress: boolean }

type Props = HitsProvided<BreakoutHit> & OwnProps

const SearchedBreakoutsRaw: React.FC<Props> = ({ hits: breakoutHits, isSearchInProgress }) => {
  const [selectedHit, setSelectedHit] = useState<BreakoutHit | null>(null)
  const [videoCallToken, setVideoCallToken] = useState<string | null>(null)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const theme = useTheme()
  const { api } = useApi()

  const handleClickEnter = useCallback(
    async ({ hitArg }: { hitArg: BreakoutHit }) => {
      setSelectedHit(hitArg)

      setVideoCallToken(
        await api.breakout.fetchBreakoutVideoCallToken({
          breakoutUuid: hitArg.uuid,
          attendanceUuid: currentPlatformUser.attendanceUuid,
        }),
      )
    },
    [currentPlatformUser.attendanceUuid, api],
  )

  const handleCloseDialog = useCallback(() => {
    setSelectedHit(null)

    setVideoCallToken(null)
  }, [])

  return (
    <WrapperBox mb={1}>
      {breakoutHits.length === 0 && isSearchInProgress && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography color="secondary" variant="h6">
            No results found
          </Typography>
        </Grid>
      )}

      {breakoutHits.map((currentBreakoutHit) => (
        <CardWrapper key={currentBreakoutHit.uuid}>
          <Box my={1.5} mx={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <Box display="flex" flexDirection="column">
                <Box>{currentBreakoutHit.title}</Box>

                <TypographySecondaryColor variant="caption">
                  {moment(currentBreakoutHit.start_time).local().format("D MMM HH:mm")}&nbsp;-&nbsp;
                  {moment(currentBreakoutHit.end_time).local().format("HH:mm")}
                </TypographySecondaryColor>
              </Box>
            </Typography>

            <TypographySecondaryColor variant="caption">
              <Box display="flex" flexDirection="column" alignItems="center">
                <PeopleIcon />

                <Box textAlign="center">Up to {currentBreakoutHit.max_capacity} attendees</Box>
              </Box>
            </TypographySecondaryColor>
          </Box>

          <ImageSection>
            <Image src={currentBreakoutHit.banner_url} alt="main image" />
          </ImageSection>

          <Box mb={1.5} px={2}>
            <TypographySecondaryColor variant="caption">{currentBreakoutHit.description}</TypographySecondaryColor>
          </Box>

          <Box px={1.5} pb={1.2}>
            <CardButton
              onClick={() => handleClickEnter({ hitArg: currentBreakoutHit })}
              color="primary"
              variant="contained"
            >
              ENTER
            </CardButton>
          </Box>
        </CardWrapper>
      ))}

      <Dialog
        open={!!(videoCallToken && selectedHit)}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: theme.custom.colors.breakout.background,
          },
        }}
      >
        <>
          {videoCallToken && selectedHit && (
            <Box height="100%" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center" pt={1.5} px={1}>
                <DialogTitle variant="h4">
                  <b>{selectedHit.title}</b>
                </DialogTitle>

                <Fab size="small" color="secondary" onClick={handleCloseDialog}>
                  <ClearIcon />
                </Fab>
              </Box>

              <VideoCallWrapper>
                <VideoCallStyled roomName={selectedHit.daily_room_name} token={videoCallToken} />
              </VideoCallWrapper>
            </Box>
          )}
        </>
      </Dialog>
    </WrapperBox>
  )
}

// @ts-ignore
export const SearchedBreakouts: React.FC<OwnProps> = connectHits(SearchedBreakoutsRaw)
