import { useInitialApi } from "api/useInitialApi"
import { useCallback } from "react"
import useEventSetup from "hooks/useEventSetup"

export const useAppLogout = () => {
  const { initialApi } = useInitialApi()
  const eventSetup = useEventSetup()

  return useCallback(async () => {
    try {
      await initialApi.authentication.logout(eventSetup.uuid)

      window.location.reload()
    } catch (e) {
      console.error(e)
    }
  }, [initialApi, eventSetup])
}
