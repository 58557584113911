import React from "react"

export const AppleIcon: React.FC = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.891602" width="52" height="52" rx="26" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4059 16.3916C29.5646 17.455 29.1288 18.4957 28.5542 19.2324C27.9388 20.0223 26.8789 20.6336 25.8552 20.6015C25.6685 19.5836 26.1486 18.5365 26.7302 17.8307C27.3712 17.0525 28.4679 16.4558 29.4059 16.3916ZM32.322 32.064C32.8517 31.259 33.0482 30.853 33.4595 29.9419C30.474 28.8131 29.9939 24.5939 32.9491 22.9739C32.0467 21.8499 30.7808 21.1994 29.5862 21.1994C28.7237 21.1994 28.1329 21.4229 27.5961 21.626C27.1489 21.7952 26.7391 21.9502 26.2407 21.9502C25.7023 21.9502 25.2252 21.7801 24.7256 21.602C24.1762 21.4062 23.5994 21.2006 22.8837 21.2006C21.5408 21.2006 20.1128 22.0155 19.2075 23.4097C17.9341 25.3703 18.1499 29.0593 20.2149 32.2023C20.9534 33.3269 21.9392 34.591 23.2296 34.6027C23.7639 34.6079 24.1196 34.4494 24.5047 34.2778C24.9458 34.0813 25.4254 33.8677 26.2559 33.863C27.0916 33.8583 27.5634 34.0743 27.9988 34.2736C28.3739 34.4453 28.722 34.6046 29.2525 34.5998C30.5428 34.5881 31.5841 33.1881 32.322 32.064Z"
        fill="white"
      />
    </svg>
  )
}
