import React from "react"
import { Box, Grid } from "@material-ui/core"
import styled from "styled-components"
import { AlgoliaSearchInput } from "components/algolia/AlgoliaSearchInput"
import { LoggedInUsersFilter } from "./LoggedInUsersFilter"

const UsersJoinedBox = styled(Box)`
  font-weight: bold;
`

export const SearchPanel: React.FC = () => {
  return (
    <Box mb={2}>
      <Grid container direction="row">
        <Grid item xs={12} sm={7} md={9}>
          <AlgoliaSearchInput label="Find friends/colleagues to connect with" autoFocusTurnedOn />
        </Grid>

        <Grid item sm={5} md={3} container direction="row" alignItems="flex-end" justifyContent="flex-end">
          <UsersJoinedBox ml={2}>
            <LoggedInUsersFilter attribute="logged_in" label="ATTENDEES JOINED" value={true} />
          </UsersJoinedBox>
        </Grid>
      </Grid>
    </Box>
  )
}
