import React from "react"
import { Box, Grid, useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { LobbyStageCard } from "./LobbyStageCard/LobbyStageCard"
import { LobbyStageAgenda } from "./LobbyStageAgenda/LobbyStageAgenda"
import styled from "styled-components"
import { LobbyStageMainCard } from "./LobbyStageMainCard/LobbyStageMainCard"
import { LobbyStageMainCardFirstLogo } from "./LobbyStageMainCard/LobbyStageMainCardFirstLogo"

const Wrapper = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: center;
`

const WrapperGrid = styled(Grid)`
  max-width: 1445px;
  padding: ${({ theme }) => theme.spacing(7, 1, 10)};
  overflow: auto;
`

export const LobbyStagePage: React.FC = () => {
  const theme = useTheme()
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"))

  return (
    <Wrapper>
      <WrapperGrid container>
        {xsUp ? (
          <Grid item container direction="row" xs={12}>
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <Box display="flex" flexDirection="column" height="100%">
                <LobbyStageMainCard />

                <LobbyStageCard />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box display="flex" flexDirection="column" height="100%">
                <LobbyStageAgenda />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box mb={10}>
            <LobbyStageMainCardFirstLogo />

            <LobbyStageAgenda />

            <LobbyStageCard />
          </Box>
        )}
      </WrapperGrid>
    </Wrapper>
  )
}
