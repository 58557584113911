import React from "react"
import { useSelector } from "store/setup/useSelector"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { currentStageDisplayTypeSelector } from "store/domain/currentStageContent/currentStageChannel"

const ButtonStyled = styled(Button)`
  ${({ theme }) => `
    background: ${theme.palette.info.light};
    color: ${theme.palette.info.contrastText};
    text-transform: unset;
  `}
`

type Props = { onClick: () => void }

export const WebinarJoinButton: React.FC<Props> = ({ onClick }) => {
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)

  return (
    <>
      {currentStageDisplayType.webinar && (
        <ButtonStyled size="small" variant="contained" onClick={onClick}>
          Join as Speaker
        </ButtonStyled>
      )}
    </>
  )
}
