import React, { PropsWithChildren } from "react"
import { Carousel as CarouselOriginal, CarouselProps } from "react-responsive-carousel"
import { Button } from "@material-ui/core"
import styled, { css } from "styled-components"
import { transparentize } from "polished"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"

export const arrowCss = css`
  position: absolute;
  z-index: 2;
  top: calc(50% - 13px);
  cursor: pointer;
  min-width: unset;
  background: ${({ theme }) => transparentize(0.4, theme.palette.secondary.main)};
  border-radius: 50%;
  padding: 4px;
`

const ArrowPrevStyled = styled(Button)`
  ${arrowCss}
  left: 10px;
`

const ArrowNextStyled = styled(Button)`
  ${arrowCss}
  right: 10px;
`

const itemsPerPage = 3

type Props<ItemType> = {
  itemList: ItemType[]
  onClickItem?: CarouselProps["onClickItem"]
  ItemComponent: React.FC<{ item: ItemType }>
}

export const Carousel = <ItemType,>({ itemList, onClickItem, ItemComponent }: PropsWithChildren<Props<ItemType>>) => {
  const enoughItemsPerPageForArrows = itemList.length > itemsPerPage

  return (
    <CarouselOriginal
      centerSlidePercentage={100 / itemsPerPage}
      onClickItem={onClickItem}
      centerMode
      showIndicators={false}
      showThumbs={false}
      swipeable={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev) =>
        hasPrev &&
        enoughItemsPerPageForArrows && (
          <ArrowPrevStyled color="secondary" onClick={onClickHandler} variant="contained" size="small">
            <ArrowLeftIcon />
          </ArrowPrevStyled>
        )
      }
      renderArrowNext={(onClickHandler, hasNext) =>
        hasNext &&
        enoughItemsPerPageForArrows && (
          <ArrowNextStyled color="secondary" onClick={onClickHandler} variant="contained" size="small">
            <ArrowRightIcon />
          </ArrowNextStyled>
        )
      }
    >
      {itemList.map((item, index) => (
        <ItemComponent key={index} item={item} />
      ))}
    </CarouselOriginal>
  )
}
