import { DailyRoomInfo } from "@daily-co/daily-js"
import styled from "styled-components"
import React, { useEffect, useState } from "react"
import Countdown from "react-countdown"
import { Tooltip } from "@material-ui/core"

const SpanWrapper = styled.span`
  margin-top: 7px;
  margin-left: 25px;
  position: absolute;
  background: white;
  border-radius: 3px;
  padding: 0 2px;
`

type Props = {
  roomInfo: DailyRoomInfo
}

export const RemainingTime: React.FC<Props> = ({ roomInfo }) => {
  const [expiry, setExpiry] = useState(0)

  useEffect(() => {
    if (roomInfo.config && roomInfo.config.exp) {
      const nowDate = Date.now()

      const expireTime = (roomInfo.config.exp * 1000 || nowDate + 60 * 1000) - nowDate

      setExpiry(Date.now() + expireTime)
    }
  }, [roomInfo])

  return (
    <>
      {expiry > 0 && (
        <Countdown
          date={expiry}
          renderer={({ formatted: { hours, minutes, seconds } }) => (
            <SpanWrapper>
              <Tooltip title="Time left on the call" placement="bottom">
                <span>
                  {hours !== "00" && `${hours}:`}
                  {minutes}:{seconds}
                </span>
              </Tooltip>
            </SpanWrapper>
          )}
        />
      )}
    </>
  )
}
