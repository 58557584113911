import React from "react"
import { connectHits } from "react-instantsearch-dom"
import styled from "styled-components"
import { Box } from "@material-ui/core"
import { UserHit } from "components/algolia/types"
import { HitsProvided } from "react-instantsearch-core"
import { UserScore } from "../../../common/UserScore"
import { transparentize } from "polished"
import { LeaderboardMeta } from "api/apiFunctions/leaderboard"

const WrapperBox = styled(Box)``

const NoResults = styled(Box)`
  text-align: center;
  background: ${({ theme }) => theme.custom.colors.leaderboard.predominant.background};
  color: ${({ theme }) => transparentize(0.5, theme.custom.colors.leaderboard.predominant.color)};
  padding-bottom: 16px;
`

type OwnProps = {
  isSearchInProgress: boolean
  leaderboardMeta: LeaderboardMeta
}

type Props = HitsProvided<UserHit> & OwnProps

const SearchedUsersRaw: React.FC<Props> = ({ hits: userHits, isSearchInProgress, leaderboardMeta }) => {
  return (
    <WrapperBox>
      {userHits
        .sort(
          ({ leaderboard_position: positionA }, { leaderboard_position: positionB }) =>
            (positionA || Infinity) - (positionB || Infinity),
        )
        .map((currentUserHit) => (
          <UserScore
            key={currentUserHit.id}
            username={currentUserHit.username}
            score={currentUserHit.score}
            lastLeaderboardMovement={currentUserHit.last_leaderboard_movement}
            leaderboardPosition={currentUserHit.leaderboard_position}
            isSearchInProgress={isSearchInProgress}
            leaderboardMeta={leaderboardMeta}
            receivedBadgeUuids={currentUserHit.displayable_badges_uuids}
          />
        ))}

      {!userHits.length && isSearchInProgress && <NoResults>No results</NoResults>}
    </WrapperBox>
  )
}

// @ts-ignore
export const SearchedUsers: React.FC<OwnProps> = connectHits(SearchedUsersRaw)
