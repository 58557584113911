import axios, { Method, AxiosRequestConfig, AxiosResponse } from "axios"
import { apiUrls } from "./apiUrls"
import camelize from "camelize"
import isArray from "lodash/isArray"
import isObject from "lodash/isObject"

const request = <ServerResponse, D = unknown>({
  url,
  method,
  data,
  params,
  headers: headersArg,
  responseType,
}: AxiosRequestConfig<D>): Promise<AxiosResponse<ServerResponse, D>> => {
  return axios
    .request<ServerResponse>({
      url,
      method,
      baseURL: apiUrls.baseApiUrl,
      headers: {
        "Content-Type": "application/json",
        ...headersArg,
      },
      data,
      params,
      responseType,
      withCredentials: true,
    })
    .then((response) => {
      const { data } = response

      const shouldCamelize = responseType !== "blob" && (isObject(data) || isArray(data))

      return { ...response, data: shouldCamelize ? camelize(data) : data }
    })
}

const obtainSpecifiedRequest =
  (method: Method) =>
  <ServerResponse>(config: Omit<AxiosRequestConfig, "method">) =>
    request<ServerResponse>({ ...config, method })

export const baseApi = {
  get: obtainSpecifiedRequest("GET"),
  post: obtainSpecifiedRequest("POST"),
  put: obtainSpecifiedRequest("put"),
  patch: obtainSpecifiedRequest("PATCH"),
  delete: obtainSpecifiedRequest("DELETE"),
}
