import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Box, Typography } from "@material-ui/core"
import PeopleIcon from "@material-ui/icons/People"
import { useApi } from "api/useApi"

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`

const TextStyled = styled(Typography)`
  margin-left: 4px;
  font-weight: bold;
`

export const AttendeesPresentCount: React.FC = () => {
  const { api } = useApi()
  const [attendeesCount, setAttendeesCount] = useState<null | number>(null)

  useEffect(() => {
    const innerFn = async () => {
      const attendeesCountFetched = await api.mingle.fetchMingleAttendeesCount()

      setAttendeesCount(attendeesCountFetched)
    }

    innerFn()
  }, [api])

  return (
    <>
      {!!attendeesCount && (
        <Wrapper>
          <PeopleIcon />

          <TextStyled variant="body2">{attendeesCount} ATTENDEE(S) PRESENT</TextStyled>
        </Wrapper>
      )}
    </>
  )
}
