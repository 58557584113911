import React, { useCallback, useState } from "react"
import { Box, Drawer, Typography } from "@material-ui/core"
import styled from "styled-components"
import { useSelector } from "store/setup/useSelector"
import {
  currentStageDisplayTypeSelector,
  getCurrentStageChannel,
} from "store/domain/currentStageContent/currentStageChannel"
import { StageMessages } from "features/assembledBlocks/StageMessages"
import { ReactionsList } from "features/baseBlocks/ReactionsList"
import { PollByStage } from "features/baseBlocks/PollByStage"
import { getCurrentStagePoll } from "store/domain/currentStageContent/currentStageChannel"
import { MobileVideoIframe } from "./MobileVideoIframe"
import { CurrentStageContent } from "features/mainContentBlocks/CurrentStageContent"
import { ContentToShow } from "features/AppTemplate"
import { Expobooth } from "features/baseBlocks/Expobooth"
import { DanceFloorJoinButton } from "features/baseBlocks/DanceFloorJoinButton"
import { DanceFloorDaily } from "features/baseBlocks/DanceFloorDaily"
import { getCurrentPlatformUser } from "store/domain/authentication"
import { WebinarDaily } from "features/baseBlocks/WebinarDaily"
import { WebinarJoinButton } from "features/baseBlocks/WebinarJoinButton"
import { WebinarAdditionalInfoDialog } from "features/assembledBlocks/WebinarAdditionalInfoDialog"
import { DanceFloorAdditionalInfoDialog } from "features/assembledBlocks/DanceFloorAdditionalInfoDialog"
import { useCloseEventAppDialog } from "features/AppTemplate/common/useCloseEventAppDialog"

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const ExpoboothWrapper = styled(Box)`
  padding-top: 6px;
  padding-left: 4px;
  padding-right: 4px;
  height: 100%;
  overflow-y: auto;
`

const DailyWrapper = styled(Box)`
  height: 350px;
`

const ExpoboothStyled = styled(Expobooth)`
  padding-left: 2px;
  padding-right: 2px;
`

const ReactionsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 8px 35px;

  .MuiIconButton-root {
    padding: 10px;

    img {
      width: 27px;
      height: 27px;
    }
  }
`

const PollTrigger = styled(Box)<{ $isHidden: boolean }>`
  background: ${({ theme }) => theme.custom.colors.mobile.poll.trigger.background};
  color: ${({ theme }) => theme.custom.colors.mobile.poll.trigger.color};
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  ${({ $isHidden }) =>
    $isHidden
      ? `
        height: 0;
        padding: 0;
      `
      : ""};
`

const StageMessagesWrapper = styled(Box)`
  flex-grow: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 6px 8px 12px;
`

const PollDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: transparent;
  }
`

const PollWrapper = styled(Box)`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 14px 20px 20px;
  background: ${({ theme }) => theme.custom.colors.mobile.main.background};
  color: ${({ theme }) => theme.custom.colors.mobile.main.color};

  .MuiListItemText-root {
    border-radius: 5px;
    margin: 5px 0;
  }
`

const PollCloseLine = styled(Box)`
  margin: 0 auto 15px;
  width: 50px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.custom.colors.poll.background};
`

const PollQuestionInPoll = styled(Typography)`
  padding: 0 0 12px;
  text-transform: uppercase;
`

export const MobileCurrentStage: React.FC<{ contentToShow: ContentToShow }> = ({ contentToShow }) => {
  const currentStageChannel = useSelector(getCurrentStageChannel)
  const currentStagePoll = useSelector(getCurrentStagePoll)
  const currentStageDisplayType = useSelector(currentStageDisplayTypeSelector)
  const currentPlatformUser = useSelector(getCurrentPlatformUser)
  const [pollOpen, setPollOpen] = useState(false)
  const [userClickedJoinDanceFloor, setUserClickedJoinDanceFloor] = useState(true)
  const [userClickedJoinWebinar, setUserClickedJoinWebinar] = useState(false)
  const closeEventAppDialog = useCloseEventAppDialog()

  const onLeftDanceFloor = useCallback(() => {
    // setUserClickedJoinDanceFloor(false)
    closeEventAppDialog()
  }, [closeEventAppDialog])

  const onLeftWebinar = useCallback(() => {
    setUserClickedJoinWebinar(false)
  }, [])

  return (
    <Wrapper>
      {contentToShow === ContentToShow.Expobooth && (
        <ExpoboothWrapper>
          <ExpoboothStyled />
        </ExpoboothWrapper>
      )}

      {contentToShow === ContentToShow.CurrentStage && (
        <>
          {currentStageDisplayType.iframe || currentStageDisplayType.dancefloor || currentStageDisplayType.webinar ? (
            <>
              {currentStageDisplayType.iframe && <MobileVideoIframe />}

              {currentStageDisplayType.dancefloor && (
                <>
                  {userClickedJoinDanceFloor ? (
                    <DailyWrapper>
                      <DanceFloorDaily onLeftDanceFloor={onLeftDanceFloor} />
                    </DailyWrapper>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="space-between" m={0.5}>
                        <DanceFloorAdditionalInfoDialog />

                        <DanceFloorJoinButton
                          onClickJoinDanceFloor={() => {
                            setUserClickedJoinDanceFloor(true)
                          }}
                        />
                      </Box>

                      <MobileVideoIframe />
                    </>
                  )}
                </>
              )}

              {currentStageDisplayType.webinar && (
                <>
                  {userClickedJoinWebinar ? (
                    <DailyWrapper>
                      <WebinarDaily onLeftWebinar={onLeftWebinar} />
                    </DailyWrapper>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="space-between" m={0.5}>
                        <WebinarAdditionalInfoDialog />

                        {currentPlatformUser.webinarModerator && (
                          <WebinarJoinButton
                            onClick={() => {
                              setUserClickedJoinWebinar(true)
                            }}
                          />
                        )}
                      </Box>

                      <MobileVideoIframe />
                    </>
                  )}
                </>
              )}

              {!currentStageChannel.custom.hide_reactions && (
                <ReactionsWrapper>
                  <ReactionsList />
                </ReactionsWrapper>
              )}

              {currentStagePoll && (
                <Typography component="div">
                  <PollTrigger
                    onClick={() => {
                      setPollOpen(true)
                    }}
                    $isHidden={pollOpen}
                  >
                    <Box>{currentStagePoll.pollQuestion}</Box>

                    <Box ml={0.8}>
                      <b>VOTE</b>
                    </Box>

                    <Box ml={0.8}>
                      <b>&#8250;</b>
                    </Box>
                  </PollTrigger>
                </Typography>
              )}

              {!currentStageChannel.custom.hide_messages && (
                <StageMessagesWrapper>
                  <StageMessages />
                </StageMessagesWrapper>
              )}

              {currentStagePoll && (
                <PollDrawer
                  anchor="bottom"
                  open={pollOpen}
                  onClose={() => {
                    setPollOpen(false)
                  }}
                >
                  <PollWrapper>
                    <PollCloseLine
                      onClick={() => {
                        setPollOpen(false)
                      }}
                    />

                    <PollQuestionInPoll variant="h5">
                      <b>{currentStagePoll.pollQuestion}</b>
                    </PollQuestionInPoll>

                    <PollByStage />
                  </PollWrapper>
                </PollDrawer>
              )}
            </>
          ) : (
            <>
              <CurrentStageContent />
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}
